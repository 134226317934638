import React from "react";
import { KatList } from "@amzn/katal-react";
import { Entity } from "src/service/amplify/appsync";
import EntityItem from "src/components/share_item/EntityItem";
import { ShareOrientation } from "src/components/share_item/ShareItem";

export type SharedWithListProps = {
    orientation?: ShareOrientation;
    permiteeList: Entity[];
    onRemove?: (id: string) => Promise<any>;
};

export default function SharedWithList(props: SharedWithListProps) {
    const { permiteeList, orientation, onRemove } = props;
    
    return (
        <div>
            <KatList
                variant="unordered"
                className={orientation === "horizontal" ? "list-inline" : ""}
            >
                {permiteeList.map((entity: Entity, index: number) => {
                    return (
                        <li
                            key={index}
                            className={`mx-0 ${orientation === "horizontal"? "list-inline-item": ""}`}
                        >
                            <EntityItem
                                key={index}
                                entity={entity}
                                removeHandler={onRemove}
                            />
                        </li>
                    );
                })}
            </KatList>
        </div>
    );
}


const ENVIRONMENT_SETTINGS_SCRIPT_ID: string = "__environment_settings__";

export const DEV_SETTINGS: AxiomConfig.Settings = {
    stage: 'dev',
    region: 'NA',
}

export enum UrlParameterConfigKeys {
    USE_COGNITO = 'ax-cognito',
    FORMS_LOG_TAB = 'ax-forms-tab',
    FORMS_LOG_DATE_RANGE = 'ax-forms-dates',
    HEADLESS_MODE = 'ax-headless',
    FILTER_STATE = 'ax-filter-state',
    FILTER_STRING = 'ax-filter-string',
    FILTER = 'ax-filter',
}


class StaticConfigManager {
    public isInitialized: boolean = false;
    public settings: any = null;

    /**
     * Optionally suppresses console warnings to info in development when SILENCE_METRICS_LOG=true
     * SILENCE_METRICS_LOG: defined in webpack using the env variable
     */
    private warn = SILENCE_METRICS_LOG ? console.info : console.warn;

    /**
     * Loads the settings file from the settings.json loaded in index.html with the identifier "__environment_settings__"
     */
    public async loadSettingsFile(): Promise<any> {
        const settingsScript: HTMLScriptElement | null = <HTMLScriptElement | null>document.getElementById(ENVIRONMENT_SETTINGS_SCRIPT_ID);
        if (!settingsScript) {
            console.error(`Unable to find settings script with id: ${ENVIRONMENT_SETTINGS_SCRIPT_ID}`);
            return Promise.reject();
        }

        console.log(`Importing settings from: ${settingsScript.src}`);
        const moduleUrl = new URL(settingsScript.src);
        // @ts-ignore
        return await fetch(moduleUrl).catch(error => {
            this.warn(`Unable to load settings file from ${settingsScript.src}`, error);
            return Promise.reject();
        });
    }

    public async initialize(): Promise<AxiomConfig.Configuration> {
        if (!this.isInitialized) {
            const settingsResponse = await this.loadSettingsFile();
            this.settings = await settingsResponse.json().catch((error: any) => {
                this.warn(`Unable to read settings.json: ${error}`);
                this.warn("Falling back to default to 'dev' settings.");
                this.settings = DEV_SETTINGS;
            });
            this.isInitialized = true;
        }
        return Promise.resolve(this.getStaticConfig());
    }

    public getStaticConfig(): AxiomConfig.Configuration {
        if (!this.settings) {
            console.warn("Static configuration not initialized, defaulting to 'dev'.");
            this.settings = DEV_SETTINGS;
        }
        const { stage, region } = this.settings;
        const stageConfig = require(`./stages/${stage.toLowerCase()}/${region.toLowerCase()}/config.json`);
        const globalConfig = require(`./globalConfig.json`);
        return { ...stageConfig, ...globalConfig, isProd: stageConfig.stage === 'prod'};
    }

    public isUrlParameterConfigKey(key: string){
        return Object.values(UrlParameterConfigKeys)
            .includes(key as UrlParameterConfigKeys);
    }
}

export const ConfigManager = new StaticConfigManager();
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useStateWithSearchParams } from "src/components/common/searchParameters/useStateWithSearchParams";
import { decodeFromString, encodeToString } from "src/components/common/Encoding";

/** Wraps react typed `useState` to sync with url search params.
 *
 * This is useful primarily to allow deeper linking from external sources
 * and allows users to bookmark certain pages more specifically.
 *
 * Implemented leveraging useStateWithSearchParams implementation by encoding/decoding the typed object into a string.
 * Unlike useSearchParamReader, this hook will update the url parameter named as {key} when the state returned changes.
 *
 * @note Because we use Hash router users will not be able to use the back button to revert through the state changes
 *
 * @argument key: the param key which appears in the URL eg. `?key=value`
 *
 * @argument initialValue?: If the param is not currently in the url this value will be used
 */
export function useStateWithTypedSearchParams<T>(key: string, initialValue: T): [T, Dispatch<SetStateAction<T>>] {

    // delegating the url manipulation to useStateWithSearchParams component
    const [encodedValue, setEncodedValue] = useStateWithSearchParams(key, encodeToString(initialValue));

    // this is the typed state to be exposed
    const [typedValue, setTypedValue] = useState<T>(decodeFromString(encodedValue));

    // when typed value externally changes, sync with encoded value
    useEffect(() => {
        setEncodedValue(encodeToString(typedValue))
    }, [typedValue]);

    // when encoded value changes, sync typedValue with the updated url params
    useEffect(() => {
        setTypedValue(decodeFromString(encodedValue))
    }, [encodedValue]);

    return [typedValue, setTypedValue];
}
import { CerebrumService } from "src/service/CerebrumCoralService";
import { withCheckUserAuthenticated } from "src/wrappers/withCheckUserAuthenticated";


class TenantConfigManager {

    private tenantConfiguration: WFM.GetTenantConfiguration = {
        webAppConfig: {
            flashBriefingConfig: {
                cardSort: [],
                bizmonDefaults: {}
            }
        }
    };

    public async initialize(userLogin: string) {
        this.tenantConfiguration = await CerebrumService.getTenantConfiguration(userLogin);
    }

    public getTenantConfig(): WFM.GetTenantConfiguration {
        return this.tenantConfiguration;
    }
}

/**
 * The Tenant Config manager has an interceptor that will check that User is logged in before running any method.
 */
export const TenantConfig = withCheckUserAuthenticated(new TenantConfigManager());


import React from 'react';
import { AxiomMetricsDriver } from "src/metrics/AxiomMetricsDriver";
import { useHistory } from "react-router-dom";

import {
    MyPerformanceCoachingLog,
    MyPerformanceCoachingLogProps
} from "@amzn/wfm-my-performance-ui-components-react"
import i18n from "i18next";
import { CerebrumService } from 'src/service/CerebrumCoralService';
import { UserManager } from 'src/user/UserIdentityManager';
import { hasAccessToLeaderCoachingLogs } from 'src/components/my_performance/PermissionHelper';
import { defaultTenant } from 'src/config/definitions';

interface MyPerformanceCoachingLogContainerProps { };

const myPerformanceRootPath = "/myp";

export function MyPerformanceCoachingLogContainer(props: React.PropsWithChildren<MyPerformanceCoachingLogContainerProps>) {
    const history = useHistory();

    const myPerformanceProps: MyPerformanceCoachingLogProps = {
        user: UserManager.userIdentity?.login || "",
        metricsDriver: AxiomMetricsDriver,
        coralService: CerebrumService,
        tenantId: UserManager.tenantId || defaultTenant,
        i18n: i18n.t.bind(i18n),
        showLeaderCoachingLogs: hasAccessToLeaderCoachingLogs(UserManager.getUserIdentity()),
        viewDetailsCallback: (formType: string, uniqueId: string) => { history.push(`${myPerformanceRootPath}/${formType}/${uniqueId}`) }
    }
    return (
        <MyPerformanceCoachingLog {...myPerformanceProps} />
    );
}
import React, { Component } from 'react';
import { KatBadge } from '@amzn/katal-react';
import i18n from 'i18next';

enum RadarDatasetBadgeStatusText {
    UPDATED = "wfm_axiom_v2_data_updated", DELAYED_THRESHOLD_WARNING = "wfm_axiom_v2_data_delayed",
    DELAYED_THRESHOLD_DANGER = "wfm_axiom_v2_data_delayed"
}

enum RadarDatasetBadgeStatusClass {
    UPDATED = "axiom-badge-primary cursor-pointer", DELAYED_THRESHOLD_WARNING = "axiom-badge-warning cursor-pointer",
    DELAYED_THRESHOLD_DANGER = "axiom-badge-danger cursor-pointer"
}

interface RadarDatasetDateBadgeProps {
    datasetDate: string;
}

interface RadarDatasetDateBadgeState {
    displayFullData: boolean;
}

export default class RadarDatasetDateBadge extends Component<RadarDatasetDateBadgeProps, RadarDatasetDateBadgeState> {

    dangerThresholdDelayDays = 3;
    warningThresholdDelayDays = 2;
    datasetDateStringId = "wfm_axiom_v2_dataset_date";

    constructor(props: RadarDatasetDateBadgeProps) {
        super(props);

        this.state = {
            displayFullData: true
        };

        this.getLabelText = this.getLabelText.bind(this);
        this.badgeOnClickHandler = this.badgeOnClickHandler.bind(this);
    }

    render() {
        let status = this.getDatasetStatus();

        return (
            // NOTE: Behavior altered to always be gray-angled and show the absolute date
            <KatBadge className='axiom-badge-gray-angled'
                label={this.getLabelText(status)}
                slot="action" type='info' variant='angled' />
        );
    }

    private getDatasetStatus() {
        let status = "UPDATED";
        let currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0); //To not compare hours.

        let datasetDate = this.parseDatasetDate();
        let daysDifference = this.getDifferenceInDays(currentDate, datasetDate);

        if (daysDifference >= this.dangerThresholdDelayDays) {
            status = "DELAYED_THRESHOLD_DANGER";
        }
        else if (daysDifference >= this.warningThresholdDelayDays) {
            status = "DELAYED_THRESHOLD_WARNING";
        }

        return status;
    }

    private getDifferenceInDays(date1: Date, date2: Date) {
        let timeDifference = Math.abs(date1.getTime() - date2.getTime());
        let millisecondsPerDay = 1000 * 60 * 60 * 24;
        let daysDifference = timeDifference / millisecondsPerDay;
        return daysDifference;
    }

    private parseDatasetDate() {
        let datasetDate = new Date();

        if (this.props.datasetDate) {
            //The dataset date comes in this format: 2020-05-26 15:27:00 (hours is optional)
            let dateComponents = this.props.datasetDate.split(' ');
            datasetDate = new Date(dateComponents[0]);
        }

        return datasetDate;
    }

    /*
    * Formatting functions
    * */
    private getClassName(status: string) {
        let className = RadarDatasetBadgeStatusClass[status.toUpperCase() as keyof typeof RadarDatasetBadgeStatusClass];
        return className;
    }

    private getLabelText(status: string) {
        let labelText = "";
        if (this.state.displayFullData) {
            let stringId = this.datasetDateStringId;
            labelText = `${i18n.t(stringId)} ${this.props.datasetDate} PST`;
        } else {
            let stringId = RadarDatasetBadgeStatusText[status.toUpperCase() as keyof typeof RadarDatasetBadgeStatusText];
            labelText = i18n.t(stringId);
        }

        return labelText;
    }

    /*
    * Event Handlers
    * */
    private badgeOnClickHandler() {
        this.setState({ displayFullData: !this.state.displayFullData });
    }
}


import { KatButton, KatDropdown, KatIcon, KatPopover } from "@amzn/katal-react";
import React, { useEffect, useState } from "react";
import './PopoverDropdowns.scss'
import { ClearableDropdown } from "src/components/common/ClearableDropdown";

export interface PopoverDropdownsPropsInput {
    value: string,
    onChange: (event: any) => void,
    label: string,
    options: { name: any, value: any }[],
    clearToValue?: string,
}

interface PopoverDropdownsProps {
    label: string,
    popoverDropdownsInputs: PopoverDropdownsPropsInput[],
}

export const PopoverDropdowns: React.FC<PopoverDropdownsProps> = (props) => {
    const { label, popoverDropdownsInputs } = props;

    const [dropDowns, setDropDowns] = useState<JSX.Element[]>([]);

    function getDropdown(dropDown: PopoverDropdownsPropsInput, i: number) {
        return dropDown.clearToValue ? <ClearableDropdown
            className='mb-2 ml-1 popover-drop-down'
            label={dropDown.label}
            options={dropDown.options}
            value={dropDown.value}
            onUpdate={dropDown.onChange}
            clearToValue={dropDown.clearToValue}
            key={`${dropDown.label}-${i}`}/> :
            <KatDropdown
                className='mb-2 m-1 popover-drop-down'
                label={dropDown.label}
                options={dropDown.options}
                value={dropDown.value}
                onChange={dropDown.onChange}
                key={`${dropDown.label}-${i}`}/>
    }

    function buildDropDowns() {
        const dropDownArray: JSX.Element[] = [];
        for (const [i, dropDown] of popoverDropdownsInputs.entries()) {
            dropDownArray.push(
                getDropdown(dropDown, i)
            )
        }
        setDropDowns(dropDownArray);
    }

    useEffect(() => {
        buildDropDowns();
    }, [popoverDropdownsInputs]);


    return (
        <KatPopover
            className='popover-drop-down flex-grow-0 ml-auto'
            kat-aria-behavior="interactive"
            position="left-start"
            trigger-type="click">
            <KatButton className='task-button' variant='link' slot="trigger">
                <KatIcon className='icon-default' name="filter_list" size="small"/>
                <span className='task-button-label'>{label}</span>
            </KatButton>
            {dropDowns}
        </KatPopover>
    );

}
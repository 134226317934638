import { KatBox } from "@amzn/katal-react";
import React, { PropsWithChildren, useContext } from "react";
import "./Layouts.scss"
import { Sidebar } from "src/components/layout/Sidebar";
import { MainContainer } from "src/components/layout/MainContainer";
import Header from "src/components/common/header/Header";
import { LayoutContext } from "src/components/layout/LayoutContext";

interface RootLayoutProps {
    main: JSX.Element;
    sidebar?: JSX.Element;
    sidebarTitle?: string;
    isItDown?: JSX.Element;
    variant?: KatBox.Variant; // Variant currently optional to minimize changes in old pages
}

/**
 * Root layout for Axiom top level container defining placeholders
 * for isItDown component, main content and a sidebar component
 */
export default function RootLayout(props: RootLayoutProps) {
    const { variant, isItDown, main, sidebar, sidebarTitle } = props;

    const layout = useContext(LayoutContext);

    const Wrapper = (wrapperProps: PropsWithChildren<unknown>) => {
        return variant ?
            <KatBox variant={variant}>{wrapperProps.children}</KatBox> :
            <div>{wrapperProps.children}</div>;
    };

    return <>
        {!layout.isHeadlessMode && <Header/>}
        <Wrapper>
            {isItDown && <div>{isItDown}</div>}
            <div className='d-flex flex-row'>
                <MainContainer>
                    {main}
                </MainContainer>
                {!layout.isHeadlessMode && sidebar &&
                    <Sidebar sidebar={sidebar} title={sidebarTitle}/>}
            </div>
        </Wrapper>
    </>;
}

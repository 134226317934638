import React, { useState } from "react";
import { KatAlert, KatIcon, KatInput, KatInputGroup, KatSpinner, } from "@amzn/katal-react";
import { Entity } from "src/service/amplify/appsync";
import SharedWithList from "src/components/share_item/SharedWithList";
import { AxiomMetricsDriver } from 'src/metrics/AxiomMetricsDriver';

export type ShareOrientation = "vertical" | "horizontal";

export type ShareItemsProps = {
    itemId: string;
    sharedWith?: Entity[];
    orientation?: ShareOrientation;
    addEntityHandlder: (itemId: string, login: string) => Promise<any>;
    removeEntityHandlder: (itemId: string, login: string) => Promise<any>;
};

const PLACE_HOLDER_STRING = "Enter a login";

export default function ShareItems(props: ShareItemsProps) {
    const {
        itemId,
        sharedWith,
        orientation,
        addEntityHandlder,
        removeEntityHandlder,
    } = props;

    const [entityId, setEntityId] = useState("");
    const [loadingAdd, setLoadingAdd] = useState(false);
    const [error, setError] = useState<string>();

    const onInput = (event: any) => {
        if (event.target) {
            setEntityId(event.target.value.trim());
        }
    };

    const onAdd = async (event: React.FormEvent) => {
        event.preventDefault();
        if (entityId.length > 2) {
            AxiomMetricsDriver.publishButtonClick('ShareItem', 'share-item')
            setLoadingAdd(true);
            setError("");

            await addEntityHandlder(itemId, entityId)
                .then(_response => setEntityId(''))
                .catch(error => {
                    setError(error)
                })

            setLoadingAdd(false)
        }
    };

    const onRemove = async (entityId: string) => {
        AxiomMetricsDriver.publishButtonClick('ShareItem', 'un-share-item')
        return await removeEntityHandlder(itemId, entityId)
        .catch(error => setError(error))
    }

    return (
        <div>
            <div className="row mb-1" onSubmit={onAdd}>
                <div className="col">
                    <KatInputGroup>
                        <KatInput
                            label="entity"
                            type="text"
                            placeholder={PLACE_HOLDER_STRING}
                            value={entityId}
                            disabled={loadingAdd}
                            onInput={onInput}
                        ></KatInput>
                        <span style={{ width: "40px" }}>
                            {loadingAdd
                            ? <KatSpinner />
                            : <KatIcon name="plus" size="small" onClick={onAdd}/>}
                        </span>
                    </KatInputGroup>
                </div>
            </div>
            {sharedWith && sharedWith.length > 0 &&
            <SharedWithList
                permiteeList={sharedWith}
                onRemove={onRemove}
                orientation={orientation || "horizontal"}
            />}
            {error && <KatAlert variant="danger" description={error} />}
        </div>
    );
}

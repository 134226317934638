import React from 'react';
import { UserContext } from "src/context/UserContext";
import {
    KatButton,
    KatButtonGroup,
    KatCard,
    KatDropdown,
    KatIcon,
    KatLabel,
    KatLink,
    KatTable,
    KatTableBody,
    KatTableCell,
    KatTableHead,
    KatTableRow
} from '@amzn/katal-react';
import { Treatments, WeblabAssignment, Weblabs } from "src/user/WeblabsManager";
import { Treatment, Weblab } from 'src/user/Weblab';
import { useStateWithLocalStorage } from "src/components/common/storage/UseStateWithLocalStorage";


export default function WeblabTreatmentSelector() {
    const userContext = React.useContext(UserContext);

    const weblabsAssignments: WeblabAssignment[] = Weblabs.getWeblabAssignments();

    return (
        <div className='col-6'>
            <KatCard>
                <span slot="title">Current Weblabs Assignments</span>
                <span slot="subtitle" className="text-size-sm text-secondary">
                    Changes here do not affect allocation in Weblab's page.
                </span>
                <ResetWebLabButton />
                <KatTable>
                    <KatTableHead>
                        <KatTableRow>
                            <KatTableCell>Weblab Name</KatTableCell>
                            <KatTableCell>Treatment Allocation</KatTableCell>
                            <KatTableCell>Dive</KatTableCell>
                        </KatTableRow>
                    </KatTableHead>
                    <KatTableBody>
                        {weblabsAssignments.map(
                            assignment => <TreatmentSelectorRow {...assignment} key={assignment.weblab} />
                        )}
                    </KatTableBody>
                </KatTable>
            </KatCard>
        </div>
    );
}

function TreatmentSelectorRow(weblabAssignment: WeblabAssignment) {
    const userContext = React.useContext(UserContext);

    const [treatment, setTreatment] = useStateWithLocalStorage<string>(weblabAssignment.weblab, '');

    // If initial value is not found in storage the given treatment is used
    if (!treatment) {
        setTreatment(weblabAssignment.treatment);
    }

    React.useEffect(() => {
        Weblabs.overrideTreatment(weblabAssignment.weblab, Treatment[treatment as keyof typeof Treatment]);
        userContext.setUserManager();
    }, [treatment]);

    const updateAssignment = (event: KatDropdown.ChangeEvent) => {
        setTreatment(event.detail.value);
    };

    const dropdownOptions: any[] = [];
    Treatments.forEach(
        treatment => dropdownOptions.push({ name: treatment, value: treatment })
    );

    return (
        <KatTableRow>
            <KatTableCell>
                <KatLabel>{`${weblabAssignment.weblab}`}</KatLabel>
            </KatTableCell>
            <KatTableCell>
                <KatDropdown
                    options={dropdownOptions}
                    value={treatment}
                    onChange={updateAssignment}
                />
            </KatTableCell>
            <KatTableCell>
                <KatLink
                    href={`https://weblab.amazon.com/wl/${weblabAssignment.weblab}`}
                    target='_blank'>
                    <KatIcon
                        name='open_in_new'
                        size='small' />
                </KatLink>
            </KatTableCell>
        </KatTableRow>
    );
}

function ResetWebLabButton() {
    const onClick = () => {
        for (const weblab in Weblab) {
            localStorage.removeItem(Weblab[weblab as keyof typeof Weblab]);
        }
        location.reload();
    };
    return (
        <KatButton label="Reset" slot="action" variant="secondary" onClick={onClick} />
    );
}
import { useState } from "react";

export function useNavigationStack<T>() {
    const [backwardStack] = useState<T[]>([]);
    const [forwardStack, setForwardStack] = useState<T[]>([]);
    const [navigationCurrent, setNavigationCurrent] = useState<T>();
    const [canNavigateBack, setCanNavigateBack] = useState<boolean>(false);
    const [canNavigateForward, setCanNavigateForward] = useState<boolean>(false);

    const goBack = () => {
        if (canNavigateBack) {
            forwardStack.push(navigationCurrent!);
            setNavigationCurrent(backwardStack.pop());
            setCanNavigateBack(backwardStack.length !== 0);
            setCanNavigateForward(true);
        }
    };

    const goForward = () => {
        if (canNavigateForward) {
            backwardStack.push(navigationCurrent!);
            setNavigationCurrent(forwardStack.pop());
            setCanNavigateBack(true);
            setCanNavigateForward(forwardStack.length !== 0);
        }
    };

    const navigationPush = (newCurrent: T) => {
        setForwardStack([]);
        setCanNavigateForward(false);
        if (navigationCurrent) {
            backwardStack.push(navigationCurrent);
            setCanNavigateBack(true);
        }
        setNavigationCurrent(newCurrent);
    };

    return [navigationCurrent, canNavigateBack, canNavigateForward, goBack, goForward, navigationPush] as const;
}

import React from 'react';
import { Nav } from "react-bootstrap";
import { KatIcon, KatPopover } from '@amzn/katal-react';
import { AxiomMetricsDriver } from "src/metrics/AxiomMetricsDriver";

interface HeaderLinkProps {
    /**
     * The user visible string in the header
     */
    name: string;
    href?: string;
    iconName?: KatIcon.Name;
    /**
     * id string that will be used for metrics tracking
     */
    metricsId: string;
    disabled?: boolean;
    hidden?: boolean;
    className?: string;
    newLabel?: boolean;
    onClick?: () => void;
}

export function HeaderLink(props: HeaderLinkProps) {
    const { href, iconName, metricsId, name, disabled, hidden, className, newLabel, onClick } = props;

    const handleClick = () => {
        AxiomMetricsDriver.publishButtonClick('Header', metricsId);
        onClick?.();
    }

    return (
        <Nav.Link onClick={handleClick}
                  href={href}
                  className={`no-underline ${className || ''}`}
                  hidden={hidden}
                  disabled={disabled}>
            {iconName && <KatIcon name={iconName} size='tiny'/>}
            {' ' + name}
            {newLabel && <label className='new-label'>NEW</label>}
        </Nav.Link>
    );
}

interface HeaderIconProps {
    /**
     * The user visible string in popover
     */
    name: string;
    href?: string;
    iconName: KatIcon.Name;
    /**
     * id string that will be used for metrics tracking
     */
    metricsId: string;
    hidden?: boolean;
    className?: string;
}

export function HeaderIcon(props: HeaderIconProps) {
    const { href, iconName, metricsId, name, hidden, className } = props;
    return (
        <KatPopover katAriaBehavior="tooltip" position="bottom" triggerType="hover">
            <a className={className}
               slot="trigger"
               href={href}
               onClick={() => AxiomMetricsDriver.publishButtonClick('Header', metricsId)}
               hidden={hidden}
            >
                <KatIcon name={iconName} size="small"/>
            </a>
            <a href={href}
               onClick={() => AxiomMetricsDriver.publishButtonClick('Header', metricsId)}
               hidden={hidden}
            >{name}</a>
        </KatPopover>
    );
}
import React, { FC, useEffect, useState } from "react";
import { CerebrumService } from "src/service/CerebrumCoralService";
import { TemplateContent } from "@amzn/wfm-template-parser";
import { loadAxiomImages } from "src/components/common/AxiomImageHelper";

interface NarrativeTemplateBannerProps {
    entityId: string;
    componentName: string;
    templateName: string;
}

export const NarrativeTemplateBanner: FC<NarrativeTemplateBannerProps> = (props) => {

    const { entityId, templateName, componentName } = props;

    const [templateContent, setTemplateContent] = useState('');

    const buildGetNarrativeTemplateInput = () : Cerebrum.GetNarrativeTemplateInput => {
        return {
            templateName,
            locale: localStorage.getItem('i18nextLng') || undefined,
            component: componentName,
            entityId
        };
    }

    const retrieveTemplate = async ()  => {
        const input = buildGetNarrativeTemplateInput();
        await CerebrumService.getNarrativeTemplate(input)
            .then(response => loadAxiomImages(response.content))
            .then(response => setTemplateContent(response))
            // tslint:disable-next-line:no-console
            .catch(error => console.log(`Unable to retrieve narrative template ${templateName}: ${JSON.stringify(error)}`));
    }

    useEffect(() => {retrieveTemplate()}, [])

    return (
        <>{templateContent &&
        <TemplateContent template={templateContent} data={{}}/>
        }</>
    );
}

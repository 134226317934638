import React, { FunctionComponent } from 'react';
import { ComponentID, ComponentRightsManager } from "src/components/authorization/ComponentRightsMapping";
import { UserManager } from "src/user/UserIdentityManager";
import { NoAccessErrorMessage } from 'src/components/error/NoAccessErrorMessage';
import { Treatment, Weblab } from "src/user/Weblab";
import { Weblabs } from "src/user/WeblabsManager";

const isAccessAllowedByPermissions = (componentId: ComponentID): boolean => {
    const isComponentForInHouse = ComponentRightsManager.isComponentForInHouse(componentId);
    const isComponentForOutsource = ComponentRightsManager.isComponentForOutsource(componentId);
    const isComponentHidden = ComponentRightsManager.isComponentHidden(componentId);
    const hasAccessRightsForComponent = ComponentRightsManager.userHasAccessRightsForComponent(componentId);

    let userHasAccessToComponent = true;
    if (!hasAccessRightsForComponent) { userHasAccessToComponent = false; }
    if (isComponentForInHouse && UserManager.isOutSourced) { userHasAccessToComponent = false; }
    if (isComponentForOutsource && !UserManager.isOutSourced) { userHasAccessToComponent = false; }
    if (isComponentHidden) { userHasAccessToComponent = false; }
    if (UserManager.canViewHiddenContent) { userHasAccessToComponent = true; }

    return userHasAccessToComponent;
}

const isAccessAllowedByWeblab = (weblab?: Weblab, allowedTreatment?: Treatment) => {
    if (weblab && allowedTreatment) {
        return Weblabs.isUserInWeblabAndTreatment(weblab, allowedTreatment);
    }
    return true; // Always allow if weblab or treatment are not defined
}

export const isComponentAccessAllowed = (componentId: ComponentID, weblab?: Weblab, allowedTreatment?: Treatment) => {
    return isAccessAllowedByPermissions(componentId) && isAccessAllowedByWeblab(weblab, allowedTreatment);
}

interface UserRestrictedComponentProps {
    componentId: ComponentID;
    weblab?: Weblab;
    weblabTreatmentAllowed?: Treatment;
    showNoAccessError?: boolean;
    customFallbackComponent?: React.Component;
}

export const UserRestrictedComponent: FunctionComponent<UserRestrictedComponentProps> = (props) => {
    const { componentId, showNoAccessError, customFallbackComponent, weblab, weblabTreatmentAllowed } = props;
    const fallbackContent = customFallbackComponent || showNoAccessError ? <NoAccessErrorMessage {...props} /> : undefined;
    return (
        <>
            { isComponentAccessAllowed(componentId, weblab, weblabTreatmentAllowed) ? props.children : fallbackContent }
        </>
    );
}


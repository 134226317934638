import React from "react";
import i18n from "i18next";
import { CardOptionsMenu } from "src/components/common/CardOptionsMenu";
import DropdownItem from "react-bootstrap/DropdownItem";
import { KatDivider } from "@amzn/katal-react";
import { AxiomMetricsDriver } from "src/metrics/AxiomMetricsDriver";
import { logButtonClick } from "src/components/tasks/task_log_table/utils";

type TaskActionsMenuProps = {
    task: Cerebrum.Tasks.Task;
    handleError: Function;
    updateTaskList: Function;
    setShowMetadata: Function;
    setShowEditTaskModal: Function;
    deleteTask: Function;
    showFeedbackModal: Function;
};

const COMPONENT_NAME = "TaskTableOption";

const TaskActionsMenu = (props: TaskActionsMenuProps) => {
    const { task, setShowMetadata, setShowEditTaskModal, showFeedbackModal, deleteTask } = props;
    const additionalMetrics = [{ name: "taskId", value: task.id }];

    const onShowMetadata = () => {
        setShowMetadata(task);
        logButtonClick(COMPONENT_NAME, "showMetadata", additionalMetrics);
    };

    const onDeleteTask = () => {
        deleteTask(task);
        AxiomMetricsDriver.publishButtonClick(
            COMPONENT_NAME,
            "deleteTask",
            additionalMetrics
        );
    };

    const onEditTask = () => {
        setShowEditTaskModal(task);
    };

    const onShowFeedback = () => {
        showFeedbackModal(task);
    };

    return (
        <CardOptionsMenu>
            <DropdownItem as="button" onClick={onEditTask}>
                {i18n.t("wfm_axiom_tasks_edit_task")}
            </DropdownItem>
            <DropdownItem as="button" onClick={onDeleteTask}>
                {i18n.t("wfm_axiom_tasks_delete_task")}
            </DropdownItem>
            <KatDivider variant="athens" />
            <DropdownItem as="button" onClick={onShowFeedback}>
                {i18n.t("wfm_axiom_fb_feedback_card_title")}
            </DropdownItem>
            <DropdownItem as="button" onClick={onShowMetadata}>
                <span className="text-muted">Show Metadata</span>
            </DropdownItem>
        </CardOptionsMenu>
    );
};

export { TaskActionsMenu };

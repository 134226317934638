import React, { Component } from 'react';
import { BadgeProps } from 'react-bootstrap';
import { KatBadge } from '@amzn/katal-react';
import { VALUE_SHOWN_METRICS } from 'src/components/balanced_scorecard/BSC';


type ScoreFormat = {
    thresholdValue: number;
    badgeClassName: BadgeProps["variant"];
};

// TEMP: Implement tenant configuration
const TENANT_SCORE_FORMAT_CONFIG: ScoreFormat[] = [
    { thresholdValue: -Infinity, badgeClassName: 'danger' },
    { thresholdValue: 40, badgeClassName: 'warning' },
    { thresholdValue: 46, badgeClassName: 'success' },
    { thresholdValue: 59, badgeClassName: 'primary' },
];

const VALUE_SHOWN_METRIC_CONFIG: ScoreFormat[] = [
    { thresholdValue: -Infinity, badgeClassName: 'danger' },
    { thresholdValue: -1.5, badgeClassName: 'warning' },
    { thresholdValue: 0, badgeClassName: 'success' },
    { thresholdValue: 1, badgeClassName: 'primary' },
];

const DEFAULT_CLASS = 'light';

function getClassNameFromScoreFormatConfig(config: ScoreFormat[], score: number) {
    return config.reduce((badgeClassName: BadgeProps["variant"], scoreFormat): BadgeProps["variant"] => {
        return score >= scoreFormat.thresholdValue ? scoreFormat.badgeClassName : badgeClassName;
    }, DEFAULT_CLASS);
}

function getFormattedBscScorebadgeClassName(score: number) {
    return getClassNameFromScoreFormatConfig(TENANT_SCORE_FORMAT_CONFIG, score);
}

function getValueShownMetricClassName(score: number): BadgeProps["variant"] {
    return getClassNameFromScoreFormatConfig(VALUE_SHOWN_METRIC_CONFIG, score);
}


interface BscScoreFormatterProps {
    score: number;
    className?: string;
}

export function BscScoreBadge(props: BscScoreFormatterProps) {
    const { score, className } = props;
    return (
        <KatBadge className={`axiom-badge-${getFormattedBscScorebadgeClassName(score)} ${className || ''}`}
            type="info"
            label={score.toString()}
        />
    );
}

export function BscScoreSpan(props: BscScoreFormatterProps) {
    const { score, className } = props;
    return (
        <span className={`score-span text-${getFormattedBscScorebadgeClassName(score)} ${className || ''}`}>{score}</span>
    );
}

// TODO: Remove this section in next CR with Quality metrics changes
export function ValueShownBscScoreSpan(props: BscScoreFormatterProps & BscScoreTableSpanProps) {
    const { score, className, metric } = props;
    // Number of decimal places to display for the score impact value
    const METRIC_IMPACT_DECIMALS = 1;
    // Number of decimal places to display for the actual metric value
    const METRIC_VALUE_DECIMALS = 0;
    // Unit for the metric value
    const METRIC_VALUE_UNITS = '%';

    // multiplies by 100 to show proper % value, 
    const formattedValue = (Number(metric.value) * 100).toFixed(METRIC_VALUE_DECIMALS) + METRIC_VALUE_UNITS;

    // prepends `+` to positive values
    const formattedScore = (score < 0 ? "" : "+") + score.toFixed(METRIC_IMPACT_DECIMALS);

    return (
        <>
            <span className={`score-span text-${getValueShownMetricClassName(score)} ${className || ''}`}>{formattedValue}</span>
            <span className={'text-gray-dark'}> ( {formattedScore} )</span>
        </>
    );
}

interface BscScoreTableSpanProps {
    metric: Cerebrum.BalancedScorecardMetric;
    value: string;
    className?: string;
}

/** Displays metrics scores for table using the corresponding TableSpanComponent based on the metric type */
export function BscScoreTableSpan(props: BscScoreTableSpanProps) {
    const { metric } = props;
    const score = Number(metric.score);
    const TableSpanComponent = VALUE_SHOWN_METRICS.includes(metric.name) ? ValueShownBscScoreSpan : BscScoreSpan;
    return <TableSpanComponent {...props} score={score} />;
}

/** Displays formatted metric value for the BSC table */
export function ValueShownSpan(props: BscScoreTableSpanProps) {
    const { className, metric, value } = props;
    const formattedValue = formatValueShown(metric, value!);
    return (
        <>
            <span className={`score-span ${className || ''}`}>{formattedValue}</span>
        </>
    );
}

function formatValueShown(metric: Cerebrum.BalancedScorecardMetric, value: string) {
    // Number of decimal places to display for the actual metric value
    const METRIC_VALUE_DECIMALS = 1;
    // Unit for the metric value
    const METRIC_VALUE_UNITS = { 'acht': ' mins', 'rate': '%' };

    // multiplies by 100 to show proper % value unless its acht,
    return metric['name'] == 'acht'
        ? Number(value).toFixed(METRIC_VALUE_DECIMALS) + METRIC_VALUE_UNITS['acht']
        : (Number(value) * 100).toFixed(METRIC_VALUE_DECIMALS) + METRIC_VALUE_UNITS['rate'];
}

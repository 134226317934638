import React, {Dispatch, SetStateAction} from "react";
import {useStateWithStorage} from "src/components/common/storage/UseStateWithStorage";

/** Custom hook to expand React's typed `useState` by saving values in sessionStorage.
 *
 * @argument sessionStorageKey: the name of the parameter to store
 *
 * @argument initialValue?: If the param is not currently in sessionStorage the initialValue will be used
 */

export function useStateWithSessionStorage<T>(sessionStorageKey: string, initialValue: T): [T, Dispatch<SetStateAction<T>>] {
    return useStateWithStorage<T>(sessionStorageKey, initialValue, window.sessionStorage);
}


import React from 'react';
import { AxiomMetricsDriver } from "src/metrics/AxiomMetricsDriver";
import { useHistory } from "react-router-dom";
import i18n from "i18next";

import {
    MyPerformanceNav,
    MyPerformanceNavProps
} from "@amzn/wfm-my-performance-ui-components-react"
import { getPermissionsForNav } from './PermissionHelper';
import { UserManager } from 'src/user/UserIdentityManager';

interface MyPerformanceNavContainerProps { };

export function MyPerformanceNavContainer(props: React.PropsWithChildren<MyPerformanceNavContainerProps>) {
    const history = useHistory();

    const myPerformanceProps: MyPerformanceNavProps = {
        metricsDriver: AxiomMetricsDriver,
        createFeedbackFunction: () => { history.push("/myp/feedback") },
        createCoachingNoteFunction: () => { history.push("/myp/coaching-note") },
        createLeaderCoachingFunction: () => { history.push("/myp/leader-coaching") },
        goToCoachingLog: () => { history.push("/myp/log") },
        goToDashboard: () => { history.push("/myp") },
        userPermissions: getPermissionsForNav(UserManager.getUserIdentity()),
        i18n: i18n.t.bind(i18n)
    }
    return (
        <div className="container-fluid row mt-3">
            <div className="col-3">
                <MyPerformanceNav {...myPerformanceProps} />
            </div>
            <div className="col-9">
                {props.children}
            </div>
        </div>
    )
}
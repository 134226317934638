import { useEffect, useState } from 'react';

const STATIC_DATA_EVENT_TYPE = 'ax-forms-static-data';

export function useFormStaticDataFromHostingApp() {
  const [staticData, setStaticData] = useState<any>();

  useEffect(() => {
    const handleStaticDataResponse = (event: MessageEvent) => {
      if (event.data && event.data.type === STATIC_DATA_EVENT_TYPE) {
        setStaticData(event.data);
      }
    };
    function registerListenerForStaticDataResponse() {
      window.addEventListener('message', handleStaticDataResponse);
    };

    function sendStaticDataRequest() {
      parent.postMessage({ type: STATIC_DATA_EVENT_TYPE }, '*');
    };

    registerListenerForStaticDataResponse();
    sendStaticDataRequest();

    return () => {
      window.removeEventListener('message', handleStaticDataResponse);
    };
  }, []);
  return staticData;
}

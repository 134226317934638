import React from "react";
import { Header, HeaderProps } from "@amzn/awsui-components-react";
import { HeaderAction, HeaderConfig, HeaderCustomAction, HeaderNativeAction } from "src/@types/extendedPolarisTable";
import { KatButton } from "@amzn/katal-react";

const generateActionButtons = (actions: HeaderAction[]) => {
    return actions.map((action, index) => {
        const { type } = action;
        switch (type) {
            case "button":
                const { label, handler, disabled } = action as HeaderNativeAction;
                return (
                    <KatButton
                        label={label}
                        key={`action-button-${index}`}
                        onClick={handler}
                        disabled={disabled}
                    ></KatButton>
                );
            case "custom":
                const { customComponent } = action as HeaderCustomAction;
                return customComponent;
        }
    });
};

export const TableHeader = (props: HeaderConfig) => {
    const { variant, description, actions, title, counter } = props;
    return (
        <Header
            variant={variant as HeaderProps.Variant}
            description={description}
            actions={actions ? generateActionButtons(actions) : []}
            counter={counter}
        >
            {title}
        </Header>
    );
};

interface EventListener {
    id: string;
    callback: (payload: any) => void;
}

export function asEventListener(id: string, callback: (payload: any) => void): EventListener {
    return {
        id,
        callback
    }
}

const EventEmitter = {
    listenersByEvent: new Map<string, Map<string, EventListener>>(),

    dispatch(event: string, data: any) {
        const listeners = this.listenersByEvent.get(event);

        listeners?.forEach((listener: EventListener) => {
            listener.callback(data);
        });
    },

    subscribe(event: string, listener: EventListener) {
        if (!this.listenersByEvent.has(event)) {  // new event
            this.listenersByEvent.set(event, new Map<string, EventListener>());
        }
        this.listenersByEvent.get(event)?.set(listener.id, listener);
    },

    unSubscribe(event: string, key: string) {
        const listeners = this.listenersByEvent.get(event);
        listeners?.delete(key);
    }
}

export default EventEmitter;

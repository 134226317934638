import React, { useCallback, useState } from "react";
import {
    CollectionPreferencesProps,
    NonCancelableCustomEvent,
    Pagination,
    PropertyFilter,
    PropertyFilterProps,
    Table,
    TableProps,
    TextFilter
} from "@amzn/awsui-components-react";
import { useCollection, PropertyFilterOperator } from "@amzn/awsui-collection-hooks";
import { TableHeader } from "src/components/polaris_table/TableHeader";
import EmptyTable from "src/components/polaris_table/EmptyTable";
import Filters from "src/components/polaris_table/Filters";
import { ExtendedColumnDefinition, FilterConfig, GenericObject, HeaderConfig, PolarisTableUniqueLocalStorageKeys } from "src/@types/extendedPolarisTable";
import { useStateWithSearchParams } from "src/components/common/searchParameters/useStateWithSearchParams";
import { UrlParameterConfigKeys } from "src/config/ConfigManager";
import { Preferences } from "src/components/polaris_table/Preferences";
import { useStateWithLocalStorage } from "src/components/common/storage/UseStateWithLocalStorage";

interface ExtendedPolarisTableProps<T> {
    itemList: T[];
    localStorageUniqueKey: PolarisTableUniqueLocalStorageKeys;
    filterConfigs: FilterConfig[] | [];
    customFilter?: JSX.Element;
    multiSelect?: boolean | undefined;
    onSelectedItemsChange?: Function;
    columnDefinitions: ExtendedColumnDefinition<T>[];
    defaultSortingColumn: number;
    selectionType?: string;
    pageSize?: number;
    headerConfig?: HeaderConfig;
    includeSearchByText?: boolean | undefined;
    trackBy: string;
    isItemDisabled?: (item: T) => boolean;
    resizableColumns?: boolean;
    wrapLines?: boolean;
    emptyTable: JSX.Element;
    noMatchTable?: JSX.Element;
    loading?: boolean;
    loadingText?: string;
    includePropertyFilter?: boolean;
    showPreferencesButton?: boolean;
}

const initState = (filterConfigs: FilterConfig[]) => {
    return filterConfigs.reduce((acc: GenericObject, config: FilterConfig) => {
        const { attributeName, defaultSelectedValue } = config;

        if (!acc[attributeName]) {
            acc[attributeName] = defaultSelectedValue;
        }

        return acc;
    }, {});
};

const matchesFilters = (item: any, filterConfigs: FilterConfig[], filterState: GenericObject) => {
    for (const config of filterConfigs) {
        const { attributeName, defaultSelectedValue, attributeGetter } = config;
        const itemValue = attributeGetter ? attributeGetter(item) : item[attributeName];
        const filterValue = filterState[attributeName];

        if (!(filterValue === defaultSelectedValue || filterValue === itemValue)) {
            return false;
        }
    }

    return true;
};

const DEFAULT_OPERATORS: PropertyFilterOperator[] = [':', '!:', '=', '!=']
const PREFERENCES_KEY = 'awsui-polaris-table-preferences';

export const buildPropertyFilteringList = <T,>(columnDefinitions: ExtendedColumnDefinition<T>[]) => {
    const filteringProperties = columnDefinitions.reduce((acc: any[], col: ExtendedColumnDefinition<T>) => {
        if (col.isSearchable) {
            acc.push({
                propertyLabel: col.header,
                key: col.id,
                groupValuesLabel: `{${col.header} values}`,
                operators: col.operators ?? DEFAULT_OPERATORS
            });
        }
        return acc;
    }, [])

    return filteringProperties
}

const buildContentDisplayOptions = (columnDefinitions: ExtendedColumnDefinition<any>[]) => {
    const contentDisplayOptions = columnDefinitions.map((columnDefinition) => {
        return {
            id: columnDefinition.id as string,
            label: columnDefinition.header as string
        }
    })

    return contentDisplayOptions
}

const buildDefaultPreferences = (columnDefinitions: ExtendedColumnDefinition<any>[]) => {
    const contentDisplay = columnDefinitions.map((columnDefinition) => {
        return {
            id: columnDefinition.id,
            visible: !columnDefinition.hiddenByDefault
        }
    })

    return {
        contentDisplay: contentDisplay,
        pageSize: 20,
        wrapLines: true,
        stripedRows: false,
        contentDensity: ('comfortable' as CollectionPreferencesProps.Preferences['contentDensity']),
        stickyColumns: { first: 0, last: 0 }
    }
}

export default function ExtendedPolarisTable<T>(props: ExtendedPolarisTableProps<T>) {
    const {
        itemList,
        localStorageUniqueKey,
        filterConfigs,
        customFilter,
        columnDefinitions,
        defaultSortingColumn,
        pageSize,
        selectionType,
        onSelectedItemsChange,
        headerConfig,
        includeSearchByText,
        trackBy,
        resizableColumns,
        isItemDisabled,
        wrapLines,
        emptyTable,
        noMatchTable,
        loading,
        loadingText,
        includePropertyFilter,
        showPreferencesButton
    } = props;


    const initialFilterState = initState(filterConfigs);
    const contentDisplayOptions = buildContentDisplayOptions(columnDefinitions)
    const defaultPreferences = buildDefaultPreferences(columnDefinitions);
    const [filterState, setFilterState] = useStateWithSearchParams(UrlParameterConfigKeys.FILTER_STATE, JSON.stringify(initialFilterState));
    const [filterStringParam, setFilterStringParam] = useStateWithSearchParams(UrlParameterConfigKeys.FILTER_STRING,'');
    const [selectedItems, setSelectedItems] = useState<any>([]);
    const [preferences, setPreferences] = useStateWithLocalStorage(
        `$${localStorageUniqueKey}-${PREFERENCES_KEY}`, defaultPreferences);
    // TODO Correct event typing
    const handleFilterStateChange = (event: any) => {
        const attributeName = event.target.getAttribute("data-attribute-name");
        setFilterState(JSON.stringify({ ...(getFilterState()), [attributeName]: event.detail.value }));
    };
    const getFilterState = () => { return JSON.parse(filterState || ''); }

    const emptyTableComponent = <EmptyTable title="No matches" subtitle="We can’t find a match." />

    const { items, collectionProps, filterProps, paginationProps, propertyFilterProps } =
        useCollection(itemList, {
            filtering: {
                empty: emptyTable,
                noMatch: (
                    noMatchTable || emptyTableComponent
                ),
                filteringFunction: (item: any) => {
                    const filteringText = filterStringParam || '';

                    if (!matchesFilters(item, filterConfigs, getFilterState())) {
                        return false;
                    }

                    const filteringTextLowerCase = filteringText.toLowerCase();

                    return columnDefinitions.some(definition => {
                        if (!definition.isSearchable) {
                            return false;
                        }

                        const value = definition.getter ? definition.getter(item) : item[definition.id];

                        return value.toLowerCase().indexOf(filteringTextLowerCase) > -1;
                    })
                },
            },
            sorting: {
                defaultState: {
                    sortingColumn: columnDefinitions[defaultSortingColumn || 0],
                },
            },
            pagination: {
                pageSize: pageSize ?? preferences.pageSize,
            },
            selection: {},
            ...(includePropertyFilter
                ?   {
                        propertyFiltering: {
                            filteringProperties: buildPropertyFilteringList(columnDefinitions)
                        }
                    }
                :   {})
        });

    const onSelectionChange = useCallback((event: NonCancelableCustomEvent<TableProps.SelectionChangeDetail<any>>) => {
            setSelectedItems(event.detail.selectedItems);
            if (onSelectedItemsChange) {
                onSelectedItemsChange(event.detail.selectedItems);
            }
        },
        [collectionProps, onSelectedItemsChange]
    );

    const additionalConfigs: GenericObject = {};
    if (selectionType) {
        additionalConfigs.selectionType = selectionType;
    }

    const propertyFilterStrings: PropertyFilterProps.I18nStrings = {
        filteringAriaLabel: 'Filter',
        clearAriaLabel: 'Clear filters',
        clearFiltersText: 'Clear filters',
        filteringPlaceholder: 'Type and hit enter to filter results',
        operatorEqualsText: 'Equals',
        operatorDoesNotEqualText: 'Is Different',
        operatorContainsText: 'Includes',
        operatorDoesNotContainText: "Doesn't include",
        operationAndText: 'And',
        operationOrText: 'Or'
    }
    
    const shrinkSearchBox = filterConfigs.length > 0

    return (
        <Table
            {...collectionProps}
            items={items}
            columnDefinitions={columnDefinitions}
            selectedItems={selectedItems}
            onSelectionChange={onSelectionChange}
            header={
                headerConfig &&
                <TableHeader
                    title={headerConfig.title}
                    variant={headerConfig.variant}
                    description={headerConfig.description}
                    actions={headerConfig.actions}
                    counter={headerConfig.counter}
                />
            }
            filter={
                <div className={`${shrinkSearchBox ? "d-lg-flex flex-wrap" : ''}`}>
                    {includeSearchByText && !includePropertyFilter &&
                        <div className="mr-3 mb-sm-1">
                            <TextFilter
                                {...filterProps}
                                filteringText={filterStringParam || ''}
                                onChange={({ detail }) =>
                                    setFilterStringParam(detail.filteringText)
                                }
                                filteringAriaLabel="Filter items"
                            />
                        </div>}
                    {includePropertyFilter &&
                        <PropertyFilter
                            {...propertyFilterProps}
                            i18nStrings={propertyFilterStrings}
                            filteringEmpty={emptyTable}
                        />}
                    <Filters
                        items={itemList}
                        filterConfigs={filterConfigs ? filterConfigs : []}
                        eventHandler={handleFilterStateChange}
                        state={getFilterState()}
                    />
                    {customFilter}
                </div>
            }
            pagination={<Pagination {...paginationProps} />}
            variant="full-page"
            trackBy={trackBy}
            isItemDisabled={isItemDisabled}
            resizableColumns={resizableColumns}
            wrapLines={preferences.wrapLines}
            loading={loading}
            loadingText={loadingText}
            preferences={
                showPreferencesButton &&
                <Preferences
                    contentDisplayOptions={contentDisplayOptions}
                    preferences={preferences}
                    setPreferences={setPreferences}
                />
            }
            columnDisplay={preferences.contentDisplay}
            stripedRows={preferences.stripedRows}
            contentDensity={preferences.contentDensity}
            stickyColumns={preferences.stickyColumns}
            empty={emptyTable}
            {...additionalConfigs}
        />
    );
}

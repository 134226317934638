export enum TaskEventName {
    EDIT_TASK_EVENT = 'edit-task-event',
    TASK_CREATED_EVENT = 'task-created-event',
    TASK_UPDATED_EVENT = 'task-updated-event'
}

export interface TaskEvent<Type> {
    name: TaskEventName;
    payload: Type;
}

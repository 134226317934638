import React from 'react';
import { MyPerformanceAssociatesLandingPageContainer } from './MyPerformanceAssociatesLandingPage';
import { MyPerformanceSupervisorLandingPageContainer } from './MyPerformanceSupervisorsLandingPage';
import { MyPerformanceManagerLandingPageContainer } from './MyPerformanceManagerLandingPage';
import { UserManager } from "src/user/UserIdentityManager";
import { KatAlert } from '@amzn/katal-react';
import i18n from "i18next";
import { AxiomRight } from "src/config/AxiomCSRights";

interface MyPerformanceHomeContainerProps {
    viewUserId: string
};

type RightToComponentList = {
    right: keyof WFM.AxiomAccessRights,
    component: React.FC
}[];

const rightToComponentList: RightToComponentList = [
    {
        right: AxiomRight.MANAGER_VIEW,
        component: MyPerformanceManagerLandingPageContainer
    },
    {
        right: AxiomRight.SUPERVISOR_VIEW,
        component: MyPerformanceSupervisorLandingPageContainer
    },
    {
        right: AxiomRight.BASIC_VIEW,
        component: MyPerformanceAssociatesLandingPageContainer
    }
];

function findComponent(userRights: WFM.AxiomAccessRights, rightToComponentMap: RightToComponentList): React.FC | undefined {
    const rightToComponent = rightToComponentList.find(rightToComponent => userRights[rightToComponent.right])
    return rightToComponent?.component;
};

export function MyPerformanceHomeContainer(props: MyPerformanceHomeContainerProps) {
    const LandingComponent = findComponent(UserManager.getUserIdentity().accessRights, rightToComponentList)
    return LandingComponent
        ? <LandingComponent />
        : < KatAlert
            header={i18n.t("wfm_axiom_no_access_alert_title")}
            variant="danger">
            {i18n.t("wfm_axiom_no_access_alert_body", { 0: ['axiom.supervisor.view', 'axiom.basic.view'] })}
        </KatAlert>
};
import React from "react";
import { Trans } from "react-i18next";
import i18n from "i18next";
import { HeaderRoute } from "src/components/common/header/HeaderRoute";
import { KatLink } from "@amzn/katal-react";
import { AxiomMetricsDriver } from "src/metrics/AxiomMetricsDriver";

export function BSCNotFoundErrorMessage() {

    const COMPONENT_NAME = 'BSCNotFoundErrorMessage';

    const faqLink = (isAuxClick: boolean) => () => {
        const route = `${HeaderRoute.HELP}/FAQ`;
        AxiomMetricsDriver.publishLinkClick(COMPONENT_NAME, 'faqLink', route, undefined, isAuxClick);
        window.open(`#${route}`); // open in new tab
    };

    return <div>
        <Trans i18nKey="wfm_axiom_bsc_not_found_error_message">
            We couldn't find any records for the specified agent in Cerebrum. There are multiple possible causes
            which are outlined in the <b>"Data Not Found" Error?</b> section on
            our
            <KatLink onClick={faqLink(false)}
                     // @ts-ignore
                     onAuxClick={faqLink(true)}>
                FAQ help page
            </KatLink>
        </Trans>
    </div>;
}

export interface BSCOpenTicketMessageProps {
    supervisor: boolean;
}

export function BSCOpenTicketMessage(props: BSCOpenTicketMessageProps) {
    return (<p>
        {props.supervisor ?
            <Trans i18nKey="wfm_axiom_bsc_supervisor_error_message">
                If you are still having issues after reviewing the relevant page from above, please report
                it <a target={"_blank"}
                      href={'https://sim.amazon.com/issues/create?assignedFolder=8f1d8f36-fabf-4ea8-aef3-111a7d9bd346'}>
                here</a>.
            </Trans> :
            i18n.t("wfm_axiom_bsc_agent_error_message")
        }
    </p>);
}
import React from 'react';
import { FormDefinition } from 'src/service/amplify/appsync';
import { METADATA_LABEL_DATA_SEPARATOR } from 'src/components/common/MetadataLabel';

export const FormDetailsLabel: React.FC<{ label: string }> = (props) => {

    // parts would be `<display name>|<name>|<version>`, see buildFormDetailsLabel
    const dataParts = props.label.split(METADATA_LABEL_DATA_SEPARATOR);

    return <div className='d-flex flex-column'>
        <span>{dataParts[0]}</span>
        <span className='text-secondary'>{`${dataParts[1]} | ${dataParts[2]}`}</span>
    </div>;
};

export const buildFormDetailsLabel = (formDefinition?: FormDefinition | null): string => {
    if (!formDefinition) {
        return '';
    }
    return [formDefinition.displayTitle, formDefinition.formName, formDefinition.version]
        .join(METADATA_LABEL_DATA_SEPARATOR);
};

import { CerebrumService } from "src/service/CerebrumCoralService";
import { CoralError } from "src/components/error/ErrorBoundComponent";
import { FormState } from "@amzn/wfm-axiom-forms-renderer-stencil/dist/types/model/Forms";
import { ACTION_RESPONSE_PREFIX, ExtensionVisitor } from "src/components/forms/extensions/ExtensionVisitor";
import { VisitableExtension } from "src/components/forms/extensions/VisitableExtension";

export class CompleteAuditAction implements ExtensionVisitor<void> {

    private readonly formState: FormState;
    private actionResult: any;

    constructor(formState: FormState) {
        this.formState = formState;
    }

    public visit(extension: VisitableExtension): void {
        // tslint:disable-next-line:no-console
        console.log("CompleteAuditAction: visiting", extension, this.formState);
        if (extension.type === "action" && extension.id === "complete_audit") {
            this.completeAudit(extension);
        }
    }

    private completeAudit = (extension: VisitableExtension) => {
        // tslint:disable-next-line:no-console
        console.log("CompleteAuditAction will run extension ", extension.name);

        const auditIdKey = ACTION_RESPONSE_PREFIX + extension.content?.find((tuple) => tuple?.key === "responses_audit_key")?.value as string;
        const auditIds = this.formState.responses[auditIdKey];
        if (auditIds) {
            const auditArray = auditIds.split(',');
            const promises = auditArray.map((auditId: string) => {
                const completeAuditInput: Cerebrum.Audits.CompleteAuditInput = {auditId};

                return CerebrumService.completeAudit.apply(CerebrumService, [completeAuditInput]);
            })
            
            Promise.all<Cerebrum.Audits.CompleteAuditInput>(promises)
            .then((results: Cerebrum.Audits.CompleteAuditInput[]) => {
                // tslint:disable-next-line:no-console
                console.log("Audit completed successfully ", auditIds, extension);
                this.actionResult = results;
            })
            .catch((error: CoralError) => {
                throw new Error("Could not complete audit " + error.message);
            });
        }
    }

    // Not used.
    getResult(): void {
        return this.actionResult;
    }

    // onboard more actions here...
}
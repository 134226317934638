import React from 'react';
import i18n from 'i18next';
import { Trans } from "react-i18next";
import { KatButton, KatIcon } from '@amzn/katal-react';
import { UserManager } from "src/user/UserIdentityManager";
import { AxiomMetricsDriver } from "src/metrics/AxiomMetricsDriver";
import { UserRestrictedComponent } from 'src/components/authorization/UserRestrictedComponent';
import AxiomLogo from './AxiomLogo';
import { ComponentID } from 'src/components/authorization/ComponentRightsMapping';

// Note: This whole page is temporary and used for POCs and styling testing

export default function Home() {
    const componentName = "Home";
    const userIdentity = UserManager.getUserIdentity();

    document.title = SITE_NAME;
    AxiomMetricsDriver.publishPageLoad(componentName, window.location.href);

    return (
        <div id="content" className="content-wrapper">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-7">
                        <div className="jumbotron bg-white my-0">
                            <AxiomLogo />
                            <div className="text-size-md my-3">
                                {i18n.t('wfm_axiom_v2_definition_v1')}
                            </div>
                            <p className="text-size-md my-3">
                                {i18n.t('wfm_axiom_v2_definition_v2')}
                            </p>
                            <KatButton onClick={() => AxiomMetricsDriver.publishButtonClick('Home', 'about')}
                                label={i18n.t('wfm_axiom_v2_more_about')} variant="secondary" />
                        </div>
                    </div>
                    <div className="col">
                        <div>
                            <img className="img-fluid" src="images/radar-snip-v3.png" />
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12">
                        <h5 className="lead">
                            {i18n.t('wfm_axiom_v2_welcome')}, {userIdentity.additionalAttributes.givenName} {userIdentity.additionalAttributes.familyName}
                        </h5>
                    </div>
                </div>

                <div className="row border border-white">
                    <UserRestrictedComponent componentId={ComponentID.HOME_BALANCEDSCORECARD_RIGHTS_ID}>
                        <div className="col-12 col-lg-6 p-3 p-md-5 bg-light border border-white">
                            <h3><KatIcon name="explore" size="small" /> {i18n.t('wfm_axiom_v2_balanced_scorecard')}</h3>
                            <p>
                                <Trans i18nKey={'wfm_axiom_v2_bsc_description1'}>
                                    Axiom's first application will provide a <strong>Balanced
                                    Scorecard</strong> (BSC) or radar diagram that provides a consolidated view of
                                    performance metrics for a Supervisor’s team.
                                </Trans>
                            </p>
                            <p>
                                <Trans i18nKey={'wfm_axiom_v2_bsc_description2'}>
                                    Paired with an integrated <strong>recommendation engine</strong> for each agent allows for
                                    quick identification of opportunities.
                                </Trans>
                            </p>
                            <div className="d-flex flex-row-reverse">
                                <a href="#/bsc">
                                    <KatButton onClick={() => AxiomMetricsDriver.publishButtonClick('Home', 'bsc')}
                                        label="" variant="secondary">
                                        <KatIcon name="arrow-right" size="tiny" />
                                    </KatButton>
                                </a>
                            </div>
                        </div>
                    </UserRestrictedComponent>
                    <UserRestrictedComponent componentId={ComponentID.HEADER_MYPERFORMANCE_RIGHTS_ID}>
                        <div className="col-12 col-lg-6 p-3 p-md-5 bg-light border border-white">
                            <h3><KatIcon name="account_circle" size="small" /> {i18n.t('wfm_axiom_v2_my_performance')}</h3>
                            <p>
                                <Trans i18nKey={'wfm_axiom_v2_myperformance_description1'}>
                                    The <strong>My Performance</strong> Portal provides SPS Supervisors with a single location
                                for creating, storing, and tracking Associate coaching and feedback.
                            </Trans>
                            </p>
                            <p>
                                {i18n.t('wfm_axiom_v2_myperformance_description2')}
                            </p>
                            <div className="d-flex flex-row-reverse">
                                <a href="#/myp">
                                    <KatButton
                                        onClick={() => AxiomMetricsDriver.publishButtonClick('Home', 'myPerformance')}
                                        label="" variant="secondary">
                                        <KatIcon name="arrow-right" size="tiny" />
                                    </KatButton>
                                </a>
                            </div>
                        </div>
                    </UserRestrictedComponent>
                </div>
            </div>
        </div>
    );
}

import React from "react";
import { TemplateContent } from "@amzn/wfm-template-parser";

type DescriptionProps = {
    task: Cerebrum.Tasks.Task;
};

const Description = (props: DescriptionProps) => {
    const { task } = props;

    return (
        <div className="task-card-body text-secondary">
            {task.template
            ?   <TemplateContent template={task.template!} data={task.templateData}/>
            :   task.body}
        </div>
    );
};

export { Description };
import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import queryString from "query-string";

export interface SearchParams {
    [key: string]: string;
}

export interface SearchParamsResult {
    searchParams: SearchParams;
    searchParamsString: string;
}

/** Wraps react `useState` to sync with all url search params.
 *
 * This is useful primarily to allow deeper linking from external sources and allows users to bookmark certain pages more specifically.
 * Similarly to useSearchParamReader (note `Param` as singular) this will read all available parameters, instead of one matched by key.
 * Unlike useStateWithSearchParams, this hook works as read only, meaning that it won't update the url parameter value at all.
 *
 * @note Because we use Hash router users will not be able to use the back button to revert through the state changes
 */
export function useSearchParamsReader(): SearchParamsResult {

    const [searchParams, setSearchParams] = useState<SearchParams>({});

    const { search } = useLocation();

    useEffect(() => {
        const queryParams = queryString.parse(search);
        setSearchParams(
            Object.keys(queryParams)
                .reduce((accumulator, current) => {
                    accumulator[current] = queryParams[current];
                    return accumulator;
                }, {} as any)
        );
    }, [search]);

    return { searchParams, searchParamsString: search};
}
import React, { useContext } from "react";
import "./TaskCard.scss"
import { KatButton, KatIcon } from "@amzn/katal-react";
import { TaskForm } from "src/components/tasks/TaskForm";
import { emptyTaskFor } from "src/components/tasks/model/Task";
import { AssistantContext } from "src/components/assistant/AssistantContext";
import { AxiomMetricsDriver } from "src/metrics/AxiomMetricsDriver";
import { useSearchParamWriter } from 'src/components/common/searchParameters/useSearchParamWriter';


interface CreateTaskCardProps {
    viewUserLogin: string;
}

const COMPONENT_NAME = "CreateTaskCard";

export const CreateTaskCard: React.FC<CreateTaskCardProps> = (props) => {

    const { viewUserLogin } = props;

    const assistantContext = useContext(AssistantContext);

    const writeTaskParam = useSearchParamWriter('task');

    const createNewTask = () => {
        writeTaskParam(''); // clear the previous highlighted task
        AxiomMetricsDriver.publishButtonClick(COMPONENT_NAME, "createTask");

        assistantContext.startCreateTask(emptyTaskFor(viewUserLogin));
    };

    const hideForm = () => (assistantContext.clearCreateTask());

    const CreateTaskButton = () => (
        <KatButton className='task-button' variant='link' onClick={createNewTask}>
            <KatIcon title='Create Task'
                     name='add_circle_outline'
                     size='small'
                     className='icon-default'/>
            <span className='task-button-label'>Create Task</span>
        </KatButton>
    );

    return (
        <div className="task-card flex-grow-1">
            {assistantContext.creatingTask ?
                <TaskForm viewUserLogin={viewUserLogin}
                          task={assistantContext.currentTask}
                          createNew
                          onSuccess={hideForm}
                          onCancel={hideForm}/> :
                <CreateTaskButton/>
            }
        </div>
    );
}

import { setupAWSConfig } from "../../configuration/amplify/aws-config";
import { ConfigManager } from "src/config/ConfigManager";
import { AxiomMetricsDriver, MetricStatus } from "src/metrics/AxiomMetricsDriver";
import { CognitoUser } from "amazon-cognito-identity-js";
import { Auth } from "aws-amplify";
import { CognitoTokenUser, CognitoUserManager } from "src/user/UserCognitoIdentityManager";

const Cookies = require('js-cookie');

const AXIOM_AMPLIFY_AUTH_TRIED = "axiom-amplify-auth-tried";
const HREF_PATH_AND_ARGS = "href-path-and-args";
const AMPLIFY_AUTHENTICATION = "Amplify.Authentication";

export class AmplifyAuthenticationService {

    private static createAmplifyAuthTryCookie(): void {
        const in12Hours = 0.5;
        // Let the cookie expire in 12 hours. After which, it will try
        // to authenticate again with Amplify if it previously failed
        Cookies.set(AXIOM_AMPLIFY_AUTH_TRIED, true, { expires: in12Hours });
    }

    private static clearAmplifyAuthTryCookie(): void {
        Cookies.remove(AXIOM_AMPLIFY_AUTH_TRIED);
    }

    private static createPathAndArgsCookie(): void {
        const in1Hour = 1 / 24;
        const pathAndArgs = this.getCurrentPathAndArgs();
        Cookies.set(HREF_PATH_AND_ARGS, pathAndArgs, { expires: in1Hour });
    }

    private static getCurrentPathAndArgs() {
        return window.location.href.substring(window.location.origin.length);
    }

    private static redirectToOriginalPathAndArgs(): void {
        const savedPathAndArgs = Cookies.get(HREF_PATH_AND_ARGS);
        Cookies.remove(HREF_PATH_AND_ARGS);
        const currentPathAndArgs = this.getCurrentPathAndArgs();
        if (savedPathAndArgs !== undefined && savedPathAndArgs !== currentPathAndArgs) {
            history.replaceState(null, "", savedPathAndArgs);
        }
    }

    private static isAmplifyAuthTryCookiePresent(): boolean {
        const cookie = Cookies.get(AXIOM_AMPLIFY_AUTH_TRIED);
        return !!cookie;
    }

    public static async authenticate(userIdentity: WFM.GetUserInformation, _finishedAuthCallBack?: () => void) {

        const finishedAuthCallBack = () => {
            // redirect in case current path and url arguments are different
            // from the ones saved in cookie before authentication redirect
            this.redirectToOriginalPathAndArgs();
            if(_finishedAuthCallBack) {
                _finishedAuthCallBack();
            }
        };

        // save current path and url arguments in case
        // we need to redirect for authentication
        this.createPathAndArgsCookie();

        if (await setupAWSConfig(ConfigManager.getStaticConfig())) {
            const hasAlreadyTriedAmplifyAuth = AmplifyAuthenticationService.isAmplifyAuthTryCookiePresent();
            const amplifyCredentials = await AmplifyAuthenticationService.getAmplifyCredInfo(hasAlreadyTriedAmplifyAuth);
            if (amplifyCredentials) {
                try{
                    await CognitoUserManager.setCognitoUser(amplifyCredentials as CognitoTokenUser);
                } catch (e) {
                    // tslint:disable-next-line:no-console
                    console.error("Error setting Cognito User", e);
                }
                finishedAuthCallBack();
                AmplifyAuthenticationService.publishSuccessfulAmplifyLoginMetric(userIdentity, amplifyCredentials);
            } else if (hasAlreadyTriedAmplifyAuth) {
                // If no credentials, and we already tried to authenticate,
                // show page as no more redirects pending.
                finishedAuthCallBack();
                AxiomMetricsDriver.publishComponentLoadError(AMPLIFY_AUTHENTICATION, MetricStatus.Failure);
            }
            return amplifyCredentials;
        } else {
            finishedAuthCallBack();
        }
    }


    private static async getAmplifyCredInfo(hasAlreadyTriedAmplifyAuth: boolean = false) {
        let amplifyCredInfo = null;
        try {
            try {
                const user: CognitoUser = await Auth.currentAuthenticatedUser();
                amplifyCredInfo = user.getSignInUserSession()!.getIdToken().decodePayload();
            } catch (e) {
                // tslint:disable-next-line:no-console
                console.log(e);
            }
            // TODO: Review this logic as it may be leftover logic from weblab
            // https://sim.amazon.com/issues/V1380945897
            
            // We should trigger Federate redirects only if we haven't redirected already
            if (!amplifyCredInfo && !hasAlreadyTriedAmplifyAuth) {
                await AmplifyAuthenticationService.attemptAmplifyFederateSignIn();
            }
            // tslint:disable-next-line:no-console
            console.log(`Amplify User Auth Info: ${amplifyCredInfo ? '' : 'not '}available`, amplifyCredInfo);
            if (!amplifyCredInfo) {
                // if we don't have cred info after all the above, clear out the cookie so it tries again on reload
                // without this, the cookie has to be removed manually or will be done automatically after 12 hours
                AmplifyAuthenticationService.clearAmplifyAuthTryCookie();
            }
        } catch (e) {
            // Amplify auth should hold in all errors
            // tslint:disable-next-line:no-console
            console.log(e);
        }
        return amplifyCredInfo;
    }

    private static async attemptAmplifyFederateSignIn() {
        // tslint:disable-next-line:no-console
        console.log("Initiating Federate Amplify authentication");
        // Mark that we have tried Amplify authentication
        AmplifyAuthenticationService.createAmplifyAuthTryCookie();
        await Auth.federatedSignIn({ customProvider: 'FederateOIDC' });
    }

    private static publishSuccessfulAmplifyLoginMetric(userIdentity: WFM.GetUserInformation, amplifyCredentials: { [x: string]: any; }) {
        const additionalMetrics: { name: string, value: any }[] = [
            { name: "globalIdentity.login", value: userIdentity.login },
            { name: "cognito.login", value: amplifyCredentials["cognito:username"] }
        ];
        AxiomMetricsDriver.publishComponentLoadError(AMPLIFY_AUTHENTICATION, MetricStatus.Success, additionalMetrics);
    }
}
import React, { useEffect, useState } from 'react';
import { KatButton, KatDivider } from "@amzn/katal-react";

interface ReverbOptionsProps {
    survey: Cerebrum.Reverb.SurveyQuestion;
    onSelectionChange: (value: number) => void;
}

export const OPT_OUT = -1;
export const NO_SELECTION = -2;

export const ReverbOptions: React.FC<ReverbOptionsProps> = (props) => {

    const { survey, onSelectionChange } = props;

    const [selectedOption, setSelectedOption] = useState(NO_SELECTION);

    useEffect(() => onSelectionChange(selectedOption), [selectedOption]);


    return (
        <div className={'d-flex flex-column'}>
            {Array.from(survey.options).map((option) =>
                <OptionButton key={option[0]} value={option[0]} label={option[1]}
                    selected={selectedOption}
                    onClick={() => setSelectedOption(option[0])} />
            )}
            <OptionButton label={"I'd rather not answer"} selected={selectedOption} optOut
                value={OPT_OUT} onClick={() => setSelectedOption(OPT_OUT)} />
        </div>
    );
};

interface OptionButtonProps {
    label: string;
    selected: number;
    value: number;
    optOut?: boolean;
    onClick: (value: number) => void;
}

const OptionButton: React.FC<OptionButtonProps> = (props) => {
    const defaultVariant: KatButton.Variant = 'tertiary';
    const defaultSize: KatButton.Size = 'base';
    const defaultClass = 'p-1 option-text';

    const optOutClass = 'pt-3 w-50 opt-out-btn';
    const optOutSize: KatButton.Size = 'small';

    const { label, selected, onClick, value, optOut } = props;

    const [isSelectedClassName, setIsSelectedClassName] = useState<String>('');

    useEffect(() => {
        if (selected === value) {
            setIsSelectedClassName('option-btn-selected');
        } else {
            setIsSelectedClassName('');
        }
    }, [selected]);

    const className = [defaultClass, isSelectedClassName, (optOut ? optOutClass : '')].join(' ');
    const size = optOut ? optOutSize : defaultSize;

    return <>
        <KatButton
            className={className}
            label={label}
            variant={defaultVariant}
            size={size}
            onClick={() => onClick(value)}>
        </KatButton>
    </>;
};
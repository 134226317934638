import { KatLabel } from "@amzn/katal-react";
import { ExtensionFormatter } from "src/components/forms/extensions/ExtensionFormatter";
import { FormState } from "@amzn/wfm-axiom-forms-renderer-stencil/dist/types/model/Forms";
import { TargetEntityVisitor } from "src/components/forms/extensions/TargetEntityVisitor";
import { TargetItemVisitor } from "src/components/forms/extensions/TargetItemVisitor";
import React, { useState } from "react";
import { FormSubmission } from "src/service/amplify/appsync";
import { formatCreatedDateTime } from "src/components/common/DateFormatter";
import {
    MetadataLabelType,
    MetadataLabelTyped,
    parseMetadataLabel
} from 'src/components/common/MetadataLabel';

interface FormDisplayInfoProps {
    formSubmission: FormSubmission;
}

export const FormDisplayInfo: React.FC<FormDisplayInfoProps> = ({ formSubmission }: FormDisplayInfoProps) => {
    const innerDivsClassName = "d-flex flex-column p-2 justify-content-between";
    const titleLabelsClassName = "text-muted pb-1";

    const [login, setLogin] = useState('');

    const targetEntityLabels = ExtensionFormatter.splitExtensionsString(
        ExtensionFormatter.formatExtensionsIntoString(formSubmission!,
            (targetFormState: FormState) => new TargetEntityVisitor(targetFormState))
    );

    const targetItemLabels = ExtensionFormatter.splitExtensionsString(
        ExtensionFormatter.formatExtensionsIntoString(formSubmission!,
            (targetFormState: FormState) => new TargetItemVisitor(targetFormState))
    );

    return (
        <>
            <div className={'border border-secondary d-flex flex-row mb-2'}>
                <div className={'d-flex flex-column flex-fill'}>
                    <div className={innerDivsClassName}>
                        <KatLabel text='Form Name' className={titleLabelsClassName}/>
                        <KatLabel>{formSubmission.formDefinition?.formName}</KatLabel>
                    </div>
                    <div className={innerDivsClassName}>
                        <KatLabel text='Form Submitter' className={titleLabelsClassName}/>
                        <MetadataLabelTyped name='formSubmitter'
                                            type={MetadataLabelType.AGENT}
                                            label={formSubmission.submitterEntityId}/>
                    </div>
                    <div className={innerDivsClassName}>
                        <KatLabel text='Submission ID' className={titleLabelsClassName}/>
                        <KatLabel>{formSubmission.id}</KatLabel>
                    </div>
                </div>
                <div className={'d-flex flex-column flex-fill border-left'}>
                    <div className={innerDivsClassName}>
                        <KatLabel text='Submit Date' className={titleLabelsClassName}/>
                        <KatLabel>{
                            formatCreatedDateTime(formSubmission.createdDateTime)
                        }</KatLabel>
                    </div>
                    <div className={innerDivsClassName}>
                        <KatLabel text='Last Updated Date' className={titleLabelsClassName}/>
                        <KatLabel>{
                            formatCreatedDateTime(formSubmission.lastUpdatedDateTime)
                        }</KatLabel>
                    </div>
                    <div className={innerDivsClassName}>
                        <KatLabel text='Target Entity' className={titleLabelsClassName}/>
                        <div className='d-flex flex-column'>
                            {targetEntityLabels.map(label => parseMetadataLabel('targetEntity', label))}
                        </div>
                    </div>
                    <div className={innerDivsClassName}>
                        <KatLabel text='Target Item' className={titleLabelsClassName}/>
                        <div className='d-flex flex-column'>
                            {targetItemLabels.map(label => parseMetadataLabel('targetItem', label))}
                        </div>
                    </div>
                    <div className={innerDivsClassName}>
                        <KatLabel text='Form Submission Status' className={titleLabelsClassName}/>
                        <KatLabel>{formSubmission.formSubmissionStatus}</KatLabel>
                    </div>
                </div>
            </div>
        </>
    )
}
import React from "react";
import { UserManager } from 'src/user/UserIdentityManager';

export interface UserContext {
    userManager: typeof UserManager;
    setUserManager: () => void;
}

export interface UserContextProps {
}

export interface UserState {
    userManager: typeof UserManager
}

export const UserContext = React.createContext<UserContext>({ userManager: UserManager, setUserManager: () => { } });

/**
 * The UserContextProvider gives an interface for any component to access the details in UserManager 
 * and properly rerender on changes.
 * 
 * The implementation here is somewhat of a workaround; we can just utilize the UserManager class but reset the provider state
 * whenever we want to trigger a rerender
 * 
 * @todo Migrate all components that currently directly access the user manager.
 * 
 * @see https://reactjs.org/docs/context.html
 */
export default class UserContextProvider extends React.Component<{}, UserState> {
    constructor(props: UserContextProps) {
        super(props);
        this.state = {
            userManager: UserManager
        };
        this.setUserManager = this.setUserManager.bind(this);
    }

    /**
     * This needs to be called to trigger a rerender of all components consuming the user context
     */
    public setUserManager() {
        this.setState({ userManager: UserManager });
    }

    render() {
        return (
            <UserContext.Provider value={{ userManager: this.state.userManager, setUserManager: this.setUserManager }}>
                {this.props.children}
            </UserContext.Provider>
        )
    }
}
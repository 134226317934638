import { useEffect, useState } from "react";

export interface Sorting<Type> {
    name: string;
    sortingDecider: (object1: Type, object2: Type) => number;
}

/*
How to implement sortingDecider? Example:

function compare(a, b) {
  if (a is less than b in this ordering criteria) {
    return -1;
  }
  if (a is greater than b in this ordering criteria) {
    return 1;
  }
  // a is to be equal to b
  return 0;
}
 */

export function useSorter<Type>(sortedBy: string,
                                 items: Type[],
                                 sortStrategies: Record<string, Sorting<Type>>): [Type[]] {

    const [sorted, setSorted] = useState<Type[]>([]);

    useEffect(() => {
        buildSorted();
    }, [items, sortedBy]);


    const buildSorted = (): void => {
        // Selected sorting defaults to first option always if there is no sortedBy passed in
        const selectedSorting: Sorting<Type> =
            sortedBy ?
                sortStrategies[sortedBy] :
                sortStrategies[Object.keys(sortStrategies)[0]];
        const sortedArray = calculateSorting(
            items,
            selectedSorting.sortingDecider
        );
        setSorted(sortedArray);
    }

    const calculateSorting = (itemsToSort: Type[], sortingDecider: (object1: Type, object2: Type) => number): Type[] => {
        // Array.prototype.sort() sorts elements in place. i.e, using the same data structure. This doesn't get 'noticed'
        // by the state management. [...itemsToSort] is used to force the mutation to get noticed by the useState hook.
        // You cannot make this custom hook return [...[]] as it creates a downstream loop with anything that uses it as
        // property. As the reference seems to be constantly changing when actively reading.
        return [...itemsToSort].sort(sortingDecider);
    }

    return [sorted];

}
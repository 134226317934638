import React, { useLayoutEffect, useRef, useState } from "react";
import { KatIcon, KatSpinner } from "@amzn/katal-react";
import {
    buildMetadataLabel,
    MetadataLabelType,
    parseMetadataLabel,
} from "src/components/common/MetadataLabel";
import { Entity } from "src/service/amplify/appsync";

type EntityItemProps = {
    entity: Entity;
    removeHandler?: (entityId: string) => Promise<any>;
};

export default function EntityItem(props: EntityItemProps) {
    const ref = useRef<any>();
    const { entity, removeHandler } = props;
    const [loadingRemove, setLoadingRemove] = useState(false);

    const onDismiss = async (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        setLoadingRemove(true);
        if (removeHandler) {
            await removeHandler(entity.entityId);
            setLoadingRemove(false);
        }
    };

    useLayoutEffect(() => {
        const { current } = ref;

        if (current) {
            current.addEventListener("dismiss", onDismiss);
        }

        return () => current.removeEventListener("dismiss", onDismiss);
    }, [ref]);

    return (
        <>
            {/* @ts-ignore */}
            <kat-tag ref={ref} label={entity.entityId} class="m-1 pl-1 pr-0">
                <div className="d-flex justify-content-between align-items-center pl-1 mw-100">
                <span className="flex-fill">
                    {parseMetadataLabel(
                        "ShareItemViewAgent",
                        buildMetadataLabel(MetadataLabelType.AGENT, entity.entityId)
                    )}
                </span>
                    {removeHandler && (
                        <span style={{ width: "20px" }}>
                        {loadingRemove
                            ? <KatSpinner size="small" variant="default" className="w-75"/>
                            : <KatIcon name="cancel" size="tiny" onClick={onDismiss}/>}
                    </span>
                    )}
                </div>
            {   /* @ts-ignore */}
            </kat-tag>
        </>
    );
}

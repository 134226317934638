import { FormSubmission } from "src/service/amplify/appsync";
import { FormState } from "@amzn/wfm-axiom-forms-renderer-stencil/dist/types/model/Forms";
import { ExtensionVisitor } from "src/components/forms/extensions/ExtensionVisitor";
import { notFalsy } from "src/components/common/NotFalsy";
import { AsVisitableExtension } from "src/components/forms/extensions/VisitableExtension";

export class ExtensionFormatter {

    public static readonly EXTENSIONS_SEPARATOR = ', ';

    public static formatExtensionsIntoString(formSubmission: FormSubmission,
                                             visitorProvider: (state: FormState) => ExtensionVisitor<string>): string {

        const { responses, formDefinition } = formSubmission;

        if (!formDefinition) {
            return '';
        }

        const parsedResponses: FormState = { responses: JSON.parse(responses) };
        const visitor = visitorProvider(parsedResponses);

        return formDefinition.extensions?.filter(notFalsy)
            .map(AsVisitableExtension)
            .map(visitor.visit)
            .filter(notFalsy)
            .join(this.EXTENSIONS_SEPARATOR)!;
    };

    public static splitExtensionsString = (extensions: string): string[] =>
        extensions.split(ExtensionFormatter.EXTENSIONS_SEPARATOR);
}

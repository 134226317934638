import React from 'react';
import { ListGroup } from 'react-bootstrap';
import SideBarListItem from './SideBarListItem'

interface SideBarProps {
    userBalancedScorecardData: Cerebrum.UserBalancedScorecardData
    selectEntityHandler: (selectedEntity: Axiom.SelectedUser) => void;
    selectedEntity: Axiom.SelectedUser;
}

export default class SideBar extends React.Component<SideBarProps> {
    render() {
        const { supervisor, teamBalancedScorecard, agentBalancedScorecards } = this.props.userBalancedScorecardData;
        return (
            <div className='m-2'>
                <ListGroup >
                    <SideBarListItem listItemEntity={{ type: "supervisor", userId: supervisor, bscMetricData: teamBalancedScorecard }} {...this.props} />
                    {agentBalancedScorecards.map(abs =>
                        <SideBarListItem key={abs.agent}
                            listItemEntity={{ type: "agent", userId: abs.agent, bscMetricData: abs.balancedScorecard }}
                            {...this.props}
                        />
                    )}
                </ListGroup>
            </div>
        );
    }
}
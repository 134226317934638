import React from 'react';
import { AxiomMetricsDriver } from 'src/metrics/AxiomMetricsDriver';
import { KatIcon, KatLink } from '@amzn/katal-react';
import { ParagonSignedUrlLink } from 'src/components/audit/table/ParagonSignedUrlLink';
import { FormDetailsLabel } from 'src/components/forms/submissions_table/FormDetailsLabel';
import { ConfigManager } from "src/config/ConfigManager";

interface MetadataLabelProps {
    name: string;
    labelPrefix?: string;
    label?: string;
    iconName?: KatIcon.Name;
    iconSize?: KatIcon.Size;
    target?: KatLink.Target;
    href?: string;
    additionalMetrics?: Axiom.AdditionalMetric[];
}

export const METADATA_LABEL_SEPARATOR = ': ';
export const METADATA_LABEL_DATA_SEPARATOR = '|';

export enum MetadataLabelType {
    AGENT = 'agent',
    FULL_NAME = 'full_name',
    TICKET = 'ticket',
    CASE = 'case',
    FORM_DETAILS = 'form_details',
    BINDLE = 'bindle'
}

export const MetadataLabel: React.FC<MetadataLabelProps> = (props) => {

    const { name, labelPrefix, label, href, target, iconName, iconSize, additionalMetrics } = props;

    const reportMetric = (isAuxClick: boolean) => {
        const metrics = additionalMetrics || [];

        if (labelPrefix) metrics.push({ name: 'labelPrefix', value: labelPrefix });
        if (label) metrics.push({ name: 'label', value: label });
        if (isAuxClick) metrics.push({ name: 'isAuxClick', value: 'true' });

        AxiomMetricsDriver.publishButtonClick("MetadataLabel", name, additionalMetrics);
    };

    const Content = () => <>
        {label && <span>{`${label}${iconName ? ' ' : ''}`}</span>}
        {iconName && <KatIcon name={iconName} size={iconSize || 'small'}/>}
    </>;

    const LabelPrefix = () => <>
        {labelPrefix && <span>{`${labelPrefix}`}</span>}
    </>;

    const NoDataView = () => <>
        {!labelPrefix && !label && !iconName && '-'}
    </>;

    return href
        ? <span>
            <LabelPrefix/>
            <KatLink
                className='cursor-pointer pr-2'
                href={href}
                target={target}
                onClick={() => reportMetric(false)}
                // @ts-ignore
                onAuxClick={() => reportMetric(true)}
            >
            <Content/>
        </KatLink>
        </span>
        : <span>
            <LabelPrefix/>
            <Content/>
            <NoDataView/>
        </span>;
};

interface MetadataLabelTypedProps {
    name: string;
    type: MetadataLabelType;
    label: string;
}

export const MetadataLabelTyped: React.FC<MetadataLabelTypedProps> = (props) => {

    const { name, type, label } = props;

    return parseMetadataLabel(name, buildMetadataLabel(type, label));
};


export const buildMetadataLabel = (type: MetadataLabelType, value: any) => {
    if(type === MetadataLabelType.FULL_NAME) {
        return `${type}${METADATA_LABEL_SEPARATOR}${value['fullName']}${METADATA_LABEL_SEPARATOR}${value['alias']}`;
    } else {
        return `${type}${METADATA_LABEL_SEPARATOR}${value}`;
    }
}   

export const buildMetadataLabelUntyped = (type: string, value: string) =>
    `${type}${METADATA_LABEL_SEPARATOR}${value}`;

const buildBindlesUrl = (resourceId: string) => {
    return `https://bindles${ConfigManager.getStaticConfig().isProd ? '' : '-gamma'}.amazon.com/resource/${resourceId}`;
}

export const parseMetadataLabel = (name: string, label: string): JSX.Element => {
    const parts = label.split(METADATA_LABEL_SEPARATOR);

    // This mapping could be enhanced if we onboard more types of custom labels
    switch (parts[0]) {
        case MetadataLabelType.AGENT:
            return <MetadataLabel name={name}
                                  label={`${parts[1]}@`}
                                  href={`https://phonetool.amazon.com/users/${parts[1]}`}
                                  target='_blank'/>;
        case MetadataLabelType.FULL_NAME:
            return <MetadataLabel name={name}
                                  label={`${parts[1]}`}
                                  href={`https://phonetool.amazon.com/users/${parts[2]}`}
                                  target='_blank'/>;
        case MetadataLabelType.TICKET:
            return <MetadataLabel name={name}
                                  labelPrefix='Ticket: '
                                  label={parts[1]}
                                  href={`https://t.corp.amazon.com/issues/${parts[1]}`}
                                  iconName='launch'
                                  iconSize='tiny'
                                  target='_blank'/>;
        case MetadataLabelType.CASE:
            return <ParagonSignedUrlLink caseId={parts[1]}
                                         displayTextPrefix='Case: '
                                         displayText={parts[1]}
                                         metricsId={name}/>;
        case MetadataLabelType.FORM_DETAILS:
            return <FormDetailsLabel label={parts[1]}/>;
        case MetadataLabelType.BINDLE:
            return <MetadataLabel name={name}
                                  label={parts[1]}
                                  labelPrefix='Bindle: '
                                  href={buildBindlesUrl(parts[1])}
                                  target='_blank'
                                  iconName='launch'
                                  iconSize='tiny'/>;
        default:
            return <MetadataLabel name={name} label={label}/>;
    }
};

import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { loadHelpContentPage } from "src/components/support/HelpContentHelper";
import { KatSpinner, KatTextarea } from "@amzn/katal-react";
import { loadAxiomImages } from "src/components/common/AxiomImageHelper";
import rehypeRaw from "rehype-raw";
import { HelpContentAdminBar } from "src/components/support/HelpContentAdminBar";
import { HelpContentUltraLink } from "src/components/support/HelpContentUltraLink";
import { PageNotFoundAlert } from "src/components/support/PageNotFoundAlert";
import { HelpContentUltraHeader } from "src/components/support/HelpContentUltraHeader";

interface HelpContentRendererProps {
    pageId?: string;
    isSidebar?: boolean;
}

export const HelpContentRenderer: React.FC<HelpContentRendererProps> = (props) => {

    const { pageId, isSidebar } = props;

    const [helpContent, setHelpContent] = useState<Cerebrum.HelpContent>();

    const [editing, setEditing] = useState(false);
    const [processedContent, setProcessedContent] = useState('');
    const [loadContentError, setLoadContentError] = useState('');

    useEffect(() => {
        async function processRawContent() {
            if (helpContent?.content) {
                const content = await loadAxiomImages(helpContent?.content)
                setProcessedContent(content);
            }
        }

        processRawContent();
    }, [helpContent]);

    useEffect(() => {
        async function loadHelpContent() {
            if (pageId) {
                setProcessedContent('');
                setLoadContentError('')
                await loadHelpContentPage(pageId)
                    .then(setHelpContent)
                    .catch(setLoadContentError);
            }
        }

        loadHelpContent();
    }, [pageId]);

    return (<>
        <HelpContentAdminBar currentGuid={pageId}
                             onEditingToggle={() => setEditing(!editing)}
                             textToCopyProvider={() => helpContent?.content!}/>

        {!loadContentError && !processedContent && <div className="row justify-content-center"><KatSpinner/></div>}

        {loadContentError && <PageNotFoundAlert pageId={pageId}/>}


        {!editing && processedContent && <ReactMarkdown rehypePlugins={[rehypeRaw]}
                                                        className="axiom-support-content help-content-renderer"
                                                        children={processedContent}
                                                        components={{
                                                            a: HelpContentUltraLink(isSidebar),
                                                            h1: HelpContentUltraHeader,
                                                            h2: HelpContentUltraHeader,
                                                            h3: HelpContentUltraHeader,
                                                            h4: HelpContentUltraHeader,
                                                            h5: HelpContentUltraHeader,
                                                            h6: HelpContentUltraHeader,
                                                        }}/>}

        {editing && <KatTextarea className='help-text-area' label={helpContent?.title}
                                 value={helpContent?.content}
                                 onChange={(evt: any) => {
                                     if (helpContent) {
                                         const updatedContent = { ...helpContent, content: evt.target.value };
                                         setHelpContent(updatedContent);
                                     }
                                 }}/>}
    </>);

}

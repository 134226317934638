import { AxiomRight } from "src/config/AxiomCSRights";
import { UserManager } from "src/user/UserIdentityManager";

export interface UserAccessManager {
    isUserAuthorizedForRight(right: AxiomRight): boolean;
}

export class UserAccessDecider implements UserAccessManager {
    isUserAuthorizedForRight(right: AxiomRight): boolean {
        return UserManager.isUserAuthorizedForRight(right);
    }
}

export const UserAccessManager = new UserAccessDecider();
import { useCallback, useEffect } from 'react';

const FORM_SCROLLED_EVENT_TYPE = "ax-forms-form-scrolled";

export function useFormScrolledMessage() {
    const handleScroll = useCallback((scrollEvent) => {
        window.postMessage({ type: FORM_SCROLLED_EVENT_TYPE }, "*");
    }, []);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll, { capture: true });
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [handleScroll]);
}

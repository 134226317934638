import React from 'react';
import i18n from 'i18next';
import { KatButton, KatDropdown, KatIcon, KatInput, KatInputGroup } from '@amzn/katal-react';
import { RouteComponentProps } from "react-router";
import { AxiomMetricsDriver } from "src/metrics/AxiomMetricsDriver";
import { ComponentID, ComponentRightsManager } from "src/components/authorization/ComponentRightsMapping";
import { UserRestrictedComponent, isComponentAccessAllowed } from "src/components/authorization/UserRestrictedComponent";
import { UserContext } from 'src/context/UserContext';
import { Treatment, Weblab } from 'src/user/Weblab';
import { useHistory, useLocation } from "react-router-dom";

export interface searchablePage {
    /**
     * visible name in the search dropdown
     */
    name: string;
    /**
     * url sub path for the page
     */
    value: string;
    componentId: ComponentID;
}

const getSearchablePages = (): searchablePage[] => {
    return [
        {
            name: 'BSC',
            value: 'bsc',
            componentId: ComponentID.HEADER_BALANCEDSCORECARD_RIGHTS_ID
        },
        {
            name: 'Reviews',
            value: 'audit',
            componentId: ComponentID.HEADER_AUDIT_RIGHTS_ID
        },
        {
            name: 'Metrics',
            value: 'metrics',
            componentId: ComponentID.HEADER_FLASHBRIEFING_RIGHTS_ID
        },
        {
            name: 'Tasks',
            value: 'tasks',
            componentId: ComponentID.HEADER_TASKS_RIGHTS_ID
        }
    ]
};

export function HeaderSearch(props: RouteComponentProps) {
    const { pathname, search } = useLocation();
    const history = useHistory();

    // initial selection is based on current page or defaults to the first option for the user 
    const initialSearchPage = getCurrentSearchablePage(pathname).value || searchablePagesForUser()[0].value;

    const [searchPage, setSearchPage] = React.useState(initialSearchPage);
    const [searchString, setSearchString] = React.useState('');

    const onInput = (event: any) => { setSearchString(event.target.value); };

    function handleSubmit(event: any) {
        if (searchString) {
            AxiomMetricsDriver.publishButtonClick('Header', 'search', [
                {
                    name: 'searchValue',
                    value: searchString
                },
                {
                    name: 'searchPage',
                    value: searchPage
                }
            ]);
            history.push({
                pathname: `/${searchPage}/${searchString}`,
                /**
                 * Drop the parameters when searching for a different page then what the user is currently on
                 * i.e. user is on Reviews and searching for Tasks so we should drop the query params that Reviews might have
                 * Without this, the parameters would be "carried over" and appended to the new query string
                 */
                search: isSearchForCurrentPage(pathname, searchPage) ? search : '',
            });
        }
        event.preventDefault();
    }

    /**
     * When the page changes, if it is a searchable option the `searchPage` is updated
     * otherwise it remains whatever the previous value was.
     */
    React.useEffect(() => {
        if (getCurrentSearchablePage(pathname)) {
            setSearchPage(getCurrentSearchablePage(pathname).value);
        }
    }, [pathname]);

    return (
        <KatInputGroup className="header-search-input-group">
            <KatInput
                id="inlineFormInputGroup"
                placeholder={i18n.t('wfm_axiom_v2_user_id')}
                value={searchString}
                onInput={onInput}
                onChange={handleSubmit}
                type="search"
                className="header-search-input" />
            <UserRestrictedComponent componentId={ComponentID.HEADER_SEARCH_RIGHTS_ID}>
                <KatDropdown
                    className="header-search-dropdown-menu"
                    onChange={e => setSearchPage(e.detail.value)}
                    value={searchPage}
                    options={searchablePagesForUser()}
                >
                </KatDropdown>
            </UserRestrictedComponent>
            <KatButton variant="primary" onClick={handleSubmit} className="header-search-button">
                <KatIcon name="search" size="small" />
            </KatButton>
        </KatInputGroup>
    );
}

/**
 * Basic logic to determine if the current page is one of the searchable options. 
 * 
 * returns the first match in the list or empty string if no matches are found
 * 
 * @param pathname 
 * 
 * @note This can be revised with more thorough logic as more complex pages are built
 */
function getCurrentSearchablePage(pathname: string) {
    return getSearchablePages().filter((page) => {
        return pathname.includes(page.value);
    })[0] || '';
}

/**
 * Filters the searchable page list to only ones the user has access to
 * 
 * Test users can see all options regardless of rights
 */
function searchablePagesForUser() {
    const userContext = React.useContext(UserContext);
    return getSearchablePages().filter((page) => {
        return ComponentRightsManager.userHasAccessRightsForComponent(page.componentId) || userContext.userManager.isTestUser;
    });
}

/**
 * Checks if the search is happening on the same page or will be taking the user to a different page.
 * 
 * @param currentLocation 
 * @param destinationLocation 
 */
function isSearchForCurrentPage(currentLocation: string, destinationLocation: string): boolean {
    return currentLocation.split('/')[1] === destinationLocation;
}
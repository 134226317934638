import React, { useEffect, useState } from 'react';
import { KatBox, KatTextarea } from '@amzn/katal-react';

interface ReverbCommentProps {
    onCommentChange: (value: string) => void;
}

export const ReverbComment: React.FC<ReverbCommentProps> = (props) => {

    const { onCommentChange } = props;

    const [comment, setComment] = useState('');

    useEffect(() => onCommentChange(comment), [comment]);

    return (
        <KatBox className='px-1 pt-3 pb-1'>
            <KatTextarea label='Additional Comments'
                placeholder='Optional Input'
                value={comment}
                maxlength={500}
                className='reverb-text-area'
                onChange={(evt: any) => setComment(evt.target.value)} />
        </KatBox>
    );
};
import React, { FC, useContext } from "react";
import { KatIcon, KatPopover } from "@amzn/katal-react";
import { LayoutContext } from "src/components/layout/LayoutContext";
import { HelpContext } from "src/context/HelpContext";
import { UserRestrictedComponent } from "src/components/authorization/UserRestrictedComponent";
import { ComponentID } from "src/components/authorization/ComponentRightsMapping";
import { CopyToClipboardButton } from "src/components/common/CopyToClipboardButton";
import { AxiomMetricsDriver } from "src/metrics/AxiomMetricsDriver";
import { AssistantContext, AssitantTab } from "src/components/assistant/AssistantContext";
import { AjudaPageEditLink } from "src/components/support/HelpContentAdminBar";
import { UserContext } from "src/context/UserContext";

interface HelpContextualLinkProps {
    componentId: string;
}

/**
 * Custom hook to encapsulate the logic of setting a contextual help document to be displayed in Charlotte's
 * sidebar. It will show the sidebar if it is hidden and also select the Help tab so the content is displayed.
 */
export const useHelpContextualLink = () => {

    const layoutContext = useContext(LayoutContext);
    const assistantContext = useContext(AssistantContext);
    const helpContext = useContext(HelpContext);
    const userContext = useContext(UserContext);

    /**
     * Sets the component id for contextual help, shows the sidebar if it is hidden and also selects
     * the Help tab so the content is displayed.
     *
     * @param componentId the id of the component to resolve the desired document by looking at the mapping
     * doc referenced by helpContext.contextualMappingPageId
     */
    return (componentId: string) => {
        if (userContext.userManager.isTestUser) { // only for admins at the moment
            helpContext.operations.setContextualHelpForComponent(componentId);
            assistantContext.setSelectedTab(AssitantTab.HELP)
            layoutContext.setSidebarVisible(true);

            const additionalMetric = { name: 'componentId', value: componentId };
            AxiomMetricsDriver.publishButtonClick('HelpContextualLink', 'useHelpContextualLink', [additionalMetric]);
        }
    };
}

export const HelpContextualLink: FC<HelpContextualLinkProps> = (props) => {

    const { componentId } = props;

    const helpContext = useContext(HelpContext);
    const setContextualLink = useHelpContextualLink();

    return (
        <KatPopover trigger-type='hover' onClick={() => setContextualLink(componentId)}>
            <KatIcon className='pl-1' name='help_outline' size='tiny' slot='trigger'/>
            More info
            <UserRestrictedComponent componentId={ComponentID.HEADER_ADMIN_RIGHTS_ID}>
                <div>
                    {' '}[{componentId}]
                    <CopyToClipboardButton textToCopyProvider={() => componentId}/>
                    <AjudaPageEditLink currentGuid={helpContext.contextualMappingPageId!}/>
                </div>
            </UserRestrictedComponent>
        </KatPopover>
    );
}

import React from 'react';
import { CerebrumService } from "src/service/CerebrumCoralService";
import { KatBox, KatSpinner } from '@amzn/katal-react';
import { ErrorBoundComponent, ErrorBoundState } from '../error/ErrorBoundComponent';
import { AxiomMetricsDriver, MetricStatus } from "src/metrics/AxiomMetricsDriver";
import * as KatalMetrics from "@katal/metrics";
import { KPICardLoader } from './KPICardLoader';
import { FlashBriefingCardLoader } from './FlashBriefingCardLoader';
import { AxiomIsItDown } from 'src/components/common/AxiomIsItDown';
import { NarrativeTemplateBanner } from "src/components/common/NarrativeTemplateBanner";
import { UserContext } from "src/context/UserContext";
import { TenantConfig } from "src/user/TenantConfigManager";

export interface FlashBriefingContainerState extends ErrorBoundState {
    categorizedCardMetadata?: CardMetadataCategories;
    additionalMetrics: { name: string, value: any }[];
}

interface CardMetadataCategories {
    kpiCards: Cerebrum.CardMetadata[],
    flashBriefingCards: Cerebrum.CardMetadata[],
    infoCards: Cerebrum.CardMetadata[];
}

export interface FlashBriefingContainerProps {
    entityId: string;
    pageName: string;
    isItDownComponentId: string;
    showKpiCardsRow?: boolean;
}

export default class FlashBriefingContainer extends ErrorBoundComponent<FlashBriefingContainerProps, FlashBriefingContainerState> {
    private componentName: string = "FlashBriefing";
    private timeOnPageMetric = new KatalMetrics.Metric.TimerStopwatch('pageTimer').withMonitor();
    static contextType = UserContext;
    context!: React.ContextType<typeof UserContext>;

    constructor(props: FlashBriefingContainerProps) {
        super(props);
        this.state = {
            hasError: false,
            errorTitle: 'wfm_axiom_v2_flash_briefing_error',
            additionalMetrics: [
                { name: "entityId", value: props.entityId },
                { name: "pageName", value: props.pageName },
            ]
        };
        this.errorMetricHandler = this.loadComponentErrorMetricHandler.bind(this);
        this.buildContent = this.buildContent.bind(this);
    }

    private loadComponentErrorMetricHandler() {
        AxiomMetricsDriver.publishComponentLoadError(this.componentName, MetricStatus.Failure, this.state.additionalMetrics);
    }

    public async componentDidMount() {
        await this.viewUserUpdate();
        this.publishPageLoad();
    }

    async viewUserUpdate(): Promise<void> {
        const { pageName, entityId } = this.props;
        document.title = `${pageName} - ${entityId}`;
        try {
            const cardsMetadata = await CerebrumService.getCardsMetadata(entityId, pageName);
            const categorizedCardMetadata = categorizeCards(cardsMetadata.cards, flashBriefingCategoryFilter);
            this.setState({ categorizedCardMetadata });
            this.publishPageLoad();
        }
        catch (exception: any) {
            this.handleError(exception);
        }
    }

    async componentDidUpdate(prevProps: Readonly<FlashBriefingContainerProps>) {
        if (this.props === prevProps) {
            return;
        }
        if (this.props.entityId !== prevProps.entityId) {
            this.setState({ categorizedCardMetadata: undefined }); // Clears the page while the user waits for the new data
            await this.viewUserUpdate();
            this.publishPageLoad();
        }
    }

    componentWillUnmount() {
        if (this.timeOnPageMetric) {
            AxiomMetricsDriver.publishMonitor(this.timeOnPageMetric, this.componentName + ".timer", this.state.additionalMetrics);
        }
        super.componentWillUnmount();
    }

    render() {
        const categorizedCardMetadata = this.state.categorizedCardMetadata;
        const errorContent = super.renderError();
        if (errorContent) { return errorContent; }
        else if (!categorizedCardMetadata) { return <KatSpinner />; }
        else { return this.buildContent(categorizedCardMetadata); }
    }

    private buildContent(cardMetadataCategories: CardMetadataCategories) {
        const { isItDownComponentId, showKpiCardsRow, pageName} = this.props;
        const tenantConfiguration = TenantConfig.getTenantConfig();
        const {bizmonDefaults, cardSort} = tenantConfiguration.webAppConfig.flashBriefingConfig;
        return (
            <div className={`flashBriefing-container-${pageName.toLowerCase()}`}>
                <div className='pl-2 pr-3'>
                    <AxiomIsItDown componentId={isItDownComponentId}/>
                </div>
                <>
                    {showKpiCardsRow &&
                    <KPICardGrid
                        cards={cardMetadataCategories.kpiCards}
                        cardSortConfig={cardSort}
                        {...this.props}
                    />}
                    <FlashBriefingMainCardGrid
                        cards={[...cardMetadataCategories.infoCards, ...cardMetadataCategories.flashBriefingCards]}
                        bizmonDefaults={this.props.pageName === 'Metrics' ? bizmonDefaults : {}} // To be removed or generalized once dashboard in Landing page is defined
                        cardSortConfig={cardSort}
                        {...this.props}
                    />
                </>
            </div>
        );
    }

    private publishPageLoad() {
        AxiomMetricsDriver.publishPageLoad(this.componentName, window.location.href, this.state.additionalMetrics);
    }
}

interface CardGridProps {
    entityId: string;
    cards?: Cerebrum.CardMetadata[];
    cardSortConfig: string[];
}

interface FlashBriefingMainCardGridProps extends CardGridProps {
    bizmonDefaults: { [cardTitle: string]: WFM.DefaultBizmonCard; };
}

function FlashBriefingMainCardGrid(props: FlashBriefingMainCardGridProps) {
    const defaultCards = props.cards && props.bizmonDefaults ?
        generateDefaults(props.cards, props.bizmonDefaults, props.entityId) : props.cards;
    const orderedCards = defaultCards ? orderCards(defaultCards, props.cardSortConfig) : defaultCards;
    return (
        <div className="flashBriefing-card-grid">
            {orderedCards?.map((card: Cerebrum.CardMetadata) => (
                <FlashBriefingCardLoader
                    entityId={props.entityId}
                    cardMetadata={card}
                    key={card.cardId}
                />
            ))}
        </div>
    );
}

function KPICardGrid(props: CardGridProps) {
    const orderedCards = props.cards ? orderCards(props.cards, props.cardSortConfig) : props.cards;

    const { entityId } = props;

    return (
        <KatBox variant="white-shadow" className="kpi-card-grid-container">
            <div className="kpi-card-grid">
                {orderedCards?.map((card: Cerebrum.CardMetadata) => (
                    <KPICardLoader
                        entityId={props.entityId}
                        cardMetadata={card}
                        key={card.cardId}
                    />
                ))}
            </div>
            <NarrativeTemplateBanner entityId={entityId}
                                     componentName='flash-briefing'
                                     templateName='kpi-cards-grid-banner'/>
        </KatBox>
    );
}

const flashBriefingCategoryFilter: arrayFilterType<Cerebrum.CardMetadata, CardMetadataCategories> = element => {
    const cardType = element.cardType;
    if (cardType === "kpiCard") {
        return "kpiCards";
    }
    else if (cardType === "infoCard") {
        return "infoCards";
    }
    else {
        return "flashBriefingCards";
    }
};

type arrayFilterType<T, U> = (element: T, index: number, elements: T[]) => keyof U;

function categorizeCards(array: Cerebrum.CardMetadata[], arrayFilter: arrayFilterType<Cerebrum.CardMetadata, CardMetadataCategories>): CardMetadataCategories {
    const result: CardMetadataCategories = {
        kpiCards: [],
        flashBriefingCards: [],
        infoCards: []
    };
    array.forEach((element, index, metadata) => {
        const category = arrayFilter(element, index, metadata);
        result[category].push(element);
    });
    return result;
}

// Order cards based on priority if defined. If not, by card titles, following the order in the tenant configuration
function orderCards(cards: Cerebrum.CardMetadata[], cardTitleOrderConfig: string[]): Cerebrum.CardMetadata[] {

    cards.sort((cardA: Cerebrum.CardMetadata, cardB: Cerebrum.CardMetadata) => {
        if (cardA.priority === 0 && cardB.priority === 0) {
            return sortByCardTitle(cardA, cardB);
        } else {
            return sortByPriority(cardA, cardB);
        }
    });

    function sortByCardTitle(cardA: Cerebrum.CardMetadata, cardB: Cerebrum.CardMetadata) {
        const orderOfCardA = getOrderForCardFromConfig(cardA);
        const orderOfCardB = getOrderForCardFromConfig(cardB);
        if (orderOfCardA === orderOfCardB) {
            // provide a consistent order if not defined by priority or config
            return sortByTitleAlphabetically(cardA, cardB);
        }
        return orderOfCardA - orderOfCardB; // lower index to go first
    }

    function getOrderForCardFromConfig(card: Cerebrum.CardMetadata) {
        let orderIndex = cardTitleOrderConfig.indexOf(card.cardTitle);
        if (orderIndex === -1) {
            orderIndex = Number.MAX_VALUE; // if not in order array send to the end
        }
        return orderIndex;
    }

    function sortByTitleAlphabetically(cardA: Cerebrum.CardMetadata, cardB: Cerebrum.CardMetadata) {
        return cardA.cardTitle < cardB.cardTitle ? -1 : 1;
    }

    function sortByPriority(cardA: Cerebrum.CardMetadata, cardB: Cerebrum.CardMetadata) {
        return cardB.priority - cardA.priority; // higher priority to go first
    }

    return cards;
}

function generateDefaults(cards: Cerebrum.CardMetadata[], bizmonDefaults: { [cardTitle: string]: WFM.DefaultBizmonCard; },
    entityId: string)
    : Cerebrum.CardMetadata[] {
    Object.keys(bizmonDefaults).forEach(key => {
        if (!cards.find(card => card.cardTitle === key)) {
            cards.push(createBizmonDefaultCard(key, bizmonDefaults[key], entityId));
        }
    });
    return cards;
}

function createBizmonDefaultCard(cardTitle: string, defaultCard: WFM.DefaultBizmonCard, entityId: string)
    : Cerebrum.Card {
    return {
        cardTitle,
        cardType: "infoCard",
        cardId: `${Math.random()}`,
        cardContent: {
            "infoItems": `[{\"date\": \"Today\", \"body\": \"${defaultCard.body}\", \"readMoreLabel\": \"Read more...\", \"readMoreUrl\": \"${defaultCard.url}${entityId}\"}]`,
            delayFrequency: "weekly"
        },
        createdDate: "invalid",
        datasetDate: "invalid",
        publisherName: "default",
        tenantId: "invalid",
        priority: 0,
        entityId: ""
    };
}

export const functionsToTest = {
    orderCards
};
import { AxiomMetricsDriver } from "src/metrics/AxiomMetricsDriver";

type AdditionalMetric = {
    name: string,
    value: string
};

const logLinkClick = (
    linkName: string,
    url: string,
    additionalMetrics: AdditionalMetric[],
    componentName: string,
    isAuxClick?: boolean
) => {
    return () => AxiomMetricsDriver.publishLinkClick(componentName, linkName, url, [
        ...additionalMetrics,
        { name: 'isAuxClick', value: (!!isAuxClick).toString() }
    ]);
};

const logButtonClick = (
    componentName: string,
    buttonName: string,
    additionalMetrics: AdditionalMetric[]
) => {
    AxiomMetricsDriver.publishButtonClick(componentName, buttonName, additionalMetrics);
}

export {
    logLinkClick,
    logButtonClick
};
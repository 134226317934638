import React, { PropsWithChildren, useState } from "react";

export enum AssitantTab {
    NOTIFICATIONS = 'notifications',
    TASKS = 'tasks',
    HELP = 'help'
}


interface AssistantContext {
    creatingTask: boolean;
    startCreateTask: (partialTask: Cerebrum.Tasks.Task) => void;
    clearCreateTask: () => void;

    currentTask?: Cerebrum.Tasks.Task;

    selectedTab: AssitantTab;
    setSelectedTab: (tab: AssitantTab) => void;
}

const defaultAssistantContext: AssistantContext = {
    creatingTask: false,
    startCreateTask: (partialTask => partialTask),
    clearCreateTask: () => undefined,

    currentTask: undefined,

    selectedTab: AssitantTab.TASKS,
    setSelectedTab: (tab) => tab
};

export const AssistantContext = React.createContext<AssistantContext>(defaultAssistantContext)

interface AssistantContextProps {
    children: any;
}

export const AssistantContextProvider = (props: PropsWithChildren<AssistantContextProps>) => {

    const [creating, setCreating] = useState(false);
    const [current, setCurrent] = useState<Cerebrum.Tasks.Task>();
    const [_selectedTab, _setSelectedTab] = useState(AssitantTab.TASKS);

    const context: AssistantContext = {
        creatingTask: creating,
        startCreateTask: (partialTask: Cerebrum.Tasks.Task) => {
            setCreating(true);
            setCurrent(partialTask);
        },
        clearCreateTask: () => setCreating(false),

        currentTask: current,

        selectedTab: _selectedTab,
        setSelectedTab: _setSelectedTab
    }

    return (
        <AssistantContext.Provider value={context}>
            {props.children}
        </AssistantContext.Provider>
    );
}

import React from "react";
import { getLocalTimezoneMoment } from "src/components/common/DateFormatter";

interface NotificationDateLabelParams {
    date: string;
    className: string;
    handleClick?: () => void;
}

export const NotificationDateLabel: React.FC<NotificationDateLabelParams> = (props: NotificationDateLabelParams) => {
    const { date, className, handleClick } = props;

    const dateLabel = 'Created ' + getLocalTimezoneMoment(date).fromNow();
    return <span onClick={handleClick} className={className}>{dateLabel}</span>;
};

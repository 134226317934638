import React from 'react';
import {
    IsItDownOnSteroids,
    IsItDownOnSteroidsProps
} from '@amzn/is-it-dow-on-steroids-react';
import { IsItDown } from './IsItDown'
import { Weblabs } from "src/user/WeblabsManager";
import { Treatment, Weblab } from "src/user/Weblab";

const DEFAULT_DASHBOARD_ID = 'wfm-axiom';

interface AxiomIsItDownProps {
    componentId: string; // either a number Id or the component alias
    dashboardId?: string;
}

export const AxiomIsItDown = React.memo((props: AxiomIsItDownProps) => {
    const { componentId, dashboardId } = props;
    const IsItDownProps: IsItDownOnSteroidsProps = {
        url: `https://is-it-down.amazon.com/${dashboardId || DEFAULT_DASHBOARD_ID}/components/${componentId ?? 'audit-beta'}`,
        intervalTime: 100000,
        type: "default",
        position: "fixed",
        alignment: "top",
        top: "",
        right: "",
        bottom: "",
        left: "",
        tooltipAlignment: ""
    };

    return (
        <>
            {Weblabs.isUserInWeblabAndTreatment(Weblab.IS_IT_DOWN, Treatment.T1)
            ?    <IsItDown componentId={componentId} dashboardId={dashboardId}/>
            :    <IsItDownOnSteroids {...IsItDownProps} />}
        </>
    );
});
import { UserManager } from "src/user/UserIdentityManager";
import { CerebrumService } from "src/service/CerebrumCoralService";

const buildGetFileInput = (fileName: string, componentName: string, locale: string, entityId: string): Cerebrum.GetNarrativeTemplateInput => {
    return {
        templateName: fileName,
        locale: localStorage.getItem('i18nextLng') || locale,
        component: componentName,
        entityId
    };
}

/**
 * Converts a path composed by <tenant>/<component>/<locale>/<filename> into the input for the backend call
 * "CerebrumService.getFile" which returns the base64 encoded content of the given file.
 *
 * Then with the retrieved content, builds and returns the src string for an html img tag, like
 * `data:image/png;base64,[base 64 encoded string with the image content here]`
 *
 * @param imagePath
 */
const retrieveImage = async (imagePath: string) => {
    // e.g.: test/help/en-US/nested/folders/trash.txt
    // i.e.: tenant/component/locale/optional/folders/filename
    const parts = imagePath.replace('\\', '/')
        .split('/');
    const [tenant, componentName, locale, ...filePathParts] = parts;
    const filePath = filePathParts.join('/');

    const entityId = UserManager.userIdentity?.login!;

    const input = buildGetFileInput(filePath, componentName, locale, entityId);

    const imageContent = await CerebrumService.getFile(input)
        .then(response => response.content)
        // tslint:disable-next-line:no-console
        .catch(error => console.log(`Unable to file ${imagePath}: ${JSON.stringify(error)}`));

    return `data:image/png;base64,${imageContent}`;
}

/**
 * Helper function to allow replacements on matches of regex in content using an async function.
 *
 * The trick consist in performing one pass identifying the matching expressions while enqueuing a promise
 * to execute the given asyncFn for each match.
 * Then once all the promises are resolved, perform a second pass, this time replacing the matches with the result of
 * their respective result.
 *
 * @param content
 * @param regex
 * @param asyncFn
 */
const replaceAsync = async (content: string, regex: RegExp, asyncFn: (match: string, ...parts: any[]) => Promise<string>) => {
    const promises: Promise<string>[] = [];

    // first pass, creating a promise for each match
    content.replace(regex, (match, ...args) => {
        const promise = asyncFn(match, args);
        promises.push(promise);
        return match;
    });

    // wait for all promises
    const data: string[] = await Promise.all(promises);

    // second pass, replacing matches with the promised results
    return content.replace(regex, () => data.shift()!);
}

/**
 * Replaces instances of paths prefixed with AXIOM-IMAGE with the actual file content retrieved from our backend.
 * The path can be enclosed in either single or double quotes and use forward or backward slashes.
 *
 * E.g:
 *     from -> ...<img src='AXIOM-IMAGE/test/help/en-US/image.png'/>...
 *     into -> ...<img src='data:image/png;base64,[base 64 encoded string with the image content here]'/>...
 * or
 *     from -> ...<img width="500" src="AXIOM-IMAGE\test\help\en-US\image.png" height="600"/>...
 *     into -> ...<img width="500" src="data:image/png;base64,[base 64 encoded string with the image content here]" height="600"/>...
 *
 * @param content
 */
export async function loadAxiomImages(content: string): Promise<string> {
    // capture from AXIOM-IMAGE and a slash, until the next quote
    const pattern = "(AXIOM-IMAGE[\\\\\\/])([\\w\\\\\\/\\-\\.\\s]*)(?=['\"])";
    const regex = new RegExp(pattern, 'g');

    return await replaceAsync(content, regex,
        // parts[1] represents the second group in our pattern, which will be the path after AXIOM-IMAGE
        (match: string, parts: any[]) => retrieveImage(parts[1]));
}
export enum HeaderRoute {
    HOME = '/',
    BALANCED_SCORE_CARD = '/bsc',
    REVIEWS = '/reviews',
    AUDIT = '/audit',
    DEV_TOOLS = '/dev-tools',
    METRICS = '/metrics',
    MY_PERFORMANCE = '/myp',
    SUPPORT = '/support',
    HELP = '/help',
    TASKS_LOG = '/tasks',
    NOTIFICATIONS_LOG = '/notifications',
    FORMS = '/forms',
    FORMS_SUBMIT = '/forms/submit',
    FORMS_EDIT = '/forms/edit',
    FORMS_VIEW = '/forms/view',
    FORMS_DESIGN = '/forms/design',
    ABOUT = '/about'
}

export enum HeaderRouteHelpPage {
    HOME = 'Home',
    BALANCED_SCORE_CARD = 'BSC',
    AUDIT = 'Audit',
    DEV_TOOLS = 'DevTools',
    METRICS = 'Metrics',
    MY_PERFORMANCE = 'MyPerformance',
    SUPPORT = 'Support',
    HELP = 'Help',
    TASKS_LOG = 'Tasks',
    NOTIFICATIONS_LOG = 'Notifications',
    FORMS = 'Forms'
}

import React from 'react';
import ReactDOM from 'react-dom';
import EventEmitter from 'src/context/EventEmitter';
import { TableMetrics } from "./TableMetrics";
import { MoreActionsDropdown } from './MoreActionsDropdown';
import { KatIcon, KatLink } from '@amzn/katal-react';
import { ParagonSignedUrlLink } from "src/components/audit/table/ParagonSignedUrlLink";
import { ACTION_RESPONSE_PREFIX } from "src/components/forms/extensions/ExtensionVisitor";
import { formatCreatedDateTime } from "src/components/common/DateFormatter";
import { MetadataLabel } from "src/components/common/MetadataLabel";
import { AuditStatusEnum } from "src/components/audit/Audit";

// TODO: Pull the constants to seperate file
const REGION_ID_MAP: { [key: string]: string; } = {
    1: 'na',
    2: 'eu',
    3: 'fe',
    4: 'cn'
};

enum KatalChannelIcons {
    WORKITEM = "email", VOICE = "call", CHAT = "chat-bubbles"
};

enum FalconChannelIcons {
    EMAIL = "email", CHAT = "chat-bubbles", PHONE = "call", VOICE = "call", OTHER = "help"
}

const COMPONENT_METRIC_NAME = "Audit";

const AUDIT_TYPES = {
    'ATM': 'atm',
    'TITUS': 'titus'
};

const PARAGON_AUDIT_TEMPLATE_NAMES = {
    atm: 'BASE_FORM',
    titus: 'TITUS_FORM'
};

const KEY_METRICS = {
    AUDIT_CASE_ID_CLICK: "caseId",
    AUDIT_TICKET_ID_CLICK: "ticketId",
    AUDIT_BEGIN_CLICK: "beginAudit",
    AUDIT_REPORT_INVALID_CLICK: "reportInvalid",
    AUDIT_MARK_AS_COMPLETE_CLICK: {
        check: "markAsCompletedCheck",
        uncheck: "markAsCompletedCheck"
    }
};

export class ColumnFormatter {
    // Common Column Formatters

    static agent(rowIndex: number, rowData: Cerebrum.Audits.RowData) {
        const text = `${rowData.agentName}
        ${rowData.agent}`;
        return { text: text };
    }

    /** Capitalizes first letter of the status  */
    static auditStatus(rowIndex: number, rowData: Cerebrum.Audits.RowData) {
        let status: string = rowData.status;
        status = `${status.charAt(0).toUpperCase()}${status.slice(1)}`;
        return { text: status };
    }

    static checkboxAuditCompleted(rowIndex: number, rowData: Cerebrum.Audits.RowData) {
        const attributes = { "style": "width: 20px;" };
        const checkElement = ColumnFormatter.createAuditCheckBoxElement(rowIndex, rowData);
        return { element: checkElement, attributes: attributes };
    }

    /** Creates a checkbox to mark the audit completed, with event listener attached */
    private static createAuditCheckBoxElement(rowIndex: number, rowData: Cerebrum.Audits.RowData, disabled: boolean = false) {
        const checkElement = document.createElement("kat-checkbox");
        checkElement["id"] = `check-${rowIndex}-${rowData.caseId}`;
        // @ts-ignore, doesn't recognize kat-checkbox properties
        checkElement["checked"] = rowData.status.toLowerCase() === "completed";
        // @ts-ignore, doesn't recognize kat-checkbox properties
        checkElement["disabled"] = disabled;
        checkElement.addEventListener("change", (event: any) => {
            ColumnFormatter._handleCompleteAuditAction(event, rowData);
        });
        return checkElement;
    }

    /** Helper function for to handle the Metrics and event dispatch for audit completion */
    private static _handleCompleteAuditAction(event: any, rowData: Cerebrum.Audits.RowData) {
        const isChecked: boolean = event?.detail?.checked;
        const payload = { isCompleted: isChecked, rowData };
        EventEmitter.dispatch('complete-audit-event', payload);

        // Publish Metrics
        const additionalMetrics = TableMetrics.buildAdditionalMetrics(rowData);
        additionalMetrics.push({ name: 'caseId', value: rowData.caseId });
        TableMetrics.publishButtonClick(KEY_METRICS.AUDIT_MARK_AS_COMPLETE_CLICK[isChecked ? "check" : "uncheck"], additionalMetrics);
    }

    /**
     * Creates the case id link element with params based on audit type
     */
    static caseId(rowIndex: number, rowData: Cerebrum.Audits.RowData, previouslyRenderedElement: any) {
        let divEl = document.createElement("div");
        const paragonSecureLinkEl = ColumnFormatter._getParagonSecureLinkButton(rowData, rowData.caseId,
            ColumnFormatter._paragonURLSearchParams(rowData));
        ReactDOM.render(paragonSecureLinkEl, divEl);

        return { element: divEl };
    }

    /**
     * Creates the paragon audit inline link without case id parameter because it's added by the signed link
     * automatically
     */
    private static _paragonURLSearchParams(rowData: Cerebrum.Audits.RowData): URLSearchParams {
        const { externalAuditId, auditType, agent } = rowData;
        const paramsKeyMap: { [key: string]: Axiom.Map; } = {
            atm: {
                mediaLegId: externalAuditId,
                templateName: PARAGON_AUDIT_TEMPLATE_NAMES.atm
            },
            titus: {
                ticketId: externalAuditId,
                templateName: PARAGON_AUDIT_TEMPLATE_NAMES.titus,
                associateLogin: agent
            }
        };
        return new URLSearchParams(paramsKeyMap[auditType]);
    }

    /**
     * Creates the case audit link including case id parameter for autocompletion purposes
     */
    private static _caseAuditURLSearchParams(rowData: Cerebrum.Audits.RowData): URLSearchParams {
        const { caseId, externalAuditId, auditType, agent } = rowData;
        const paramsKeyMap: { [key: string]: Axiom.Map; } = {
            atm: {
                caseId: caseId,
                mediaLegId: externalAuditId,
                templateName: PARAGON_AUDIT_TEMPLATE_NAMES.atm
            },
            titus: {
                caseId: caseId,
                ticketId: externalAuditId,
                templateName: PARAGON_AUDIT_TEMPLATE_NAMES.titus,
                associateLogin: agent

            }
        };
        return new URLSearchParams(paramsKeyMap[auditType]);
    }

    /**
     * Returns the paragon origin with proper region based on given caseId
     *
     * eg. https://paragon-na.amazon.com
     */
    private static _paragonOrigin(caseId: string) {
        return `https://paragon-${ColumnFormatter._paragonRegion(caseId)}.amazon.com`;
    }

    /**
     * Returns region of the given paragon case
     *
     * eg. na, eu, fe
     */
    private static _paragonRegion(caseId: string) {
        return REGION_ID_MAP[caseId.slice(-1)] || 'na';
    }

    static moreActions(rowIndex: number, rowData: Cerebrum.Audits.RowData, previouslyRenderedElement: any) {
        let divEl = document.createElement("div");
        const dropDownEl = MoreActionsDropdown({ rowData: rowData });
        ReactDOM.render(dropDownEl, divEl);
        return { element: divEl };
    }

    // ===
    // Titus Formatters
    static ticketId(rowIndex: number, rowData: Cerebrum.Audits.TitusRowData, previouslyRenderedElement: any) {
        const { ticketId } = rowData;

        const element = (previouslyRenderedElement ? previouslyRenderedElement : document.createElement("kat-link"));
        if (!previouslyRenderedElement) {
            element.variant = "link";
            element.target = "_blank";
        }
        element.id = `case-${rowIndex}-${ticketId}`;
        element.href = ColumnFormatter._buildTicketIdHref(rowData);
        element.label = ticketId;

        const additionalMetrics = TableMetrics.buildAdditionalMetrics(rowData);
        element.onclick = element.onauxclick = () => {
            TableMetrics.publishLinkClick(KEY_METRICS.AUDIT_TICKET_ID_CLICK, element.href, additionalMetrics);
        };

        return { element };
    }

    /**
     * Builds the proper href to view the ticket based on the user type
     *
     * Inhouse users view normally with the tt url
     *
     * OutSourced users view via the paragon diagnostics page
     */
    static _buildTicketIdHref(rowData: Cerebrum.Audits.TitusRowData) {
        const { caseId, ticketId, isOutSourced } = rowData;
        const paragonOrigin = ColumnFormatter._paragonOrigin(caseId);
        const inHouseUrl = new URL(`https://tt.amazon.com/${ticketId}`);
        // Build OS URL for diagnostic tool
        const diagnosticSearchParams = new URLSearchParams({
            resourcePath: 'ViewTicket',
            ticket_id: ticketId,
            region: ColumnFormatter._paragonRegion(caseId).toUpperCase()
        });
        const outSourceUrl = new URL(`/hz/diagnostic/show?${diagnosticSearchParams}`, paragonOrigin);

        return (isOutSourced ? outSourceUrl : inHouseUrl).href;
    }

    /**
     * Creates icon with tooltip which links directly to the Titus audit form in Quality Hub
     *
     * This is temporary for testing until Audit inline feature in up and running
     */
    static beginAuditLink(rowIndex: number, rowData: Cerebrum.Audits.CustomerObsessionRowData, previouslyRenderedElement: any) {
        const tooltipEl = previouslyRenderedElement ? previouslyRenderedElement : document.createElement("kat-tooltip");

        if (!previouslyRenderedElement) {
            tooltipEl["position"] = "top";
            tooltipEl["label"] = `Begin Audit`;
        }

        const href = ColumnFormatter._buildBeginAuditHref(rowData);
        const beginAuditEl = (
            <KatLink href={href} target='_blank'>
                <KatIcon name='launch' size='small'>
                </KatIcon>
            </KatLink>
        );

        const divEl = document.createElement("div");
        ReactDOM.render(beginAuditEl, divEl);
        tooltipEl["triggerText"] = divEl;

        const additionalMetrics = TableMetrics.buildAdditionalMetrics(rowData);
        tooltipEl.onclick = tooltipEl.onauxclick = () => {
            TableMetrics.publishLinkClick(KEY_METRICS.AUDIT_BEGIN_CLICK, href, additionalMetrics);
        };

        return { element: tooltipEl };
    }

    /** Falcon audits can only be system completed. Checkbox is disabled */
    static checkboxFalconCompleted(rowIndex: number, rowData: Cerebrum.Audits.RowData) {
        const attributes = { "style": "width: 20px;" };
        const checkElement = ColumnFormatter.createAuditCheckBoxElement(rowIndex, rowData, true);
        return { element: checkElement, attributes: attributes };
    }


    static beginFalconIntrospectionLink(rowIndex: number, rowData: Cerebrum.Audits.FalconIntrospectionRowData, previouslyRenderedElement: any) {
        const AUDIT_TO_COMPLETE = ACTION_RESPONSE_PREFIX+'audit_id'
        const relativePath = rowData.auditFormUrl.split("#")[1];

        // add falcon audit id to the url params, so it can be handled as an extension
        // build form link relatively to avoid wrong origin if user is in a different region
        const href = `${window.location.origin}/#${relativePath}&${AUDIT_TO_COMPLETE}=${rowData.externalAuditId}`;

        return ColumnFormatter.toTableElement(<MetadataLabel
            name='FalconOpenForm'
            href={rowData.status === AuditStatusEnum.COMPLETED ? '' : href}
            key={rowData.externalAuditId}
            iconName={'assignment'}
            target={'_blank'}
        />);
    }

    static falconIntrospectionDate(rowIndex: number, rowData: Cerebrum.Audits.FalconIntrospectionRowData) {
        const formattedDate = formatCreatedDateTime(rowData.actionDate)
        return { text: formattedDate };
    }

    static falconWikiLink(rowIndex: number, rowData: Cerebrum.Audits.FalconIntrospectionRowData) {
        return ColumnFormatter.toTableElement(<MetadataLabel
            name='FalconWikiLink'
            label={rowData.falconRuleId}
            href={rowData.falconWikiUrl}
            key={rowData.externalAuditId}
            target={'_blank'}
        />);
    }

    // TODO: Change for typedMetadataLabel: Case, once the current error w/ signed Paragon Links is resolved
    static falconCaseId(rowIndex: number, rowData: Cerebrum.Audits.FalconIntrospectionRowData) {
        return ColumnFormatter.toTableElement(<MetadataLabel
            name='FalconCaseId'
            label={rowData.caseId}
            href={rowData.paragonCaseUrl}
            labelPrefix={'Case: '}
            iconName={'launch'}
            iconSize={'tiny'}
            target={'_blank'}
        />);
    }

    static falconCaseChannel(rowIndex: number, rowData: Cerebrum.Audits.FalconIntrospectionRowData) {
        const iconName = FalconChannelIcons[rowData.caseChannel.toUpperCase() as keyof typeof FalconChannelIcons];
        return ColumnFormatter.toTableElement(<MetadataLabel
            name='FalconCaseChannel'
            iconName={iconName}
            label={!iconName ? rowData.caseChannel : ''}
            key={rowData.caseChannel + '-' + rowData.externalAuditId}
        />);
    }


    private static toTableElement(renderedElement: JSX.Element) {
        const divEl = document.createElement("div");
        ReactDOM.render(renderedElement, divEl);
        return {element: divEl};
    }

    /** Provides a url href to the paragon case-audit page with params needed for audit inline */
    private static _buildBeginAuditHref(rowData: Cerebrum.Audits.CustomerObsessionRowData) {
        const paragonOrigin = ColumnFormatter._paragonOrigin(rowData.caseId);
        const searchParams = ColumnFormatter._caseAuditURLSearchParams(rowData).toString();
        const url = new URL(`/hz/case-audit?${searchParams}`, paragonOrigin);
        return url.href;
    }

    static ticketResolveDate(rowIndex: number, rowData: Cerebrum.Audits.TitusRowData, previouslyRenderedElement: any) {
        const formattedDate = formatCreatedDateTime(rowData.resolvedDateUtc)
        return { text: formattedDate };
    }

    static ticketAssignedToGroup(rowIndex: number, rowData: Cerebrum.Audits.TitusRowData, previouslyRenderedElement: any) {
        return { text: rowData.assignedGroup };
    }

    static ticketRootCause(rowIndex: number, rowData: Cerebrum.Audits.TitusRowData, previouslyRenderedElement: any) {
        return { text: rowData.rootCause };
    }

    private static _getParagonSecureLinkButton(rowData: Cerebrum.Audits.RowData, displayText: string, extraParams: URLSearchParams) {
        const additionalMetrics = TableMetrics.buildAdditionalMetrics(rowData);
        return (
            <ParagonSignedUrlLink caseId={rowData.caseId}
                                  displayText={displayText}
                                  extraParams={extraParams}
                                  metricsId={KEY_METRICS.AUDIT_CASE_ID_CLICK}
                                  additionalMetrics={additionalMetrics}/>
        );
    }
}
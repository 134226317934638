const Cookies = require('js-cookie');

export const FAILED_AUTHENTICATION_STATUS = 401;

export const AUTHENTICATION_REDIRECTING_CODE = '__redirecting_for_authentication__';

const AXIOM_REDIRECT_COOKIE = 'axiom-redirect';

export class AuthenticationService {

    public static redirectFromLocationHeader(response: Response, overrideParams: { [key: string]: string } = {}): boolean {
        const locationFromResponse = AuthenticationService.getLocationFromResponse(response);
        if (locationFromResponse) {
            const federateUrl: URL = new URL(locationFromResponse);
            for (const [key, value] of Object.entries(overrideParams)) {
                federateUrl.searchParams.set(key, value);
            }
            window.location.replace(federateUrl.href);
            return true;
        } else {
            console.error("No redirect location specified in 401 response.");
            return false;
        }
    }

    public static getAuthenticatedRedirectUrl(): string | null {
        return Cookies.get(AXIOM_REDIRECT_COOKIE);
    }

    /**
     * Handles authentication errors, can be used for both then and catch flows. This because 302 will return as a
     * resolved promise but 401 will return as a rejected promise.
     * @param response
     */
    public static handleRedirection(response: Response) {
        if (response.status === FAILED_AUTHENTICATION_STATUS) {
            this.createAuthRedirectCookie();
            // Replace the redirect uri with the auth.html page from the UI.
            const overrideParams = {
                redirect_uri: this.getAuthUrl().href
            };
            if (this.redirectFromLocationHeader(response, overrideParams)) {
                return Promise.reject(AUTHENTICATION_REDIRECTING_CODE);
            } else {
                return Promise.reject("Failed to redirect ");
            }
        }
        return Promise.resolve(response);
    }

    private static getAuthUrl(): URL {
        const homeUrl: URL = new URL(window.location.href);
        return new URL(`${homeUrl.protocol}//${homeUrl.hostname}:${homeUrl.port}/auth.html`);
    }

    private static getLocationFromResponse(response: Response): string | null {
        return response.headers.get("Location");
    }

    private static createAuthRedirectCookie(): void {
        Cookies.set(AXIOM_REDIRECT_COOKIE, window.location.href);
    }
}
import { API, Auth } from 'aws-amplify';
import { ConfigManager, UrlParameterConfigKeys } from "src/config/ConfigManager";


export async function setupAWSConfig(config: AxiomConfig.Configuration): Promise<boolean> {

    if (!config.amplify) {
        return false;
    }

    try{
        const {
            apiName,
            isBeta,
            apiConfig,
            authConfig
        } = config.amplify;

        // tslint:disable-next-line:no-console
        console.log(`Setting up amplify for: ${apiName}, beta: ${isBeta}`, );

        const callbackUrl = window.location.origin;

        const authenticationOptions = authConfig;
        // Need to set redirect urls for cognito auth on runtime
        authenticationOptions.oauth.redirectSignIn = callbackUrl;
        authenticationOptions.oauth.redirectSignOut = callbackUrl;

        // Set up Amplify
        Auth.configure(authenticationOptions);
        API.configure({
            ...apiConfig,
            graphql_headers: async () => ({
                idtoken: await getJWTToken(),
            }),
        });

        return true;
    } catch (error) {
        // tslint:disable-next-line:no-console
        console.error("Error running setupAWSConfig!", error);
    }
    return false;
}

export async function getJWTToken() {
    const session = await Auth.currentSession();
    return session.getIdToken().getJwtToken();
}
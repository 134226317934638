import React, { PropsWithChildren, useEffect, useState } from "react";
import { AxiomMetricsDriver } from "src/metrics/AxiomMetricsDriver";
import { UrlParameterConfigKeys } from "src/config/ConfigManager";

interface LayoutContext {
    isSidebarVisible: boolean;
    setSidebarVisible: (visible: boolean) => void;
    toggleSidebarVisible: () => void;

    isSidebarPinned: boolean;
    setSidebarPinned: (pinned: boolean) => void;
    toggleSidebarPinned: () => void;

    isBannerVisible: boolean;
    setBannerVisible: (visible: boolean) => void;

    isHeadlessMode: boolean;
}

const defaultLayoutContext: LayoutContext = {
    isSidebarVisible: true,
    setSidebarVisible: (visible: boolean) => visible,
    toggleSidebarVisible: () => undefined,

    isSidebarPinned: true,
    setSidebarPinned: (pinned: boolean) => pinned,
    toggleSidebarPinned: () => undefined,

    isBannerVisible: false,
    setBannerVisible: (visible: boolean) => visible,

    isHeadlessMode: false
};

export const LayoutContext = React.createContext<LayoutContext>(defaultLayoutContext)

interface LayoutContextProps {
    children: any;
}

const COMPONENT_NAME = 'LayoutContext';

export const LayoutContextProvider = (props: PropsWithChildren<LayoutContextProps>) => {

    // sidebar visible by default if the user has assistant features enabled (i.e.: only sups at the moment)
    const [visible, setVisible] = useState(true);
    const [pinned, setPinned] = useState(true);
    const [isBannerVisible, setBannerVisible] = useState(false);

    useEffect(() => {
        const additionalMetrics: Axiom.AdditionalMetric[] = [{ name: "window.location.href", value: window.location.href }];
        AxiomMetricsDriver.publishButtonClick(COMPONENT_NAME, visible ? 'sidebarVisible' : 'sidebarHidden', additionalMetrics);
    }, [visible])

    const toggleVisibility = () => {
        setVisible(current => !current);
    };

    const togglePin = () => {
        setPinned(current => !current);
    };

    const setBannerVisibleFlag = (value: boolean) => {
        setBannerVisible(value);
    }

    const isHeadlessMode = () => window.location.href.includes(UrlParameterConfigKeys.HEADLESS_MODE);

    const context: LayoutContext = {
        isSidebarVisible: !isHeadlessMode() && visible,
        setSidebarVisible: setVisible,
        toggleSidebarVisible: toggleVisibility,

        isSidebarPinned: pinned,
        setSidebarPinned: setPinned,
        toggleSidebarPinned: togglePin,

        isBannerVisible,
        setBannerVisible: setBannerVisibleFlag,

        isHeadlessMode: isHeadlessMode()
    }

    return (
        <LayoutContext.Provider value={context}>
            {props.children}
        </LayoutContext.Provider>
    );
}

import {Dispatch, SetStateAction} from "react";
import {useStateWithStorage} from "src/components/common/storage/UseStateWithStorage";

/**
 * Syncs between react functional component state with local storage.
 * @param localStorageKey unique identifer for local storage item
 * @param initialValue initial value for state if none available in storage
 */
export function useStateWithLocalStorage<T>(localStorageKey: string, initialValue: T): [T, Dispatch<SetStateAction<T>>] {
    return useStateWithStorage(localStorageKey, initialValue, window.localStorage);
}
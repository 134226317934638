import React, { FC, PropsWithChildren, useRef, useState } from "react";
import { KatButton, KatDatePicker, KatIcon } from "@amzn/katal-react";

interface ClearableDatePickerProps {
    label: string;
    onUpdate: (e: any) => void;
    value?: string;
}

export const ClearableDatePicker: FC<PropsWithChildren<ClearableDatePickerProps>> = (props) => {

    const { label, onUpdate } = props;

    const [value, setValue] = useState(props.value);
    const clearableRef = useRef<any>();

    const clearValue = () => {
        setValue('');
        clearableRef.current.value = '';
        onUpdate({ target: { value: null } })
    }

    return (
        <div className='clearable-container'>
            <KatDatePicker className='clearable-item'
                           ref={clearableRef}
                           label={label} value={value}
                           onChange={onUpdate}/>
            <KatButton className='clear-button' variant='link' onClick={clearValue}>
                <KatIcon title='Clear'
                         name='snooze'
                         size='tiny'/>
            </KatButton>
        </div>
    );
}
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";


/** Wraps react `useState` to sync with url search params.
 *
 * This is useful primarily to allow deeper linking from external sources and allows users to bookmark certain pages more specifically.
 * Unlike useSearchParamReader, this hook will update the url parameter named as {key} when the state returned changes.
 *
 * @note Because we use Hash router users will not be able to use the back button to revert through the state changes
 *
 * @argument key: the param key which appears in the URL e.g.: `?key=value`
 *
 * @argument initialValue?: If the param is not currently in the url this value will be used
 */
export function useStateWithSearchParams(key: string, initialValue?: string): [string | undefined, React.Dispatch<React.SetStateAction<string | undefined>>] {
    const history = useHistory();

    const { search } = useLocation();

    const readCurrentValue = () => {
        const queryValues = queryString.parse(search);
        return typeof queryValues[key] === 'string' ?
            queryValues[key] as string :
            undefined;
    }

    const [value, setValue] = useState(() => readCurrentValue() ?? initialValue);

    useEffect(() => {
        const queryValues = queryString.parse(search);
        queryValues[key] = value!;
        const searchString = queryString.stringify(queryValues);
        // Hash router does not allow pushing unless the hash changes
        // This means the 'back' button would do nothing, so we use history replace instead
        history.replace({ search: searchString });
    }, [value]);

    useEffect(() => {
        const newValue = readCurrentValue();
        if (newValue) {
            setValue(newValue);
        }
    }, [search]);

    return [value, setValue];
}
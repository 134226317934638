import React from "react";
import useIsItDown from "src/hooks/useIsItDown";
import { Levels } from "src/service/is_it_down_service/IsItDown.model";
import { KatAlert } from "@amzn/katal-react";
import * as DomPurify from "dompurify";

const buildMessageHtml = (htmlString: string) => {
    const sanitizedHTML = DomPurify.sanitize(htmlString);
    const htmlObject = {
        __html: sanitizedHTML
    };

    return <div dangerouslySetInnerHTML={htmlObject}></div>;
};

const getVariantPerLevel = (level: number | null) => {
    switch (level) {
        case Levels.DOWN:
            return "danger";
        case Levels.WARN:
            return "warning";
        case Levels.OK:
        default:
            return "info";
    }
};

export const IsItDown = (props: any) => {
    const { dashboard, componentId } = props;
    const { message, level, tickets } = useIsItDown(
        dashboard,
        componentId ?? "audit-beta"
    );
    const variant = getVariantPerLevel(level);

    return (
        <>
            {message && level !== 0 &&
            <KatAlert variant={variant}>
                {buildMessageHtml(message)}
                {tickets && <div>{tickets}</div>}
            </KatAlert>}
        </>
    );
};

import React, { Component } from 'react';
import { KatAlert, KatIcon, KatLink, KatSpinner } from '@amzn/katal-react';
import { UserManager } from "src/user/UserIdentityManager";
import { CoralError } from "src/components/error/ErrorBoundComponent";
import { TableMetrics } from "src/components/audit/table/TableMetrics";

interface ParagonSignedUrlLinkProps {
    caseId: string;
    displayTextPrefix?: string;
    displayText: string;
    metricsId: string;
    extraParams?: URLSearchParams;
    additionalMetrics?: Axiom.AdditionalMetric[];
}

interface ParagonSignedUrlButtonState {
    loading: boolean;
    error: boolean;
    caseId: string;
    displayTextPrefix?: string;
    displayText: string;
    metricsId: string;
    extraParams?: URLSearchParams;
    additionalMetrics?: Axiom.AdditionalMetric[];
}

/**
 * Component to handle the logic of getting the paragon signed url
 */
export class ParagonSignedUrlLink extends Component<ParagonSignedUrlLinkProps, ParagonSignedUrlButtonState> {

    constructor(props: ParagonSignedUrlLinkProps) {
        super(props);
        const { caseId, displayTextPrefix, displayText, extraParams, metricsId } = props;

        const metrics = props.additionalMetrics || [];
        metrics.push({ name: 'caseId', value: caseId });

        this.state = {
            loading: false,
            error: false,
            caseId,
            displayTextPrefix,
            displayText,
            extraParams,
            metricsId,
            additionalMetrics: metrics
        };
    }

    private paragonSignedUrl = (evt: any) => {
        evt.preventDefault();
        this.setState({
            loading: true
        }, () => this.fetchParagonSignedUrl());
    };

    private dismissError = () => this.setState({ error: false });

    private async fetchParagonSignedUrl() {
        const getSignedUrlInput: Cerebrum.Audits.GetParagonSignedUrlInput = {
            caseId: this.state.caseId,
            requesterId: UserManager.getUserIdentity().login
        };

        const response = await this.resolveParagonUrl(getSignedUrlInput);

        if (response) {
            const url = new URL(`${response.signedUrl}&${this.state.extraParams ?? ''}`);
            window.open(url.href, "_blank");
            this.setState({ loading: false });
            this.publishMetrics(response);
        }
    }

    private async resolveParagonUrl(_getSignedUrlInput: Cerebrum.Audits.GetParagonSignedUrlInput) {
        // const response = await CerebrumService.getParagonSignedUrl(getSignedUrlInput).catch(error => {
        //     this.handleError(error);
        // });
        // Workaround until paragon signed url starts working again
        const lastDigit = this.state.caseId.charAt(this.state.caseId.length - 1);
        const region = lastDigit === '1' ? 'na'
            : lastDigit === '2' ? 'eu' :
                'fe';
        const response = { signedUrl: `https://paragon-${region}.amazon.com/hz/case?caseId=${this.state.caseId}` };
        // \Workaround
        return response;
    }

    private publishMetrics(response: Cerebrum.Audits.GetParagonSignedUrlOutput) {
        TableMetrics.publishLinkClick(this.state.metricsId, response.signedUrl, this.state.additionalMetrics);
    }

    private handleError(error: CoralError) {
        // tslint:disable-next-line:no-console
        console.error(error);
        this.setState({ loading: false, error: true });
    }

    render() {
        return this.state.error ? (
                <KatAlert
                    header='Error creating url'
                    onDismiss={this.dismissError}
                    variant='danger'/>
            ) :
            (
                <>
                    {this.state.loading
                        ? <KatSpinner size='small'/>
                        : <span>
                            {this.state.displayTextPrefix &&
                                <span>{this.state.displayTextPrefix}</span>}
                            <KatLink variant='link'
                                     className='cursor-pointer'
                                     onClick={this.paragonSignedUrl}
                                     href=''
                                // @ts-ignore
                                     onAuxClick={this.paragonSignedUrl}>
                                {this.state.displayText + " "}
                                <KatIcon name='launch' size='tiny'/>
                            </KatLink>
                        </span>}
                </>
            );
    }

}
import React, { useEffect, useState } from "react";
import { isToday } from "src/components/common/Comparator";
import {
    beginningOfTomorrowMoment,
    patternMatchMomentAndFormat,
    getLocalTimezoneMoment
} from "src/components/common/DateFormatter";

interface TasksDateLabelParams {
    dueDate: string;
    defaultClass: string;
    handleClick?: () => void;
    noSpacer?: boolean;
}

export const TasksDateLabel: React.FC<TasksDateLabelParams> = (props: TasksDateLabelParams) => {
    const { dueDate, defaultClass, handleClick, noSpacer } = props;

    const [dueDateLabel, setDueDateLabel] = useState('');
    const [dueDateClass, setDueDateClass] = useState('');

    useEffect(() => {
        const dueDateMoment = getLocalTimezoneMoment(dueDate);
        const isOverdue = beginningOfTomorrowMoment().diff(dueDateMoment) > 0;

        const klass = isToday(dueDateMoment) ? "text-success text-size-sm" : // Today should be green
            isOverdue ? "text-danger text-size-sm" : // Overdue should be red
                defaultClass; // Future should be gray

        // Showing short month names, and year only if it is not current
        const label = 'Due ' + patternMatchMomentAndFormat(dueDateMoment, '[Yesterday]', '[Today]',
            '[Tomorrow]', "MMM D", "MMM D, YYYY");

        setDueDateClass(klass);
        setDueDateLabel(label);
    }, [dueDate]);

    return <>
        {!noSpacer && <span className={defaultClass}> | </span>}
        <span onClick={handleClick} className={dueDateClass}>{dueDateLabel}</span>
    </>;
};

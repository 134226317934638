import React from 'react';
import { ErrorMessage, GlobalErrorProps } from './ErrorMessage';
import i18n from 'i18next';
import { AUTHENTICATION_REDIRECTING_CODE } from "src/service/AuthenticationService";
import { BSCNotFoundErrorMessage, BSCOpenTicketMessage } from "src/components/error/BSCNotFoundErrorMessage";

export interface CoralError {
    __type: string;
    message: string;
}

export interface ErrorBoundState {
    hasError: boolean;
    errorMessage?: string | CoralError | Error;
    errorTitle?: string;
    supervisor?: boolean;
}

export class ErrorBoundComponent<ComponentProps, ComponentState extends ErrorBoundState> extends React.Component<ComponentProps, ComponentState> {

    errorMetricHandler?: () => void;

    constructor(props: any) {
        super(props);
    }

    handleError(errorMessage: string | CoralError | Error, supervisor?: boolean) {
        this.setState({
            hasError: true,
            errorMessage: errorMessage,
            supervisor: supervisor
        })

        if (errorMessage != AUTHENTICATION_REDIRECTING_CODE && this.errorMetricHandler != undefined) {
            this.errorMetricHandler();
        }
    }

    renderError() {
        const errorProps: GlobalErrorProps = this.buildErrorProps();
        if (this.state.hasError) {
            return (
                <ErrorMessage {...errorProps} />
            )
        }
        return null;
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => { return; };
    }

    buildErrorProps(): GlobalErrorProps {
        const titleKey: string | undefined = this.state.errorTitle;
        const errorProps: GlobalErrorProps = {
            title: i18n.t(titleKey || "wfm_axiom_v2_default_error_title"),
            message: ""
        };
        // If no error message is provided
        if (!this.state.errorMessage) {
            errorProps.message = "Unspecified error";
        }
        // If we're redirecting. We display the redirection notice as an info rather than an error or warning since
        // the users will need to see this frequently and it's expected behavior.
        else if (this.state.errorMessage == AUTHENTICATION_REDIRECTING_CODE) {
            errorProps.message = i18n.t("wfm_axiom_v2_redirecting_message");
            errorProps.title = i18n.t("wfm_axiom_v2_redirecting_title");
            errorProps.level = "info";
        }
        // If it's just a message
        else if (typeof this.state.errorMessage == 'string') {
            errorProps.message = this.state.errorMessage;
        }
        // If it's a Coral Error
        else if ("__type" in this.state.errorMessage) {
            const type: string = this.state.errorMessage.__type;
            const errorCode: string = type.substr(type.lastIndexOf("#") + 1);
            errorProps.details = (
                <>
                    {this.state.errorMessage.message}<br />
                    {this.state.errorMessage.__type}
                </>
            );

            if (this.isBSCNotFoundError(errorProps.title, errorCode)) {
                errorProps.message = <BSCNotFoundErrorMessage/>;
                errorProps.openTicketMessage = <BSCOpenTicketMessage supervisor={this.state.supervisor!}/>;
            }
            else {
                errorProps.message = i18n.t(`wfm_axiom_v2_coral_error_${errorCode}`);
            }
        }
        // If it's a node error
        else if ("name" in this.state.errorMessage) {
            errorProps.message = this.state.errorMessage.message;
        }
        // If it's anything else
        else {
            errorProps.message = String(this.state.errorMessage);
        }
        return errorProps;
    }

    isBSCNotFoundError(title: string, errorCode: string) {
        return title === 'Balanced Score Card' &&
            (errorCode === 'SupervisorNotFoundException' || errorCode === 'AgentNotFoundException');
    }
}

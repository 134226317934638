import React from 'react';
import i18n from 'i18next';
import { Nav, Navbar } from "react-bootstrap";
import { RouteComponentProps, withRouter } from "react-router";
import ExternalResourceService from "src/service/ExternalResourceService";
import { UserContext } from "src/context/UserContext";
import { AxiomMetricsDriver } from "src/metrics/AxiomMetricsDriver";
import { HeaderLink } from './HeaderLink';
import { HeaderSearch } from './HeaderSearch';
import { HeaderBrand } from './HeaderBrand';
import { LanguageDropdown } from "src/components/common/header/LanguageDropdown";
import { UserRestrictedComponent } from "src/components/authorization/UserRestrictedComponent";
import { ComponentID } from "src/components/authorization/ComponentRightsMapping";
import { SidebarToggle } from "src/components/layout/SidebarToggle";
import { ASSISTANT_ICON, ASSISTANT_NAME } from "src/components/assistant/Assistant";
import { HeaderRoute } from "src/components/common/header/HeaderRoute";
import { Treatment, Weblab } from "src/user/Weblab";

// tslint:disable-next-line:no-empty-interface
interface HeaderState {
}

class Header extends React.Component<RouteComponentProps, HeaderState> {
    static contextType = UserContext;
    context!: React.ContextType<typeof UserContext>;

    constructor(props: RouteComponentProps) {
        super(props);
    }

    render() {
        const userIdentity: WFM.GetUserInformation = this.context.userManager.getUserIdentity();
        return (
            <>
                <Navbar sticky="top" bg="light" expand="lg" className="d-flex axiom-nav">
                    <HeaderBrand />
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mr-auto">
                            <UserRestrictedComponent componentId={ComponentID.HEADER_BALANCEDSCORECARD_RIGHTS_ID}>
                                <HeaderLink
                                    metricsId="bsc"
                                    name={i18n.t('wfm_axiom_v2_balanced_scorecard')}
                                    href={`#${HeaderRoute.BALANCED_SCORE_CARD}/${userIdentity.login}`}
                                    iconName="explore"
                                />
                            </UserRestrictedComponent>
                            <UserRestrictedComponent componentId={ComponentID.HEADER_FLASHBRIEFING_RIGHTS_ID}>
                                <HeaderLink
                                    metricsId="metrics"
                                    name={i18n.t('wfm_axiom_metrics')}
                                    href={`#${HeaderRoute.METRICS}`}
                                    iconName="graph"
                                />
                            </UserRestrictedComponent>
                            {/* TODO: Open Review for everyone (basic) user.
                            Each tab will have its unique independent permissions
                            For now, we will use the auditor permission to grant access
                            to falcon users, and further restricting titus by making it
                            only available for supervisors. The idea is to have titus tab in the
                            review selector only for auditors, and falcon only for falcon users.*/}
                            <UserRestrictedComponent componentId={ComponentID.HEADER_AUDIT_RIGHTS_ID}>
                                <HeaderLink
                                    metricsId="audit"
                                    name={i18n.t('wfm_axiom_v2_reviews')}
                                    href={`#${HeaderRoute.REVIEWS}`}
                                    iconName="list"
                                />
                            </UserRestrictedComponent>
                            <UserRestrictedComponent 
                                componentId={ComponentID.HEADER_TASKS_RIGHTS_ID}>
                                <HeaderLink
                                        metricsId="tasks"
                                        name='Tasks'
                                        href={`#${HeaderRoute.TASKS_LOG}`}
                                        iconName="offline_pin"
                                    />
                            </UserRestrictedComponent>
                            <UserRestrictedComponent componentId={ComponentID.FORMS_RIGHTS_ID}>
                                <HeaderLink
                                    metricsId="forms"
                                    name={i18n.t('wfm_axiom_v2_forms')}
                                    href={`#${HeaderRoute.FORMS}`}
                                    iconName="assignment"
                                />
                            </UserRestrictedComponent>
                            <UserRestrictedComponent componentId={ComponentID.HEADER_MYPERFORMANCE_RIGHTS_ID}>
                                <HeaderLink
                                    metricsId="myp"
                                    name={i18n.t('wfm_axiom_my_performance')}
                                    href={`#${HeaderRoute.MY_PERFORMANCE}`}
                                    iconName="account_circle"
                                />
                            </UserRestrictedComponent>

                            <UserRestrictedComponent componentId={ComponentID.HEADER_ADMIN_RIGHTS_ID}>
                                <HeaderLink
                                    metricsId="devTools"
                                    name={i18n.t('wfm_axiom_v2_dev_tools')}
                                    href={`#${HeaderRoute.DEV_TOOLS}`}
                                    iconName="lock"
                                />
                            </UserRestrictedComponent>
                        </Nav>
                        <Nav className="ml-auto align-items-center">
                            <UserRestrictedComponent componentId={ComponentID.HEADER_SEARCH_RIGHTS_ID}>
                                <HeaderSearch {...this.props} />
                            </UserRestrictedComponent>
                            <HeaderAvatar userIdentity={userIdentity} />
                            <LanguageDropdown />
                            <div className='border-left border-secondary'>
                                <HeaderLink
                                    name='Help'
                                    metricsId='help'
                                    href={`#${HeaderRoute.HELP}`}
                                    iconName='help'
                                    className='py-0 pr-1'
                                />
                            </div>
                            <UserRestrictedComponent componentId={ComponentID.CHARLOTTE_RIGHTS_ID}>
                                <div className="border-left border-secondary">
                                    <SidebarToggle label={ASSISTANT_NAME} iconName={ASSISTANT_ICON} />
                                </div>
                            </UserRestrictedComponent>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </>
        );
    }
}


function HeaderAvatar(props: { userIdentity: WFM.GetUserInformation }) {
    const { userIdentity } = props;
    return (
        <a onClick={() => AxiomMetricsDriver.publishLinkClick('Header', 'phonetool', ExternalResourceService.phoneToolLink(userIdentity.login))}
            target="_blank"
            href={ExternalResourceService.phoneToolLink(userIdentity.login)}>
            <img src={ExternalResourceService.userAvatar(userIdentity)} className="img-badgephoto" alt="user badge"/>
        </a>
    )
}

export default withRouter(Header);
import React from 'react';
import { CerebrumService } from 'src/service/CerebrumCoralService';
import { AxiomMetricsDriver } from "src/metrics/AxiomMetricsDriver";
import { UserManager } from "src/user/UserIdentityManager";
import {
    getPermissionsForForm,
    getPermissionsForLeaderCoachingForm
} from "src/components/my_performance/PermissionHelper";

import { MyPerformanceForm, MyPerformanceFormProps, formTypes } from "@amzn/wfm-my-performance-ui-components-react"
import { useHistory } from 'react-router-dom';
import i18n from "i18next";
import { defaultTenant } from "src/config/definitions";

interface MyPerformanceFormContainerProps {
    uniqueFormId: string
    formType: myPerformanceForms
};

export enum myPerformanceForms {
    feedback,
    coachingNote,
    leaderCoaching
}

const FormComponentMap: { [name in myPerformanceForms]: formTypes } =
{
    [myPerformanceForms.feedback]: formTypes.feedback,
    [myPerformanceForms.coachingNote]: formTypes.coachingNote,
    [myPerformanceForms.leaderCoaching]: formTypes.leaderCoaching
};

const pathComponentMap: { [name in myPerformanceForms]: string } =
{
    [myPerformanceForms.feedback]: "feedback",
    [myPerformanceForms.coachingNote]: "coaching-note",
    [myPerformanceForms.leaderCoaching]: "leader-coaching"
};

const responseMap: { [name in myPerformanceForms]: string } =
{
    [myPerformanceForms.feedback]: "feedback",
    [myPerformanceForms.coachingNote]: "coachingNote",
    [myPerformanceForms.leaderCoaching]: "leaderCoaching"
};

export function MyPerformanceFormContainer(props: MyPerformanceFormContainerProps) {
    const { uniqueFormId, formType } = props;
    const history = useHistory();
    const myPerformanceRootPath = "/myp/";
    const path = `${myPerformanceRootPath}${pathComponentMap[formType]}`;

    const userPermissions = formType === myPerformanceForms.leaderCoaching ?
        getPermissionsForLeaderCoachingForm(UserManager.getUserIdentity()) : getPermissionsForForm(UserManager.getUserIdentity());

    const feedbackFormProps: MyPerformanceFormProps = {
        user: UserManager.userIdentity?.login || '',
        metricsDriver: AxiomMetricsDriver,
        coralService: CerebrumService,
        tenantId: UserManager.tenantId || defaultTenant,
        uniqueFormId: uniqueFormId,
        i18n: i18n.t.bind(i18n),
        formType: FormComponentMap[formType],
        userPermissions: userPermissions,

        onCreateCallback: (payload: any) => { history.push(`${path}/${payload[responseMap[formType]].uniqueId}`) },
        onUpdateCallback: (payload: any) => { history.push(`${path}/${payload[responseMap[formType]].uniqueId}`) },
        onDeleteCallback: (payload: any) => { history.push(`${myPerformanceRootPath}log`) },
        onErrorCallback: (payload: any) => { history.push(`${myPerformanceRootPath}log`) },
    }

    return (
        <MyPerformanceForm {...feedbackFormProps} />
    )
}
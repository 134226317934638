import { AxiomMetricsDriver } from "src/metrics/AxiomMetricsDriver";
import React, { useContext, useEffect, useState } from "react";
import { KatBox, KatColumn, KatDivider, KatIcon, KatLink, KatRow, KatSpinner } from "@amzn/katal-react";
import { AxiomIsItDown } from "src/components/common/AxiomIsItDown";
import { LayoutContext } from "src/components/layout/LayoutContext";
import FlashBriefingContainer from "src/components/flash_briefing/FlashBriefingContainer";
import { UserContext } from "src/context/UserContext";
import { HeaderRoute } from "src/components/common/header/HeaderRoute";
import { useHistory } from "react-router-dom";


export default function Landing() {

    const COMPONENT_NAME = "Landing";

    const history = useHistory();

    const layout = useContext(LayoutContext);
    const userContext = useContext(UserContext);

    const [viewUserLogin, setViewUserLogin] = useState('');

    useEffect(() => {
        setViewUserLogin(userContext.userManager.getUserIdentity().login);
        // remember what was the preference in other pages
        const wasVisible = layout.isSidebarVisible;
        const wasPinned = layout.isSidebarPinned;
        // but sidebar with assistant should be visible and pinned every time Landing page is loaded
        layout.setSidebarVisible(true);
        layout.setSidebarPinned(true);
        return () => {
            // and restore the preference from other pages when leaving Landing page
            layout.setSidebarVisible(wasVisible);
            layout.setSidebarPinned(wasPinned);
        };
    }, []);

    // Publish page load metrics
    useEffect(() => {
        if (viewUserLogin) {
            document.title = `${SITE_NAME} - ${viewUserLogin}`;

            const additionalMetrics: Axiom.AdditionalMetric[] = [{ name: "viewUserLogin", value: viewUserLogin }];
            AxiomMetricsDriver.publishPageLoad(COMPONENT_NAME, window.location.href, additionalMetrics);
        }
    }, [viewUserLogin]);

    const userName = () => (userContext.userManager.getUserIdentity().additionalAttributes.givenName);

    const handleAboutClick = () => {
        const route = HeaderRoute.ABOUT;
        AxiomMetricsDriver.publishLinkClick(COMPONENT_NAME, 'About', route);
        history.push(route);
    };

    return (
        <KatBox className='h-100' variant='ltgrey'>
            <AxiomIsItDown componentId={COMPONENT_NAME}/>
            {viewUserLogin ?
                <>
                    <KatBox>
                        <KatRow>
                            <KatColumn md={8}>
                                <h1> Welcome back, {userName()}.</h1>
                            </KatColumn>
                            <KatColumn md={4} className={"text-right"}>
                                <KatLink label={"About Axiom"} onClick={handleAboutClick}/>
                            </KatColumn>
                        </KatRow>
                    </KatBox>
                    <div className='px-1'>
                        <div className='landing-dashboard px-3'>
                            <FlashBriefingContainer entityId={viewUserLogin}
                                                    pageName={COMPONENT_NAME}
                                                    isItDownComponentId={`FlashBriefing-${COMPONENT_NAME}`}/>
                        </div>
                    </div>
                </> : <span> Loading user details... <KatSpinner/> </span>}
        </KatBox>
    );
}

import React, { PropsWithChildren, useContext } from "react";
import { KatBox, KatButton, KatIcon } from "@amzn/katal-react";
import { LayoutContext } from "src/components/layout/LayoutContext";

interface SidebarProps {
    sidebar?: JSX.Element;
    title?: string;
}

export const Sidebar = (props: PropsWithChildren<SidebarProps>) => {

    const { sidebar, title } = props;

    const layout = useContext(LayoutContext);

    const sidebarClass = () => {
        let sbClass = "sidebar";
        if (!layout.isSidebarPinned) sbClass += " sidebar-float";
        if (!layout.isSidebarVisible) sbClass += " d-none";
        return sbClass;
    };

    return (
        <>{sidebar &&
        <div className={sidebarClass()}>
            <KatBox className='assistant-header p-0 d-flex align-items-center' variant="white-shadow">
                <KatButton className="p-1" variant='link' onClick={layout.toggleSidebarPinned}>
                    <KatIcon title={layout.isSidebarPinned ? 'Detach sidebar' : 'Attach sidebar'}
                             name={layout.isSidebarPinned ? 'print-right' : 'print-with-receipt'}
                             size='small'/>
                </KatButton>
                {title && <h2 className="text-center flex-grow-1 pt-2">{title}</h2>}
                <KatButton className="p-1" variant='link' onClick={layout.toggleSidebarVisible}>
                    <KatIcon title='Close sidebar'
                             name='clear'
                             size='small'/>
                </KatButton>
            </KatBox>
            {sidebar}
        </div>
        }</>
    );
}
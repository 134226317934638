import React from "react";
import { ListGroupItem } from "react-bootstrap";

function convertLink(originalUri: string): string {
    return `#/support/${originalUri}`;
}

export interface SupportNavigationItemProps {
    entry: Array<string>
    currentPage?: string
}

export function SupportNavigationItem(props: SupportNavigationItemProps) {
    let isActive: boolean = props.entry[1] === props.currentPage;
    return (
        <ListGroupItem className={isActive ? "active text-light" : ""}>
            <a className={isActive ? "text-light" : ""} href={convertLink(props.entry[1])}>{props.entry[0]}</a>
        </ListGroupItem>
    )
}
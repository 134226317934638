import { HeaderRoute } from "src/components/common/header/HeaderRoute";
import { CerebrumService } from "src/service/CerebrumCoralService";
import i18n from "i18next";

const absoluteURIRegExp = new RegExp('^(//|[a-z]+:)', 'i');

export function toAbsoluteHelpUri(target: string): string {
    return isURIAbsolute(target) ? target : `#${HeaderRoute.HELP}/${target}`;
}

function isURIAbsolute(originalUri: string): boolean {
    return absoluteURIRegExp.test(originalUri);
}

export function loadHelpContentPage(pageId: string) {
    return CerebrumService.getHelpContentById(pageId, i18n.language);
}
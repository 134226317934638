import React, { useEffect, useState } from "react";
import { AXIOM_FORMS_PREFIX } from "src/components/forms/constants/FormConstants";
import 'src/components/forms/Forms.scss'
import { DateRangePicker, DateRangePickerProps } from "@amzn/awsui-components-react";
import moment, { Moment } from "moment";
import { AxiomMetricsDriver } from "src/metrics/AxiomMetricsDriver";
import { useStateWithSearchParams } from "src/components/common/searchParameters/useStateWithSearchParams";
import { UrlParameterConfigKeys } from "src/config/ConfigManager";

const COMPONENT_NAME = AXIOM_FORMS_PREFIX + 'FormsDateRangePicker';

interface FormsDateRangePickerProps {
    onDateRangeChanged: (dateRange: DateRange) => void;
}

export interface DateRange {
    from?: Moment;
    to?: Moment;
    dateRangePickerValue?: DateRangePickerValue;
}

export type DateRangePickerValue = DateRangePickerProps.AbsoluteValue | DateRangePickerProps.RelativeValue;

export const getDateRangePickerValueFromParamString = (urlParamString: string): DateRangePickerValue | null => {
    try {
        const valueObj = JSON.parse(urlParamString);
        if (valueObj.type === 'absolute') {
            return {
                startDate: valueObj.startDate,
                endDate: valueObj.endDate,
                type: 'absolute',
            };
        } else if (valueObj.type === 'relative') {
            return {
                key: valueObj.key,
                amount: valueObj.amount,
                unit: valueObj.unit,
                type: 'relative',
            };
        } else {
            return null;
        }
    } catch (e) {
        // tslint:disable-next-line:no-console
        console.error('Failed to parse date range picker value from URL parameter', e);
        return null;
    }
};

export const parseRange = (newValue: DateRangePickerValue | null) => {
    if (!newValue) return {};
    return newValue.type === "relative"
        ? parseRelativeRange(newValue)
        : parseAbsoluteRange(newValue);
}

const parseRelativeRange = (newValue: DateRangePickerProps.RelativeValue): DateRange => {
    const now = moment();
    const before = now.clone().subtract(newValue.amount, newValue.unit);
    return {
        from: before,
        to: now,
        dateRangePickerValue: newValue
    };
}

const parseAbsoluteRange = (absoluteRange: DateRangePickerProps.AbsoluteValue): DateRange => ({
    from: moment(absoluteRange.startDate),
    to: moment(absoluteRange.endDate),
    dateRangePickerValue: absoluteRange
})

export const FormsDateRangePicker: React.FC<FormsDateRangePickerProps> = (props) => {

    const { onDateRangeChanged } = props;

    const [value, setValue] = useState<DateRangePickerValue | null>({
        key: "previous-2-weeks",
        amount: 2,
        unit: "week",
        type: "relative"
    });

    const [paramDateRangePickerValue, setDateRange] = useStateWithSearchParams(UrlParameterConfigKeys.FORMS_LOG_DATE_RANGE, "");

    /**
     * Processes param date range and updates the date range picker
     */
    useEffect(() => {
        const dateRangePickerValueFromParam = getDateRangePickerValueFromParamString(paramDateRangePickerValue || '');
        // If the date range picker value from the URL param is different than the current value, update the value
        if (dateRangePickerValueFromParam && dateRangePickerValueFromParam !== value) {
            setValue(dateRangePickerValueFromParam);
        }
    }, [paramDateRangePickerValue]);

    const sendSelectedRangeMetric = (newRange: DateRange) =>
        AxiomMetricsDriver.publishButtonClick(COMPONENT_NAME, 'selected-range', [{
            name: 'selected-range',
            value: JSON.stringify(newRange)
        }]);

    useEffect(() => {
        const newRange = parseRange(value);
        onDateRangeChanged(newRange);
        sendSelectedRangeMetric(newRange);
    }, [value]);

    const isStartDateAfterEndDate = (range: DateRangePickerProps.AbsoluteValue) => {
        const startDate: any = new Date(range.startDate);
        const endDate: any = new Date(range.endDate);
        return startDate - endDate > 0;
    }

    const dateRangeChangeHandler = (evt: any) => {
        setValue(evt.detail.value);
    }

    const isValidRange = (range: DateRangePickerProps.Value | null): DateRangePickerProps.ValidationResult => {
        if (range?.type === "absolute") {
            const [startDateWithoutTime] = range.startDate.split("T");
            const [endDateWithoutTime] = range.endDate.split("T");
            if (!startDateWithoutTime || !endDateWithoutTime) {
                return {
                    valid: false,
                    errorMessage:
                        "The selected date range is incomplete. Select a start and end date for the date range."
                };
            }
            if (isStartDateAfterEndDate(range)) {
                return {
                    valid: false,
                    errorMessage:
                        "The selected date range is invalid. The start date must be before the end date."
                };
            }
        }
        return { valid: true };
    };

    const predefinedOptions: DateRangePickerProps.RelativeOption[] = [
        {
            key: "previous-week",
            amount: 1,
            unit: "week",
            type: "relative"
        },
        {
            key: "previous-2-weeks",
            amount: 2,
            unit: "week",
            type: "relative"
        },
        {
            key: "previous-month",
            amount: 1,
            unit: "month",
            type: "relative"
        },
        {
            key: "previous-3-months",
            amount: 3,
            unit: "month",
            type: "relative"
        }
    ];
    return (<div>
        <div className='column submission-container-row'>
            <DateRangePicker onChange={dateRangeChangeHandler}
                             value={value || null}
                             relativeOptions={predefinedOptions}
                             isValidRange={isValidRange}
                             i18nStrings={{
                                 todayAriaLabel: "Today",
                                 nextMonthAriaLabel: "Next month",
                                 previousMonthAriaLabel: "Previous month",
                                 customRelativeRangeDurationLabel: "Duration",
                                 customRelativeRangeDurationPlaceholder:
                                     "Enter duration",
                                 customRelativeRangeOptionLabel: "Custom range",
                                 customRelativeRangeOptionDescription:
                                     "Set a custom range in the past",
                                 customRelativeRangeUnitLabel: "Unit of time",
                                 formatRelativeRange: e => {
                                     const amount = e.amount === 1
                                         ? ''
                                         : e.amount;
                                     const unit = 1 === e.amount
                                         ? e.unit
                                         : `${e.unit}s`;
                                     return `Last ${amount} ${unit}`;
                                 },
                                 formatUnit: (unit, amount) => (
                                     1 === amount
                                         ? unit
                                         : `${unit}s`
                                 ),
                                 relativeModeTitle: "Relative range",
                                 absoluteModeTitle: "Absolute range",
                                 relativeRangeSelectionHeading: "Choose a range",
                                 startDateLabel: "Start date",
                                 endDateLabel: "End date",
                                 startTimeLabel: "Start time",
                                 endTimeLabel: "End time",
                                 clearButtonLabel: "Clear and dismiss",
                                 cancelButtonLabel: "Cancel",
                                 applyButtonLabel: "Apply"
                             }}
                             placeholder="Filter by a date and time range"
            />
        </div>
    </div>);
}
import React from "react";
import ExtendedPolarisTable from "../../polaris_table/ExtendedPolarisTable";
import COMPARATORS from "src/components/utils/Comparators";
import GETTERS from "src/components/utils/AttributeGetters";
import { ACTION_RESPONSE_PREFIX } from "src/components/forms/extensions/ExtensionVisitor";
import { formatCreatedDate } from "src/components/common/DateFormatter";
import { FilterConfig, GenericObject, HeaderConfig, PolarisTableUniqueLocalStorageKeys, } from "src/@types/extendedPolarisTable";
import { buildMetadataLabel, MetadataLabelType, parseMetadataLabel } from 'src/components/common/MetadataLabel'
import { HeaderRoute } from "src/components/common/header/HeaderRoute";
import EmptyTable from "src/components/polaris_table/EmptyTable";
import ItemStatusFormatter from './AuditStatusFormatter';
import { KatButton, KatIcon } from "@amzn/katal-react";
import { MetadataLabel } from "src/components/common/MetadataLabel";
import { AuditStatusEnum } from "src/components/audit/Audit";


type CustomerObsessionAuditTableProps = {
    itemList: Cerebrum.Audits.AuditItem[];
    auditType: string;
    refreshHandler: Function;
};

const itemCountPerStatus = (itemList: Cerebrum.Audits.AuditItem[]) => {
    return itemList.reduce((acc: GenericObject, item: Cerebrum.Audits.AuditItem) => {
        ++acc.totalItems;
        ++acc[`${item.status}Items`];
        return acc;
    }, { completedItems: 0, pendingItems: 0, totalItems: 0 })
}

const generateAuditLink = (auditItem: Cerebrum.Audits.AuditItem) => {
    //TODO Confirm link generation is correct & move constants to their own file
    const AUDIT_TO_COMPLETE = ACTION_RESPONSE_PREFIX + "audit_id";
    const auditDocument = auditItem.auditDocument as Cerebrum.Audits.CustomerObsessionDocument;
    
    const auditMetadata = {
        ...formatAuditMetadata(auditDocument),
        associate_id: auditItem.agent,
        [AUDIT_TO_COMPLETE]: auditItem.externalAuditId,
        related_reviews: auditDocument.reviewId
    };
    
    const relativePath = `${HeaderRoute.FORMS_SUBMIT}/${auditDocument.targetFormName}`;
    const searchParams = new URLSearchParams(auditMetadata);
    const url = new URL(`${window.location.origin}/#${relativePath}?${searchParams.toString()}`);

    return url.toString();
};

const formatAuditMetadata = (auditDocument: any) => {
    const keys = auditDocument.metaDataKeys.split("||");
    const values = auditDocument.metaDataValues.split("||");

    const auditData = keys.reduce(
        (accumulator: any, key: any, index: number) => {
            accumulator[key] = values[index];
            return accumulator;
        },
        {} as any,
    );

    return auditData;
};

export default function CustomerObsessionAuditTable(
    props: CustomerObsessionAuditTableProps,
) {
    const { itemList, auditType, refreshHandler } = props;

    const comparators = COMPARATORS[auditType];
    const getters = GETTERS[auditType];
    //TODO: Update typings
    const columnDefinitions = [
        {
            id: 'status',
            header: '',
            cell: (item: Cerebrum.Audits.AuditItem) => (
                <ItemStatusFormatter status={item.status} />
            )
        },
        {
            id: "agent",
            header: "Associate",
            cell: (item: Cerebrum.Audits.AuditItem) =>parseMetadataLabel(
                "COViewAgent",
                buildMetadataLabel(MetadataLabelType.FULL_NAME, {"fullName": item.agentName, "alias": item.agent})
            ),
            sortingComparator: comparators.associate,
            isSearchable: true
        },
        {
            id: "source",
            header: "Program",
            cell: (item: any) => item.auditDocument.source,
            sortingComparator: comparators.source,
            getter: getters.source,
            isSearchable: true
        },
        {
            id: "reviewIdentificationDate",
            header: "Date",
            cell: (item: any) => formatCreatedDate(item.auditDocument.reviewIdentificationDate),
            sortingComparator: comparators.date,
            getter: getters.reviewIdentificationDate,
            isSearchable: true
        },
        {
            id: "targetItemId",
            header: "Related Item",
            cell: (item: any) => parseMetadataLabel(
                "COViewCase",
                buildMetadataLabel(item.auditDocument.targetItemType, item.auditDocument.targetItemId)
            ),
            sortingComparator: comparators.case,
            getter: getters.targetItemId,
            isSearchable: true
        },
        {
            id: "reviewSubject",
            header: "Opportunity Type",
            cell: (item: any) => item.auditDocument.reviewSubject,
            sortingComparator: comparators.subject,
            getter: getters.reviewSubject,
            isSearchable: true
        },
        {
            id: "formDetails",
            header: "Actions",
            cell: (item: Cerebrum.Audits.AuditItem) => (
                <MetadataLabel
                    name='DCPOpenForm'
                    href={item.status !== AuditStatusEnum.COMPLETED ? generateAuditLink(item) : ''}
                    key={item.externalAuditId}
                    iconName={'assignment'}
                    target={'_blank'}
                />
            )
        }
    ];

    const { pendingItems, totalItems } = itemCountPerStatus(itemList);

    const headerConfig: HeaderConfig = {
        title: `Reviews (${totalItems})`,
        variant: "awsui-h1-sticky",
        actions: [
            {
                type: "custom",
                customComponent: <KatButton variant="tertiary" onClick={() => refreshHandler()}>
                    <KatIcon name="refresh" size="small"></KatIcon>
                </KatButton>
            }
        ]
    };

    const filterConfigs = [
        {
            type: "buttonGroup",
            attributeName: "status",
            defaultSelectedValue: "all",
            defaultSelectedLabel: "All",
            buttons: [
                {
                    label: `All`,
                    value: "all",
                },
                {
                    label: `To Do (${ pendingItems })`,
                    value: "pending",
                }
            ]
        },
        {
            type: "dropdown",
            attributeName: "agent",
            defaultSelectedValue: "0",
            defaultSelectedLabel: "All Associates",
        },
        {
            type: "dropdown",
            attributeName: "source",
            attributeGetter: getters.source,
            defaultSelectedValue: "0",
            defaultSelectedLabel: "All Programs",
        },
    ] as FilterConfig[];

    const emptyTable = <EmptyTable
        title="No Reviews"
        subtitle="No Reviews to display."
    />

    return (
        <>
            <ExtendedPolarisTable <Cerebrum.Audits.AuditItem>
                itemList={itemList}
                columnDefinitions={columnDefinitions}
                filterConfigs={filterConfigs as FilterConfig[]}
                defaultSortingColumn={0}
                headerConfig={headerConfig as HeaderConfig}
                includeSearchByText={true}
                trackBy="externalAuditId"
                pageSize={25}
                emptyTable={emptyTable}
                localStorageUniqueKey={PolarisTableUniqueLocalStorageKeys.CUSTOMER_OBSESSION_TABLE} />
        </>
    );
}

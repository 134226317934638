import { toOptionsArray } from "src/components/common/Enums";

export enum TaskStatus {
    NOT_STARTED = 'NotStarted',
    // IN_PROGRESS = 'InProgress', // currently not used
    COMPLETED = 'Completed'
}

export const taskStatusToInt: Record<TaskStatus, number> = {
    [TaskStatus.NOT_STARTED]: 0,
    // [TaskStatus.IN_PROGRESS]: 1, // currently not used
    [TaskStatus.COMPLETED]: 2
} as const;

export const taskStatusOptions = toOptionsArray(TaskStatus);
import React, { Component } from 'react';
import i18n from 'i18next';
import { KatIcon, KatLink, KatTable, KatTableBody, KatTableCell, KatTableHead, KatTableRow } from '@amzn/katal-react';
import { AxiomMetricsDriver } from "src/metrics/AxiomMetricsDriver";
import { BscScoreSpan, BscScoreTableSpan, ValueShownSpan } from "src/components/common/ScoreFormatters";
import { BSC_METRICS_LIST, VALUE_SHOWN_METRICS } from '../../BSC';
import { HelpContextualLink } from "src/components/support/HelpContextualLink";
import MetricDefinitionPopover from './metric_definition_popover/MetricDefinitionPopover';
import { UserContext } from "src/context/UserContext";


interface RadarTableProps {
    datasetDate: string;
    selectedEntity: Axiom.SelectedUser;
    bscMetricData: Cerebrum.BalancedScorecardMetricData;
    selectedMetricHandler: (selectedMetric: string) => void;
}

export interface RadarTableState {
    selectedMetric?: string;
}

export default class RadarTable extends Component<RadarTableProps, RadarTableState> {

    static contextType = UserContext;
    context!: React.ContextType<typeof UserContext>;

    constructor(props: RadarTableProps) {
        super(props);
        this.state = {};
        this.getClassNameForMetric = this.getClassNameForMetric.bind(this);
        this.RadarTableRow = this.RadarTableRow.bind(this);
    }

    render() {
        const { bscMetricData } = this.props;
        const metricsList: Axiom.metricList = bscMetricData.metrics.map(metric => (metric.name));
        return (
            <>
                <div className="kat-row mb-3">
                    <div className='kat-col-12 combined-score-display'>
                        <BscScoreSpan score={bscMetricData.score} />
                    </div>
                </div>
                <div className="kat-row">
                    <KatTable>
                        <KatTableHead>
                            <KatTableRow>
                                <KatTableCell>
                                    {i18n.t('wfm_axiom_v2_Metric')}
                                    {this.context.userManager.isTestUser ?
                                        // Only for admins at the moment
                                        <HelpContextualLink componentId={'bsc-radar-table-metric'} /> :
                                        // Regular users will keep seeing the old popover
                                        <MetricDefinitionPopover metricList={metricsList} />}
                                </KatTableCell>
                                <KatTableCell>{i18n.t('wfm_axiom_v2_score')}</KatTableCell>
                                <KatTableCell>Actual</KatTableCell>
                                <KatTableCell>Target</KatTableCell>
                                <KatTableCell>
                                    <KatIcon name="timeline" size="tiny"></KatIcon>
                                </KatTableCell>
                            </KatTableRow>
                        </KatTableHead>
                        <KatTableBody>
                            {bscMetricData.metrics.filter(this.isBSCMetricFilter).map(this.RadarTableRow)}
                            {bscMetricData.metrics.filter(this.isValueShownMetricFilter).map(this.RadarTableRow)}
                        </KatTableBody>
                    </KatTable>
                </div>
            </>
        );
    }

    // Array Filters for if metric is VALUE_SHOWN or not
    private isBSCMetricFilter(metric: Cerebrum.BalancedScorecardMetric, i: number, arr: Cerebrum.BalancedScorecardMetric[]) {
        return BSC_METRICS_LIST.includes(metric.name);
    }

    private isValueShownMetricFilter(metric: Cerebrum.BalancedScorecardMetric, i: number, arr: Cerebrum.BalancedScorecardMetric[]) {
        return VALUE_SHOWN_METRICS.includes(metric.name);
    }


    private RadarTableRow(metric: Cerebrum.BalancedScorecardMetric, i: number, arr: Cerebrum.BalancedScorecardMetric[]): JSX.Element {
        // if its the first row in the group then it will have a darker top-border
        const className = i == 0 ? 'separated-table-row' : '';
        const { score, value, name, target_value: targetValue } = metric;

        const showExplainMyScoreLink: boolean = this.props.selectedEntity?.type == 'agent';
        const explainMyScoreDetailUrl = this.buildExplainMyScoreDetailUrl(this.props.selectedEntity, metric, this.props.datasetDate);

        return (
            <React.Fragment key={name}>
                <KatTableRow
                    onClick={() => this.onClickRowHandler(name)}
                    className={this.getClassNameForMetric(name)}
                >
                    <KatTableCell className={className}>
                        {name.toUpperCase()}
                    </KatTableCell>
                    <KatTableCell className={className}>
                        <BscScoreTableSpan metric={metric} value={score} />
                    </KatTableCell>
                    <KatTableCell className={className}>
                        <ValueShownSpan metric={metric} value={value} />
                    </KatTableCell>
                    <KatTableCell className={className}>
                        <ValueShownSpan metric={metric} value={targetValue} />
                    </KatTableCell>

                    <KatTableCell className={className}>
                        {showExplainMyScoreLink &&
                            <KatLink
                                href={explainMyScoreDetailUrl}
                                target='_blank'
                                onClick={() => this.OnExplainMyScoreClick(this.props.selectedEntity, metric, explainMyScoreDetailUrl)}
                                /* @ts-ignore */
                                onAuxClick={() => this.OnExplainMyScoreClick(this.props.selectedEntity, metric, explainMyScoreDetailUrl)}
                            >
                                <KatIcon name="chevron-right" size="tiny">
                                </KatIcon>
                            </KatLink>
                        }
                    </KatTableCell>
                </KatTableRow>
            </React.Fragment>
        );
    }

    /*
    * Formatting functions
    * */
    private getClassNameForMetric(metric: string) {
        if (this.state.selectedMetric == metric) {
            return "selected-row cursor-pointer";
        }
        return "cursor-pointer";
    }


    /*
    * Event Handlers
    * */
    private onClickRowHandler(metric: string) {
        this.props.selectedMetricHandler(metric);
        this.setState({ selectedMetric: this.state.selectedMetric == metric ? undefined : metric });
        AxiomMetricsDriver.publishButtonClick('RadarTable', 'filter', [{
            name: "filterValue",
            value: metric
        }]);
    }

    /**
     * Builds the Quicksight URL with relavant params for the explain-my-score feature
     */
    private buildExplainMyScoreDetailUrl(selectedEntity: Axiom.SelectedUser, metric: Cerebrum.BalancedScorecardMetric, activityDate: string) {
        const qsOrigin = 'https://us-east-1.quicksight.aws.amazon.com';
        const qsDashboardPath = '/sn/dashboards/b5d37ec1-a631-4cee-be21-cbcc3087cfbe';
        const dashboardParams = `#p.AssociateLogin=${selectedEntity.userId}&p.Metric=${metric.name}&p.activitydate=${activityDate}`;
        return qsOrigin + qsDashboardPath + dashboardParams;
    }


    private OnExplainMyScoreClick(selectedEntity: Axiom.SelectedUser, metric: Cerebrum.BalancedScorecardMetric, href: string) {
        const additionalMetrics = [
            {
                name: "selectedEntityId",
                value: selectedEntity.userId
            },
            {
                name: "metric",
                value: metric.name
            },
            {
                name: "href",
                value: href
            }
        ];
        return AxiomMetricsDriver.publishButtonClick('BscMetricsTable', 'explainMyScoreLink', additionalMetrics);
    }
}
import { ExtensionVisitor } from "./ExtensionVisitor";
import { Extension, Tuple } from 'src/service/amplify/appsync';


/**
 * The Element in terms of the Visitor design pattern.
 *
 * It will call visit on the given ExtensionVisitor to enable
 * double dispatch based on the runtime extension type if defined
 */
export interface VisitableExtension extends Omit<Extension, '__typename'> {

    accept(visitor: ExtensionVisitor<any>): void;
}

/**
 * An adapter class to convert Extensions into VisitableExtensions
 * to be visited by ExtensionVisitors
 */
export class VisitableExtensionAdapter implements VisitableExtension {
    private readonly extension: Extension;

    constructor(extension: Extension) {
        this.extension = extension;
    }

    accept(visitor: ExtensionVisitor<any>): void {
        visitor.visit(this);
    }

    get type(): string | null {
        return this.extension.type || null;
    }

    get name(): string | null {
        return this.extension.name || null;
    }

    get id(): string {
        return this.extension.id;
    }

    get content(): (Tuple | null)[] | null | undefined {
        return this.extension.content;
    }
}

/**
 * Wraps the given Extension into a VisitableExtension
 */
export const AsVisitableExtension = (extension: Extension) =>
    new VisitableExtensionAdapter(extension);


export const AsVisitableExtensionArray = (extensions: Extension[]) => {
    const visitableExtensions: VisitableExtension[] =
        extensions.map((extension: Extension) => {
            return AsVisitableExtension(extension);
        });
    return visitableExtensions;
}

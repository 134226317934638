import React, { useContext, useState } from 'react';
import i18n from 'i18next';
import { KatIcon, KatInput, KatInputGroup, KatDivider } from '@amzn/katal-react';
import { UserContext } from 'src/context/UserContext';
import { AuditTypeTableConfigMap } from "./AuditTableConfig";
import { AuditStatusSelector } from '../selector/AuditStatusSelector';
import { AuditTable } from './AuditTable';
import { AuditTableExport } from './AuditTableExport';
import CustomerObsessionAuditTable from './CustomerObsessionAuditTable';

interface AuditTableContainerProps {
    auditType: Cerebrum.Audits.AuditType;
    auditData: Cerebrum.Audits.GetAuditsOutput;
    auditMetaData: Cerebrum.Audits.ExportMetaData;
    refreshHandler: Function;
}

/**
 * Container to hold logic for search, status filters, Audit Table, Pagination
 *
 * it also sets up the data and config for the table
 */
export const AuditTableContainer: React.FC<AuditTableContainerProps> = (props) => {
    const userContext = useContext(UserContext);
    const isOutSourced = userContext.userManager.isOutSourced;
    const { auditData, auditType, auditMetaData, refreshHandler } = props;
    const { auditor } = auditMetaData;
    const [searchTerm, setSearchTerm] = useState<string>();
    const [selectionFilters, setSelectionFilters] = useState<Axiom.SelectionFilter[]>([]);

    function searchHandler(event: any) {
        setSearchTerm(event.target.value || "");
    }

    function auditStatusSelectionHandler(selectedStatus: Cerebrum.Audits.AuditStatus) {
        let statusFilter: Axiom.SelectionFilter = { field: "status", value: selectedStatus };
        if (selectedStatus == 'all') {
            // if the selection is 'all' then the existing filter is applied again to toggle it off
            statusFilter = selectionFilters.filter(filter => filter.field == "status")[0];
        }
        // statusFilter will be undefined if the user clicks "all" first, so we guard for that
        statusFilter && updateSelectedFilters(statusFilter);
    }

    /**
     * Generalized helper to update selection filters;
     * This will replace any filter in the same field,
     * if the new filter passed is identical to the existing filter then it is removed
     *
     * This facilitates the "toggle" like actions
     */
    function updateSelectedFilters(newFilter: Axiom.SelectionFilter) {
        const currentSelectedFilters = selectionFilters;
        // Look for an existing filter that matches the field and value of new one given
        let indexOfExistingFilter = currentSelectedFilters.findIndex((filter) => {
            return filter.field === newFilter.field && filter.value === newFilter.value;
        });

        let updatedFilters = currentSelectedFilters.filter(filter => filter.field !== newFilter.field);

        // if it a matching filter was found, toggle it off
        if (indexOfExistingFilter != -1) {
            setSelectionFilters(updatedFilters);
        }

        // otherwise add it to the updatedFilters
        else {
            updatedFilters.push(newFilter);
            setSelectionFilters(updatedFilters);
        }
    }

    // Flatten the data so it can be easily used as rowdata for the submissions_table, and add extra data contextual points
    const flattenedData: Cerebrum.Audits.TableData = auditData.audits.map(audit => {
        return {
            ...audit,
            ...audit.auditDocument,
            auditType,
            auditor,
            isOutSourced,
        };
    });

    const tableConfig = new AuditTypeTableConfigMap[auditType]();

    return (
        <AuditTableContainerLayout
            auditType={auditType}
            auditStatusSelector={
                <AuditStatusSelector
                    onChangeHandler={auditStatusSelectionHandler}
                    auditData={auditData}
                    selectedAuditType={auditType}
                />
            }
            searchInputGroup={
                <KatInputGroup>
                    <KatIcon name="search"/>
                    <KatInput placeholder={i18n.t('wfm_axiom_v2_search')} type="search" value={searchTerm}
                              onInput={searchHandler}/>
                </KatInputGroup>
            }
            auditExport={
                <AuditTableExport
                    auditTableData={flattenedData}
                    auditMetaData={auditMetaData}
                />
            }
            auditTable={
                auditType === 'customerObsession'
                ?   <CustomerObsessionAuditTable itemList={auditData.audits} auditType={auditType} refreshHandler={refreshHandler}/>
                :   <AuditTable
                        auditType={auditType}
                        auditTableData={flattenedData}
                        tableConfig={tableConfig}
                        searchTerm={searchTerm}
                        selectionFilters={selectionFilters}
                    />
            }
        />
    );
};


interface AuditTableContainerLayoutProps {
    auditStatusSelector: JSX.Element;
    searchInputGroup: JSX.Element;
    auditTable: JSX.Element;
    auditExport: JSX.Element;
    auditType: string;
}

/**
 * Dictates the layout of the components within the Audit Table section of the page
 * seperately from the business logic
 */
function AuditTableContainerLayout(props: AuditTableContainerLayoutProps) {
    const { auditStatusSelector, auditTable, searchInputGroup, auditExport, auditType } = props;

    return (
        <div className="pt-1 mb-5">
            <KatDivider variant="athens"></KatDivider>
            {
                auditType !== 'customerObsession' &&
                <div className="row justify-content-between py-2">
                    <div className="col">
                        {auditStatusSelector}
                    </div>
                    <div className="col-2">
                        {searchInputGroup}
                    </div>
                </div>
            }
            {auditTable}
            {
                auditType !== 'customerObsession' &&
                <div className="mt-2 ml-2">
                    {auditExport}
                </div>
            }
        </div>
    );
}
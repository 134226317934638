import { toOptionsArray } from "src/components/common/Enums";
import {Sorting} from "src/components/common/Sorter";
import {taskStatusToInt} from "src/components/tasks/model/TaskStatus";
import {compareImportance} from "src/components/tasks/model/TaskImportance";
import {compareAlphaNumerical, compareDates, compareIfDefined} from "src/components/common/Comparator";

export enum TaskSortingOption {
    DEFAULT = 'Default',
    TITLE = 'Title',
    STATUS = 'Status',
    DUE_DATE = 'Due Date',
    DATE_ADDED = 'Date Added',
    PRIORITY = 'Priority',
    TYPE = 'Type'
}

export function TaskSortingOptionFromString(taskSortingOption: string): TaskSortingOption {
    return taskSortingOption ?
        TaskSortingOption[taskSortingOption.toUpperCase() as keyof typeof TaskSortingOption] :
        TaskSortingOption.DEFAULT;
}

export const TaskSortingFromOption: Record<TaskSortingOption, Sorting<Cerebrum.Tasks.Task>> = {
    [TaskSortingOption.DEFAULT]: {
        name: TaskSortingOption.DEFAULT,
        sortingDecider: (task1: Cerebrum.Tasks.Task, task2: Cerebrum.Tasks.Task) => {
            // Automated > Manual. Works w/ Alphabetical order of A < M
            return compareAlphaNumerical(task1.taskSource, task2.taskSource);
        }
    },
    [TaskSortingOption.TITLE]: {
        name: TaskSortingOption.TITLE,
        sortingDecider: (task1: Cerebrum.Tasks.Task, task2: Cerebrum.Tasks.Task) => {
            // A.Title > B.Title
            return compareAlphaNumerical(task1.title, task2.title);
        }
    },
    [TaskSortingOption.STATUS]: {
        name: TaskSortingOption.STATUS,
        sortingDecider: (task1: Cerebrum.Tasks.Task, task2: Cerebrum.Tasks.Task) => {
            return compareIfDefined<Cerebrum.Tasks.TaskStatus>(task1.taskStatus, task2.taskStatus, (a: Cerebrum.Tasks.TaskStatus, b: Cerebrum.Tasks.TaskStatus) => {
                return taskStatusToInt[a] - taskStatusToInt[b];
            });
        }
    },
    [TaskSortingOption.DUE_DATE]: {
        name: TaskSortingOption.DUE_DATE,
        sortingDecider: (task1: Cerebrum.Tasks.Task, task2: Cerebrum.Tasks.Task) => {
            return compareDates(task1.dueDateTime || '', task2.dueDateTime || '');
        }
    },
    [TaskSortingOption.PRIORITY]: {
        name: TaskSortingOption.PRIORITY,
        sortingDecider: (task1: Cerebrum.Tasks.Task, task2: Cerebrum.Tasks.Task) => {
            return compareImportance(task1.importance, task2.importance);
        }
    },
    [TaskSortingOption.DATE_ADDED]: {
        name: TaskSortingOption.DATE_ADDED,
        sortingDecider: (task1: Cerebrum.Tasks.Task, task2: Cerebrum.Tasks.Task) => {
            return compareDates(task1.createdDateTime || '', task2.createdDateTime || '');
        }
    },
    [TaskSortingOption.TYPE]: {
        name: TaskSortingOption.TYPE,
        sortingDecider: (task1: Cerebrum.Tasks.Task, task2: Cerebrum.Tasks.Task) => {
            return compareAlphaNumerical(task1.taskType, task2.taskType);
        }
    }
} as const;

export function getSelectedTaskSortingFromString(s: string) {
    return TaskSortingFromOption[TaskSortingOptionFromString(s)] || TaskSortingFromOption.Default;
}

export const taskSortingOptions = toOptionsArray(TaskSortingOption);

import moment, { Moment } from "moment";

/**
 * Compares the given values the specified comparator by considering them last if they are undefined
 * @param maybeA first value to compare
 * @param maybeB second value to compare
 * @param comparator the comparator to use if there are not undefined
 */
export function compareIfDefined<Type>(maybeA: Type | undefined, maybeB: Type | undefined,
                                       comparator: (a: Type, b: Type) => number): number {
    if (maybeA === undefined && maybeB === undefined) return 0;
    if (maybeA === undefined) return 1;
    if (maybeB === undefined) return -1;

    return comparator(maybeA, maybeB);
}

/**
 * Compares the given dates by parsing them as default moment unless they are undefined or empty (going last in that case).
 * @param dateA
 * @param dateB
 */
export function compareDates(dateA: string, dateB: string): number {
    const notEmptyA = dateA || undefined;
    const notEmptyB = dateB || undefined;
    return compareIfDefined(notEmptyA, notEmptyB,
        (a, b) => moment(a).diff(moment(b))
    );
}

/**
 * Tells whether the given moment represents a date in the current year
 * @param aMoment
 */
export function isCurrentYear(aMoment: Moment) {
    return moment().year() === aMoment.year();
}

/**
 * Tells whether the given moment represents a date in the current week
 * @param aMoment
 */
export function isCurrentWeek(aMoment: string): boolean {
    return moment().startOf("week")
        .isSame(aMoment, "week");
}

/**
 * Tells whether the given moment represents a date in the current day
 * @param aMoment
 */
export function isToday(aMoment: Moment) {
    return moment().isSame(aMoment, "day");
}

/**
 * Tells whether the given moment represents a date in the previous day
 * @param aMoment
 */
export function isYesterday(aMoment: Moment) {
    return moment().subtract(1, 'day')
        .isSame(aMoment, "day");
}

/**
 * Tells whether the given moment represents a date in the next day
 * @param aMoment
 */
export function isTomorrow(aMoment: Moment) {
    return moment().add(1, 'day')
        .isSame(aMoment, "day");
}

/**
 * Compares two strings, in a none-case sensitive, alphanumeric order
 * @param stringA
 * @param stringB
 */
export function compareAlphaNumerical(stringA: string, stringB: string): number {
    const collator = new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'});
    return compareIfDefined(stringA, stringB,
        (a, b) => collator.compare(a, b)
    );
}

export function compareReverseAlphaNumerical(stringA: string, stringB: string): number {
    return -1 * compareAlphaNumerical(stringA, stringB);
}

export function isDateBetweenNowAndTimeframe(createdDateTime: string, timeframe: number) {
    const millisecondsDelta = new Date().getTime() - new Date(createdDateTime).getTime();
    const minutesDelta = millisecondsDelta / 1000 / 60;
    return minutesDelta <= (timeframe);
}

import { toOptionsArray } from "src/components/common/Enums";
import { Sorting } from "src/components/common/Sorter";
import { compareImportance } from "src/components/tasks/model/TaskImportance";
import { compareAlphaNumerical, compareDates, compareIfDefined } from "src/components/common/Comparator";

export enum NotificationSortingOption {
    DEFAULT = 'Default',
    TITLE = 'Title',
    SOURCE = 'Source',
    PRIORITY = 'Priority',
    TYPE = 'Type'
}

export function NotificationSortingOptionFromString(notificationSortingOption: string): NotificationSortingOption {
    return notificationSortingOption ?
        NotificationSortingOption[notificationSortingOption.toUpperCase() as keyof typeof NotificationSortingOption] :
        NotificationSortingOption.DEFAULT;
}

export const NotificationSortingFromOption: Record<NotificationSortingOption, Sorting<Cerebrum.Tasks.Notification>> = {
    [NotificationSortingOption.DEFAULT]: {
        name: NotificationSortingOption.DEFAULT,
        sortingDecider: (notification1: Cerebrum.Tasks.Notification, notification2: Cerebrum.Tasks.Notification) => {
            return compareDates(notification1.createdDateTime || '', notification2.createdDateTime || '');
        }
    },
    [NotificationSortingOption.TITLE]: {
        name: NotificationSortingOption.TITLE,
        sortingDecider: (notification1: Cerebrum.Tasks.Notification, notification2: Cerebrum.Tasks.Notification) => {
            return compareAlphaNumerical(notification1.title, notification2.title);
        }
    },
    [NotificationSortingOption.SOURCE]: {
        name: NotificationSortingOption.SOURCE,
        sortingDecider: (notification1: Cerebrum.Tasks.Notification, notification2: Cerebrum.Tasks.Notification) => {
            return compareIfDefined<Cerebrum.Tasks.Source>(notification1.notificationSource, notification2.notificationSource,
                (a: Cerebrum.Tasks.Source, b: Cerebrum.Tasks.Source) => {
                return (a.toLowerCase() > b.toLowerCase()) ? 1 : (b.toLowerCase() > a.toLowerCase()) ? -1 : 0;
            });
        }
    },
    [NotificationSortingOption.PRIORITY]: {
        name: NotificationSortingOption.PRIORITY,
        sortingDecider: (notification1: Cerebrum.Tasks.Notification, notification2: Cerebrum.Tasks.Notification) => {
            return compareImportance(notification1.priority, notification2.priority);
        }
    },
    [NotificationSortingOption.TYPE]: {
        name: NotificationSortingOption.TYPE,
        sortingDecider: (notification1: Cerebrum.Tasks.Notification, notification2: Cerebrum.Tasks.Notification) => {
            return compareAlphaNumerical(notification1.notificationType, notification2.notificationType);
        }
    }
} as const;

export function getSelectedNotificationSortingFromString(s: string) {
    return NotificationSortingFromOption[NotificationSortingOptionFromString(s)] || NotificationSortingFromOption.Default;
}

export const notificationSortingOptions = toOptionsArray(NotificationSortingOption);

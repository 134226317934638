import React, { Component } from 'react';
import { CerebrumService } from "src/service/CerebrumCoralService";
import { FeedbackContainer } from 'src/components/flash_briefing/FeedbackCard';
import { AxiomMetricsDriver, MetricStatus } from "src/metrics/AxiomMetricsDriver";
import { Dropdown } from 'react-bootstrap';
import i18n from 'i18next';
import { FlashBriefingModal } from '@amzn/wfm-axiom-flash-briefing-ui-components'
import { CardOptionsMenu } from "src/components/common/CardOptionsMenu";
import { newTask } from "src/components/tasks/model/Task";
import { getWeekNumber } from "src/components/audit/selector/WeekSelector";
import { UserManager } from "src/user/UserIdentityManager";
import { AssistantContext } from "src/components/assistant/AssistantContext";
import { KatDivider } from "@amzn/katal-react";


export interface CardLoaderProps<T> {
    entityId: string;
    cardMetadata: Cerebrum.Card,
    CardComponent: React.FC<T>,
    cardAdapterFunction: (cardMetadata: Cerebrum.Card) => T,
    LoadingCardComponent: React.FC<Cerebrum.CardMetadata>,
    ErrorCardComponent?: React.FC<any>
}

export interface CardLoaderState {
    showFeedback: boolean;
    showCardMetadata: boolean;
    card?: Cerebrum.Card;
    hasError: boolean;
    errorMessage?: string;
}

export default class CardLoader<T> extends Component<CardLoaderProps<T>, CardLoaderState> {
    private componentName: string = "CardLoader";

    constructor(props: CardLoaderProps<T>) {
        super(props);
        this.state = { card: undefined, showFeedback: false, showCardMetadata: false, hasError: false, errorMessage: undefined };
        this.hideFeedback = this.hideFeedback.bind(this);
        this.showFeedback = this.showFeedback.bind(this);
        this.hideCardMetadata = this.hideCardMetadata.bind(this);
        this.showCardMetadata = this.showCardMetadata.bind(this);
    }

    public async componentDidMount() {
        try {
            const { entityId, cardMetadata } = this.props;
            const cardResponse = cardMetadata.cardContent ? cardMetadata :
                (await CerebrumService.getCardContent(cardMetadata.cardId, entityId)).card;
            this.setState({ card: cardResponse });
        } catch (error) {
            this.setState({ ...this.state, hasError: true, errorMessage: JSON.stringify(error) });
            AxiomMetricsDriver.publishComponentLoadError(this.componentName, MetricStatus.Failure);
        }
    }

    private hideFeedback() {
        this.setState({ showFeedback: false });
    }

    private showFeedback() {
        this.setState({ showFeedback: true });
    }

    private hideCardMetadata() {
        this.setState({ showCardMetadata: false });
    }

    private showCardMetadata() {
        this.setState({ showCardMetadata: true });
    }

    private static onAddTaskForCard(title: string, cardId: string, cardType: string, createTask: (partialTask: Cerebrum.Tasks.Task) => void): void {
        const task = newTask(title, "Metric", UserManager.getUserIdentity().login);
        createTask(task);

        const additionalMetrics = [{ name: 'cardId', value: cardId }, {name: 'cardType', value: cardType}];
        AxiomMetricsDriver.publishButtonClick('FlashBriefingCard', 'AddTask', additionalMetrics);
    }

    componentDidCatch(error: Error) {
        this.setState({ ...this.state, hasError: true, errorMessage: JSON.stringify(error) });
        AxiomMetricsDriver.publishComponentLoadError(this.componentName, MetricStatus.Failure)
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => { return; };
    }

    render() {
        const { cardMetadata, LoadingCardComponent, ErrorCardComponent, entityId } = this.props;
        const { hasError, card, showFeedback, showCardMetadata, errorMessage } = this.state;
        if (hasError && !ErrorCardComponent) { return null }
        if (hasError && ErrorCardComponent) {
            return <ErrorCardComponent
                cardTitle={cardMetadata.cardTitle}
                errorLabel={i18n.t("wfm_axiom_error_card_label")}
                errorMessage={errorMessage}
                showErrorActionLabel={i18n.t("wfm_axiom_error_card_see_details")}
                cardMetadata={{ ...cardMetadata, ...card }} />
        }
        else if (!card) { return <LoadingCardComponent {...cardMetadata} /> }
        else if (showFeedback) {
            return <FeedbackContainer
                cardMetadata={cardMetadata}
                onClose={this.hideFeedback}
            />
        }
        else {
            return (
                <>
                    {showCardMetadata &&
                    <FlashBriefingModal
                        visible={showCardMetadata}
                        title={i18n.t("wfm_axiom_fb_view_card_metadata_title")}
                        body={JSON.stringify(card, null, 2)}
                        onClose={this.hideCardMetadata}
                    />}
                    <BuildCard
                        card={card}
                        entityId={entityId}
                        menuContent={<FlashBriefingCardMenu
                            card={card}
                            onFeedbackClick={this.showFeedback}
                            onViewMetadataClick={this.showCardMetadata}
                            onAddTaskForCardClick={CardLoader.onAddTaskForCard}
                        />}
                        CardComponent={this.props.CardComponent}
                        cardAdapterFunction={this.props.cardAdapterFunction}/>
                </>
            )
        }
    }
}

interface BuildCardProps<T> {
    card: Cerebrum.Card,
    menuContent: React.ReactElement,
    CardComponent: React.FC<T>
    cardAdapterFunction: (card: Cerebrum.Card) => T,
    entityId: string
}

function BuildCard<T>(props: BuildCardProps<T>) {
    const { card, menuContent, CardComponent, cardAdapterFunction } = props;
    const flashBriefingCardProps = cardAdapterFunction(card);
    return <CardComponent {...flashBriefingCardProps} menuContent={menuContent} metricsDriver={AxiomMetricsDriver}/>;
}

interface FlashBriefingCardMenuProps {
    card: Cerebrum.Card;
    onFeedbackClick: () => void;
    onViewMetadataClick: () => void;
    onAddTaskForCardClick: (title: string, cardId: string, cardType: string, createTask: (partialTask: Cerebrum.Tasks.Task) => void) => void;
}

function FlashBriefingCardMenu(props: FlashBriefingCardMenuProps) {
    const { cardTitle, cardContent, cardType, cardId } = props.card;

    const kpiCardTaskTitle = (): string => {
        const dataPoint = (cardContent as Cerebrum.KPIContent).dataPoint;
        return `${cardTitle}: ${dataPoint} | Week ${getWeekNumber(0)}`;
    }

    return (
        <CardOptionsMenu horizontalIcon>
            {(cardType === 'kpiCard') &&
            <>
                <AssistantContext.Consumer>
                {assistantContext => (
                    <Dropdown.Item as="button" onClick={() => props.onAddTaskForCardClick(kpiCardTaskTitle(), cardId, cardType, assistantContext.startCreateTask)}>
                        {i18n.t("wfm_axiom_add_to_task")}
                    </Dropdown.Item>
                )}
                </AssistantContext.Consumer>
                <KatDivider variant="athens"/>
            </>}
            <Dropdown.Item as="button" onClick={props.onFeedbackClick}>
                {i18n.t("wfm_axiom_fb_feedback_card_title")}
            </Dropdown.Item>
            <Dropdown.Item as="button" onClick={props.onViewMetadataClick}>
                <span className="text-muted">{i18n.t("wfm_axiom_fb_card_metadata_menu_text")}</span>
            </Dropdown.Item>
        </CardOptionsMenu>
    );
}

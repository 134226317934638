import React from "react";
import { KatOption, KatBadge } from "@amzn/katal-react";
import { shortLocales } from "src/i18n/locales"

type DefinitionDropdownOptionProps = {
    name: string,
    value: string,
    language: string,
    showLanguage?: boolean
}

export const DefinitionDropdownOption = ({name, value, language, showLanguage}: DefinitionDropdownOptionProps) => {
    const shortLocale = shortLocales[language] || language
    return (
        <KatOption value={value} searchkey={value}>
            <div className="d-flex align-items-center">
                {showLanguage &&
                <KatBadge label={shortLocale} type="default" className="mr-1"/>}
                <span> {name} </span>
            </div>
        </KatOption>
    )
}
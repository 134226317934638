import { TableProps } from "@amzn/awsui-components-react";
import { PropertyFilterOperator } from "@amzn/awsui-collection-hooks"

export type filterType = "buttonGroup" | "dropdown";

export interface ExtendedColumnDefinition<T> extends TableProps.ColumnDefinition<T> {
    id: string;
    isSearchable?: boolean;
    getter?: (item: T) => string | number;
    operators?: PropertyFilterOperator;
    hiddenByDefault?: boolean;
}

export type GenericObject = { [key: string]: any };

export type DropdownOption = {
    [key: string]: string | number;
};

export type GroupButton = {
    label: string;
    value: string | number;
};

export type FilterConfig = {
    type: filterType;
    attributeName: string;
    attributeGetter?: Function;
    buttons?: GroupButton[];
    options?: DropdownOption[];
    defaultSelectedLabel: string;
    defaultSelectedValue: string;
    eventHandler: () => void;
    customStyle?: object;
};

export interface ButtonGroupFilterConfig extends FilterConfig {
    buttons: GroupButton[];
}

export interface DropdownFilterConfig extends FilterConfig {
    options: DropdownOption[];
}

export type HeaderActionType = "button";

export type HeaderNativeAction = {
    label: string;
    type: HeaderActionType;
    handler: ({}) => void;
    disabled: boolean;
};

export type HeaderCustomAction = {
    type: "custom";
    customComponent: JSX.Element;
};

export type HeaderAction = HeaderNativeAction | HeaderCustomAction;

export type HeaderConfig = {
    title: string | JSX.Element;
    counter?: string,
    variant?: string;
    description?: string;
    actions?: HeaderAction[];
};

export enum PolarisTableUniqueLocalStorageKeys {
    CUSTOMER_OBSESSION_TABLE = "customer-obsession-table",
    FORM_SUBMISSIONS_TABLE = "form-submissions-table",
    TASK_LOG_TABLE = "task-log-table"
}
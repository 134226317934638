import React from "react";
import ReactMarkdown from "react-markdown";
import { CerebrumService } from "src/service/CerebrumCoralService";
import i18n from 'i18next';
import { ErrorBoundComponent, ErrorBoundState } from "src/components/error/ErrorBoundComponent";
import { KatSpinner } from "@amzn/katal-react";
import { SupportNavigation } from "./SupportNavigation"
import { AxiomMetricsDriver } from "src/metrics/AxiomMetricsDriver";
import { ElementContent } from "react-markdown/lib/ast-to-react";
import rehypeRaw from 'rehype-raw';


const DEFAULT_HOME = 'About';

const INITIAL_STATE = {
    helpContent: null,
    hasError: false
};

export interface SupportState extends ErrorBoundState {
    helpContent: Cerebrum.HelpContent | null;
}

export interface SupportProps {
    supportId: string | null;
}

const absoluteURIRegExp = new RegExp('^(//|[a-z]+:)', 'i');

function transformLinkUri(href: string, children: Array<ElementContent>, title: string | null): string {
    return isURIAbsolute(href) ? href : `#/support/${href}`;
}

function isURIAbsolute(originalUri: string): boolean {
    return absoluteURIRegExp.test(originalUri)
}

export default class Support extends ErrorBoundComponent<SupportProps, SupportState> {
    private componentName = "Support";

    constructor(props: SupportProps) {
        super(props);
        this.state = INITIAL_STATE;
    }

    componentDidMount(): void {
        this.loadSupportPage();
        this.publishPageLoad();
    }

    componentDidUpdate(prevProps: Readonly<SupportProps>) {
        if (this.props == prevProps) {
            return;
        }
        if (this.props.supportId !== prevProps.supportId) {
            this.clearState();
            this.loadSupportPage();
            this.publishPageLoad();
        }
    }

    private publishPageLoad() {
        const additionalMetrics = [
            {
                name: "supportId",
                value: this.props.supportId || DEFAULT_HOME
            }
        ];
        AxiomMetricsDriver.publishPageLoad(this.componentName, window.location.href, additionalMetrics);
    }

    private loadSupportPage() {
        CerebrumService.getHelpContent(this.props.supportId || DEFAULT_HOME, i18n.language)
            .then(helpContent => {
                this.setState({ helpContent: helpContent });
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    private clearState() {
        this.setState({
            helpContent: null,
            hasError: false
        });
    }

    render() {
        let content = this.renderError();
        if (!content) {
            document.title = `${SITE_NAME} - ${this.state.helpContent?.title || ''}`;
            content = (
                <div className="mt-4 container d-flex justify-content-start">
                    <SupportNavigation currentPage={this.props.supportId || undefined}/>
                    {!this.state.helpContent ?
                        <KatSpinner/> :
                        <ReactMarkdown rehypePlugins={[rehypeRaw]}
                                       transformLinkUri={transformLinkUri}
                                       className="axiom-support-content"
                                       children={this.state.helpContent?.content ?? ''}/>
                    }
                </div>
            )
        }
        return content;
    }
}
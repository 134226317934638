import React, { useEffect, useMemo, useState } from "react";
import { emptyTaskFor } from "src/components/tasks/model/Task";
import { CerebrumService } from "src/service/CerebrumCoralService";
import { CoralError } from "src/components/error/ErrorBoundComponent";
import EventEmitter from "src/context/EventEmitter";
import { TaskCreatedEvent } from "src/components/tasks/events/TaskCreatedEvent";
import { taskTypeOptions } from "src/components/tasks/model/TaskAndNotificationType";
import { KatButton, KatCheckbox, KatDatePicker, KatDropdown, KatInput, KatSpinner, KatTextarea, KatToggle } from "@amzn/katal-react";
import { UserContext } from "src/context/UserContext";
import { TaskUpdatedEvent } from "src/components/tasks/events/TaskUpdatedEvent";
import { AxiomMetricsDriver } from "src/metrics/AxiomMetricsDriver";
import i18n from "i18next";
import { toISOStringUTC } from "src/components/common/DateFormatter";
import { Weblabs } from "src/user/WeblabsManager";
import { Weblab, Treatment } from "src/user/Weblab";

interface TaskFormProps {
    viewUserLogin: string;
    task?: Cerebrum.Tasks.Task;
    createNew?: boolean;
    onSuccess?: (task: Cerebrum.Tasks.Task) => void;
    onCancel?: () => void;
}

const COMPONENT_NAME = "TasksForm";

export const TaskForm: React.FC<TaskFormProps> = (props) => {

    const { viewUserLogin, onSuccess, onCancel, createNew } = props

    const userContext = React.useContext(UserContext);
    const userLogin = userContext.userManager.userIdentity!.login;

    const [task, setTask] = useState(props.task ?? emptyTaskFor(viewUserLogin));

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [titleState, setTitleState] = useState(undefined as KatInput.State | undefined);

    const [status, setStatus] = useState('');

    const [assignToUserToggle, setAssignToUserToggle] = useState<boolean>(false);

    const [assignToUserState, setAssignToUserState] = useState(undefined as KatInput.State | undefined);

    useEffect(() => {
        if (createNew && !assignToUserToggle) {
            setTask({ ...task, taskOwner: userLogin });
        }
    }, [assignToUserToggle])

    const handleSubmit = async () => {
        if (isValidForm()) {
            setIsLoading(true);
            if (createNew) {
                task.createdBy = userLogin;
                await CerebrumService.createTask(task)
                    .then(handleResponse)
                    .catch(handleError);

                AxiomMetricsDriver.publishButtonClick(COMPONENT_NAME, "createTask");
            } else {
                await CerebrumService.updateTask(task)
                    .then(handleResponse)
                    .catch(handleError);

                const additionalMetric = { name: "taskId", value: task.id };
                AxiomMetricsDriver.publishButtonClick(COMPONENT_NAME, "updateTask", [additionalMetric]);
            }
        }
    };

    function handleResponse(response: Cerebrum.Tasks.CreateTaskResponse) {
        setIsLoading(false);
        setTask(emptyTaskFor(viewUserLogin));
        const taskEvent = createNew ?
            new TaskCreatedEvent(response.task) :
            new TaskUpdatedEvent({ ...response.task, seen: true });
        
        if (response.task.taskOwner === viewUserLogin){    
            EventEmitter.dispatch(taskEvent.name, taskEvent);
        }

        onSuccess?.(response.task);
    }

    function handleError(error: CoralError) {
        setIsLoading(false);
        const errorTitle = createNew ? i18n.t('wfm_axiom_tasks_error_creating_task')
            : i18n.t('wfm_axiom_tasks_error_updating_task')
        setStatus(`${errorTitle}. ${error.message}`);
    }

    function isValidForm(): boolean {
        let passing = true

        function isEmptyOrSpaces(str: string) {
            return str === null || str.match(/^ *$/) !== null;
        }

        if (isEmptyOrSpaces(task.title)) {
            passing = false;
            setTitleState('error');
        } else {
            setTitleState(undefined);
        }

        if (assignToUserToggle && task.taskOwner === userLogin){
            passing = false;
            setAssignToUserState('error');
        } else {
            setAssignToUserState(undefined);
        }

        return passing;
    }

    function toggleAssignToUser(): void {
        setAssignToUserToggle(!assignToUserToggle);
    }

    const LoadingSpinner = () => (
        <>{isLoading &&
        <div className="row justify-content-center"><KatSpinner size="large"/></div>}
        </>
    );

    const proccessAssignToValue = () => {
        if (task.taskOwner === userLogin) {
            return undefined;
        }
        return task.taskOwner;
    }

    const Form = () => (
        <>{!isLoading &&
        <>
            <span className='h6 text-danger'>{status}</span>

            <KatInput className='py-1' label={`${i18n.t('wfm_axiom_tasks_title')}*`}
                      required autofocus value={task.title}
                      onChange={update({ title: undefined })} state={titleState}
                      stateEmphasis={i18n.t('wfm_axiom_tasks_error')}
                      maxlength={250}
                      stateLabel={i18n.t('wfm_axiom_tasks_title_required')}/>

            { createNew &&
            <KatCheckbox className="py-1" label={`${i18n.t('wfm_axiom_tasks_assign_to_other_user_title')}`}
                         onChange={toggleAssignToUser}
                         checked={assignToUserToggle}
            >
            </KatCheckbox>
            }

            { assignToUserToggle &&
             <KatInput className='py-1' label={`${i18n.t('wfm_axiom_tasks_assign_to')}*`}
                       required autofocus value={proccessAssignToValue()}
                       onChange={update({ taskOwner: undefined })} state={assignToUserState}
                       stateEmphasis={i18n.t('wfm_axiom_tasks_error')}
                       maxlength={15}
                       stateLabel={i18n.t('wfm_axiom_tasks_login_required')}/>   
            }

            <KatDatePicker className='py-1' label={i18n.t('wfm_axiom_tasks_due_date')} value={toISOStringUTC(task.dueDateTime!)}
                           onChange={update({ dueDateTime: undefined })}/>


            <KatDropdown className='py-1' label={i18n.t('wfm_axiom_tasks_type')} options={taskTypeOptions}
                         value={task.taskType}
                         onChange={update({ taskType: undefined })}/>

            <KatTextarea className='py-1' label={i18n.t('wfm_axiom_tasks_description')} value={task.body}
                         onChange={update({ body: undefined })}
                         maxlength={500}
                         disabled={!!task.templateName} // disabling body updates when it is rendered from a template
                         stateEmphasis={task.templateName && i18n.t('wfm_axiom_tasks_note')}
                         stateLabel={task.templateName && i18n.t('wfm_axiom_tasks_cannot_edit_description')}/>

            <KatButton label={createNew ? i18n.t('wfm_axiom_tasks_create') : i18n.t('wfm_axiom_tasks_update')}
                       onClick={handleSubmit}
                       className='task-button py-1 pr-1'/>
            <KatButton label={i18n.t('wfm_axiom_tasks_cancel')}
                       onClick={() => onCancel?.()}
                       className='task-button py-1 pl-1' variant='secondary'/>
        </>}
        </>
    );

    return <>
        <Form/>
        <LoadingSpinner/>
    </>

    /**
     * Helper curried function to avoid repeating the event handler for each input field.
     * Receives a partial task to have compiler's help with the field to update definition,
     * though only the first field form the partial task will be considered.
     * @param partialTask indicating the field to update
     */
    function update(partialTask: Partial<Cerebrum.Tasks.Task>) {
        return (event: any) => {
            const fieldName = Object.keys(partialTask)[0];
            // @ts-ignore is ok since we know it has to be a Task field, since it comes from a Partial Task
            task[fieldName] = event.target.value;
            isValidForm();
        }
    }
}
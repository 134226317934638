import React, { useEffect, useState } from "react";
import "./Notifications.scss"
import { KatButton, KatDivider, KatSpinner } from "@amzn/katal-react";
import { CoralError } from "src/components/error/ErrorBoundComponent";
import { ErrorMessage } from "src/components/error/ErrorMessage";
import { NotificationsList } from "src/components/notifications/NotificationsList";
import { CerebrumService } from "src/service/CerebrumCoralService";
import { EmptyListView } from "src/components/common/EmptyListView";
import EventEmitter, { asEventListener } from "src/context/EventEmitter";
import { NotificationEventName } from "src/components/notifications/events/NotificationEvents";
import { uniqueId } from "src/context/UniqueId";
import { NotificationUpdatedEvent } from "src/components/notifications/events/NotificationUpdatedEvent";
import { useHistory } from "react-router-dom";
import { HeaderRoute } from "src/components/common/header/HeaderRoute";
import { AxiomMetricsDriver } from "src/metrics/AxiomMetricsDriver";
import { beginningOfLastWeek } from "src/components/common/DateFormatter";
import i18n from "i18next";
import { AssitantTab } from "src/components/assistant/AssistantContext";
import {
    NotificationGroupingFromOption,
    NotificationGroupingOptionFromString,
    notificationGroupingOptions
} from "src/components/notifications/model/NotificationGroupingOptions";
import {
    NotificationSortingFromOption,
    NotificationSortingOptionFromString,
    notificationSortingOptions
} from "src/components/notifications/model/NotificationSortingOptions";
import { Grouped } from "src/components/common/Grouper";
import { ItemOrganizer, ItemOrganizerConfigOptions } from "src/components/common/itemOrganizer/ItemOrganizer";
import { compareReverseAlphaNumerical } from "src/components/common/Comparator";

interface NotificationsProps {
    viewUserLogin: string;
    setUnseenNumberCallback: (tabName: AssitantTab, unseenNumber: number) => void;
}

const COMPONENT_NAME = "Notifications";
const FILTER_NOTIFICATIONS_VIEW_TEXT = "View";

export const NOTIFICATION_SORTING_GROUPING_CONFIG: ItemOrganizerConfigOptions<Cerebrum.Tasks.Notification> = {
    sortStrategies: NotificationSortingFromOption,
    groupStrategies: NotificationGroupingFromOption,
    sortingOptionFromString: NotificationSortingOptionFromString,
    groupingOptionFromString: NotificationGroupingOptionFromString,
    groupingOptions: notificationGroupingOptions,
    sortingOptions: notificationSortingOptions
}

export const Notifications: React.FC<NotificationsProps> = (props) => {

    const [instanceId] = useState(() => uniqueId(COMPONENT_NAME));

    const { viewUserLogin } = props;

    const history = useHistory();

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [notifications, setNotifications] = useState<Cerebrum.Tasks.Notification[]>([]);

    const [groupedNotifications, setGroupedNotifications] = useState<Grouped<Cerebrum.Tasks.Notification>>({});

    const [error, setError] = useState<CoralError>();

    useEffect(() => {
        getNotifications();
    }, [viewUserLogin]);

    const markUnseenNotificationsAsSeen = () => {
        const unseenNotifications = notifications.filter(notification => !notification.seen)
            .map(notification => notification.id);
        if (unseenNotifications.length > 0) {
            const input: Cerebrum.Tasks.MarkNotificationsAsSeenInput = {
                ids: unseenNotifications,
                notificationOwner: viewUserLogin
            };
            CerebrumService.markNotificationsAsSeen(input)
                // tslint:disable-next-line:no-console
                .catch(coralError => console.error(coralError));
        }
    }
    useEffect(markUnseenNotificationsAsSeen, [notifications]);

    // Event listening and cleanup
    const onNotificationUpdated = (notificationUpdatedEvent: NotificationUpdatedEvent) => {
        const updatedNotification = notificationUpdatedEvent.payload;

        setNotifications(currentNotifications => currentNotifications
            .map(currentNotification => currentNotification.id === updatedNotification.id ?
                updatedNotification :
                currentNotification)
            .filter(notification => !notification.dismissed)
        );
    }

    const notificationUpdatedListener = asEventListener(instanceId, onNotificationUpdated);
    useEffect(() => {
        EventEmitter.subscribe(NotificationEventName.NOTIFICATION_UPDATED_EVENT, notificationUpdatedListener);
        return () => {
            EventEmitter.unSubscribe(NotificationEventName.NOTIFICATION_UPDATED_EVENT, instanceId);
        }
    }, []);

    // Api calls
    const getNotifications = async () => {
        const input: Cerebrum.Tasks.GetNotificationsInput = {
            notificationOwner: viewUserLogin,
            notificationFilter: { createdFrom: beginningOfLastWeek(), dismissed: false }
        };
        await CerebrumService.getNotifications(input)
            .then(handleResponse)
            .catch(handleError);
    }

    // Api calls handlers
    const handleResponse = (response: Cerebrum.Tasks.GetNotificationsOutput) => {
        setNotifications(response.notifications);
        setIsLoading(false);
        setError(undefined);
        const unseenCount = response.notifications.filter(notification => !notification.seen).length;
        props.setUnseenNumberCallback(AssitantTab.NOTIFICATIONS, unseenCount);
    }

    const handleError = (coralError: CoralError) => {
        // tslint:disable-next-line:no-console
        console.error(coralError);
        setNotifications([]);
        setIsLoading(false);
        setError(coralError);
    }

    const handleViewLogClick = () => {
        const route = HeaderRoute.NOTIFICATIONS_LOG;
        AxiomMetricsDriver.publishLinkClick(COMPONENT_NAME, 'ViewLog', route);
        history.push(route);
    }

    // UI elements
    const LoadingSpinner = () => (
        <>{isLoading &&
        <div className="row justify-content-center"><KatSpinner size="large"/></div>}
        </>
    );

    const LoadingErrorMessage = () => (
        // TODO: error handling to be improved in https://issues.amazon.com/issues/SUPSUP-781
        <>{error &&
        <ErrorMessage
            title={i18n.t('wfm_axiom_notifications_error_getting_notifications')}
            message={error.__type + "\n" + error.message ? error.message : ""}
            level="warning"/>
        }</>
    );

    const EmptyNotificationsView = () => (
        <>{!isLoading && notifications.length === 0 &&
        <EmptyListView iconName='notifications' iconBorder
                       message={i18n.t('wfm_axiom_notifications_no_new')}/>
        }</>
    )

    const NotificationsLists = () => (
        <>{!isLoading && notifications.length > 0 &&
            <>
                {Object.keys(groupedNotifications).sort(compareReverseAlphaNumerical).map((key: string, index: number) => <>
                    <NotificationsList title={key}
                               key={`${key}-${index}-notification`}
                               notifications={groupedNotifications[key].filter(notification => !notification.dismissed)}
                               viewUserLogin={viewUserLogin}/>
                    {index !== Object.keys(groupedNotifications).length - 1 &&
                        <KatDivider variant='eastern' key={`${key}-${index}-divider`}/>}
                </>)}
            </>}
        </>
    );

    return (
        <>
            <div className="d-flex flex-column px-3 h-100">
                <div className="d-flex flex-row">
                    <ItemOrganizer<Cerebrum.Tasks.Notification>
                        parentComponentName={COMPONENT_NAME} label={FILTER_NOTIFICATIONS_VIEW_TEXT}
                        items={notifications}
                        itemOrganizerConfigOptions={NOTIFICATION_SORTING_GROUPING_CONFIG}
                        updateItemsToDisplay={setGroupedNotifications}/>
                </div>
                <LoadingErrorMessage/>
                <LoadingSpinner/>
                <EmptyNotificationsView/>
                <NotificationsLists/>
                <KatButton className='task-button' label={i18n.t('wfm_axiom_tasks_view_log')} variant='link' onClick={handleViewLogClick}>
                    <span className='task-button-label'>{i18n.t('wfm_axiom_tasks_view_log')}</span>
                </KatButton>
            </div>
        </>
    );
}
import { AxiomMetricsDriver } from "src/metrics/AxiomMetricsDriver";

const COMPONENT_NAME = 'Audit';

/**
 * Wraps the base AxiomMetricsDriver for use in the AuditTable;
 *
 * additional metrics for `AuditType` and `AuditId` based on rowdata will always automatically get added
 */
export class TableMetrics {
    static publishLinkClick(buttonName: string, linkUrl: string, additionalMetrics?: Axiom.AdditionalMetric[]) {
        AxiomMetricsDriver.publishLinkClick(COMPONENT_NAME, buttonName, linkUrl, additionalMetrics);
    }

    static publishButtonClick(buttonName: string, additionalMetrics?: Axiom.AdditionalMetric[]) {
        AxiomMetricsDriver.publishButtonClick(COMPONENT_NAME, buttonName, additionalMetrics);
    }

    static buildAdditionalMetrics(rowData: Cerebrum.Audits.RowData, additionalMetrics: Axiom.AdditionalMetric[] = []) {
        const { externalAuditId, auditType } = rowData;
        additionalMetrics.push(
            { name: 'auditType', value: auditType },
            { name: 'externalAuditId', value: externalAuditId }
        );
        return additionalMetrics;
    }
}
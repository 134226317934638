import locales from "src/i18n/locales";
import { NavDropdown } from "react-bootstrap";
import React from "react";
import i18n from "i18next";

export function LanguageDropdown() {

    const changeLanguage = (locale: string) => {
        console.error(locale);
        i18n.changeLanguage(locale);
        window.location.reload();
    }

    const getLocaleName = () =>
        locales.find(locale => locale.value === localStorage.getItem('i18nextLng'))?.name || '';

    return (
        <NavDropdown id="dropdown-basic-button"
            className="axiom-dropdown-navigation language-dropdown"
            title={getLocaleName()}>
            {locales.map(locale => (
                <NavDropdown.Item onClick={() => changeLanguage(locale.value)}
                    key={locale.value}
                    target="_blank">
                    {locale.name}
                </NavDropdown.Item>
            ))}
        </NavDropdown>
    );
}
import { TaskImportance } from "src/components/tasks/model/TaskImportance";
import { TaskSource } from "src/components/tasks/model/TaskSource";
import { TaskAndNotificationType } from "src/components/tasks/model/TaskAndNotificationType";
import { TaskStatus } from "src/components/tasks/model/TaskStatus";


export function emptyTask(): Cerebrum.Tasks.Task {
    return {
        id: '',
        title: '',
        taskOwner: '',
        taskType: TaskAndNotificationType.GENERAL,
        importance: TaskImportance.NORMAL,
        taskStatus: TaskStatus.NOT_STARTED,
        taskSource: TaskSource.MANUAL,
        deleted: false,
        seen: true
    }
}

export function emptyTaskFor(owner: string): Cerebrum.Tasks.Task {
    const task = emptyTask();
    task.taskOwner = owner;
    return task;
}

export function newTask(title: string, type: Cerebrum.Tasks.Type, owner: string): Cerebrum.Tasks.Task {
    const task = emptyTaskFor(owner);
    task.title = title;
    task.taskType = type;
    return task;
}

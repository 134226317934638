import React, { useEffect } from 'react';
import i18n from 'i18next';
import UserLookup from "src/components/admin/UserLookup";
import UserDetailsSwitcher from "src/components/admin/UserDetailsSwitcher";
import AccessRightsSelector from "src/components/admin/AccessRightsSelector";
import { AxiomMetricsDriver } from 'src/metrics/AxiomMetricsDriver';
import WeblabTreatmentSelector from "src/components/admin/WeblabTreatmentSelector";


export default function DevTools() {
    const componentName = "DevTools";
    useEffect(() => {
        // Update the document title using the browser API
        document.title = `${SITE_NAME} - ${componentName}`;
        AxiomMetricsDriver.publishPageLoad(componentName, window.location.href);
    });

    return (
        <div id="content" className="content-wrapper">
            <div className="container-fluid">
                <div className="mt-2">
                    <h1>{i18n.t('wfm_axiom_v2_dev_tools')}</h1>
                </div>
                <div className="row mb-4">
                    <UserDetailsSwitcher />
                    <AccessRightsSelector />
                </div>
                <div className="row mb-4">
                    <UserLookup />
                    <WeblabTreatmentSelector />
                </div>
            </div>
        </div >
    );
}

;
import React from "react";
import { ListGroup } from "react-bootstrap";
import { SupportNavigationItem } from "./SupportNavigationItem";

interface SupportNavigationProps {
    currentPage?: string,
}

const pages: Axiom.Map = {
    "About": "About",
    "Overview": "Overview",
    "WalkThrough": "WalkThrough",
    "Audit": "Audit",
    "NVA Audits": "NVAAudits",
    "Recomendation Engine": "RecomendationEngine",
    "Scoring Methodology": "ScoringMethodology",
    "ASR Scoring": "ASRScoring",
    "My Performance": "MyPerformance",
    "Updates": "Updates",
    "Permissions": "Permissions",
    "FAQs": "Faqs",
    "Support": "Support",
    "Roadmap": "Roadmap",
};

export class SupportNavigation extends React.Component<SupportNavigationProps>  {
    constructor(props: SupportNavigationProps) {
        super(props);
    }

    render() {
        return (
            <div className="col-sm-3" >
                <ListGroup>
                    {Object.entries(pages).map(entry => (
                        <SupportNavigationItem entry={entry} currentPage={this.props.currentPage} />
                    ))}
                </ListGroup>
            </div>

        );
    }
}
import { AxiomMetricsDriver } from "src/metrics/AxiomMetricsDriver";

const COMPONENT_NAME = 'Audit';

interface publishAuditSelectorMetricProps {
    /** Name of selector, ex. `statusSelector` */
    selectorName: string;
    /** value chosen at the time of click, ex. `pending` */
    selectorValue: string;
    /** value of the current selected auditType to give context on the metric */
    selectedAuditType: string;
}

export const publishAuditSelectorMetric = (props: publishAuditSelectorMetricProps) => {
    const { selectorName, selectorValue, selectedAuditType } = props;

    let additionalMetrics = [];
    if (selectedAuditType) {
        additionalMetrics.push({
            name: 'auditType',
            value: selectedAuditType
        });
    }

    AxiomMetricsDriver.publishButtonClick(COMPONENT_NAME, `${selectorName}.${selectorValue}`, additionalMetrics);
};
import React, { FC, PropsWithChildren, useRef, useState } from "react";
import { KatButton, KatDropdown, KatIcon } from "@amzn/katal-react";

interface ClearableDropdownProps {
    label: string;
    options: { name: any, value: any }[];
    onUpdate: (e: any) => void;
    value?: string;
    clearToValue?: string;
    className?: string;
}

export const ClearableDropdown: FC<PropsWithChildren<ClearableDropdownProps>> = (props) => {

    const { label, options, onUpdate, clearToValue, className } = props;

    const [value, setValue] = useState(props.value);
    const dropdownRef = useRef<any>();

    const clearValue = () => {
        setValue(clearToValue || undefined);
        dropdownRef.current.value = clearToValue || undefined;
        onUpdate({ target: { value: clearToValue || null } })
    }

    return (
        <div className={`clearable-container ${className || ''}`}>
            <KatDropdown className='clearable-item'
                         label={label} options={options} value={value}
                         ref={dropdownRef}
                         onChange={onUpdate}/>
            <KatButton className='clear-button' variant='link' onClick={clearValue}>
                <KatIcon title='Clear'
                         name='snooze'
                         size='tiny'/>
            </KatButton>
        </div>
    );
}
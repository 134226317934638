import React, { useMemo } from "react";
import ButtonGroupFilter from "./ButtonGroupFilter";
import DropdownFilter from "./DropdownFilter";

import { KatDropdown, KatButtonGroup } from "@amzn/katal-react";

import {
    GenericObject,
    DropdownOption,
    FilterConfig,
    ButtonGroupFilterConfig,
    DropdownFilterConfig,
} from "src/@types/extendedPolarisTable";

type FilterProps = {
    filterConfigs: FilterConfig[];
    items: any; //TODO: Improve typing
    eventHandler: (e: KatDropdown.ChangeEvent) => void;
    state: GenericObject
};

const generateOptionsPerAttributeMap = (itemList: any, attributes: GenericObject[]) => {
    const attributesMap = attributes.reduce((acc: any, attribute: GenericObject ) => ({ ...acc, [attribute.attributeName]: new Set() }), {});

    for (const item of itemList) {
        for (const attribute of attributes) {
            const { attributeName, attributeGetter } = attribute
            const attributeValue =  attributeGetter ? attributeGetter(item) : item[attributeName]
            attributesMap[attributeName].add(attributeValue);
        }
    }

    return attributesMap;
};

//TODO Improve typings of optionsPerAttribute
const generateComponent = (config: FilterConfig, optionsPerAttribute: any, eventHandler: Function, value: string) => {
    switch (config.type) {
        case "buttonGroup":
            return (
                <ButtonGroupFilter
                    config={config as ButtonGroupFilterConfig}
                    eventHandler={eventHandler as (e: KatButtonGroup.ChangeEvent) => void}
                    value={value}
                />
            );
        case "dropdown":
            const {
                attributeName,
                defaultSelectedLabel,
                defaultSelectedValue,
            } = config;
            const options = Array.from(optionsPerAttribute[attributeName]).map(
                (option) => ({ name: option, value: option }),
            );
            const optionsWithDefault = [
                { name: defaultSelectedLabel, value: defaultSelectedValue },
                ...options,
            ] as DropdownOption[];

            return (
                <DropdownFilter
                    config={
                        {
                            ...config,
                            options: optionsWithDefault,
                        } as DropdownFilterConfig
                    }
                    eventHandler={
                        eventHandler as (e: KatDropdown.ChangeEvent) => void
                    }
                    value={value}
                />
            );
    }
};

export default function Filters(props: FilterProps) {
    const { filterConfigs, items, eventHandler, state } = props;
    const optionAttributes: {}[] = filterConfigs
        .filter((config: FilterConfig) => config.type === "dropdown")
        .map((config: FilterConfig) => ({attributeName: config.attributeName, attributeGetter: config.attributeGetter}));
    const optionsPerAttribute = useMemo(() => generateOptionsPerAttributeMap(items, optionAttributes), [items]);

    return (
        <>
            {filterConfigs.map((config: FilterConfig) =>
                generateComponent(config, optionsPerAttribute, eventHandler, state[config.attributeName])
            )}
        </>
    );
}

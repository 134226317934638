import React, { useContext } from "react";
import "../assistant/Assistant.scss";
import { HeaderLink } from "src/components/common/header/HeaderLink";
import { KatIcon } from "@amzn/katal-react";
import { LayoutContext } from "src/components/layout/LayoutContext";

export interface SidebarTogglerProps {
    label: string;
    iconName: KatIcon.Name;
}

export const SidebarToggle: React.FC<SidebarTogglerProps> = (props) => {

    const { label, iconName } = props;

    const layout = useContext(LayoutContext);

    return <HeaderLink name={label}
        metricsId="SidebarToggler"
        iconName={iconName}
        className={`py-0 ${layout.isSidebarVisible ? 'active' : ''}`}
        onClick={layout.toggleSidebarVisible}
    />;
};

import { useState, useEffect } from "react";
import useInterval from "src/hooks/useInterval";
import { IsItDownService } from "src/service/is_it_down_service/IsItDown.service";
import { Levels } from "src/service/is_it_down_service/IsItDown.model";

const DEFAULT_DASHBOARD = "wfm-axiom";

const fetchIsItDownData = (dashboard: string, componentName?: string) => {
    const url = `https://is-it-down.amazon.com/${dashboard || DEFAULT_DASHBOARD}/components/${componentName}`;

    return IsItDownService.getItIsDown(url);
};

const useIsItDown = (dashboard: string, componentName: string) => {
    const [message, setMessage] = useState<string | null>(null);
    const [level, setLevel] = useState<Levels | null>(null);
    const [tickets, setTickets] = useState<string | null>(null);

    const handleFetchData = async () => {
        const { message, level, tickets } = await fetchIsItDownData(
            dashboard,
            componentName
        );

        if (message && message !== "no data") {
            setMessage(message);
        }

        if (level !== null) {
            setLevel(level);
        }

        if (tickets) {
            setTickets(tickets);
        }
    };

    useEffect(() => {
        handleFetchData();
    });

    useInterval(handleFetchData, 10000);

    return {  message, level, tickets };
};

export default useIsItDown;

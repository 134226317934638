import i18n from "i18next";
import React from "react";
import { KatLink } from "@amzn/katal-react";
import { logLinkClick } from "src/components/tasks/task_log_table/utils";
import { COMPONENT_NAME, IS_AUX_CLICK } from "../TasksDisplay";

type TaskLinksProps = {
    task: Cerebrum.Tasks.Task;
};

const TaskLinks = (props: TaskLinksProps) => {
    const { task } = props;
    const additionalMetrics = [{ name: "taskId", value: task.id }];

    return (
        <>
            {task.links &&
            <div className='d-flex flex-column'>
                {task.links.map(link =>
                    <>{link.uri &&
                        <div className='flex-row text-secondary'>
                            <KatLink
                                className='py-1'
                                label={link.title}
                                href={link.uri}
                                target='_blank'
                                onClick={logLinkClick('taskLinkNewTab', link.uri, additionalMetrics, COMPONENT_NAME)}
                                // @ts-ignore
                                onAuxClick={logLinkClick('taskLinkNewTab', link.uri, additionalMetrics, COMPONENT_NAME, IS_AUX_CLICK)}
                            >
                                <span>{link.title || i18n.t('wfm_axiom_link')}</span>
                            </KatLink>
                        </div>
                    }</>
                )}
            </div>}
        </>
    );
};

export { TaskLinks };
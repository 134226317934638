import React, { useEffect, useState } from "react";
import { HelpContentMenu } from "src/components/support/HelpContent";
import { SideNavigation } from "@amzn/awsui-components-react/polaris";
import { useHistory } from "react-router-dom";
import { HeaderRoute } from "src/components/common/header/HeaderRoute";
import { SideNavigationProps } from "@amzn/awsui-components-react/polaris/side-navigation/interfaces";
import { AxiomMetricsDriver } from "src/metrics/AxiomMetricsDriver";

interface HelpContentNavigationProps {
    currentPageKey: string;
    helpContentMenu?: HelpContentMenu;
}

const BASE_URL = `#${HeaderRoute.HELP}`;

const COMPONENT_NAME = 'HelpContentNavigation';

export function HelpContentNavigation(props: HelpContentNavigationProps) {

    const [activeHref, setActiveHref] = useState<string>();
    const history = useHistory();

    const getChildrenPageDetails = (pageKey: string) => {
        return props.helpContentMenu![pageKey];
    };

    const getItemChildren = (children: string[]): SideNavigationProps.Item[] => {
        return children.map((childPageKey: string) => {
            const childPage = getChildrenPageDetails(childPageKey);
            return getItemData(childPageKey, childPage.name, childPage.children);
        });
    };

    const getItemData = (pageKey: string, pageName: string, children: string[]): SideNavigationProps.Item => {
        if (children && children.length > 0) {
            return {
                type: 'expandable-link-group',
                text: pageName,
                href: `${BASE_URL}/${pageKey}`,
                items: getItemChildren(children)
            };
        } else {
            return {
                type: 'link',
                text: pageName,
                href: `${BASE_URL}/${pageKey}`
            };
        }
    };

    const getItems = (): SideNavigationProps.Item[] => {
        return Object.entries(props.helpContentMenu!)
            .filter(([key, value]) => !value.isChildPage)
            .map(([key, value]) => {
                return getItemData(key, value.name, value.children);
            });
    };

    const [items, setItems] = useState<SideNavigationProps.Item[]>([]);

    useEffect(() => {
        setItems(getItems());
    }, [props.helpContentMenu]);

    useEffect(() => {
        setActiveHref(`${BASE_URL}/${props.currentPageKey}`);
    }, [props.currentPageKey]);

    return (<div className="support-list-item">
        <SideNavigation
            activeHref={activeHref}
            header={{ href: BASE_URL, text: "Axiom Help" }}
            onFollow={(event) => {
                if (!event.detail.external) {
                    event.preventDefault();
                    AxiomMetricsDriver.publishLinkClick(COMPONENT_NAME, '', event.detail.href);
                    setActiveHref(event.detail.href);
                    history.push(event.detail.href.substring(1));
                }
            }}
            items={items}
        />
    </div>);
}
import React from "react";
import "./HelpContent.scss";
import { ErrorBoundComponent, ErrorBoundState } from "src/components/error/ErrorBoundComponent";
import { HelpContentNavigation } from "./HelpContentNavigation";
import { AxiomMetricsDriver } from "src/metrics/AxiomMetricsDriver";
import { AxiomIsItDown } from "src/components/common/AxiomIsItDown";
import { localStorageKeyMap } from "src/components/admin/UserDetailsSwitcher";
import { ConfigManager } from "src/config/ConfigManager";
import { loadAxiomImages } from "src/components/common/AxiomImageHelper";
import { loadHelpContentPage } from "src/components/support/HelpContentHelper";
import { HelpContentRenderer } from "src/components/support/HelpContentRenderer";
import { PageNotFoundAlert } from "src/components/support/PageNotFoundAlert";
import { KatSpinner } from "@amzn/katal-react";

const INITIAL_STATE = {
    helpContent: undefined,
    helpContentMenu: undefined,
    hasError: false,
    loadingPage: false
};

export interface HelpContentState extends ErrorBoundState {
    helpContent?: Cerebrum.HelpContent;
    helpContentProcessed?: Cerebrum.HelpContent;
    helpContentMenu?: HelpContentMenu;
    helpContentHomePage?: HelpContentPageProps;
    editing?: boolean
    loadingPage: boolean;
}

export interface HelpContentProps {
    helpContentKey: string;
}

export interface HelpContentMenu {
    [pageKey: string]: HelpContentPageProps;
}

export interface HelpContentPageProps {
    name: string;
    guid: string;
    isChildPage: boolean;
    /** specifies which page should be shown when no key is given
     * only one item in the should have this property
     */
    isHome?: boolean;
    children: string[];
}

export default class HelpContent extends ErrorBoundComponent<HelpContentProps, HelpContentState> {
    private componentName = "Help";
    /**
     * Ajuda GUID for the Help Menu which defines the whole nav tree
     * Uses the override set in admin panel otherwise falls back to default
     */
    private readonly menuId: string;

    constructor(props: HelpContentProps) {
        super(props);
        this.state = INITIAL_STATE;
        this.menuId = localStorage.getItem(localStorageKeyMap.SELECTED_HELP_CONTENT_NAV_GUID) ||
            // TODO(SUPSUP-977): move definition of menu id to tenant config
            (ConfigManager.getStaticConfig().isProd ? 'G28CK2TSKJEUARLW' : 'GR3PJMEH7BXDJQJT');
    }

    async componentDidMount() {
        await this.loadPage(this.menuId);
        this.setPageTitle();
        this.publishPageLoad();
    }

    componentDidUpdate(prevProps: Readonly<HelpContentProps>) {
        if (this.props === prevProps) {
            return;
        }
        if (this.props.helpContentKey !== prevProps.helpContentKey) {
            this.clearState();
            this.setPageTitle();
            this.publishPageLoad();
        }
    }

    private setPageTitle() {
        let titleString = 'Help - ';
        if (this.state.helpContentMenu && this.props.helpContentKey) {
            titleString += this.state.helpContentMenu[this.props.helpContentKey]?.name;
        } else if (this.state.helpContentHomePage) {
            titleString += this.state.helpContentHomePage.name;
        } else {
            titleString = 'Axiom - Help';
        }
        document.title = titleString;
    }

    /**
     *  Resolves ajuda GUID based on the page key in the menu.
     * @param pageKey the key to be mapped in helpContentMenu
     * @returns If pageKey is provided, the mapped GUID or undefined in case of not mapping found
     *          If pageKey is not provided, the home page id
     * @private
     */
    private resolvePageId(pageKey: string): string | undefined {
        if (this.state.helpContentMenu && pageKey) {
            // pageKey is provided, try to get the mapped id
            if (this.state.helpContentMenu[pageKey]) {
                return this.state.helpContentMenu[pageKey].guid;
            }
            // mapping is undefined
            return undefined;
        }
        // there is not page key, return home page
        return this.state.helpContentHomePage?.guid
    }

    private publishPageLoad() {
        const additionalMetrics = [
            {
                name: "helpContentId",
                value: this.props.helpContentKey || this.state.helpContentHomePage?.name || 'N/A'
            }
        ];
        AxiomMetricsDriver.publishPageLoad(this.componentName, window.location.href, additionalMetrics);
    }

    private loadPage(pageId: string) {
        this.setState({loadingPage: true});
        return loadHelpContentPage(pageId)
            .then(async (helpContent: Cerebrum.HelpContent) => {
                this.setState({loadingPage: false});
                this.handleSuccess(helpContent, pageId);
            })
            .catch(error => {
                this.setState({loadingPage: false});
                this.handleError(error);
            });
    }

    private async handleSuccess(helpContent: Cerebrum.HelpContent, pageId: string) {
        if (pageId === this.menuId) {
            const helpContentMenu: HelpContentMenu = JSON.parse(helpContent.content.replace(/\n/g, ''));
            const helpContentHomePage = HelpContent.getHomePageFromMenu(helpContentMenu);
            this.setState({ helpContentMenu, helpContentHomePage });
        } else {
            const helpContentProcessed = { ...helpContent, content: await loadAxiomImages(helpContent.content) };
            this.setState({ helpContent, helpContentProcessed });
        }
    }

    // returns PageProps for the entry identified as the home page in the menu
    private static getHomePageFromMenu(helpContentMenu: HelpContentMenu): HelpContentPageProps {
        return Object.entries(helpContentMenu)
            .filter(([key, value]) => value.isHome)
            .map(([key, value]) => value)[0];
    }

    private clearState() {
        this.setState({
            helpContent: undefined,
            hasError: false
        });
    }

    private renderSelectedPageOrElseHome() {
        if (this.state.loadingPage) return <div className="row justify-content-center"><KatSpinner/></div>;
        const pageId = this.resolvePageId(this.props.helpContentKey);
        if (pageId) {
            return <HelpContentRenderer pageId={pageId}/>
        }
        return <>
            <PageNotFoundAlert pageId={this.props.helpContentKey}/>
            <HelpContentRenderer pageId={this.state.helpContentHomePage?.guid}/>
        </>
    }

    render() {
        const error = this.renderError();
        return (
            <>
                <div className="d-flex flex-row h-main">
                    <div className="w-25 overflow-auto">
                        {this.state.helpContentMenu ?
                            <HelpContentNavigation currentPageKey={this.props.helpContentKey || ''}
                                                   helpContentMenu={this.state.helpContentMenu}/> :
                            <div className="row justify-content-center"><KatSpinner/></div>
                        }
                    </div>
                    <div className="vertical-line"/>
                    <div className="w-75 overflow-auto">
                        <div className="pr-2">
                            <AxiomIsItDown componentId={this.componentName}/>
                        </div>
                        <div className="help-content-container mt-2">
                            {error || this.renderSelectedPageOrElseHome()}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}


import moment from 'moment';
import { GenericObject } from 'src/@types/extendedPolarisTable';
import getters from './AttributeGetters';

const CO_DATE_STRING_FORMAT = 'DD/MM/YY';

const compareValues = (value1: string | number, value2: string | number) => {
    if (value1 > value2) return 1;
    if (value1 < value2) return -1;

    return 0;
}

const compareDateStrings = (dateString1: any, dateString2: any) => {
    const date1 = moment(dateString1, CO_DATE_STRING_FORMAT);
    const date2 = moment(dateString2, CO_DATE_STRING_FORMAT);
    return date1.diff(date2);
}

const compareDateTimestamps = (timeStamp1: any, timeStamp2: any) => {
    const date1 = moment.utc(timeStamp1);
    const date2 = moment.utc(timeStamp2);
    return date1.diff(date2);
}

const descending = (order: number) => -1 * order;

const auditGetters = getters['audit'];
const compareAuditDocumentStringValues = (attributeName: string, item1: any, item2: any) => {
    return compareValues(
        auditGetters.getAuditDocumentValue(attributeName, item1),
        auditGetters.getAuditDocumentValue(attributeName, item2)
    )
}

const coGetters = getters['customerObsession'];
const formSubmissionGetters = getters['formSubmission'];

const CO_AUDIT_COMPARATORS = {
    date: (item1: any, item2: any) => {
        return compareDateStrings(
            coGetters.reviewIdentificationDate(item1),
            coGetters.reviewIdentificationDate(item2)
        )
    },
    case: (item1: any, item2: any) => compareAuditDocumentStringValues('targetItemId', item1, item2),
    associate: (item1: any, item2: any) => compareValues(item1.agent, item2.agent),
    source: (item1: any, item2: any) => compareAuditDocumentStringValues('source', item1, item2),
    subject: (item1: any, item2: any) => compareAuditDocumentStringValues('reviewSubject', item1, item2)
}


const FORM_SUBMISSION_COMPARATORS = {
    createdDateTime: (item1: any, item2: any) => {
        /**
         * Using descending() to enable descending order as
         * default on primary sorting column, when first loading table.
         * There is no way to use sortingDescending table property
         * if using custom sorting functions.
         *
         * source: https://polaris.a2z.com/components/table/?tabId=api
         */
        return descending(compareDateTimestamps(
            formSubmissionGetters.createdDateTime(item1),
            formSubmissionGetters.createdDateTime(item2)
            )
        );
    }
}

const COMPARATORS: GenericObject = {
    'customerObsession': CO_AUDIT_COMPARATORS,
    'formSubmission': FORM_SUBMISSION_COMPARATORS
}

export default COMPARATORS;
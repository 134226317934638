import React, { useState } from 'react';
import { UserManager } from "src/user/UserIdentityManager";
import { KatButton, KatIcon, KatModal } from "@amzn/katal-react";
import { NO_SELECTION, OPT_OUT, ReverbOptions } from "src/components/reverb/ReverbOptions";
import { ReverbComment } from "src/components/reverb/ReverbComment";
import { WasUsefulBanner } from "src/components/reverb/WasUsefulBanner";

interface ReverbFormProps {
    survey: Cerebrum.Reverb.SurveyQuestion;
    onClose: () => void;
    onSubmit: (response: Cerebrum.Reverb.SurveyResponse) => void;
    onSnooze: () => void;
}

export const ReverbForm: React.FC<ReverbFormProps> = (props) => {

    const { survey, onClose, onSubmit, onSnooze } = props;

    const [selectedOption, setSelectedOption] = useState(NO_SELECTION);
    const [additionalComment, setAdditionalComment] = useState('');

    const [useful, setUseful] = useState<boolean | undefined>(undefined);

    const userIdentity = UserManager.getUserIdentity();

    const onSubmitClicked = () => {
        const surveyResponse: Cerebrum.Reverb.SurveyResponse = {
            comment: additionalComment,
            entityId: userIdentity.login,
            optionId: selectedOption.toString(),
            optionValue: survey.options.get(selectedOption) || '',
            surveyId: survey.surveyId
        };

        if (useful !== undefined) {
            surveyResponse.useful = useful;
        }

        if (selectedOption === OPT_OUT) {
            surveyResponse.optOut = true;
        }

        onSubmit(surveyResponse);
    };

    const Title = () => (
        <div className='flex-row' slot='title'>
            <span>Reverb</span>
            <a slot='trigger' className='legend-help-icon no-underline px-2 text-nordic'
                href='#/help/Reverb' target='_blank'>
                <KatIcon name='help_outline' size='tiny' />
            </a>
        </div>
    );

    const Question = () => <h1 className="text-center">{survey.question}</h1>;

    const Footer = () => (
        <div slot='footer' className='kat-group-horizontal'>
            <KatButton variant='secondary' label='Remind me later' onClick={onSnooze} />
            <KatButton label='Submit' disabled={selectedOption === NO_SELECTION} onClick={onSubmitClicked} />
        </div>
    );

    return (
        <KatModal className='reverb-modal' visible onClose={onClose}>
            <Title />
            <Question />
            <div className='reverb-option-container'>
                <ReverbOptions survey={survey} onSelectionChange={setSelectedOption} />
                <ReverbComment onCommentChange={setAdditionalComment} />
                <WasUsefulBanner onFeedback={setUseful} />
            </div>
            <Footer />
        </KatModal>
    );
};

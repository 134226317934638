import React, { useContext, useEffect, useRef, useState } from "react";
import {KatAlert, KatColumn, KatDivider, KatIcon, KatLink, KatRow, KatSpinner} from "@amzn/katal-react";
import { taskAndNotificationTypeToIconName } from "src/components/tasks/model/TaskAndNotificationType";
import { CardOptionsMenu } from "src/components/common/CardOptionsMenu";
import i18n from "i18next";
import DropdownItem from "react-bootstrap/DropdownItem";
import { AxiomMetricsDriver } from "src/metrics/AxiomMetricsDriver";
import { NotificationDateLabel } from "src/components/notifications/model/NotificationDateLabel";
import EventEmitter from "src/context/EventEmitter";
import { newTask } from "src/components/tasks/model/Task";
import { CerebrumService } from "src/service/CerebrumCoralService";
import { NotificationUpdatedEvent } from "src/components/notifications/events/NotificationUpdatedEvent";
import { AssistantContext } from "src/components/assistant/AssistantContext";
import { FeedbackContainer } from "src/components/flash_briefing/FeedbackCard";
import { FlashBriefingModal } from "@amzn/wfm-axiom-flash-briefing-ui-components";
import { useHighlighter } from 'src/components/common/useHighlighter';

interface NotificationCardProps {
    viewUserLogin: string;
    notification: Cerebrum.Tasks.Notification;
    showDivider: boolean
}

enum CardStatus {
    DISPLAY,
    LOAD,
    FEEDBACK
}

const COMPONENT_NAME = "NotificationCard";

export const NotificationCard: React.FC<NotificationCardProps> = (props) => {
    const { viewUserLogin, showDivider } = props;

    const assistantContext = useContext(AssistantContext);

    const [notification, setNotification] = useState<Cerebrum.Tasks.Notification>(props.notification);
    const additionalMetrics = [{ name: "notificationId", value: notification.id }];

    const [cardStatus, setCardStatus] = useState(CardStatus.DISPLAY);
    const [error, setError] = useState(false);

    const [showMetadata, setShowMetadata] = useState(false);

    const [highlight, setHighlight] = useState(false);
    const [highlightClasses, setHighlightClasses] = useState('');

    const mainRef = useRef(null);
    useHighlighter('notification', notification.id, mainRef,
        () => setHighlight(true), () => setHighlight(false));

    useEffect(() => {
        const seenClass = `${notification.seen ? '' : 'unseen-card'}`;
        const highlightClass = `${highlight ? 'border border-info' : ''}`;
        setHighlightClasses(`${seenClass} ${highlightClass}`);
    }, [highlight]);

    // Event listening and cleanup
    const addToTask = () => {
        logButtonClick("addToTask");

        const task = newTask(notification.title, notification.notificationType, viewUserLogin);
        assistantContext.startCreateTask(task);
    }

    const handleDismiss = async () => {
        setCardStatus(CardStatus.LOAD);

        const input: Cerebrum.Tasks.DismissNotificationInput = {
            id: notification.id,
            notificationOwner: notification.notificationOwner,
            dismissed: true
        };
        await CerebrumService.dismissNotification(input)
            .then(handleSuccess)
            .catch(handleError);

        logButtonClick('dismiss');
    };

    const logButtonClick = (buttonName: string) => {
        AxiomMetricsDriver.publishButtonClick(COMPONENT_NAME, buttonName, additionalMetrics);
    }

    const dismissError = () => {
        setError(false);
    };

    const onShowMetadata = () => {
        setShowMetadata(true);
        logButtonClick('showMetadata')
    }

    const handleSuccess = (response: Cerebrum.Tasks.SingleNotificationOutput) => {
        setNotification(current => {
            const updatedNotification = { ...current, dismissed: response.notification.dismissed }
            const event = new NotificationUpdatedEvent(updatedNotification);
            EventEmitter.dispatch(event.name, event);
            return updatedNotification;
        })
        setCardStatus(CardStatus.DISPLAY);
    };

    const handleError = () => {
        // tslint:disable-next-line:no-console
        console.error(error);
        setError(true);
        setCardStatus(CardStatus.DISPLAY);
    };

    const logLinkClick = (linkName: string, url: string) => (
        () => AxiomMetricsDriver.publishLinkClick(COMPONENT_NAME, linkName, url, additionalMetrics)
    );

    // UI Components
    const LoadingSpinner = () => (
        <>{cardStatus === CardStatus.LOAD &&
            <div className="row justify-content-center"><KatSpinner size="large"/></div>}
        </>
    );

    const OptionsMenu = () => (
        <CardOptionsMenu>
            <DropdownItem as="button" onClick={addToTask}>
                {i18n.t("wfm_axiom_add_to_task")}
            </DropdownItem>
            <DropdownItem as="button" onClick={handleDismiss}>
                {i18n.t("wfm_axiom_dismiss")}
            </DropdownItem>
            <KatDivider variant="athens"/>
            <DropdownItem as="button" onClick={() => setCardStatus(CardStatus.FEEDBACK)}>
                {i18n.t("wfm_axiom_fb_feedback_card_title")}
            </DropdownItem>
            {/* TODO: Internationalize (AXIOM-390) */}
            <DropdownItem as="button" onClick={onShowMetadata}>
                <span className="text-muted">Show Metadata</span>
            </DropdownItem>
        </CardOptionsMenu>
    );

    const cardMetadata: Partial<Cerebrum.CardMetadata> = {
        cardId: notification.id,
        cardTitle: notification.title,
        cardType: "notification",
        createdDate: notification.createdDateTime,
        entityId: viewUserLogin,
        publisherName: notification.createdBy
    };

    const FeedbackCard = () => <>{
        CardStatus.FEEDBACK === cardStatus &&
        <FeedbackContainer
            cardMetadata={cardMetadata}
            onClose={() => setCardStatus(CardStatus.DISPLAY)}
        />}
    </>;

    const Header = () => (
        <KatRow>
            <KatColumn className="task-card-checkbox-column" md={2}>
                <KatIcon name={taskAndNotificationTypeToIconName[notification.notificationType]} size={'small'}/>
            </KatColumn>
            <KatColumn className="task-card-content-column" md={9}>
                <span className='pt-1'>
                    {notification.title}
                </span>

                {notification.body &&
                <div className="notification-card-body text-secondary">
                    {notification.body}
                </div>
                }
                <NotificationLinks />
            </KatColumn>
            <KatColumn className="task-card-three-buttons-column" md={1}>
                <OptionsMenu/>
            </KatColumn>
        </KatRow>
    );

    const NotificationLinks = () => (<>{
            notification.links &&
            <div className='d-flex flex-column'>
                {notification.links.map(link =>
                    <>{link.uri &&
                    <div className='flex-row text-secondary'>
                        <KatLink className='py-1' label={link.title} href={link.uri}
                                 onClick={logLinkClick('notificationLink', link.uri)}>
                            <span>{link.title || i18n.t('wfm_axiom_link')}</span>
                        </KatLink>
                        <span> | </span>
                        <KatLink className='py-1' label={link.title} href={link.uri} target='_blank'
                                 onClick={logLinkClick('notificationLinkNewTab', link.uri)}>
                            <KatIcon title={i18n.t('wfm_axiom_open_new_tab')} name='open_in_new' size='tiny'/>
                        </KatLink>
                    </div>
                    }</>
                )}
            </div>
        }</>
    );

    const Footer = () => {
        return (
            <KatRow>
                <KatColumn className="task-card-checkbox-column" md={2}/>
                <KatColumn className="task-card-content-column" md={10}>
                    <NotificationDateLabel date={notification.createdDateTime}
                                           className="text-size-sm text-secondary"/>
                </KatColumn>
            </KatRow>
        );
    };

    const NotificationDisplay = () => (<>
        {CardStatus.DISPLAY === cardStatus &&
            <>
                <Header/>
                <Footer/>
            </>}
    </>);

    const ErrorPanel = () => (<>
        {error &&
            <KatAlert
                className='mb-3'
                header={i18n.t('wfm_axiom_notification_error_dismissing')}
                onDismiss={dismissError}
                variant='danger'/>}
    </>);

    const MetadataCard = () => <>{
        showMetadata &&
        <FlashBriefingModal
            visible={showMetadata}
            title={'Notification Metadata'} // TODO: Internationalize (AXIOM-390)
            body={JSON.stringify(notification, null, 2)}
            onClose={() => setShowMetadata(false)}
        />}
    </>;

    return (
        <>
            <div className={`task-card pt-2 pb-1 px-2 ${highlightClasses}`}>
                <ErrorPanel/>
                <LoadingSpinner/>
                <NotificationDisplay/>
                <FeedbackCard/>
                <MetadataCard/>
            </div>
            {showDivider && <KatDivider variant={'athens'}/>}
        </>
    );
};

import React from 'react';
import { HashRouter as Router, Redirect, Route, Switch, } from "react-router-dom";
import BSC from './balanced_scorecard/BSC';
import DevTools from './admin/DevTools';
import Support from './support/Support';
import AxiomHmd from './common/AxiomHmd';
import FlashBriefingContainer from './flash_briefing/FlashBriefingContainer';
import Audit from './audit/Audit';
import { MyPerformanceRouter } from './my_performance/MyPerformanceRouter';
import { UserManager } from "src/user/UserIdentityManager";
import UserContextProvider from "src/context/UserContext";
import { UserRestrictedComponent } from "src/components/authorization/UserRestrictedComponent";
import { ComponentID } from "src/components/authorization/ComponentRightsMapping";
import Landing from "src/components/landing/Landing";
import { Treatment, Weblab } from "src/user/Weblab";
import RootLayout from "src/components/layout/RootLayout";
import { Assistant, ASSISTANT_NAME } from "src/components/assistant/Assistant";
import { LayoutContextProvider } from "src/components/layout/LayoutContext";
import { HeaderRoute, HeaderRouteHelpPage } from "src/components/common/header/HeaderRoute";
import { TasksLog } from "src/components/tasks/TasksLog";
import { NotificationsLog } from "src/components/notifications/NotificationsLog";
import HelpContent from "src/components/support/HelpContent";
import { AssistantContextProvider } from "src/components/assistant/AssistantContext";
import Home from "src/components/home/Home";
import Reverb from "src/components/reverb/Reverb";
import { HelpContext, HelpContextProvider, SetHelpPageFunction } from 'src/context/HelpContext';
import { FormSubmission } from "src/components/forms/FormSubmission";
import { FormsLog } from './forms/FormsLog';
import { FormView } from "src/components/forms/FormView";
import { FormDesigner } from 'src/components/forms/FormDesigner';
import { BannerContextProvider } from "src/components/layout/BannerContext";

export class WFMAxiomWebApp extends React.Component<any, {}> {
    static contextType = HelpContext;
    context!: React.ContextType<typeof HelpContext>;

    render() {
        const userIdentity = UserManager.getUserIdentity();

        const Routes = () => <Switch>
            <Route exact path={HeaderRoute.BALANCED_SCORE_CARD}>
                {
                    // If the viewUserId is missing, just redirect to the users bsc
                    // This ensures a login is always in the url for links sharing
                }
                <Redirect
                    to={`${HeaderRoute.BALANCED_SCORE_CARD}/${userIdentity.login}`}/>
            </Route>
            <Route path={`${HeaderRoute.BALANCED_SCORE_CARD}/:viewUserId`}
                   render={(props) =>
                       <UserRestrictedComponent
                           componentId={ComponentID.HEADER_BALANCEDSCORECARD_RIGHTS_ID}
                           showNoAccessError>
                           <SetHelpPageFunction componentName={HeaderRouteHelpPage.BALANCED_SCORE_CARD}/>
                           <BSC viewUserLogin={props.match.params.viewUserId || ''}/>
                       </UserRestrictedComponent>}>
            </Route>
            <Route path={HeaderRoute.DEV_TOOLS}>
                <UserRestrictedComponent componentId={ComponentID.HEADER_ADMIN_RIGHTS_ID}
                                         showNoAccessError>
                    <SetHelpPageFunction componentName={HeaderRouteHelpPage.DEV_TOOLS}/>
                    <DevTools/>
                </UserRestrictedComponent>
            </Route>
            <Route exact path={HeaderRoute.AUDIT}>
                <Redirect to={`${HeaderRoute.REVIEWS}/${userIdentity.login}`}/>
            </Route>
            <Route exact path={`${HeaderRoute.AUDIT}/:viewUserId`}
                render={(props) => (<Redirect to={`${HeaderRoute.REVIEWS}/${props.match.params.viewUserId}`} />)}
            />
            <Route exact path={HeaderRoute.REVIEWS}>
                <Redirect to={`${HeaderRoute.REVIEWS}/${userIdentity.login}`}/>
            </Route>
            <Route path={`${HeaderRoute.REVIEWS}/:viewUserId`} render={(props) =>
                <>
                    <SetHelpPageFunction componentName={HeaderRouteHelpPage.AUDIT}/>
                    <Audit viewUserLogin={props.match.params.viewUserId || ''}/>
                </>
                }>
            </Route>
            <Route path={[`${HeaderRoute.SUPPORT}/:supportId`, HeaderRoute.SUPPORT]}
                   render={(props) =>
                       <>
                           <SetHelpPageFunction componentName={HeaderRouteHelpPage.SUPPORT}/>
                           <Support supportId={props.match?.params.supportId || null}/>
                       </>
                   }>
            </Route>
            <Route
                path={[`${HeaderRoute.HELP}/:supportId`, HeaderRoute.HELP]}
                render={(props) =>
                    <>
                        <SetHelpPageFunction componentName={HeaderRouteHelpPage.HELP}/>
                        <HelpContent helpContentKey={props.match?.params.supportId || ''}/>
                    </>
                }>
            </Route>
            <Route exact path={HeaderRoute.METRICS} render={() =>
                <UserRestrictedComponent
                    componentId={ComponentID.HEADER_FLASHBRIEFING_RIGHTS_ID}
                    showNoAccessError>
                    <Redirect to={`${HeaderRoute.METRICS}/${userIdentity.login}`}/>
                </UserRestrictedComponent>}>
            </Route>
            <Route path={`${HeaderRoute.METRICS}/:viewUserId`} render={(props) =>
                <UserRestrictedComponent
                    componentId={ComponentID.HEADER_FLASHBRIEFING_RIGHTS_ID}
                    showNoAccessError>
                    <SetHelpPageFunction componentName={HeaderRouteHelpPage.METRICS}/>
                    <FlashBriefingContainer entityId={props.match.params.viewUserId || ''}
                                            pageName='Metrics'
                                            isItDownComponentId='flash-briefing'
                                            showKpiCardsRow/>
                </UserRestrictedComponent>}>
            </Route>
            <Route path={HeaderRoute.MY_PERFORMANCE} render={() =>
                <UserRestrictedComponent componentId={ComponentID.HEADER_MYPERFORMANCE_RIGHTS_ID}
                                         showNoAccessError>
                    <SetHelpPageFunction componentName={HeaderRouteHelpPage.MY_PERFORMANCE}/>
                    <MyPerformanceRouter/>
                </UserRestrictedComponent>}>
            </Route>
            <Route exact path={HeaderRoute.TASKS_LOG} render={() =>
                <UserRestrictedComponent componentId={ComponentID.CHARLOTTE_RIGHTS_ID} showNoAccessError>
                    <Redirect to={`${HeaderRoute.TASKS_LOG}/${userIdentity.login}`}/>
                </UserRestrictedComponent>}>
            </Route>
            <Route path={`${HeaderRoute.TASKS_LOG}/:viewUserId`} render={(props) =>
                <UserRestrictedComponent componentId={ComponentID.CHARLOTTE_RIGHTS_ID} showNoAccessError>
                    <SetHelpPageFunction componentName={HeaderRouteHelpPage.TASKS_LOG}/>
                    <TasksLog viewUserLogin={props.match.params.viewUserId || ''}/>
                </UserRestrictedComponent>}>
            </Route>
            <Route exact path={HeaderRoute.NOTIFICATIONS_LOG} render={() =>
                <UserRestrictedComponent componentId={ComponentID.CHARLOTTE_RIGHTS_ID} showNoAccessError>
                    <Redirect to={`${HeaderRoute.NOTIFICATIONS_LOG}/${userIdentity.login}`}/>
                </UserRestrictedComponent>}>
            </Route>
            <Route path={`${HeaderRoute.NOTIFICATIONS_LOG}/:viewUserId`} render={(props) =>
                <UserRestrictedComponent componentId={ComponentID.CHARLOTTE_RIGHTS_ID} showNoAccessError>
                    <SetHelpPageFunction componentName={HeaderRouteHelpPage.NOTIFICATIONS_LOG}/>
                    <NotificationsLog
                        viewUserLogin={props.match.params.viewUserId || ''}/>
                </UserRestrictedComponent>}>
            </Route>
            <Route path={`${HeaderRoute.FORMS_VIEW}/:submissionId`} render={(props) =>
                <UserRestrictedComponent componentId={ComponentID.FORMS_RIGHTS_ID} showNoAccessError>
                    <SetHelpPageFunction componentName={HeaderRouteHelpPage.FORMS}/>
                    <FormSubmission formSubmissionId={props.match.params.submissionId} readonly={true}/>
                </UserRestrictedComponent>}>
            </Route>
            <Route path={`${HeaderRoute.FORMS_SUBMIT}/:formId`} render={(props) =>
                <UserRestrictedComponent componentId={ComponentID.FORMS_RIGHTS_ID} showNoAccessError>
                    <SetHelpPageFunction componentName={HeaderRouteHelpPage.FORMS}/>
                    <FormSubmission formId={props.match.params.formId}/>
                </UserRestrictedComponent>}>
            </Route>

            <Route path={`${HeaderRoute.FORMS_EDIT}/:formId/:formSubmissionId`} render={(props) =>
                <UserRestrictedComponent componentId={ComponentID.FORMS_RIGHTS_ID} showNoAccessError>
                    <SetHelpPageFunction componentName={HeaderRouteHelpPage.FORMS}/>
                    <FormSubmission formId={props.match.params.formId}
                                    formSubmissionId={props.match.params.formSubmissionId}/>
                </UserRestrictedComponent>}>
            </Route>

            <Route path={`${HeaderRoute.FORMS_SUBMIT}`} render={(props) =>
                <UserRestrictedComponent componentId={ComponentID.FORMS_RIGHTS_ID} showNoAccessError>
                    <SetHelpPageFunction componentName={HeaderRouteHelpPage.FORMS}/>
                    <FormSubmission/>
                </UserRestrictedComponent>}>
            </Route>
            <Route path={`${HeaderRoute.FORMS_DESIGN}/:formId`} render={(props) =>
                <UserRestrictedComponent componentId={ComponentID.FORMS_RIGHTS_ID} showNoAccessError>
                    <SetHelpPageFunction componentName={HeaderRouteHelpPage.FORMS}/>
                    <FormDesigner formId={props.match.params.formId}/>
                </UserRestrictedComponent>}>
            </Route>
            <Route path={`${HeaderRoute.FORMS}`} render={(_props) =>
                <UserRestrictedComponent componentId={ComponentID.FORMS_RIGHTS_ID} showNoAccessError>
                    <SetHelpPageFunction componentName={HeaderRouteHelpPage.FORMS}/>
                    <FormsLog/>
                </UserRestrictedComponent>}>
            </Route>
            <Route path={HeaderRoute.ABOUT}>
                <SetHelpPageFunction componentName={HeaderRouteHelpPage.HOME}/>
                <Home/>
            </Route>
            <Route exact path={HeaderRoute.HOME} render={() => (
                <>
                    <SetHelpPageFunction componentName={HeaderRouteHelpPage.HOME}/>
                    <UserRestrictedComponent
                        componentId={ComponentID.LANDING_RIGHTS_ID}
                    >
                        <SetHelpPageFunction componentName={HeaderRouteHelpPage.HOME}/>
                        <Landing/>
                    </UserRestrictedComponent>
                </>
            )}>
            </Route>
        </Switch>;

        return (
            <>
                <UserContextProvider>
                    <AssistantContextProvider>
                        <LayoutContextProvider>
                            <HelpContextProvider>
                                <Router>
                                    <BannerContextProvider>
                                        <RootLayout
                                            main={
                                                <Routes/>
                                            }
                                            sidebar={
                                                <UserRestrictedComponent componentId={ComponentID.CHARLOTTE_RIGHTS_ID}>
                                                    <Assistant/>
                                                </UserRestrictedComponent>
                                            }
                                            sidebarTitle={ASSISTANT_NAME}
                                        />
                                    </BannerContextProvider>
                                </Router>
                            </HelpContextProvider>
                            <AxiomHmd/>
                            <UserRestrictedComponent componentId={ComponentID.REVERB_RIGHTS_ID}>
                                <Reverb/>
                            </UserRestrictedComponent>
                        </LayoutContextProvider>
                    </AssistantContextProvider>
                </UserContextProvider>
            </>
        );
    }
}

export default WFMAxiomWebApp;

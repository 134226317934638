import React from 'react'

export default function AxiomLogo() {
    return (
        <svg width="382px" height="119px" viewBox="0 0 382 119" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <title>AxiomLogo</title>
            <g id="AxiomLogo" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <polygon id="Triangle-Copy-7" fill="#00A4B4" points="60 2 24.3038705 109 1 44.2885056"></polygon>
                <polygon id="Triangle-Copy-6" fill="#00A4B4" transform="translate(90.176849, 51.219443) rotate(145.000000) translate(-90.176849, -51.219443) " points="118.676849 -4.78055708 84.8275495 107.219443 61.6768486 37.0983394"></polygon>
                <path d="M74.8105602,80.116117 L59.6826112,129.911224 C48.2979409,129.190733 42.0976993,124.541949 41.0818863,115.964874 C40.0660734,107.387798 41.9227923,96.3670403 46.652043,82.9025993 L74.8105602,80.116117 Z" id="Triangle-Copy-5" fill="#00A4B4" transform="translate(57.811515, 105.013671) rotate(72.000000) translate(-57.811515, -105.013671) "></path>
                <polygon id="Triangle-Copy-4" fill="#00A4B4" transform="translate(57.739606, 66.038763) rotate(82.000000) translate(-57.739606, -66.038763) " points="79.7417743 46.7188649 74.3113051 85.3586608 35.7374371 57.7662342"></polygon>
                <path d="M64.4237183,3.10747963 L118.926269,42.3941021 C120.692575,43.6672934 121.431882,45.937148 120.754219,48.0063567 L99.9522144,111.524235 C99.2793797,113.578702 97.3623834,114.968071 95.2005459,114.968071 L27.7994541,114.968071 C25.6376166,114.968071 23.7206203,113.578702 23.0477856,111.524235 L2.24578069,48.0063567 C1.56811809,45.937148 2.30742546,43.6672934 4.07373096,42.3941021 L58.5762817,3.10747963 C60.3222693,1.84893386 62.6777307,1.84893386 64.4237183,3.10747963 Z" id="Polygon-Copy" stroke="#002F36" strokeWidth="4"></path>
                <path d="M41.5130168,113 L47.0551302,94.3020936 L75.0015314,94.3020936 L80.7794793,113 L100,113 L64.1492672,2 L58.4164305,2 L23,113 L41.5130168,113 Z M72,80.9959268 L51,80.9959268 L61.3793103,46.991599 L72,80.9959268 Z" id="A-Copy" fill="#002F36" fillRule="nonzero"></path>
                <path d="M41.7534456,114 L47.3675345,95.133644 L75.676876,95.133644 L81.5298622,114 L101,114 L64.6836733,2 L58.8763841,2 L23,114 L41.7534456,114 Z M72,82 L51,82 L61.3793103,48 L72,82 Z" id="A-Copy" fill="#002F36" fillRule="nonzero"></path>
                <text id="XIOM" fontFamily="AmazonEmber-Medium, Amazon Ember" fontSize="100" fontWeight="400" letterSpacing="5" fill="#002F36">
                    <tspan x="122" y="117">XIOM</tspan>
                </text>
            </g>
        </svg>
    )
}

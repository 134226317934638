import React, { useCallback, useEffect, useState } from "react";
import { KatButton, KatDropdown, KatOption } from "@amzn/katal-react";
import { ErrorMessage } from "src/components/error/ErrorMessage";
import { FormDefinition, FormDefinitionsResponse } from "src/service/amplify/appsync";
import { useStateWithLocalStorage } from "src/components/common/storage/UseStateWithLocalStorage";
import { ConfigManager } from "src/config/ConfigManager";
import { HeaderRoute } from "src/components/common/header/HeaderRoute";
import { AxiomMetricsDriver } from "src/metrics/AxiomMetricsDriver";
import { useHistory } from "react-router-dom";
import { FormsService } from "src/service/Forms.service";
import { UserContext } from "src/context/UserContext";
import { AXIOM_FORMS_PREFIX } from "src/components/forms/constants/FormConstants";
import 'src/components/forms/Forms.scss';
import { useNoConfigKeysSearch } from "src/components/common/searchParameters/useNoConfigKeysSearch";
import getFormattedPreferredLanguage from "src/i18n/getFormattedPreferredLanguage";
import { ENGLISH } from "src/i18n/locales";
import { GenericObject } from "src/@types/extendedPolarisTable";
import { DefinitionDropdownOption } from "src/components/forms/new_submission_button/DefinitionDropdownOption";

const COMPONENT_NAME = AXIOM_FORMS_PREFIX + 'NewSubmissionButton';

export const NewSubmissionButton: React.FC = () => {
    const history = useHistory();
    const noConfigsSearch = useNoConfigKeysSearch();

    const config: AxiomConfig.Configuration = ConfigManager.getStaticConfig();
    const userContext = React.useContext(UserContext);
    const userLogin = userContext.userManager.userIdentity!.login;

    const DROPDOWN_LOCAL_STORAGE_KEY = config.localStorageKeys.SUBMIT_NEW_FORM_DROPDOWN;

    const [availableFormDefinitions, setAvailableFormDefinitions] = useState<(FormDefinition | null)[]>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const [selectedFormDefinition, setSelectedFormDefinition] = useStateWithLocalStorage<string>(DROPDOWN_LOCAL_STORAGE_KEY, "");

    useEffect(() => {
        listAvailableFormDefinitions();
    }, [])

    const LoadingErrorMessage = () => (
        <>{error &&
            <ErrorMessage
                title={"Error getting FormsDefinitions..."}
                message={error}/>
        }</>
    );

    const handleError = (coralError: string) => {
        // tslint:disable-next-line:no-console
        console.error(coralError);
        setAvailableFormDefinitions([]);
        setIsLoading(false);
        setError(coralError.toString());
    }

    const handleNewSubmissionClick = useCallback(() => {
        const route = `${HeaderRoute.FORMS_SUBMIT}/${selectedFormDefinition}${noConfigsSearch || ''}`;
        const additionalMetrics: Axiom.AdditionalMetric[] = [
            { name: 'selectedFormDefinition', value: selectedFormDefinition },
        ];
        AxiomMetricsDriver.publishButtonClick(COMPONENT_NAME, 'NewSubmission', additionalMetrics);
        history.push(route);
    }, [noConfigsSearch, selectedFormDefinition, history]);

    const handleDefinitionDropdownOptionChange = (event: KatDropdown.ChangeEvent) => {
        setSelectedFormDefinition(event.detail.value);
        const additionalMetrics: Axiom.AdditionalMetric[] = [
            { name: 'selectedFormDefinition', value: event.detail.value },
        ];
        AxiomMetricsDriver.publishButtonClick(COMPONENT_NAME, 'NewSubmissionDropdown', additionalMetrics);
    }

    const handlerListAvailableFormDefinitions = (responses: FormDefinitionsResponse[]) => {
        const formDefinitionList = buildFormDefinitionList(responses)
        const filteredFormDefinitions = filterRepeatedFormDefinitions(formDefinitionList)
        setAvailableFormDefinitions(filteredFormDefinitions);
        setIsLoading(false);
    }
    
    //Each response contains form definitions for a single language
    const buildFormDefinitionList = (responses: FormDefinitionsResponse[]) => {
        return responses.map(res => res && res.body ? res.body : []).flat() as FormDefinition[];
    }

    //formDefinitions is sorted by language and only the first form definition of each name is kept
    const filterRepeatedFormDefinitions = (formDefinitions: FormDefinition[]) => {
        const formDefinitionsByName = formDefinitions.reduce((acc: GenericObject, formDefinition) => {
            const formName = formDefinition?.formName as string;
            if (!acc[formName]) {
                acc[formName] = formDefinition;
            }

            return acc;
        },{});

        return Object.values(formDefinitionsByName);
    }

    const listAvailableFormDefinitions = () => {
        setIsLoading(true);
        
        //Prioritize form definitions in user's preferredLanguage by querying them first
        const promises = [FormsService.listAvailableFormDefinitions(userLogin)];

        const english = ENGLISH.toLowerCase();
        if (getFormattedPreferredLanguage() !== english) {
            promises.push(FormsService.listAvailableFormDefinitions(userLogin, english));
        }

        Promise.all(promises)
            .then(responses => handlerListAvailableFormDefinitions(responses as FormDefinitionsResponse[]))
            .catch(handleError);
    }

    const populateSubmitDropdown = (formDefinitions: (FormDefinition | null)[] | undefined) => {
        return formDefinitions ? formDefinitions.map(formDefinition => ({
            "name": formDefinition?.formName,
            "value": formDefinition?.formName,
            "language": formDefinition?.language
        })) : [];
    }

    return (
        <>
            <div className="container submission-container">
                <LoadingErrorMessage/>
                <div className='row align-items-center submission-container-row'>
                    <div className="col-7 p-0">
                        <KatDropdown options='slot'
                                     className="w-100" value={selectedFormDefinition}
                                     searchable={true} disabled={isLoading}
                                     placeholder={"Select a form..."}
                                     onChange={handleDefinitionDropdownOptionChange}
                                     rich-selection-label
                        >
                            {populateSubmitDropdown(availableFormDefinitions).map((option, index) => {
                                return (
                                    <DefinitionDropdownOption name={option.name as string}
                                                              value={option.value as string}
                                                              language={option.language as string}
                                                              key={index}
                                    />
                                )
                            })}
                        </KatDropdown>
                    </div>
                    <div className="col-5">
                        <KatButton label="New Submission" variant="primary" loading={isLoading}
                                   disabled={!selectedFormDefinition} onClick={handleNewSubmissionClick}
                                   className="w-100"/>
                    </div>
                </div>
            </div>
        </>
    )
}
import React, { FC, useEffect, useRef, useState } from "react";
import { TaskStatus } from "src/components/tasks/model/TaskStatus";
import { CerebrumService } from "src/service/CerebrumCoralService";
import { AxiomMetricsDriver } from "src/metrics/AxiomMetricsDriver";
import EventEmitter from "src/context/EventEmitter";
import { CoralError } from "src/components/error/ErrorBoundComponent";
import { EditTaskEvent } from "src/components/tasks/events/EditTaskEvent";
import { CardOptionsMenu } from "src/components/common/CardOptionsMenu";
import DropdownItem from "react-bootstrap/DropdownItem";
import i18n from "i18next";
import { KatAlert, KatCheckbox, KatColumn, KatDivider, KatIcon, KatLabel, KatLink, KatRow } from "@amzn/katal-react";
import { TasksDateLabel } from "src/components/tasks/TasksDateLabel";
import { TemplateContent } from "@amzn/wfm-template-parser";
import { FlashBriefingModal } from "@amzn/wfm-axiom-flash-briefing-ui-components";
import { useHighlighter } from 'src/components/common/useHighlighter';

interface TaskDisplayParams {
    task: Cerebrum.Tasks.Task;
    onDeleteTask: () => void;
    onShowFeedback?: () => void;
    showFeedbackOption?: boolean;
}

export const COMPONENT_NAME = 'TaskDisplay';
export const IS_AUX_CLICK = true;

export const TaskDisplay: FC<TaskDisplayParams> = (props) => {

    const { showFeedbackOption, onDeleteTask, onShowFeedback } = props;

    const [task, setTask] = useState<Cerebrum.Tasks.Task>(props.task);
    const [statusChangeFailed, setStatusChangeFailed] = useState<boolean>(false);

    const [showMetadata, setShowMetadata] = useState(false);

    const [highlight, setHighlight] = useState(false);
    const [highlightClasses, setHighlightClasses] = useState('');

    const mainRef = useRef(null);
    useHighlighter('task', task.id, mainRef,
        () => setHighlight(true), () => setHighlight(false));

    useEffect(() => {
        const seenClass = `${task.seen ? '' : 'unseen-card'}`;
        const highlightClass = `${highlight ? 'border border-info animate' : ''}`;
        setHighlightClasses(`${seenClass} ${highlightClass}`);
    }, [highlight]);

    const additionalMetrics = [{ name: "taskId", value: task.id }];

    const isCompleted = () => {
        return ("Completed" === task.taskStatus);
    }

    const updateTaskStatus = async (event: any) => {
        dismissError();
        const newStatus = event.target.checked ?
            TaskStatus.COMPLETED :
            TaskStatus.NOT_STARTED;
        // Then
        const input: Cerebrum.Tasks.ChangeTaskStatusInput = {
            id: task.id,
            taskOwner: task.taskOwner,
            newStatus
        };
        await CerebrumService.changeTaskStatus(input)
            .then(handleChangeTaskStatusResponse)
            .catch(handleChangeTaskStatusError);

        logButtonClick('changeStatus');
    }

    const handleChangeTaskStatusResponse = (response: Cerebrum.Tasks.ChangeTaskStatusOutput) => {
        const updatedTask = { ...task, taskStatus: response.task.taskStatus };
        setTask(updatedTask);
        setHighlight(true);
    };

    const handleChangeTaskStatusError = (error: CoralError) => {
        // tslint:disable-next-line:no-console
        console.error(error);
        setStatusChangeFailed(true);
    }

    const onEditTask = () => {
        const editTaskEvent = new EditTaskEvent(task);
        EventEmitter.dispatch(editTaskEvent.name, editTaskEvent);
    }

    const onShowMetadata = () => {
        setShowMetadata(true);
        logButtonClick('showMetadata')
    }

    const dismissError = () => {
        setStatusChangeFailed(false);
    };

    const logLinkClick = (linkName: string, url: string, isAuxClick?: boolean) => (
        () => AxiomMetricsDriver.publishLinkClick(COMPONENT_NAME, linkName, url, [
            ...additionalMetrics,
            { name: 'isAuxClick', value: (!!isAuxClick).toString() }
        ])
    );

    const logButtonClick = (buttonName: string) => {
        AxiomMetricsDriver.publishButtonClick(COMPONENT_NAME, buttonName, additionalMetrics);
    }

    const OptionsMenu = () => (
        <CardOptionsMenu>
            <DropdownItem as="button" onClick={onEditTask}>
                {i18n.t("wfm_axiom_tasks_edit_task")}
            </DropdownItem>
            <DropdownItem as="button" onClick={onDeleteTask}>
                {i18n.t("wfm_axiom_tasks_delete_task")}
            </DropdownItem>
            <KatDivider variant="athens"/>
            {showFeedbackOption &&
                <DropdownItem as="button" onClick={onShowFeedback}>
                    {i18n.t("wfm_axiom_fb_feedback_card_title")}
                </DropdownItem>}
            {/* TODO: Internationalize (AXIOM-390) */}
            <DropdownItem as="button" onClick={onShowMetadata}>
                <span className="text-muted">Show Metadata</span>
            </DropdownItem>
        </CardOptionsMenu>
    );

    const Header = () => (
        <KatRow>
            <KatColumn className="task-card-checkbox-column" md={2}>
                <KatCheckbox checked={isCompleted()} onChange={updateTaskStatus}/>
            </KatColumn>
            <KatColumn className="task-card-content-column" md={9}>
                <span className='pt-1'>
                    {task.title}
                </span>

                <div className="task-card-body text-secondary">
                    <TaskBody/>
                </div>
                <TaskLinks/>
            </KatColumn>
            <KatColumn className="task-card-three-buttons-column" md={1}>
                <OptionsMenu/>
            </KatColumn>
        </KatRow>
    );

    const TaskBody = () => (
        task.template ?
            <TemplateContent template={task.template!} data={task.templateData}/>
            : <>{task.body}</>
    );

    const TaskLinks = () => (<>{
            task.links &&
            <div className='d-flex flex-column'>
                {task.links.map(link =>
                    <>{link.uri &&
                        <div className='flex-row text-secondary'>
                            <KatLink className='py-1' label={link.title} href={link.uri}
                                     onClick={logLinkClick('taskLink', link.uri)}
                                // @ts-ignore
                                     onAuxClick={logLinkClick('taskLink', link.uri, IS_AUX_CLICK)}>
                                <span>{link.title || i18n.t('wfm_axiom_link')}</span>
                            </KatLink>
                            <span> | </span>
                            <KatLink className='py-1' label={link.title} href={link.uri} target='_blank'
                                     onClick={logLinkClick('taskLinkNewTab', link.uri)}
                                // @ts-ignore
                                     onAuxClick={logLinkClick('taskLinkNewTab', link.uri, IS_AUX_CLICK)}>
                                <KatIcon title={i18n.t('wfm_axiom_open_new_tab')} name='open_in_new' size='tiny'/>
                            </KatLink>
                        </div>
                    }</>
                )}
            </div>
        }</>
    );

    const Footer = () => {
        const taskTypeClass = "text-size-sm text-secondary"
        const rowClass = task.body || task.templateContent ? '' : 'mt-m7'; // lift row up when there is no body

        const createdByLabel = () => {
            return task.createdBy &&
            <>
                <span className={taskTypeClass}> | </span>
                <KatLabel className={taskTypeClass} text={`${i18n.t('wfm_axiom_tasks_created_by')} ${task.createdBy}`}></KatLabel>
            </>
        }

        return (
            <KatRow className={rowClass}>
                <KatColumn className="task-card-checkbox-column" md={2}/>
                <KatColumn className="task-card-content-column" md={10}>
                    <span className={taskTypeClass}>
                        {task.taskType}
                    </span>
                    {task.dueDateTime &&
                        <TasksDateLabel dueDate={task.dueDateTime} defaultClass={taskTypeClass}/>}
                    {createdByLabel()}
                </KatColumn>
            </KatRow>
        );
    };

    const MetadataCard = () => <>{
        showMetadata &&
        <FlashBriefingModal
            visible={showMetadata}
            title={'Task Metadata'} // TODO: Internationalize (AXIOM-390)
            body={JSON.stringify(task, null, 2)}
            onClose={() => setShowMetadata(false)}
        />}
    </>;

    return (
        <div className={`task-card pt-2 pb-1 ${highlightClasses}`} ref={mainRef}>
            {statusChangeFailed &&
                <KatAlert
                    header={i18n.t("wfm_axiom_error_changing_status")}
                    onDismiss={dismissError}
                    variant="danger"/>
            }
            <Header/>
            <Footer/>
            <MetadataCard/>
        </div>
    );
};
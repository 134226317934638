import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import katalReady from '@amzn/katal-components-ready';

import AppInitWrapper from './components/AppInitWrapper';
import './i18n';
import './index.scss';
import { whenDOMContentLoaded } from './whenDOMContentLoaded';
import { applyPolyfills, defineCustomElements } from "@amzn/wfm-axiom-forms-renderer-stencil/loader";

const WFMAxiomWebApp = React.lazy(() => import('./components/WFMAxiomWebApp'));

katalReady(() => whenDOMContentLoaded(() => {
	// The Suspense component causes React to display the fallback KatSpinner until
	// the i18n translation strings have been loaded.

	ReactDOM.render(
		<AppInitWrapper />,
		document.getElementById('root')
	);

	applyPolyfills().then(() => {
		defineCustomElements();
	});

}));
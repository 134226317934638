import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { decodeFromString, encodeToString } from "src/components/common/Encoding";

/**
 * Syncs between react functional component state with desired storage.
 * @param key Storage key
 * @param initialValue initial value for state if none available in storage
 * @param storage Storage to use; example: window.localStorage, window.sessionStorage, etc.
 */
export function useStateWithStorage<T>(key: string, initialValue: T, storage: Storage): [T, Dispatch<SetStateAction<T>>] {

    // storing the string representation of the given value in a state
    const [encodedValue, setEncodedValue] = useState(storage.getItem(key) || encodeToString(initialValue))

    // the typed state to be exposed, linked with the encodedValue
    const [typedValue, setTypedValue] = useState<T>(decodeFromString(encodedValue));

    // when typed value externally changes, sync with encoded value
    useEffect(() => {
        setEncodedValue(encodeToString(typedValue))
    }, [typedValue]);

    // when encoded value changes, sync typedValue with the updated url params
    useEffect(() => {
        storage.setItem(key, encodedValue);
        setTypedValue(decodeFromString(encodedValue));
    }, [encodedValue]);

    return [typedValue, setTypedValue];
}


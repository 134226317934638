import React, { useContext, useEffect } from 'react';
import { UserContext } from "src/context/UserContext";
import { KatCard, KatCheckbox } from '@amzn/katal-react';
import { useStateWithSessionStorage } from "src/components/common/storage/UseStateWithSessionStorage";


export default function AccessRightsSelector() {

    const userContext = useContext(UserContext);

    const accessRights = userContext.userManager.getUserIdentity().accessRights;

    const rightsList = [];
    for (const right in accessRights) {
        if (Object.prototype.hasOwnProperty.call(accessRights, right)) {
            rightsList.push({ name: right, value: accessRights[right] });
        }
    }

    return (
        <div className='col-6'>
            <KatCard>
                <span slot="title">Current Access Rights</span>
                <span slot="subtitle" className="text-size-sm text-secondary">
                    Changes here persist through a refresh but not across sessions (new tab/window).
                </span>
                <div>
                    {rightsList.map(right =>
                        <RightSelector {...right} key={right.name}/>
                    )}
                </div>
            </KatCard>
        </div>
    )
}


interface RightSelectorProps {
    name: string;
    value: boolean;
}

function RightSelector(props: RightSelectorProps) {

    const { name, value } = props;

    const userContext = React.useContext(UserContext);

    const [hasAccess, setHasAccess] = useStateWithSessionStorage(name, value);

    const onChange = (event: any) => {
        setHasAccess(event.target.checked)
    };

    useEffect(() => {
        userContext.userManager.userIdentity!.accessRights[name] = hasAccess;
        userContext.setUserManager();
    }, [hasAccess]);

    return <KatCheckbox label={name} checked={value} onChange={onChange} className="mb-2"/>
}

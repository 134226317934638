import React from 'react';
import { KatCard, KatIcon } from '@amzn/katal-react';
import RadarChart from './RadarChart';
import RadarTable from './radar_table/RadarTable';
import RadarDatasetDateBadge from './RadarDatasetDateBadge';
import { RadarCardDropdown } from "src/components/balanced_scorecard/radar_card/RadarCardDropdown";


interface BSCRadarCardProps {
    userBalancedScorecardData: Cerebrum.UserBalancedScorecardData;
    selectedEntity: Axiom.SelectedUser;
    selectedMetricHandler: (selectedMetric: string) => void;
    viewUserDetails: WFM.GetUserDetails;
}

export interface BSCRadarCardState {
}

export default class BSCRadarCard extends React.Component<BSCRadarCardProps, BSCRadarCardState> {
    constructor(props: BSCRadarCardProps) {
        super(props);
        this.selectedMetricHandler = this.selectedMetricHandler.bind(this);
    }

    render() {
        const { selectedEntity, viewUserDetails, userBalancedScorecardData } = this.props;
        const { supervisor } = userBalancedScorecardData;
        const datasetDate = userBalancedScorecardData.teamBalancedScorecard.datasetDate;
        return (
            <>
                <div className="content row">
                    <div className="col-md-12">
                        <KatCard>
                            <span slot="title">
                                <div className="axiom-bsc-nav-helper">
                                    <KatIcon name="explore" size="small" />&nbsp;
                                    <RadarCardDropdown theme={'text-orange-nav-helper'}
                                        userId={supervisor}
                                        viewUserLogin={viewUserDetails.login} />
                                    {this.props.selectedEntity.type == 'agent' &&
                                        <>
                                            <span>{' | '}</span>
                                            <RadarCardDropdown userId={selectedEntity.userId}
                                                viewUserLogin={viewUserDetails.login} />
                                        </>
                                    }
                                </div>
                            </span>
                            <RadarDatasetDateBadge
                                datasetDate={datasetDate}
                            />
                            <div className="kat-row text-center">
                                <div className="kat-col-sm-8 my-auto px-0">
                                    <RadarChart {...this.props} />
                                </div>
                                <div className="kat-col-sm-4">
                                    <RadarTable
                                        bscMetricData={selectedEntity.bscMetricData}
                                        selectedMetricHandler={this.selectedMetricHandler}
                                        datasetDate={datasetDate}
                                        selectedEntity={selectedEntity}
                                    />
                                </div>
                            </div>
                        </KatCard>
                    </div>
                </div>
            </>
        );
    }

    /*
    * Event Handlers
    * */
    private selectedMetricHandler(metric: string) {
        this.props.selectedMetricHandler(metric);
    }
}

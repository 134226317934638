import { UserManager } from 'src/user/UserIdentityManager';
import { BaseCoralService } from './BaseCoralService';
import { AuthenticationService } from "./AuthenticationService";
import { ConfigManager } from "src/config/ConfigManager";
import { resolveTasksTemplates, resolveTaskTemplates } from "src/service/TasksServiceHelper";
import { getDueDateUTCTimeString } from "src/components/common/DateFormatter";


export enum CerebrumActivity {
    // TODO: progressively add other endpoints here while raising coverage as we work on them
    GET_USER_INFORMATION  = 'com.amazon.wfmaxiomcerebrumservice.WFMAxiomCerebrumService.GetUserInformation',
    GET_USER_DETAILS = 'com.amazon.wfmaxiomcerebrumservice.WFMAxiomCerebrumService.GetUserDetails',

    GET_BALANCED_SCORECARD = 'com.amazon.wfmaxiomcerebrumservice.WFMAxiomCerebrumService.GetAgentBalancedScorecard',
    LIST_SUPERVISOR_BALANCED_SCORECARD = 'com.amazon.wfmaxiomcerebrumservice.WFMAxiomCerebrumService.ListSupervisorBalancedScorecards',
    LIST_SUPERVISOR_RECOMMENDATIONS = 'com.amazon.wfmaxiomcerebrumservice.WFMAxiomCerebrumService.ListSupervisorRecommendations',
    LIST_AGENT_RECOMMENDATIONS = 'com.amazon.wfmaxiomcerebrumservice.WFMAxiomCerebrumService.ListAgentRecommendations',

    GET_AUDITS = 'com.amazon.wfmaxiomcerebrumservice.WFMAxiomCerebrumService.GetAuditsV2',
    COMPLETE_AUDIT = 'com.amazon.wfmaxiomcerebrumservice.WFMAxiomCerebrumService.CompleteAuditV2',
    UNCOMPLETE_AUDIT = 'com.amazon.wfmaxiomcerebrumservice.WFMAxiomCerebrumService.UnCompleteAuditV2',

    GET_HELP_CONTENT = 'com.amazon.wfmaxiomcerebrumservice.WFMAxiomCerebrumService.GetHelpContent',
    GET_HELP_CONTENT_BY_ID = 'com.amazon.wfmaxiomcerebrumservice.WFMAxiomCerebrumService.GetHelpContentById',

    GET_NARRATIVE_TEMPLATE = 'com.amazon.wfmaxiomcerebrumservice.WFMAxiomCerebrumService.GetNarrativeTemplate',
    GET_FILE = 'com.amazon.wfmaxiomcerebrumservice.WFMAxiomCerebrumService.GetFile',

    CHANGE_TASK_STATUS = 'com.amazon.wfmaxiomcerebrumservice.WFMAxiomCerebrumService.ChangeTaskStatus',
    GET_TASKS = 'com.amazon.wfmaxiomcerebrumservice.WFMAxiomCerebrumService.GetTasks',
    UPDATE_TASK = 'com.amazon.wfmaxiomcerebrumservice.WFMAxiomCerebrumService.UpdateTask',

    GET_SURVEY_QUESTIONS = 'com.amazon.wfmaxiomcerebrumservice.WFMAxiomCerebrumService.GetSurveyQuestions',
    CREATE_SURVEY_RESPONSE = 'com.amazon.wfmaxiomcerebrumservice.WFMAxiomCerebrumService.CreateSurveyResponse'
}


/**
 * Interface for interacting with the Cerebrum Coral Service.
 */
class CerebrumCoralService extends BaseCoralService {
    /**
     * Allows devs to view data from tenant 1 using the admin tool
     *
     * Injects tenantId & versionId if they are set and don't already exist in the request data
     */
    public async doCoralPost(activity: string, data: any): Promise<any> {
        if (UserManager.tenantId && !data["tenantId"]) {
            data["tenantId"] = UserManager.tenantId.toString();
        }
        if (UserManager.versionId && !data["versionId"]) {
            data["versionId"] = UserManager.versionId;
        }
        return super.doCoralPost(activity, data);
    }

    // BSC
    public getAgentBalancedScorecard(agentId: string, data?: Axiom.Map): Promise<Cerebrum.GetAgentBalancedScorecard> {
        return this.doCoralPost(CerebrumActivity.GET_BALANCED_SCORECARD, { agentId, ...data });
    }

    public listSupervisorBalancedScorecards(supervisorId: string, data?: Axiom.Map): Promise<Cerebrum.ListSupervisorBalancedScorecards> {
        return this.doCoralPost(CerebrumActivity.LIST_SUPERVISOR_BALANCED_SCORECARD, { supervisorId, ...data });
    }

    // Flash Briefing
    public getCardsMetadata(entityId: string, targetPage: string): Promise<Cerebrum.GetCardMetadata> {
        const getCardsMetadataActivity = 'com.amazon.wfmaxiomcerebrumservice.WFMAxiomCerebrumService.GetCardsMetadata';
        return this.doCoralPost(getCardsMetadataActivity, { "entityId": entityId, 'targetPage': targetPage });
    }

    public getCardContent(cardId: string, entityId: string): Promise<Cerebrum.GetCardContent> {
        const getCardContentActivity = 'com.amazon.wfmaxiomcerebrumservice.WFMAxiomCerebrumService.GetCardContent';
        return this.doCoralPost(getCardContentActivity, { "entityId": entityId, "cardId": cardId });
    }

    // Recommendations
    public listAgentRecommendations(agentId: string, data?: Axiom.Map): Promise<Cerebrum.ListAgentRecommendations> {
        return this.doCoralPost(CerebrumActivity.LIST_AGENT_RECOMMENDATIONS, { agentId, ...data });
    }

    public listSupervisorRecommendations(supervisorId: string, data?: Axiom.Map): Promise<Cerebrum.ListSupervisorRecommendations> {
        return this.doCoralPost(CerebrumActivity.LIST_SUPERVISOR_RECOMMENDATIONS, { supervisorId, ...data });
    }

    // Audits
    public getAudits(input: Cerebrum.Audits.GetAuditsInput): Promise<Cerebrum.Audits.GetAuditsOutput> {
        // Convert basic auditType string from input to related backend type string based on config
        input.auditType = this.config.auditTypeKeyMap[input.auditType];
        return this.doCoralPost(CerebrumActivity.GET_AUDITS, input);
    }

    public completeAudit(input: Cerebrum.Audits.CompleteAuditInput): Promise<Cerebrum.Audits.CompleteAuditOutput> {
        return this.doCoralPost(CerebrumActivity.COMPLETE_AUDIT, input);
    }

    public unCompleteAudit(input: Cerebrum.Audits.CompleteAuditInput): Promise<Cerebrum.Audits.CompleteAuditOutput> {
        return this.doCoralPost(CerebrumActivity.UNCOMPLETE_AUDIT, input);
    }

    public getParagonSignedUrl(input: Cerebrum.Audits.GetParagonSignedUrlInput): Promise<Cerebrum.Audits.GetParagonSignedUrlOutput> {
        const getParagonSignedUrlActivity = 'com.amazon.wfmaxiomcerebrumservice.WFMAxiomCerebrumService.GetParagonSignedUrl';
        return this.doCoralPost(getParagonSignedUrlActivity, input);
    }

    // Tasks
    public getTasks(input: Cerebrum.Tasks.GetTasksInput): Promise<Cerebrum.Tasks.GetTasksOutput> {
        return this.doCoralPost(CerebrumActivity.GET_TASKS, { application: "Axiom", ...input })
            .then(resolveTasksTemplates);
    }

    public createTask(input: Cerebrum.Tasks.Task): Promise<Cerebrum.Tasks.CreateTaskResponse> {
        const endpoint = "com.amazon.wfmaxiomcerebrumservice.WFMAxiomCerebrumService.CreateTask";
        input['dueDateTime'] = input.dueDateTime ? getDueDateUTCTimeString(input.dueDateTime) : undefined;
        return this.doCoralPost(endpoint, { application: "Axiom", ...input });
    }

    public changeTaskStatus(input: Cerebrum.Tasks.ChangeTaskStatusInput): Promise<Cerebrum.Tasks.ChangeTaskStatusOutput> {
        return this.doCoralPost(CerebrumActivity.CHANGE_TASK_STATUS, { application: "Axiom", ...input })
            .then(resolveTaskTemplates);
    }

    public updateTask(input: Cerebrum.Tasks.Task): Promise<Cerebrum.Tasks.CreateTaskResponse> {
        input['dueDateTime'] = input.dueDateTime ? getDueDateUTCTimeString(input.dueDateTime) : undefined;
        return this.doCoralPost(CerebrumActivity.UPDATE_TASK, { application: "Axiom", ...input })
            .then(resolveTaskTemplates);
    }

    public deleteTask(input: Cerebrum.Tasks.DeleteTaskInput): Promise<Cerebrum.Tasks.DeleteTaskOutput> {
        const deleteTaskEndpoint = "com.amazon.wfmaxiomcerebrumservice.WFMAxiomCerebrumService.DeleteTask";
        return this.doCoralPost(deleteTaskEndpoint, { application: "Axiom", ...input });
    }

    public markTasksAsSeen(input: Cerebrum.Tasks.MarkTasksAsSeenInput): Promise<Cerebrum.Tasks.GetTasksOutput> {
        const endpoint = "com.amazon.wfmaxiomcerebrumservice.WFMAxiomCerebrumService.MarkTasksAsSeen";
        return this.doCoralPost(endpoint, { application: "Axiom", ...input });
    }

    // Notifications
    public getNotifications(input: Cerebrum.Tasks.GetNotificationsInput): Promise<Cerebrum.Tasks.GetNotificationsOutput> {
        const endpoint = "com.amazon.wfmaxiomcerebrumservice.WFMAxiomCerebrumService.GetNotifications";
        return this.doCoralPost(endpoint, { application: "Axiom", ...input });
    }

    public dismissNotification(input: Cerebrum.Tasks.DismissNotificationInput): Promise<Cerebrum.Tasks.SingleNotificationOutput> {
        const endpoint = "com.amazon.wfmaxiomcerebrumservice.WFMAxiomCerebrumService.DismissNotification";
        return this.doCoralPost(endpoint, { application: "Axiom", ...input });
    }

    public markNotificationsAsSeen(input: Cerebrum.Tasks.MarkNotificationsAsSeenInput): Promise<Cerebrum.Tasks.GetNotificationsOutput> {
        const endpoint = "com.amazon.wfmaxiomcerebrumservice.WFMAxiomCerebrumService.MarkNotificationsAsSeen";
        return this.doCoralPost(endpoint, { application: "Axiom", ...input });
    }

    // User Info
    public getUserInformation(): Promise<WFM.GetUserInformation> {
        return this.doCoralPost(CerebrumActivity.GET_USER_INFORMATION, {});
    }

    public getUserDetails(login: string): Promise<WFM.GetUserDetails> {
        return this.doCoralPost(CerebrumActivity.GET_USER_DETAILS, { "login": login });
    }

    public getUserTreatment(weblabName: string, login: string): Promise<WFM.GetWeblabTreatment> {
        const getUserTreatmentActivity = 'com.amazon.wfmaxiomcerebrumservice.WFMAxiomCerebrumService.GetUserTreatment';
        return this.doCoralPost(getUserTreatmentActivity, { "weblabName": weblabName, "login": login });
    }

    // Help Content
    public getHelpContent(page: string, locale: string): Promise<Cerebrum.HelpContent> {
        return this.doCoralPost(CerebrumActivity.GET_HELP_CONTENT, { page, locale });
    }

    public getHelpContentById(pageId: string, locale: string): Promise<Cerebrum.HelpContent> {
        return this.doCoralPost(CerebrumActivity.GET_HELP_CONTENT_BY_ID, { pageId, locale });
    }

    // Tenant Configuration
    public getTenantConfiguration(entityId: string): Promise<WFM.GetTenantConfiguration> {
        const getTenantConfigurationActivity = 'com.amazon.wfmaxiomcerebrumservice.WFMAxiomCerebrumService.GetTenantConfiguration';
        return this.doCoralPost(getTenantConfigurationActivity, { "appId": "Axiom", "agentId": entityId });
    }

    public getNarrativeTemplate(input: Cerebrum.GetNarrativeTemplateInput): Promise<Cerebrum.GetNarrativeTemplateOutput> {
        return this.doCoralPost(CerebrumActivity.GET_NARRATIVE_TEMPLATE, input);
    }

    public getFile(input: Cerebrum.GetNarrativeTemplateInput): Promise<Cerebrum.GetNarrativeTemplateOutput> {
        return this.doCoralPost(CerebrumActivity.GET_FILE, input);
    }

    // Reverb
    public getSurveyQuestions(input: Cerebrum.Reverb.GetSurveyQuestionsInput): Promise<Cerebrum.Reverb.GetSurveyQuestionsOutput> {
        return this.doCoralPost(CerebrumActivity.GET_SURVEY_QUESTIONS, input);
    }

    public createSurveyResponse(input: Cerebrum.Reverb.SurveyResponse): Promise<Cerebrum.Reverb.SurveyResponse> {
        return this.doCoralPost(CerebrumActivity.CREATE_SURVEY_RESPONSE, input);
    }

    // Authentication
    public async authenticateWithFederateToken(): Promise<any> {
        const params = this.paramsToJson(new URL(window.location.href).searchParams);
        const redirectUri = AuthenticationService.getAuthenticatedRedirectUrl();
        if (redirectUri) {
            params['redirect_uri'] = redirectUri;
        }
        const response = await this.doCoralGet(null, params);
        AuthenticationService.redirectFromLocationHeader(response);
    }

    public async authenticateWithGlobalIdentityToken(): Promise<any> {
        const params = this.paramsFromHash();
        const redirectUri = AuthenticationService.getAuthenticatedRedirectUrl();
        if (redirectUri) {
            params['redirect_uri'] = redirectUri;
        }
        console.error(params);
        const response = await this.doCoralGet(null, params);
        AuthenticationService.redirectFromLocationHeader(response);
    }

    private paramsFromHash() {
        return window.location.hash.substr(1).split('&').reduce(
            (accumulator: { [key: string]: string; }, param: string) => {
                const keyValue = param.split('=');
                accumulator[keyValue[0]] = keyValue[1];
                return accumulator;
            }
            , {});
    }

    public getApiEndpoint(): string {
        const authMethodPath = this.config.cerebrum.authMethodPath
        const defaultApiPath = this.config.cerebrum.endPoint + authMethodPath;
        // Use override from storage if one exists
        return this.apiPathOverride || defaultApiPath;
    }

    private get apiPathOverride(): string {
        const apiEndpoint = localStorage.getItem(this.config.localStorageKeys.API_ENDPOINT);
        const apiAuthMethod = localStorage.getItem(this.config.localStorageKeys.API_AUTH_METHOD);
        if (apiEndpoint && apiAuthMethod) { // Both need to be selected or it will cause failures
            return apiEndpoint + apiAuthMethod;
        }
        return '';
    }

    private get config(): AxiomConfig.Configuration {
        return ConfigManager.getStaticConfig();
    }

    public get emptyUserInformation() {
        return {
            accessRights: {
                "axiom.ops_leadership.view": false,
                "axiom.auditor.view": false,
                "axiom.admin.view": false,
                "axiom.supervisor.view": false,
                "axiom.power_user.view": false,
                "axiom.basic.view": false
            },
            additionalAttributes: {
                email: 'unknown',
                manager: 'unknown',
                givenName: 'unknown',
                familyName: 'unknown',
                supervisorId: 'unknown',
                supervisorFullName: 'unknown'
            },
            login: 'unknown',
            outsourced: false,
            supervisor: false
        }
    };
}

// Export the single instance of the service
export const CerebrumService = new CerebrumCoralService();

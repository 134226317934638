import React, { FC, PropsWithChildren, useContext, useEffect, useState } from "react";
import "./Assistant.scss"
import { KatBadge, KatBox, KatIcon, KatSpinner, KatTab, KatTabs } from "@amzn/katal-react";
import { Tasks } from "src/components/tasks/Tasks";
import { UserContext } from "src/context/UserContext";
import { Notifications } from "src/components/notifications/Notifications";
import { LayoutContext } from "src/components/layout/LayoutContext";
import { AxiomMetricsDriver } from "src/metrics/AxiomMetricsDriver";
import { AssistantContext, AssitantTab } from "src/components/assistant/AssistantContext";
import { UserRestrictedComponent } from "src/components/authorization/UserRestrictedComponent";
import { ComponentID } from "src/components/authorization/ComponentRightsMapping";
import { HelpContentRenderer } from "src/components/support/HelpContentRenderer";
import { HelpContext } from "src/context/HelpContext";
import { TabData } from "src/components/common/tabsSelector/TabsSelector";


export const ASSISTANT_ICON: KatIcon.Name = "assignment_ind";
export const ASSISTANT_NAME = 'Charlotte';


const AssistantTabs: Record<AssitantTab, TabData> = {
    [AssitantTab.NOTIFICATIONS]: {
        name: AssitantTab.NOTIFICATIONS,
        label: 'Notifications',
        unseen: undefined
    },
    [AssitantTab.TASKS]: {
        name: AssitantTab.TASKS,
        label: 'Tasks',
        unseen: undefined
    },
    [AssitantTab.HELP]: {
        name: AssitantTab.HELP,
        label: 'Help',
        unseen: undefined
    }
}

const COMPONENT_NAME = 'Assistant';

export const Assistant: React.FC<unknown> = () => {

    const userContext = useContext(UserContext);
    const layout = useContext(LayoutContext);
    const assistantContext = useContext(AssistantContext);
    const helpContext = useContext(HelpContext);

    const [tabs, setTabs] = useState(AssistantTabs);
    const [currentTab, setCurrentTab] = useState(AssistantTabs[assistantContext.selectedTab]);

    // Event listening and cleanup
    const showSidebarAndSelectTasksTab = () => {
        layout.setSidebarVisible(true);
        assistantContext.setSelectedTab(AssitantTab.TASKS);
    };

    useEffect(() => {
        if (assistantContext.creatingTask) {
            showSidebarAndSelectTasksTab();
        }
    }, [assistantContext.creatingTask, assistantContext.currentTask]);

    useEffect(() => {
        setCurrentTab(AssistantTabs[assistantContext.selectedTab])
    }, [assistantContext.selectedTab]);

    const handleTabChange = (evt: KatTabs.ChangeEvent) => {
        // check if it is a valid tab name cause the event could be
        // from a child component and not related with tabs
        const newSelectedTab = tabs[evt.detail.selected as AssitantTab];
        if (newSelectedTab) {
            setUnseenNumber(currentTab.name as AssitantTab, 0);
            assistantContext.setSelectedTab(newSelectedTab.name as AssitantTab);
            AxiomMetricsDriver.publishButtonClick(COMPONENT_NAME, newSelectedTab.name);
        }
    }

    const isSelected = (tabName: AssitantTab) => currentTab?.name === tabName;

    const userLogin = () => userContext.userManager.userIdentity?.login ?? '';

    const setUnseenNumber = (tabName: AssitantTab, unseenNumber: number) => {
        setTabs(currentTabs => (
            { ...currentTabs, [tabName]: { ...AssistantTabs[tabName], unseen: unseenNumber } }));
    }

    return (
        <KatBox variant="white" className='p-0 assistant-body d-flex flex-column'>
            <KatTabs onChange={handleTabChange} selected={currentTab.name}>
                <TabHeader tabName={AssitantTab.NOTIFICATIONS} isSelected={isSelected} tabs={tabs}/>
                <TabHeader tabName={AssitantTab.TASKS} isSelected={isSelected} tabs={tabs}/>
                {/*Help tab only visible to admins for now...*/}
                <UserRestrictedComponent componentId={ComponentID.HEADER_ADMIN_RIGHTS_ID}>
                    <TabHeader tabName={AssitantTab.HELP} isSelected={isSelected} tabs={tabs}/>
                </UserRestrictedComponent>
            </KatTabs>
            {/* Content is extracted from KatTabs into our custom TabContent abstraction in order to make divs
             respect the parent container size, which will be otherwise hidden behind KatTabs shadow root */}
            <TabContent display={isSelected(AssitantTab.NOTIFICATIONS)}>
                <Notifications viewUserLogin={userLogin()} setUnseenNumberCallback={setUnseenNumber}/>
            </TabContent>
            <TabContent display={isSelected(AssitantTab.TASKS)}>
                <Tasks viewUserLogin={userLogin()} setUnseenNumberCallback={setUnseenNumber}/>
            </TabContent>
            <TabContent display={isSelected(AssitantTab.HELP)}>
                <UserRestrictedComponent componentId={ComponentID.HEADER_ADMIN_RIGHTS_ID}>
                    {
                        !helpContext.currentContextualHelpId ?
                            <KatSpinner/> :
                            <div className='px-2 mx-2'>
                                <HelpContentNavigator/>
                                {helpContext.currentContextualHelpId ?
                                    <HelpContentRenderer pageId={helpContext.currentContextualHelpId} isSidebar/> :
                                    <span>Help page not found: {helpContext.currentContextualHelpId}</span>
                                }
                            </div>
                    }
                </UserRestrictedComponent>
            </TabContent>
        </KatBox>
    );
}

interface TabHeaderProps {
    tabName: AssitantTab;
    isSelected: (name: AssitantTab) => boolean;
    tabs: Record<AssitantTab, TabData>;
}


const HelpContentNavigator: React.FC<unknown> = () => {

    const helpContext = useContext(HelpContext);
    return (
        <div className="assistant-help-navigation">
            { helpContext.canNavigateBack &&
                <KatIcon name="arrow-left" className="assistant-help-navigation-backward" size="large" onClick={helpContext.operations.navigateBackward}/>
            }
            { helpContext.canNavigateForward &&
                <KatIcon name="arrow-right" className="assistant-help-navigation-forward" size="large" onClick={helpContext.operations.navigateForward}/>
            }
        </div>
    );
}

const TabHeader: FC<PropsWithChildren<TabHeaderProps>> = (props) => {

    const { tabName, isSelected, tabs } = props;

    const tab = tabs[tabName];

    const Badge = () => <>{tab.unseen ?
        <KatBadge slot='status' className="pb-2" type="warning" label={`${tab.unseen}`}/> : ''
    }</>

    return (
        <KatTab tabId={tab.name}>
            <h4 slot='label' className={isSelected(tabName) ? 'assistant-selected-tab' : ''}>
                {tab.label}
            </h4>
            <Badge/>
        </KatTab>
    );
}

interface TabContentProps {
    display: boolean;
}

const TabContent: FC<PropsWithChildren<TabContentProps>> = (props) => {

    const { display, children } = props;

    return (
        <div className={`assistant-content mb-2 ${display ? '' : 'd-none'}`}>
            {children}
        </div>
    );
}

import { CerebrumService } from "src/service/CerebrumCoralService";
import { AxiomMetricsDriver } from "src/metrics/AxiomMetricsDriver";
import { Treatment, Weblab } from "src/user/Weblab";
import { withCheckUserAuthenticated } from "src/wrappers/withCheckUserAuthenticated";

export class Treatments {

    public static is(desiredTreatment: Treatment, candidate: string | undefined): boolean {
        const found = Treatment[candidate as keyof typeof Treatment];
        return !!found;
    }

    public static forEach(action: (treatment: Treatment) => any) {
        for (const treatment in Treatment) {
            action(Treatment[treatment as keyof typeof Treatment]);
        }
    }
}

/**
 * Represents the allocation of a Treatment in a given Weblab
 */
export interface WeblabAssignment {
    readonly weblab: Weblab;
    readonly treatment: Treatment;
}

class WeblabsManager {

    private readonly weblabsByName: Map<Weblab, WeblabAssignment> = new Map();

    public async initialize(login: string) {
        for (const weblab in Weblab) {
            Weblabs.overrideTreatment(Weblab[weblab as keyof typeof Weblab], Treatment.C);
        }

        const promises: Promise<void>[] = [];
        for (const weblab in Weblab) {
            promises.push(this.initializeWeblab(Weblab[weblab as keyof typeof Weblab], login));
        }
        await Promise.all(promises);

        // consider local overrides
        for (const weblab in Weblab) {
            const treatment = localStorage.getItem(Weblab[weblab as keyof typeof Weblab]);
            treatment && Weblabs.overrideTreatment(Weblab[weblab as keyof typeof Weblab], Treatment[treatment as keyof typeof Treatment]);
        }
    }

    private async initializeWeblab(weblab: Weblab, login: string) {
        await this.getTreatmentForUser(weblab, login)
            .then(treatment => {
                this.assignTreatment(weblab, treatment, login);
            })
            .catch(reason => {
                console.log(`Unable to initialize ${weblab}, ${reason}`);
                // We don't want the app to be affected by a single Weblab misconfiguration
                this.assignTreatment(weblab, Treatment.C, login);
            });
    }

    private assignTreatment(weblab: Weblab, treatment: Treatment, login: string) {
        this.weblabsByName.set(weblab, new WeblabAssignmentImpl(weblab, treatment));
        AxiomMetricsDriver.publishWeblabAssignment(login, weblab, treatment.toString());
    }

    public async getTreatmentForUser(weblabName: string, login: string): Promise<Treatment> {
        const treatmentInfo = await CerebrumService.getUserTreatment(weblabName, login);
        return Treatment[treatmentInfo.treatment as keyof typeof Treatment];
    }

    public getWeblabAssignments(): WeblabAssignment[] {
        return [...this.weblabsByName.values()];
    }

    public getTreatment(weblab: Weblab): Treatment {
        return this.resolveWeblabWrapper(weblab).treatment;
    }

    /**
     * @deprecated This method shouldn't be used but for testing purposes by admins
     * @param weblab The Weblab to modify
     * @param treatment The new Treatment to be assigned for this session
     */
    public overrideTreatment(weblab: Weblab, treatment: Treatment) {
        this.weblabsByName.set(weblab, new WeblabAssignmentImpl(weblab, treatment));
        console.log(`WEBLABS: ${weblab} set to ${treatment}`);
    }

    private resolveWeblabWrapper(weblabName: Weblab): WeblabAssignment {
        const weblab = this.weblabsByName.get(weblabName);
        if (!weblab) {
            throw Error(`Weblab ${weblabName} not initialized.`);
        }
        return weblab;
    }

    public isUserInWeblabAndTreatment(weblab: Weblab, treatment: Treatment): boolean {
        return Weblabs.getTreatment(weblab) === treatment;
    }

}

// tslint:disable-next-line:max-classes-per-file
class WeblabAssignmentImpl implements WeblabAssignment {

    constructor(weblab: Weblab, treatment: Treatment) {
        this.weblab = weblab;
        this.treatment = treatment;
    }

    readonly treatment: Treatment;
    readonly weblab: Weblab;
}

/**
 * The Weblabs manager has an interceptor that will check that User is logged in before running any method.
 */
export const Weblabs = withCheckUserAuthenticated(new WeblabsManager());


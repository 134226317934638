import React from 'react';
import ReactDOM from 'react-dom';
import { AxiomMetricsDriver } from "src/metrics/AxiomMetricsDriver";
import { DiveLinkManager } from './DiveLinkBuilder';
import { KatIcon, KatPopover } from '@amzn/katal-react';


/**
 * Default dive link formatter
 */
export function diveColumnFormatter(rowIndex: number, rowData: Cerebrum.RecommendationTableData, previouslyRenderedElement: any) {
    let linkEl: HTMLAnchorElement;
    if (previouslyRenderedElement) {
        linkEl = previouslyRenderedElement;
    }
    else {
        linkEl = document.createElement("a");
        linkEl.target = "_blank";
        let iconEl = <KatIcon name="arrow-right" size="tiny" />;
        ReactDOM.render(iconEl, linkEl);
    }
    linkEl.setAttribute('data-linktype', 'default'); // This is to make it easy to identify the default formatter was used
    linkEl.href = new DiveLinkManager({ diveData: rowData }).getUrl();
    linkEl.onclick = linkEl.onauxclick = () => diveLinkOnclick(rowData, linkEl.href);
    return { element: linkEl as HTMLElement };
}


/**
 * Dive Link formatter for only OS Agents
 * 
 * Seperated as a special case because the use of previouslyRenderedElement is more limited and could impact performance if used supervisors who
 * have larger datasets in the table. For individual agents the number of rows is far less so performance won't be a factor.
 */
export function osAgentDiveColumnFormatter(rowIndex: number, rowData: Cerebrum.RecommendationTableData, previouslyRenderedElement: any) {
    if (rowData.caseIdList[0] !== "nan") { // if there are cases available
        if (previouslyRenderedElement) {
            if ((previouslyRenderedElement as HTMLElement).getAttribute('data-linktype') === 'default') {
                // if there are cases available and the previous element linktype was default then we can reuse it
                return diveColumnFormatter(rowIndex, rowData, previouslyRenderedElement);
            }
        }
        // if there are cases available but the previous element didnt exist or is the wrong type 
        // then we can't reuse the previous element
        return diveColumnFormatter(rowIndex, rowData, null);
    }
    // Create special element for no cases available scenario
    const linkEl = document.createElement("div"); // This creates a container element for react to render into
    const noCasesTooltipString = "No Cases in the Last 14 days are available for this skill."; // TODO: Translation 
    const iconEl = (
        <KatIcon name="x" size="tiny"
            slot="trigger"
            data-toggle="tooltip"
            title={noCasesTooltipString}
            className="cursor-not-allowed" />
    );
    // The Kat popover element does not render in the right place
    // TODO: Create SIM with Katal Team to fix this.
    const popoverEl = (
        <KatPopover katAriaBehavior="interactive" triggerType="hover" position="left">
            {iconEl}
            {noCasesTooltipString}
        </KatPopover>
    );
    ReactDOM.render(iconEl, linkEl); // using just the icon with oldschool tool tip for now
    return { element: linkEl as HTMLElement };
}


function diveLinkOnclick(rowData: Cerebrum.RecommendationTableData, href: string) {
    const destination = new URL(href);
    const additionalMetrics = [
        {
            name: "agentId",
            value: rowData.agentId
        },
        {
            name: "skill",
            value: rowData.skill
        },
        {
            name: "metric",
            value: rowData.metric
        },
        {
            name: "destination",
            value: destination.host
        }
    ];
    return AxiomMetricsDriver.publishButtonClick('RecommendationsTable', 'diveDeep', additionalMetrics);
}

import React, { useCallback, useContext, useEffect, useState } from 'react';
import './Forms.scss';
import { KatAlert, KatExpander, KatLabel, KatLink, KatSpinner } from '@amzn/katal-react';
import { AxiomMetricsDriver, MetricStatus } from "src/metrics/AxiomMetricsDriver";
import { FormsService } from "src/service/Forms.service";
import { AxiomFormsWebInterfaceContainer } from "src/components/axiom-forms-web-interface/AxiomFormsWebInterfaceContainer";
import { Extension, FormDefinition, FormDefinitionResponse, FormDefinitionStatus, FormSubmission as FormSubmissionModel, FormSubmissionResponse, FormSubmissionUpdateResponse } from "src/service/amplify/appsync";
import { useSearchParamsReader } from "src/components/common/searchParameters/useSearchParamsReader";
import { FormDisplay, FormResponses } from "src/components/forms/FormDisplay";
import { AXIOM_FORMS_PREFIX, FormSubmissionStatus } from "src/components/forms/constants/FormConstants";
import { BannerContext, ToggleBannerVisibleProps } from "src/components/layout/BannerContext";
import { useHistory } from "react-router-dom";
import { HeaderRoute } from "src/components/common/header/HeaderRoute";
import { CompleteAuditAction } from "src/components/forms/extensions/CompleteAuditAction";
import { FormState } from "@amzn/wfm-axiom-forms-renderer-stencil/dist/types/model/Forms";
import { AsVisitableExtensionArray } from "src/components/forms/extensions/VisitableExtension";
import { ExtensionVisitorsRegistry } from "src/components/forms/extensions/ExtensionVisitorsRegistry";
import { parseMetadataLabel } from "src/components/common/MetadataLabel";
import { CustomMessages, ResponseCustomMessage } from "src/service/types/ResponseCustomMessage";
import { LayoutContext } from "src/components/layout/LayoutContext";
import { NewSubmissionButton } from "src/components/forms/new_submission_button/NewSubmissionButton";
import { UrlParameterConfigKeys } from "src/config/ConfigManager";
import { useFormStaticDataFromHostingApp } from "src/hooks/useFormStaticDataFromHostingApp";


interface FormSubmissionProps {
    formId?: string;
    formSubmissionId?: string;
    readonly?: boolean;
}

type ActionName = '.AfterSubmission' | '.AfterUpdate';

const PAGE_NAME = 'FormSubmission';
const COMPONENT_NAME = AXIOM_FORMS_PREFIX + PAGE_NAME;
const FORM_SUBMITTED_EVENT_TYPE = "ax-forms-form-submitted";

export const FormSubmission: React.FC<FormSubmissionProps> = (props: FormSubmissionProps) => {
    const { formId: formIdOrName, formSubmissionId, readonly } = props

    const [isLoading, setLoading] = useState<boolean>(true);

    const [errorMessage, setErrorMessage] = useState<any>();

    const [formDefinition, setFormDefinition] = useState<FormDefinition>();

    const [formSubmission, setFormSubmission] = useState<FormResponses>();

    const [formSubmissionData, setFormSubmissionData] = useState<FormSubmissionModel>();

    const [formSubmissionStatus, setFormSubmissionStatus] = useState<string>();

    const [initialFormResponses, setInitialFormResponses] = useState<FormResponses>();

    const staticData = useFormStaticDataFromHostingApp();

    const searchParameters = useSearchParamsReader();

    const { toggleBannerVisible } = useContext(BannerContext);
    const history = useHistory();

    const layout = useContext(LayoutContext);

    // Publish page load metrics
    useEffect(() => {
        document.title = `${SITE_NAME} - ${PAGE_NAME}`;
        AxiomMetricsDriver.publishPageLoad(COMPONENT_NAME, window.location.href);
    }, []);

    // Effects

    useEffect(() => {
        submitForm();
    }, [formSubmission]);

    useEffect(() => {
        getForm();
    }, [formIdOrName]);

    useEffect(() => {
        setInitialFormResponses({
            responses: searchParameters.searchParams
        });
    }, [formIdOrName, searchParameters.searchParams]);

    useEffect(() => {
        getFormSubmission()
    }, [formSubmissionId]);

    // Api calls
    const getForm = async () => {
        if (formIdOrName) {
            setLoading(true);
            setErrorMessage(undefined);
            setFormDefinition(undefined);

            // If formIdOrName is an id, it will get that form, if not, it will get latest version of that form
            FormsService.getFormDefinition(formIdOrName)
                .then(handleGetFormResponse)
                .catch(handleError);

        } else if (!formSubmissionId) {
            setLoading(false);
            setErrorMessage('Select a form');
            setFormDefinition(undefined);
            
        }
    };

    const submitForm = async () => {
        if (formDefinition && formSubmission) {
            setLoading(true);

            if (Object.keys(formSubmission.responses).length === 0) {
                setErrorMessage("Submissions can't be empty");
                setLoading(false);
                return;
            }

            if (formSubmissionId) {
                FormsService.updateFormSubmission(formSubmissionId,
                    JSON.stringify(formSubmission!.responses),
                    formDefinition!.id,
                    formSubmissionStatus!
                ).then(res => handleResponse(res, true))
                 .catch(handleError);
            } else {
                FormsService.submitForm(formDefinition!.id, JSON.stringify(formSubmission!.responses), formSubmissionStatus!)
                    .then(handleResponse)
                    .catch(handleError);
            }
        }
    };

    const getFormSubmission = async () => {
        if (formSubmissionId) {
            FormsService.getFormSubmission(formSubmissionId)
                    .then(handleGetFormSubmissionResponse)
                    .catch(handleError);
        }
    }

    const latestFormDefinitionLabel = (formName: string) => {
        return (
            <p>
                This is not the latest version of this form definition. Click {` `}
                <KatLink label="here"
                    href={`#${HeaderRoute.FORMS_SUBMIT}/${formName}${searchParameters.searchParamsString}`}>
                </KatLink> 
                {` `} to got to the latest version.
            </p>
        )
    }

    // Api calls handlers
    const handleGetFormResponse = (response?: FormDefinitionResponse) => {
        if (!FormsService.isSuccessful(response)) {
            handleError(`Form with id ${formIdOrName} not found`);
            
        } else {
            const _formDefinition = response?.body?.formDefinition;
            if (_formDefinition) {
                setFormDefinition(_formDefinition);
                
                if (formSubmissionId || _formDefinition.status === FormDefinitionStatus.Active) {
                    setErrorMessage(undefined);
                } else {
                    setErrorMessage(latestFormDefinitionLabel(_formDefinition.formName));
                    
                }
                setLoading(false);
            }
            
        }
    };

    const handleGetFormSubmissionResponse = (response?: FormSubmissionResponse) => {
        if (FormsService.isSuccessful(response) && response?.body?.id) {
            const submission = response?.body;
            setFormSubmissionData(submission);
            const _formDefinition = submission.formDefinition;
            if (_formDefinition)
                setFormDefinition(_formDefinition);
            
            setInitialFormResponses({responses: JSON.parse(submission.responses)});
            setErrorMessage(undefined);
            setLoading(false);
        } else {
            handleError(`Form with id ${formSubmissionId} not found`);
        }
    };

    /**
     * This processes the extensions for the particular case for Form Submission.
     * Visitors 'visit'  each extension in the form definition. There can be any amount and types of visitors;
     * each will decide whether to act upon the extension or not.
     * @param response
     */
    const processExtensions = (response: FormSubmissionResponse) => {
        // Visitable extensions
        const visitableExtensions = AsVisitableExtensionArray(formDefinition?.extensions as Extension[]);

        // Create visitors for submit form
        const formState: FormState = { responses: JSON.parse(response?.body?.responses || '') };
        const actionVisitor = new CompleteAuditAction(formState);
        // add more visitors here...

        // Register visitors and let them visit
        const submittedFormVisitors: ExtensionVisitorsRegistry = new ExtensionVisitorsRegistry(false);
        submittedFormVisitors.registerVisitor(actionVisitor);
        submittedFormVisitors.visit(visitableExtensions);
    }

    const sendMetric = (
        customMessages: ResponseCustomMessage[] = [],
        toggleBannerVisibleProps: any = {},
        status: MetricStatus,
        actionName: ActionName) => {
        const additionalMetrics: Axiom.AdditionalMetric[] = [
            { name: "customMessages", value: JSON.stringify(customMessages).slice(0, 255) },
            { name: "header", value: toggleBannerVisibleProps?.header || '' },
            { name: "description", value: toggleBannerVisibleProps?.description || '' },
            { name: "formDefinitionName", value: formDefinition?.formName || '' },
            { name: "isHeadless", value: layout.isHeadlessMode ? "true" : "false"}
        ];
        AxiomMetricsDriver.publishComponentLoadError(
            COMPONENT_NAME + actionName,
            status, additionalMetrics);
    }

    const notifyFormSubmissionToParent = (result: string, response: FormSubmissionResponse | FormSubmissionUpdateResponse) => {
        parent.postMessage({
            type: FORM_SUBMITTED_EVENT_TYPE,
            result,
            submissionId: response?.body?.id,
            responses: response?.body?.responses
        }, "*");
    }

    const handleResponse = (response?: FormSubmissionResponse | FormSubmissionUpdateResponse, isUpdate = false) => {
        const metricActionName = isUpdate ? '.AfterUpdate' : '.AfterSubmission';
        const headerAction =  isUpdate ? 'updated' : 'submitted';

        if (FormsService.isSuccessful(response)) {
            notifyFormSubmissionToParent('SUCCESS', response!);

            processExtensions(response as FormSubmissionResponse);

            history.push(HeaderRoute.FORMS);
            const pathToSubmission = `/forms/view/${response?.body?.id}`;
            if (layout.isHeadlessMode) {
                const toggleBannerVisibleProps: ToggleBannerVisibleProps = {
                    header: `Form ${headerAction} successfully`,
                    description: "You can check your submission in the Axiom forms log",
                    variant: "success"
                };
                sendMetric(
                    undefined,
                    toggleBannerVisibleProps,
                    MetricStatus.Success,
                    metricActionName
                );
                // redirect to the read-only view of the submission
                toggleBannerVisible(toggleBannerVisibleProps);
                history.push({
                    pathname: pathToSubmission,
                    search: '?' + UrlParameterConfigKeys.HEADLESS_MODE
                })
            } else {
                const toggleBannerVisibleProps: ToggleBannerVisibleProps = {
                    header: `Form ${headerAction} successfully`,
                    description: "You can check your submission in the forms log or ",
                    linkHref: '#' + pathToSubmission,
                    linkLabel: "click here to view submission",
                    variant: "success"
                };
                sendMetric(
                    undefined,
                    toggleBannerVisibleProps,
                    MetricStatus.Success,
                    metricActionName
                );
                // redirect to forms log
                toggleBannerVisible(toggleBannerVisibleProps);
                history.push(HeaderRoute.FORMS);
            }
        } else if (FormsService.isPartiallySuccessful(response)) {
            notifyFormSubmissionToParent('SUCCESS_WITH_ERRORS', response!);

            processExtensions(response as FormSubmissionResponse);

            const customMessages: CustomMessages = JSON.parse(response?.message || '') || [];
            const toggleBannerVisibleProps: ToggleBannerVisibleProps = {
                header: `Form ${headerAction} successfully with some errors`,
                description: `The form was ${headerAction} successfully, but some extended logic failed to process.`,
                linkHref: `#/forms/view/${response?.body?.id}`,
                linkLabel: "Click here to view submission",
                variant: "warning",
                extraContent: <ExtensionErrorsDisplay customMessages={customMessages}/>
            };
            sendMetric(
                customMessages,
                toggleBannerVisibleProps,
                MetricStatus.Failure,
                metricActionName
            );
            toggleBannerVisible(toggleBannerVisibleProps);
            history.push(HeaderRoute.FORMS);
        } else {
            sendMetric(
                undefined,
                undefined,
                MetricStatus.Failure,
                metricActionName
            );
            handleError(`Error ${isUpdate ? 'updating' : 'submitting'} form:
             [${response!.statusCode}]
             [${response!.message}]
             [${JSON.stringify(response!.body, null, 2)}]`);
        }
    };

    const handleSubmit = useCallback((responses: FormResponses) => {
        setFormSubmissionStatus(FormSubmissionStatus.SUBMITTED);
        setFormSubmission(responses);
    }, []);

    const handleSubmitDraft = useCallback((responses: FormResponses) => {
        setFormSubmissionStatus(FormSubmissionStatus.DRAFT);
        setFormSubmission(responses);
    }, []);

    const handleDiscardDraft = useCallback((responses: FormResponses) => {
        setFormSubmissionStatus(FormSubmissionStatus.DISCARDED)
        setFormSubmission(responses);
    }, []);

    const buildUnauthorizedMessage = (message: string) => {
        const bindleLabel = message.substring(message.indexOf('bindle: '))
        const parsedLabel = parseMetadataLabel('unauthorizedFormDefinition', bindleLabel);
        return <>You are not authorized to use form <b>{formIdOrName}.</b>
            <div>Contact the bindle owner to request access. {parsedLabel}</div>
        </>;
    }

    const buildNotFoundMessage = () => (
        <>Form <b>{formIdOrName}</b> was not found.</>
    )

    const handleError = (responseError: any) => {
        setLoading(false);
        // tslint:disable-next-line:no-console
        console.error(responseError);

        if (responseError!.data!.getLatestFormDefinition
            && FormsService.isUnauthorized(responseError.data.getLatestFormDefinition)) {
            const unauthorizedMessage = buildUnauthorizedMessage(responseError.data.getLatestFormDefinition.message);
            setErrorMessage(unauthorizedMessage);
        } else if (FormsService.isNotFound(responseError.data.getLatestFormDefinition)) {
            setErrorMessage(buildNotFoundMessage());
        } else {
            setErrorMessage(typeof responseError === 'string' ? responseError : JSON.stringify(responseError));
        }
    };

    // UI elements
    const LoadingSpinner = () => <>{
        isLoading &&
        <div className='row justify-content-center'><KatSpinner size='large'/></div>
    }</>;

    const ErrorBanner = () => <>{
        errorMessage &&
        <KatAlert title='Error loading form' variant='danger' persistent>
            {errorMessage}
        </KatAlert>
    }</>;

    const ExtensionErrorsDisplay = (props: { customMessages: ResponseCustomMessage[] }) => {
        return <div className='row align-items-center'>
            <div className='col-9'>
                <KatExpander label='Show errors' className='d-block'>
                    <ul>
                        {props.customMessages.map((message, index) => (
                            <li key={index}>
                                <strong>{message.title}.</strong> {message.details}
                                <small> - Exception: {message.stack_trace}</small>
                            </li>
                        ))}
                    </ul>
                </KatExpander>
            </div>
        </div>;
    }

    const defineReadOnly = () => {
        // This logic allows to use FormSubmission in submit, view and edit paths
        if (!formSubmissionId && formDefinition?.status != FormDefinitionStatus.Active) {
            return true;
        }
        
        if (!formSubmissionId) {
            return false;
        }

        if (formSubmissionData?.formSubmissionStatus == FormSubmissionStatus.DRAFT) {
            return false;
        }

        return readonly;
    }

    return (
        <AxiomFormsWebInterfaceContainer>
            <div className='d-flex justify-content-center'>
            <div className={`flex-column ${layout.isHeadlessMode ? 'w-100' : 'w-75'}`}>
                <div className='p-3 d-flex flex-column'>
                    {/* New submissions dropdown component is commented here until use case is well defined
                        as part of the migration from Quality Hub to Axiom Forms */}
                    {/*{layout.isHeadlessMode && <div className='pb-2'>*/}
                    {/*    <NewSubmissionButton/>*/}
                    {/*</div>}*/}
                    <ErrorBanner/>
                    <LoadingSpinner/>
                    {formDefinition && <FormDisplay formDefinition={formDefinition}
                                                    initialFormResponses={initialFormResponses}
                                                    readOnly={defineReadOnly()}
                                                    onSubmit={handleSubmit}
                                                    staticData={staticData}
                                                    onSubmitDraft={handleSubmitDraft}
                                                    onDiscardDraft={handleDiscardDraft}
                                                    formSubmission={formSubmissionData}
                    />}                             
                    </div>
                </div>
            </div>
        </AxiomFormsWebInterfaceContainer>
    );
};

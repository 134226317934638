import React, { useEffect } from 'react';
import './Forms.scss';
import { KatTabs } from '@amzn/katal-react';
import { AxiomMetricsDriver } from "src/metrics/AxiomMetricsDriver";
import { AxiomIsItDown } from "src/components/common/AxiomIsItDown";
import { FormSubmissionTableContainer } from "src/components/forms/submissions_table/FormSubmissionTableContainer";
import { AXIOM_FORMS_PREFIX } from "src/components/forms/constants/FormConstants";
import { FormsService } from "src/service/Forms.service";
import { Tab, TabData, TabsSelector } from "src/components/common/tabsSelector/TabsSelector";
import { useStateWithSearchParams } from "src/components/common/searchParameters/useStateWithSearchParams";
import { UrlParameterConfigKeys } from "src/config/ConfigManager";
import { DateRange } from './date_range_picker/FormsDateRangePicker';
import { FormSubmissionsResponse } from 'src/service/amplify/appsync';

const PAGE_NAME = 'FormsLog';
const COMPONENT_NAME = AXIOM_FORMS_PREFIX + PAGE_NAME;


export enum FormsLogTab {
    LOG = 'log-tab',
    SHARED_WITH_ME = 'shared-with-me-tab',
    DRAFTS_TAB = 'drafts-tab'
}

const FormsLogTabs: Record<FormsLogTab, TabData> = {
    [FormsLogTab.LOG]: {
        name: FormsLogTab.LOG,
        label: 'Log'
    },
    [FormsLogTab.SHARED_WITH_ME]: {
        name: FormsLogTab.SHARED_WITH_ME,
        label: 'Shared with me'
    },
    [FormsLogTab.DRAFTS_TAB]: {
        name: FormsLogTab.DRAFTS_TAB,
        label: 'Drafts'
    }
};

const parseTabDataFromUrl = (urlParamString: string): TabData => {
    try {
        const tabObj = JSON.parse(decodeURIComponent(urlParamString));
        return {
            name: tabObj.name,
            label: tabObj.label,
            unseen: tabObj.unseen,
        };
    } catch (e) {
        // tslint:disable-next-line:no-console
        console.error('Failed to parse tab data from URL parameter', e);
        return FormsLogTabs[FormsLogTab.LOG];
    }
};

export const FormsLog: React.FC = () => {

    const logTab: TabData = FormsLogTabs[FormsLogTab.LOG];
    const sharedTab: TabData = FormsLogTabs[FormsLogTab.SHARED_WITH_ME];
    const draftsTab: TabData = FormsLogTabs[FormsLogTab.DRAFTS_TAB];

    const [currentTab, setCurrentTab] = useStateWithSearchParams(UrlParameterConfigKeys.FORMS_LOG_TAB, JSON.stringify(logTab));


    // Publish page load metrics
    useEffect(() => {
        document.title = `${SITE_NAME} - ${PAGE_NAME}`;
        AxiomMetricsDriver.publishPageLoad(COMPONENT_NAME, window.location.href);
    }, []);


    const handleTabChange = (evt: KatTabs.ChangeEvent) => {
        const newSelectedTab = FormsLogTabs[evt.detail.selected as FormsLogTab];
        if (newSelectedTab) {
            AxiomMetricsDriver.publishButtonClick(COMPONENT_NAME, newSelectedTab.name);
            setCurrentTab(JSON.stringify(newSelectedTab));
        }
    };

    const isTabSelected = (tabName: string) => {
        const parsedTabData = parseTabDataFromUrl(currentTab || '');
        return parsedTabData?.name === tabName as FormsLogTab;
    }

    type TabFunctionMap = Record<FormsLogTab, (userLogin: string, dateRange: DateRange) => Promise<FormSubmissionsResponse | undefined>>;

    const tabFunctions: TabFunctionMap = {
        [FormsLogTab.LOG]: FormsService.getSubmittedFormsBySubmitter,
        [FormsLogTab.SHARED_WITH_ME]: FormsService.getSubmissionsSharedWithUser,
        [FormsLogTab.DRAFTS_TAB]: FormsService.getDraftSubmissionsBySubmitter,
    };
    
    // Inside the component
    const fetchSubmissionOperation = tabFunctions[parseTabDataFromUrl(currentTab || '')?.name as FormsLogTab] || FormsService.getSubmissionsBySubmitter;    

    return (
        <>
            <AxiomIsItDown componentId={PAGE_NAME} />
            <div className='p-3 h-100 w-100 d-flex flex-column'>
                <div className='pb-5'>
                    <TabsSelector
                        className={"forms-log-tabs"}
                        onChange={handleTabChange}
                        currentTab={parseTabDataFromUrl(currentTab || '')}
                        tabs={[
                            <Tab tab={logTab} isTabSelected={isTabSelected} />,
                            <Tab tab={sharedTab} isTabSelected={isTabSelected} />,
                            <Tab tab={draftsTab} isTabSelected={isTabSelected} />
                        ]} />
                    <FormSubmissionTableContainer fetchSubmissionOperation={
                        fetchSubmissionOperation
                    } />
                </div>
            </div>
        </>
    );
};

import React from "react";
import { KatIcon, KatPopover } from "@amzn/katal-react";
import ShareItem from "src/components/share_item/ShareItem";
import { Entity } from "src/service/amplify/appsync";
import { FormsService } from "src/service/Forms.service";
import { CerebrumService } from "src/service/CerebrumCoralService";

type ShareFormProps = {
    itemId: string;
    sharedWith?: (Entity | null)[] | null | undefined;
};

export default function ShareForm(props: ShareFormProps) {
    const { itemId, sharedWith } = props;


    const addEntityHandlder = async (itemId: string, entityId: string) => {
        return CerebrumService.getUserDetails(entityId)
            .then(_details => FormsService.shareFormSubmission(itemId, entityId))
            .catch(handleError(entityId));
    };
    const removeEntityHandlder = async (itemId: string, entityId: string) => {
        return FormsService.unShareFormSubmission(itemId, entityId)
    };

    const handleError = (entityId: string) => ((error: any) => {
        if (error.__type === 'com.amazon.wfmaxiomcerebrumservice#InvalidUserException') {
            throw Error(`"${entityId}" is not a valid Axiom user`)
        } else {
            throw error
        }
    })

    return (
        <KatPopover position="auto" className='pr-3' title='Share'>
            <KatIcon name="share" size="small" slot="trigger"></KatIcon>
            <div style={{ width: "20rem" }}>
                <ShareItem
                    sharedWith={sharedWith as Entity[]}
                    itemId={itemId}
                    addEntityHandlder={addEntityHandlder}
                    removeEntityHandlder={removeEntityHandlder}
                />
            </div>
        </KatPopover>
    );
}

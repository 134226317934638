import { FormState, Tuple } from '@amzn/wfm-axiom-forms-renderer-stencil/dist/types/model/Forms';
import { ExtensionVisitor, METADATA_RESPONSE_PREFIX } from 'src/components/forms/extensions/ExtensionVisitor';
import { VisitableExtension } from 'src/components/forms/extensions/VisitableExtension';
import { buildMetadataLabelUntyped } from 'src/components/common/MetadataLabel';


/**
 * An ExtensionVisitor that will look for extensions of type target_item.
 *
 *  When the conditions are met, a target item string will be generated.
 */
export class TargetItemVisitor implements ExtensionVisitor<string> {

    static readonly TARGET_ITEM_TYPE = 'target_item';
    static readonly TYPE_KEY = 'type';

    private readonly formState: FormState;
    private targetItemString: string = '';

    constructor(formState: FormState) {
        this.formState = formState;
    }

    visit = (extension: VisitableExtension): string => {
        this.targetItemString = '';
        if (extension.type === TargetItemVisitor.TARGET_ITEM_TYPE && extension.content) {
            extension.content.forEach(
                tuple => this.processContent(extension.id, tuple)
            );
        }
        return this.targetItemString;
    };

    getResult = () => this.targetItemString;

    private processContent = (extensionId: string, content: Tuple | null) => {
        if (content) {
            // extract key
            if (content.key === TargetItemVisitor.TYPE_KEY) {
                const metadataKey = content.value;
                const responseId = METADATA_RESPONSE_PREFIX + extensionId;
                const metadataValue = this.formState.responses[responseId];
                if (metadataValue) {
                    this.targetItemString = buildMetadataLabelUntyped(metadataKey, metadataValue);
                }
            }
        }
    };
}
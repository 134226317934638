import React from 'react';
import { KatCard, KatIcon, KatInput, KatInputGroup } from '@amzn/katal-react';
import RecommendationTable from "src/components/balanced_scorecard/recommendation_card/RecommendationTable";
import { CerebrumService } from "src/service/CerebrumCoralService";
import { AxiomMetricsDriver, MetricStatus } from "src/metrics/AxiomMetricsDriver";
import i18n from 'i18next';
import { ErrorBoundComponent, ErrorBoundState } from "src/components/error/ErrorBoundComponent";
import { DEFAULT_VERSION_IDS, filterNonBscMetrics } from 'src/components/balanced_scorecard/BSC';
import { UserContext } from 'src/context/UserContext';

interface RecommendationCardProps {
    viewUserDetails: WFM.GetUserDetails;
    selectedFilters: Axiom.SelectionFilter[];
}

interface RecommendationCardState extends ErrorBoundState {
    recommendations?: Cerebrum.RecommendationTableData[];
    searchTerm?: string;
}

export default class RecommendationCard extends ErrorBoundComponent<RecommendationCardProps, RecommendationCardState> {
    static contextType = UserContext;
    context!: React.ContextType<typeof UserContext>;

    private componentName: string = "Recommendations";
    state: RecommendationCardState = {
        hasError: false,
        errorTitle: 'wfm_axiom_v2_recommendations_error_title',
    };

    constructor(props: RecommendationCardProps) {
        super(props);
        this.errorMetricHandler = this.loadComponentErrorMetricHandler.bind(this);
        this.searchHandler = this.searchHandler.bind(this);
    }

    public async componentDidMount() {
        await this.getRecommendations();
    }

    async getRecommendations() {
        try {
            let recommendationsData: Cerebrum.RecommendationTableData[] = [];
            if (this.props.viewUserDetails.supervisor) {
                const response = await CerebrumService.listSupervisorRecommendations(
                    this.props.viewUserDetails.login,
                    { versionId: DEFAULT_VERSION_IDS.RECS }
                );
                // Flatten response and add additional information for the table
                for (let recommendation of response.recommendations) {
                    for (let agentRecommendation of recommendation.recommendations) {
                        recommendationsData.push({
                            agentId: recommendation.agentId,
                            supervisorId: response.supervisorId,
                            versionId: response.versionId,
                            ...agentRecommendation
                        });
                    }
                }
            } else {
                const response = await CerebrumService.listAgentRecommendations(
                    this.props.viewUserDetails.login,
                    { versionId: DEFAULT_VERSION_IDS.RECS }
                );
                for (let recommendation of response.recommendations) {
                    recommendationsData.push({
                        agentId: response.agentId,
                        supervisorId: response.supervisorId,
                        versionId: response.versionId,
                        ...recommendation
                    });
                }
            }
            recommendationsData = recommendationsData.filter(recommendation => filterNonBscMetrics(recommendation.metric));
            this.setState({ recommendations: recommendationsData });
        } catch (error: any) {
            this.handleError(error);
        }
    }

    render() {
        return (
            <div className="row mt-2 mb-5">
                <div className="col-md-12">
                    <KatCard title={i18n.t('wfm_axiom_v2_recommendations')}>
                        <KatInputGroup slot="action">
                            <KatIcon name="search" />
                            <KatInput placeholder={i18n.t('wfm_axiom_v2_search')} type="search" value={this.state.searchTerm} onInput={this.searchHandler} />
                        </KatInputGroup>
                        <this.renderRecommendations />
                    </KatCard>
                </div>
            </div>
        );
    }

    private searchHandler(event: any) {
        this.setState({ searchTerm: event.target.value || "" });
    }

    private renderRecommendations = () => {
        let recommendations = this.renderError();
        if (!recommendations) {
            recommendations = (
                <RecommendationTable
                    selectionFilters={this.props.selectedFilters}
                    recommendations={this.state.recommendations || []}
                    isSupervisor={this.props.viewUserDetails.supervisor}
                    searchTerm={this.state.searchTerm}
                />
            );
        }
        return recommendations;
    };


    /*
    * Event Handlers
    * */
    private loadComponentErrorMetricHandler() {
        AxiomMetricsDriver.publishComponentLoadError(this.componentName, MetricStatus.Failure);
    }
}
import React from 'react';
import { dateToAmazonWeekAsWW } from '../common/AmazonWeekCalculator';
import { WeekSelector } from './selector/WeekSelector';
import { AuditTypeSelector } from './selector/AuditTypeSelector';

interface AuditHeaderProps {
    auditor: string;
    selectedAuditType: Cerebrum.Audits.AuditType;
    selectedTimeFrame: Cerebrum.Audits.TimeFrame;
    timeFrameSelectorDisabled: boolean;
    changeWeekIndexHandler: (weekIndexForTimeFrame: string) => void;
    weekIndex: string;
    changeAuditTypeHandler: (auditType: Cerebrum.Audits.AuditType) => void;
}

/**
 * The Audit header give context to the user about the data currently displayed on the page
 *
 * any data selectors should be within this component
*/
export function AuditHeader(props: AuditHeaderProps) {
    const { auditor, selectedTimeFrame, selectedAuditType, timeFrameSelectorDisabled, weekIndex, changeWeekIndexHandler, changeAuditTypeHandler } = props;

    return (
        <div className="d-flex justify-content-between align-items-top mb-2">
            <div className="d-flex align-items-center">
                <AuditTypeSelector selectedAuditType={selectedAuditType} onChangeHandler={changeAuditTypeHandler} auditor={auditor}/>
                <AuditTitle auditor={auditor} selectedTimeFrame={selectedTimeFrame} />
            </div>
            <WeekSelector
                disabled={timeFrameSelectorDisabled}
                onChangeHandler={changeWeekIndexHandler}
                selectedAuditType={selectedAuditType}
                weekIndex={weekIndex}
            />
        </div>
    );
}


function AuditTitle(props: { auditor: string; selectedTimeFrame: Cerebrum.Audits.TimeFrame; }) {
    const { auditor, selectedTimeFrame } = props;
    const auditWeek = dateToAmazonWeekAsWW(new Date(selectedTimeFrame.toDate));
    return (
        <h1 className="mb-0 ml-3">
            <b>Reviews for <span className="text-eastern">@{auditor}</span>: Week {auditWeek}</b>
        </h1>
    );
}




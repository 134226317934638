import React from "react";
import { KatIcon, KatLink } from "@amzn/katal-react";
import { toAbsoluteHelpUri } from "src/components/support/HelpContentHelper";
import { useHelpContextualLink } from "src/components/support/HelpContextualLink";
import { AxiomMetricsDriver } from "src/metrics/AxiomMetricsDriver";

enum HelpContentUltraLinkType {
    SIDEBAR = 'SIDEBAR',
    HELP_PAGE = 'HELP-PAGE'
}

interface HelpContentUltraLinkProps {
    children: any;
    href?: any;
    isSidebar?: boolean;
}

const COMPONENT_NAME = 'HelpUltraLink';

/**
 * Curried function that will customize the rendering of links based on whether it is rendered in the help sidebar
 * or in the main help page.
 *
 * Returned function will work as a replacement of html links (`a` tags) in ReactMarkdown custom components.
 *
 * @param isSidebar When true, it will add an icon after links pointing to HELP-PAGE.
 * @constructor
 */
export const HelpContentUltraLink = (isSidebar?: boolean) => (props: HelpContentUltraLinkProps) => {

    const { href } = props;

    const setContextualLink = useHelpContextualLink();

    const makeAbsoluteLinkAndOpenIt = (target: string, isAuxClick?: boolean) => {
        const absoluteTarget = toAbsoluteHelpUri(target);
        if (isAuxClick) {
            window.open(absoluteTarget); // open in new tab
        } else {
            window.location.href = absoluteTarget; // change location in current tab
        }
    };

    const linkProcessors: Record<HelpContentUltraLinkType, (target: string, isAuxClick?: boolean) => void> = {
        [HelpContentUltraLinkType.SIDEBAR]: (target) => {
            setContextualLink(target);
        },
        [HelpContentUltraLinkType.HELP_PAGE]: makeAbsoluteLinkAndOpenIt
    };

    const matchPattern = (ultralink: HelpContentUltraLinkType, targetUrl: string) => {
        // capture from the respective HelpUltralinkType value and a slash, until optionally the next quote
        const pattern = `(${ultralink}[\\\\\\/])([\\w\\\\\\/\\-\\.\\s]+)(?=['"]?)`;
        const regex = new RegExp(pattern);
        return targetUrl.match(regex);
    };

    const publishMetric = (targetUrl: string, isAuxClick?: boolean) => {
        const additionalMetrics = [
            { name: 'isAuxClick', value: (!!isAuxClick).toString() },
            { name: 'renderer', value: isSidebar ? 'sidebar' : 'help-page' }
        ];
        AxiomMetricsDriver.publishLinkClick(COMPONENT_NAME, '', targetUrl, additionalMetrics);
    };

    const processLink = (targetUrl: string, isAuxClick?: boolean) => {
        publishMetric(targetUrl, isAuxClick);
        for (const ultraLinkType in HelpContentUltraLinkType) {
            if (isNaN(Number(ultraLinkType))) {
                const ultralinkValue = HelpContentUltraLinkType[ultraLinkType as keyof typeof HelpContentUltraLinkType];
                const parts = matchPattern(ultralinkValue, targetUrl);
                // second part will be the path after the HelpUltraLinkType wildcard
                if (parts && parts[2]) {
                    return linkProcessors[ultralinkValue](parts[2], isAuxClick);
                }
            }
        }
        // it is not an Ultralink
        makeAbsoluteLinkAndOpenIt(targetUrl, isAuxClick);
    };

    const isLinkToHelpPage = () => matchPattern(HelpContentUltraLinkType.HELP_PAGE, href);

    return (
        <KatLink className='help-link'
                 onClick={(evt) => {
                     evt.preventDefault();
                     processLink(href);
                 }}
                 href={''}
            /* @ts-ignore */
                 onAuxClick={(evt) => {
                     evt.preventDefault();
                     processLink(href, true);
                 }}>
            {props.children}
            {isSidebar && isLinkToHelpPage() && <KatIcon className='pl-1' name='open_in_new' size='tiny'/>}
        </KatLink>
    );
};
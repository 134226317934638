import { METADATA_TYPE_KEY } from 'src/components/forms/constants/FormConstants';
import { FormState, Tuple } from '@amzn/wfm-axiom-forms-renderer-stencil/dist/types/model/Forms';
import { ExtensionVisitor, METADATA_RESPONSE_PREFIX } from 'src/components/forms/extensions/ExtensionVisitor';
import { VisitableExtension } from 'src/components/forms/extensions/VisitableExtension';
import { buildMetadataLabelUntyped } from 'src/components/common/MetadataLabel';


/**
 * An ExtensionProcessor that will look for metadata extensions
 *  (i.e.: these with type matching FormConstants.METADATA_TYPE_KEY)
 *  with a key matching TYPE ('type').
 *
 *  When the conditions are met, a metadata string will be generated.
 */
export class TypeMetadataVisitor implements ExtensionVisitor<string> {

    static readonly TYPE_KEY = 'type';

    private readonly formState: FormState;
    private metadataString: string = '';

    constructor(formState: FormState) {
        this.formState = formState;
    }

    visit = (extension: VisitableExtension): string => {
        this.metadataString = '';
        if (extension.type === METADATA_TYPE_KEY && extension.content) {
            extension.content.forEach(
                tuple => this.processContent(extension.id, tuple)
            );
        }
        return this.metadataString;
    };

    getResult = () => this.metadataString;

    private processContent = (extensionId: string, content: Tuple | null) => {
        if (content) {
            // extract key
            if (content.key === TypeMetadataVisitor.TYPE_KEY) {
                const metadataKey = content.value;
                const responseId = METADATA_RESPONSE_PREFIX + extensionId;
                const metadataValue = this.formState.responses[responseId];
                if (metadataValue) {
                    this.metadataString = buildMetadataLabelUntyped(metadataKey, metadataValue);
                }
            }
        }
    };
}
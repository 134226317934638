import React from 'react'
import { UserContext } from 'src/context/UserContext';

interface SplitUserGroupComponentProps {
    inHouseElement: JSX.Element;
    outSourceElement: JSX.Element;
    /**
     * fallbackElement will show if the user is not authenticated
     */
    fallbackElement?: JSX.Element;
}

export default function SplitUserGroupComponent(props: SplitUserGroupComponentProps) {
    const { inHouseElement, outSourceElement, fallbackElement } = props;
    try {
        const isOutSourced = React.useContext(UserContext).userManager.isOutSourced;
        return isOutSourced ? outSourceElement : inHouseElement;
    } catch (error) {
        console.error(error);
        return fallbackElement || <></>;
    }
}

import React, { FunctionComponent } from "react";
import i18n from "i18next";
import { ErrorMessage } from "src/components/error/ErrorMessage";
import { ComponentID, ComponentRightsManager } from "../authorization/ComponentRightsMapping";
import { UserManager } from "src/user/UserIdentityManager";

const OUTSOURCE_ONLY_MESSAGE = "This component is only offered to Outsource Partner users";
const INHOUSE_ONLY_MESSAGE = "This component is not currently offered to Outsource Partner users";

interface NoAccessErrorMessageProps {
    componentId: ComponentID;
}

export const NoAccessErrorMessage: FunctionComponent<NoAccessErrorMessageProps> = (props) => {
    const { componentId } = props;

    const user = UserManager.getUserIdentity();
    const supportPermissionsLink = `#/support/Permissions`;

    // Check if component is available for user group
    const isComponentForInHouse = ComponentRightsManager.isComponentForInHouse(componentId);
    const isComponentForOutsource = ComponentRightsManager.isComponentForOutsource(componentId);

    let incorrectUserGroupMessage: string = '';
    if (isComponentForInHouse && user.outsourced) { incorrectUserGroupMessage = INHOUSE_ONLY_MESSAGE; }
    if (isComponentForOutsource && !user.outsourced) { incorrectUserGroupMessage = OUTSOURCE_ONLY_MESSAGE; }

    // Check if user has necessary rights
    const componentRights = ComponentRightsManager.getRightsForComponent(componentId);
    const hasAccessRightsForComponent = ComponentRightsManager.userHasAccessRightsForComponent(componentId);

    let needsRightsMessage: string = '';
    if (!hasAccessRightsForComponent) { needsRightsMessage = i18n.t("wfm_axiom_no_access_alert_body", { 0: componentRights }); }

    const message = incorrectUserGroupMessage || needsRightsMessage || 'Reason for access restriction could not be determined.';

    return (
        <ErrorMessage
            title={i18n.t("wfm_axiom_no_access_alert_title")}
            message={message}
            helpLink={supportPermissionsLink}
        />
    );
};
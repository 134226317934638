import { VisitableExtension } from './VisitableExtension';

/**
 * A Visitor for Extensions in terms of the Visitor design pattern.
 *
 * Implementations of this interface will perform operations over
 * the list of extensions in a form definition.
 *
 * This interface could be extended to support multiple versions of
 * visit based on the VisitableExtension type at runtime.
 *
 * ExtensionVisitors can be grouped in a ExtensionVisitorsRegistry.
 */
export interface ExtensionVisitor<T> {

    visit(extension: VisitableExtension): T;

    getResult: () => T;
}

/**
 * Used as a prefix for metadata related responses in FormState
 */
export const METADATA_RESPONSE_PREFIX = 'md_';


/**
 * Used as a prefix for action related responses in FormState
 */
export const ACTION_RESPONSE_PREFIX = 'action_';

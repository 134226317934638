import { DropdownButton } from "react-bootstrap";
import { KatIcon } from "@amzn/katal-react";
import React from "react";

/**
 * Properties for CardOptionsMenu
 */
export interface CardOptionsMenuProps {
    /** When set as true it will use a horizontal three dots icon, which will be vertical by default */
    horizontalIcon?: boolean;
    /** Children nodes to be rendered as menu options */
    children?: React.ReactNode;
}

/**
 * A dropdown options menu with tree dots icon to be used in cards with custom children.
 * @param props CardOptionsMenuProps with children and optionally a flag to have a horizontal icon
 * @constructor
 */
export const CardOptionsMenu: React.FC<CardOptionsMenuProps> = (props) => {
    const { horizontalIcon } = props;
    const iconName = horizontalIcon ? 'more_horiz' : 'more_vert';
    return (

        <DropdownButton
            id="card-action-menu"
            className={'card-options-menu'}
            title={<KatIcon name={iconName} size="small"/>}
            alignRight={true}>
            <>{props.children}</>
        </DropdownButton>);
}
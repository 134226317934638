import React, { useEffect, useRef, useState } from "react";
import { useHighlighter } from "src/components/common/useHighlighter";
import { CopyToClipboardButton } from "src/components/common/CopyToClipboardButton";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { KatButton, KatPopover } from "@amzn/katal-react";
import { UserRestrictedComponent } from "src/components/authorization/UserRestrictedComponent";
import { ComponentID } from "src/components/authorization/ComponentRightsMapping";
import { HeaderRoute } from "src/components/common/header/HeaderRoute";

interface HelpContentUltraHeaderProps {
    children: any;
    level?: number;
}

/**
 * Component to augment functionality of help content headings so that they can be highlighted based on url parameters
 *
 * Returned function will work as a replacement of html links (`h<n>` tags) in ReactMarkdown custom components.
 */
export const HelpContentUltraHeader = (props: HelpContentUltraHeaderProps) => {

    const paramKey = 'title';

    // this could be enhanced or use a library if we need smarter or nicer titles
    const slugify = (text: string) => typeof text !== 'string' ? '' :
        text.toLowerCase()
            .replace(/ /g, '-')
            .replace(/[^\w-]+/g, '');

    const [title, setTitle] = useState(() => props.children[0]);
    const [titleSlug, setTitleSlug] = useState(() => slugify(props.children[0]));

    const [hovering, setHovering] = useState(false);

    const location = useLocation();

    const headerRef = useRef(null);
    useHighlighter(paramKey, titleSlug, headerRef);

    useEffect(() => {
        // considering only first child of the heading, this could be extended to consider more complex references as needed
        setTitle(props.children[0]);
        setTitleSlug(slugify(props.children[0]));
    }, [props.children]);

    const buildSearchString = () => {
        const queryValues = queryString.parse(location.search);
        queryValues[paramKey] = titleSlug;
        return `?${queryString.stringify(queryValues)}`;
    }

    const buildDeepLink = () => {
        const searchString = buildSearchString();
        return location.search ?
            window.location.href.replace(location.search, searchString) :
            window.location.href + searchString;
    }

    const getHelpContentIdFromCurrentUrl = () => {
        const url = window.location.href; // e.g.: /#/help or /#/help/Links or /#/help/Links?title=something
        const startOfHelpPath = url.indexOf(HeaderRoute.HELP);
        const helpContentIdAndQueryString = url.substr(startOfHelpPath).replace(HeaderRoute.HELP, '');
        const indexOfQuestionMark = helpContentIdAndQueryString.indexOf('?');
        const documentId = indexOfQuestionMark === -1 ?
            helpContentIdAndQueryString :
            helpContentIdAndQueryString.substr(0, indexOfQuestionMark);
        return documentId.startsWith('/') ?
            documentId.substr(1) : // remove leading / if present
            documentId;
    }

    const buildMarkdownLink = () => {
        const helpContentId = getHelpContentIdFromCurrentUrl();
        const searchString = buildSearchString();
        return `[${title}](${helpContentId}${searchString})`;
    }

    const buildProdLink = (region: string) => {
        const helpContentId = getHelpContentIdFromCurrentUrl();
        const searchString = buildSearchString();
        return `https://${region}.axiom.selling-partners.amazon.dev/#${HeaderRoute.HELP}/${helpContentId}${searchString}`;
    }

    const LinkToolsForAdmins = () => (
        <UserRestrictedComponent componentId={ComponentID.HEADER_ADMIN_RIGHTS_ID}>
            <KatPopover triggerType='click'>
                <KatButton slot="trigger" variant="primary">Link Tools</KatButton>
                <div className={'d-flex flex-column'}>
                    <CopyToClipboardButton label='Markdown relative link'
                                           textToCopyProvider={buildMarkdownLink}/>
                    <CopyToClipboardButton label='NA absolute link'
                                           textToCopyProvider={() => buildProdLink('na')}/>
                    <CopyToClipboardButton label='EU absolute link'
                                           textToCopyProvider={() => buildProdLink('eu')}/>
                    <CopyToClipboardButton label='FE absolute link'
                                           textToCopyProvider={() => buildProdLink('fe')}/>
                </div>

            </KatPopover>
        </UserRestrictedComponent>
    )

    const CopyDeepLinks = () => <>{
        hovering &&
        <>
            <CopyToClipboardButton textToCopyProvider={buildDeepLink} iconName='link'/>
            <LinkToolsForAdmins/>
        </>
    }</>;

    // there has to be a better way to do this for each heading level, though I've spent more time that what I'd like to admit
    // fighting with JSX.IntrinsicProperties and IntrinsicElements without luck
    return <>
        {props.level === 1 && <h1 {...props} ref={headerRef}
                                  onMouseEnter={() => setHovering(true)}
                                  onMouseLeave={() => setHovering(false)}>
            {props.children}
            <CopyDeepLinks/>
        </h1>}
        {props.level === 2 && <h2 {...props} ref={headerRef}
                                  onMouseEnter={() => setHovering(true)}
                                  onMouseLeave={() => setHovering(false)}>
            {props.children}
            <CopyDeepLinks/>
        </h2>}
        {props.level === 3 && <h3 {...props} ref={headerRef}
                                  onMouseEnter={() => setHovering(true)}
                                  onMouseLeave={() => setHovering(false)}>
            {props.children}
            <CopyDeepLinks/>
        </h3>}
        {props.level === 4 && <h4 {...props} ref={headerRef}
                                  onMouseEnter={() => setHovering(true)}
                                  onMouseLeave={() => setHovering(false)}>
            {props.children}
            <CopyDeepLinks/>
        </h4>}
        {props.level === 5 && <h5 {...props} ref={headerRef}
                                  onMouseEnter={() => setHovering(true)}
                                  onMouseLeave={() => setHovering(false)}>
            {props.children}
            <CopyDeepLinks/>
        </h5>}
        {props.level === 6 && <h6 {...props} ref={headerRef}
                                  onMouseEnter={() => setHovering(true)}
                                  onMouseLeave={() => setHovering(false)}>
            {props.children}
            <CopyDeepLinks/>
        </h6>}
    </>;
}
import React from 'react';
import i18n from 'i18next';
import { KatLink } from '@amzn/katal-react';
import { AxiomMetricsDriver } from "src/metrics/AxiomMetricsDriver";
import SplitUserGroupComponent from 'src/components/authorization/SplitUserGroupComponent';
import { HeaderRoute } from "src/components/common/header/HeaderRoute";

// TODO: These will be unique per tenant so we should probably have them stored in a ddb table and passed from Cerebrum
export const SCORING_WIKI_URL = 'https://w.amazon.com/bin/view/Seller_Support/Analytic_Program/Axiom/Documentation/Scoring_Methodology#HMetrics';
export const METRIC_DEFINITIONS_WIKI_URL = 'https://w.amazon.com/bin/view/Seller_Support/Analytic_Program/Axiom/Documentation/Scoring_Methodology#HMetrics';


export function MetricDefinitionMoreInfo() {
    return (
        <SplitUserGroupComponent
            inHouseElement={<InhouseMetricDefinitionMoreInfo />}
            outSourceElement={<OSMetricDefinitionMoreInfo />}
        />
    );
}

const COMPONENT_NAME = 'RadarTable';

function publishLinkClick(linkName: string, linkUrl: string, isAux?: boolean) {
    AxiomMetricsDriver.publishLinkClick(COMPONENT_NAME, linkName, linkUrl, undefined, isAux);
}


/**
 * More info for inhouse users directs to the internal wiki page
 */
function InhouseMetricDefinitionMoreInfo() {
    return (
        <>
            <span>{i18n.t('wfm_axiom_v2_more_info_on_the')} </span>
            <KatLink
                onClick={() => publishLinkClick('metric_definitions_wiki', METRIC_DEFINITIONS_WIKI_URL)}
                // @ts-ignore
                onAuxClick={() => publishLinkClick('metric_definitions_wiki', METRIC_DEFINITIONS_WIKI_URL, true)}
                href={METRIC_DEFINITIONS_WIKI_URL}
                target="_blank"
                label={i18n.t('wfm_axiom_v2_metric_definitions')}>
            </KatLink>
            <span> {i18n.t('wfm_axiom_v2_and')} </span>
            <KatLink
                onClick={() => publishLinkClick('scoring_wiki', SCORING_WIKI_URL)}
                // @ts-ignore
                onAuxClick={() => publishLinkClick('scoring_wiki', SCORING_WIKI_URL, true)}
                href={SCORING_WIKI_URL}
                target="_blank"
                label={i18n.t('wfm_axiom_v2_scoring_calculations')}>
            </KatLink>
        </>
    );
}

/**
 * More info for OS users directs to the in-app help content from Ajuda
 */
function OSMetricDefinitionMoreInfo() {

    const scoringHelpLink = (isAuxClick: boolean) => () => {
        const route = `${HeaderRoute.HELP}/HowBSCscoringworksMethodology`;
        publishLinkClick('scoring_wiki', route, isAuxClick);
        window.open(`#${route}`); // open in new tab
    };

    return (
        <>
            <span>{i18n.t('wfm_axiom_v2_more_info_on_the')} </span>
            <KatLink
                onClick={scoringHelpLink(false)}
                // @ts-ignore
                onAuxClick={scoringHelpLink(true)}
                label={i18n.t('wfm_axiom_v2_scoring_calculations')}>
            </KatLink>
        </>
    );
}

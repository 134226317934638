import { useEffect, useState } from "react";

export interface Grouped<Type> {
    [group: string]: Type[]
}

export interface Grouping<Type> {
    name: string;
    groupDecider: (object: Type) => string | undefined;
}

export function useGrouper<Type>(groupedBy: string,
                                 items: Type[],
                                 groupStrategies: Record<string, Grouping<Type>>): [Grouped<Type>] {

    const [grouped, setGrouped] = useState<Grouped<Type>>({});

    useEffect(() => {
        buildGroups();
    }, [items, groupedBy]);


    const buildGroups = (): void => {
        // Selected grouping defaults to first option always if there is no groupedBy passed in
        const selectedGrouping: Grouping<Type> =
            groupedBy ?
                groupStrategies[groupedBy] :
                groupStrategies[Object.keys(groupStrategies)[0]];
        const groupedObj = calculateGrouping(
            items,
            selectedGrouping.groupDecider
        );
        setGrouped(groupedObj);
    }

    const calculateGrouping = (itemsToGroup: Type[], groupDecider: (object: Type) => string | undefined): Grouped<Type> => {
        const groupedObj: Grouped<Type> = {};
        itemsToGroup.forEach(obj => {
            const group = groupDecider(obj);
            if (group) {
                groupedObj[group] ? groupedObj[group].push(obj) : groupedObj[group] = [obj];
            }
        });
        return groupedObj;
    }

    return [grouped];

}
import { FormDefinition, FormSubmission } from "src/service/amplify/appsync";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { UserContext } from "src/context/UserContext";
import { AxiomMetricsDriver } from "src/metrics/AxiomMetricsDriver";
import { FormsRendererCustomEvent } from "@amzn/wfm-axiom-forms-renderer-stencil";
import { FlashBriefingModal } from "@amzn/wfm-axiom-flash-briefing-ui-components";
import { KatDivider, KatIcon, KatModal, KatLink, KatTooltip, KatPopover, KatButton } from "@amzn/katal-react";
import { FeedbackContainer } from "src/components/flash_briefing/FeedbackCard";
import { CardOptionsMenu } from "src/components/common/CardOptionsMenu";
import DropdownItem from "react-bootstrap/DropdownItem";
import i18n from "i18next";
import { AXIOM_FORMS_PREFIX, FormSubmissionStatus } from "src/components/forms/constants/FormConstants";
import { UserRestrictedComponent } from "../authorization/UserRestrictedComponent";
import { ComponentID } from "../authorization/ComponentRightsMapping";
import { useStateWithLocalStorage } from "src/components/common/storage/UseStateWithLocalStorage";
import { FormDisplayInfo } from "src/components/forms/FormDisplayInfo";
import { FormsRenderer } from "@amzn/wfm-axiom-forms-renderer";
import { notFalsy } from "src/components/common/NotFalsy";
import { UserManager } from "src/user/UserIdentityManager";
import ShareForm from "./share_forms/ShareForm";
import { HeaderRoute } from "src/components/common/header/HeaderRoute";
import { isDateBetweenNowAndTimeframe } from "src/components/common/Comparator";
import { useFormScrolledMessage } from "src/hooks/useFormScrolledMessage";

interface FormDisplayProps {
    formDefinition: FormDefinition;
    initialFormResponses?: FormResponses;
    onSubmit?: (submission: FormResponses) => void;
    onSubmitDraft?: (submission: FormResponses) => void;
    onDiscardDraft?: (submission: FormResponses) => void;
    readOnly?: boolean;
    includeMetadata?: boolean;
    formSubmission?: FormSubmission;
    staticData?: any;
}

interface FormHeaderProps {
    formDefinition: FormDefinition;
    readOnly: boolean | undefined;
    compactView: boolean;
    onCompactViewChange: (value: boolean) => void;
    logButtonClick: (id: string) => void;
    onShowFeedback: () => void;
    onShowMetadata: () => void;
    setDebug: () => void;
    formSubmission?: FormSubmission;
    isDebug: boolean;
}

interface CompactViewProps {
    compactView: boolean;
    onCompactViewChange: (value: boolean) => void;
}

interface EditFormSubmissionProps {
    formDefinitionId: string;
    formSubmissionId: string;
    formResponses: string;
    isEditable: boolean;
}

// This interface should be exported by the FormsRenderer wrapper
export interface FormResponses {
    responses: {
        [key: string]: string;
    }
}

const COMPONENT_NAME = AXIOM_FORMS_PREFIX + 'FormDisplay';
const FORM_OPEN_MODAL_EVENT_TYPE = "ax-forms-form-open-modal";

const buildQueryParams = (responses: string) => {
    return `?${new URLSearchParams(JSON.parse(responses)).toString()}`;
}

export const FormHeader = (props: FormHeaderProps) => {
    const { formDefinition, formSubmission } = props

    const OptionsMenu = () => (
        <CardOptionsMenu>
            <DropdownItem as="button" onClick={() => props.logButtonClick('help')}>
                <a className='no-underline text-nordic'
                   href={formDefinition!.helpContentLink || `#/help/${formDefinition!.formName}`}
                   target='_blank'
                   rel="noopener noreferrer"
                >
                    {'Help on this Form'}
                </a>
            </DropdownItem>
            <DropdownItem as="button" onClick={props.onShowFeedback}>
                {i18n.t("wfm_axiom_fb_feedback_card_title")}
            </DropdownItem>
            <KatDivider variant="athens"/>
            {/*/!* TODO: Internationalize (AXIOM-390) *!/*/}
            <DropdownItem as="button" onClick={props.onShowMetadata}>
                <span className="text-muted">Show Metadata</span>
            </DropdownItem>
            <UserRestrictedComponent componentId={ComponentID.HEADER_ADMIN_RIGHTS_ID}>
                <DropdownItem as="button" onClick={props.setDebug}>
                    <span className="text-muted">
                        {`${props.isDebug ? 'Disable' : 'Enable'} debug mode`}
                    </span>
                </DropdownItem>
            </UserRestrictedComponent>
        </CardOptionsMenu>
    );
    
    return (
        formDefinition && <>
            <div className='d-flex flex-row align-items-center'>
                <h1 className='flex-grow-1 text-left'>{formDefinition!.displayTitle}</h1>
                {props.readOnly && <CompactView compactView={props.compactView}
                                                onCompactViewChange={props.onCompactViewChange}/>}
                
                {formSubmission &&
                formSubmission.submitterEntityId === UserManager.getUserIdentity().login &&
                <div>
                    {props.readOnly &&
                    <EditFormSubmission formDefinitionId={formDefinition.id}
                                        formSubmissionId={formSubmission.id}
                                        formResponses={formSubmission.responses}
                                        isEditable={
                                            isDateBetweenNowAndTimeframe(
                                            formSubmission?.createdDateTime,
                                            formSubmission?.formDefinition?.timeToEditFormSubmissions || 0) 
                                            && formSubmission?.formSubmissionStatus != FormSubmissionStatus.DISCARDED
                                        }
                    />}
                    {formSubmission.formSubmissionStatus === FormSubmissionStatus.SUBMITTED && 
                    <ShareForm itemId={formSubmission.id}
                               sharedWith={formSubmission.sharedWith}
                    />}
                </div>}
                <OptionsMenu/>
            </div>
        </>);
};

const EditFormSubmission = (props: EditFormSubmissionProps) => {
    const { formDefinitionId, formSubmissionId, formResponses, isEditable } = props;
    
    const hrefString = isEditable ? `#${HeaderRoute.FORMS_EDIT}/${formDefinitionId}/${formSubmissionId}${buildQueryParams(formResponses)}` : undefined
    const tooltipText = isEditable ? 'Edit form submission' : "This submission can no longer be edited as it's past the time allowed to do so."
    const textColorClass = isEditable ? 'text-body' : 'text-muted'

    return (
        <KatPopover kat-aria-behavior="tooltip"
                        position="bottom"
                        trigger-type="hover"
                        variant="tooltip"
        >
            <KatLink className={`mr-3 ${textColorClass}`} slot="trigger"
                        href={hrefString}
            >
                <KatIcon name="edit" size="small"></KatIcon>
            </KatLink>
            {tooltipText}
        </KatPopover>
    )
};

const CompactView = (props: CompactViewProps) => (
    <KatIcon title={props.compactView ? 'Full view' : 'Compact view'}
             name={props.compactView ? 'unfold_more' : 'unfold_less'}
             size='small' className='m-3 cursor-pointer'
             onClick={() => props.onCompactViewChange(!props.compactView)}
    />
);

export const FormDisplay: React.FC<FormDisplayProps> = (props: FormDisplayProps) => {

    const {
        formDefinition,
        initialFormResponses,
        onSubmit,
        onSubmitDraft,
        onDiscardDraft,
        readOnly,
        formSubmission,
        includeMetadata = true,
        staticData
    } = props;

    const userContext = useContext(UserContext);

    const [isDebug, setDebug] = useState<boolean>(false);

    const [isCompactView, setIsCompactView] = useStateWithLocalStorage<boolean>('FORM_VIEW_COMPACT_VIEW', false);

    const [showMetadata, setShowMetadata] = useState(false);
    const [showFeedback, setShowFeedback] = useState(false);
    const [showDiscardConfirmation, setShowDiscardConfirmation] = useState(false);
    const [formState, setFormState] = useState()
    const [additionalMetrics, setAdditionalMetrics] = useState<Axiom.AdditionalMetric[]>();
    const [feedbackMetadata, setFeedbackMetadata] = useState<Partial<Cerebrum.CardMetadata>>();
    const [discardEvent, setDiscardEvent] = useState<FormsRendererCustomEvent<any>> ();

    const logButtonClick = (buttonName: string) => {
        AxiomMetricsDriver.publishButtonClick(COMPONENT_NAME, buttonName, additionalMetrics);
    }

    useEffect(() => {
        if (formDefinition) {
            setAdditionalMetrics(
                [{ name: "formDefinitionId", value: formDefinition!.id }]
            );
            setFeedbackMetadata({
                cardId: formDefinition!.id,
                cardTitle: formDefinition!.formName,
                cardType: "formDefinition",
                createdDate: formDefinition!.createdDateTime,
                entityId: userContext.userManager.getUserIdentity().login,
                publisherName: formDefinition!.createdBy
            });
        }
    }, [formDefinition]);

    // UI Elements Handlers
    const onShowMetadata = () => {
        setShowMetadata(true);
        logButtonClick('showMetadata');
    }

    const onShowFeedback = () => {
        setShowFeedback(true);
        logButtonClick('showFeedback');
    }

    const handleSubmit = useCallback((submissionEvent: FormsRendererCustomEvent<any>) => {
        if (isDebug) {
            setFormState(submissionEvent.detail.responses)
            onShowMetadata()
        } else {
            logButtonClick('submit');
            onSubmit && onSubmit(submissionEvent.detail);
        }
    }, [isDebug]);

    const handleSubmitDraft = useCallback((submissionEvent: FormsRendererCustomEvent<any>) => {
        if (isDebug) {
            setFormState(submissionEvent.detail.responses)
            onShowMetadata()
        } else {
            logButtonClick('draft');
            onSubmitDraft && onSubmitDraft(submissionEvent.detail);
        }
    }, [isDebug]);

    const handleDiscardDraft = useCallback((submissionEvent: FormsRendererCustomEvent<any>) => {
        setShowDiscardConfirmation(true);
        setDiscardEvent(submissionEvent);
    }, [isDebug]);

    const discardDraft = () => {
        if (isDebug) {
            setFormState(discardEvent!.detail.responses)
            onShowMetadata()
        } else {
            logButtonClick('discard');
            onDiscardDraft && onDiscardDraft(discardEvent!.detail);
        }
        cancelDiscard();
    }

    const cancelDiscard = () => {
        setShowDiscardConfirmation(false);
    }

    const handleOpenModal = useCallback((rowData: any) => {
        parent.postMessage({
            type: FORM_OPEN_MODAL_EVENT_TYPE,
            formInfo: rowData
        }, "*");
    }, [isDebug]);

    const formCallbacks = {"openModalCallback": handleOpenModal};

    useFormScrolledMessage();

    const formatJSONString = (jsonObject: Object) => {
        return JSON.stringify(jsonObject, null, 2)
    }

    const MetadataModal = () => {
        if (!showMetadata) return null;

        const metadata = { ...formDefinition } as Partial<FormDefinition>;
        delete metadata.formTemplate;

        const metadataSections = [
            {
                label: "Metadata Details",
                content: <pre>{formatJSONString(metadata)}</pre>
            },
            {
                label: "Form Definition",
                content: <pre>{formatJSONString(JSON.parse(formDefinition!.formTemplate))}</pre>
            }
        ]

        if (initialFormResponses) {
            metadataSections.push({
                label: "Prefilled Responses",
                content: <pre>{formatJSONString(initialFormResponses.responses)}</pre>
            })
        }

        if (isDebug && formState) {
            metadataSections.push({
                label: "Current Form State",
                content: <pre>{formatJSONString(formState || {})}</pre>
            })
        }

        return <FlashBriefingModal
            visible={showMetadata}
            title={'Form Metadata'} // TODO: Internationalize (AXIOM-390)
            onClose={() => setShowMetadata(false)}>
            {metadataSections.map(section => {
                return [
                    <h3>{section.label}</h3>,
                    section.content
                ]
            })}
        </FlashBriefingModal>
    };

    const FeedbackModal = () => <>{
        showFeedback &&
        <KatModal visible={true} noCloseIcon>
            <FeedbackContainer cardMetadata={feedbackMetadata!}
                               onClose={() => setShowFeedback(false)}/>
        </KatModal>}
    </>;

    const DiscardConfirmationModal = () => <>{
            showDiscardConfirmation &&
                <KatModal visible={true} title="Discard draft confirmation">
                    <p>Confirm that you want to discard this draft</p> 
                    <div slot="footer" className="d-flex justify-content-end">
                        <KatButton
                            variant="link"
                            label="Cancel"
                            onClick={cancelDiscard}>
                        </KatButton>
                        <KatButton
                            variant="primary"
                            label="Confirm"
                            onClick={discardDraft}>
                        </KatButton>
                    </div>
                </KatModal>}
    </>;

    return (
        <>
            <MetadataModal/>
            <FeedbackModal/>
            <DiscardConfirmationModal/>
            <FormHeader setDebug={() => setDebug(!isDebug)}
                        formDefinition={formDefinition}
                        readOnly={readOnly}
                        compactView={isCompactView}
                        onCompactViewChange={(value: boolean) => {
                            setIsCompactView(value);
                            logButtonClick('compactView');
                        }}
                        logButtonClick={() => logButtonClick}
                        onShowFeedback={() => setShowFeedback(true)}
                        onShowMetadata={() => setShowMetadata(true)}
                        formSubmission={formSubmission}
                        isDebug={isDebug}
            />
            {formSubmission && includeMetadata &&
                <FormDisplayInfo formSubmission={formSubmission!}/>}
             {/*@ts-ignore*/}
            <FormsRenderer debug={isDebug}
                           compactView={readOnly && isCompactView}
                           schemaString={formDefinition.formTemplate}
                           initialState={initialFormResponses}
                           onFormSubmitted={handleSubmit}
                           onFormDraftSubmitted={handleSubmitDraft}
                           onFormDraftDiscarded={handleDiscardDraft}
                           formCallbacks={formCallbacks}
                           readOnly={readOnly}
                           allowDraft={formDefinition.allowDrafts && (!formSubmission || formSubmission.formSubmissionStatus === FormSubmissionStatus.DRAFT)}
                           allowDiscard={formDefinition.allowDrafts && (!!formSubmission && formSubmission.formSubmissionStatus === FormSubmissionStatus.DRAFT)}
                           formExists={formSubmission !== undefined}
                           extensions={formDefinition.extensions?.filter(notFalsy) ?? []}
                           className='mb-5'
                           staticData={staticData}
            />
        </>
    );
};

import { KatBadge } from '@amzn/katal-react';
import React, { Component } from 'react';
import { ListGroup } from 'react-bootstrap';
import { BscScoreBadge } from 'src/components/common/ScoreFormatters';
import { NEW_HIRE_METRIC_NAME } from '../BSC';

interface SideBarListItemProps {
    listItemEntity: Axiom.SelectedUser;
    selectEntityHandler: (selectedEntity: Axiom.SelectedUser) => void;
    selectedEntity: Axiom.SelectedUser;
}

export default class SideBarListItem extends Component<SideBarListItemProps> {
    render() {
        const { selectEntityHandler, selectedEntity, listItemEntity } = this.props;
        const { userId, type, bscMetricData } = listItemEntity;

        const nhMetric = bscMetricData.metrics.filter(metric => metric.name == NEW_HIRE_METRIC_NAME)[0];

        const idSpan: JSX.Element = (type == 'supervisor')
            ? <span className="name-label text-white">{userId}</span>
            : <span className={`name-label ${userId == selectedEntity.userId ? 'text-white' : 'text-blue'}`}>{userId}</span>;

        const bgClass = (type == 'supervisor') ? 'bg-gray-dark' : '';

        return (
            <ListGroup.Item action
                active={userId == selectedEntity.userId}
                onClick={() => selectEntityHandler({ ...listItemEntity })}
                className={`d-flex justify-content-between align-items-center ${bgClass}`}>
                {idSpan}
                <span>
                    <NewHireBadge nhMetric={nhMetric} listItemEntity={listItemEntity} />
                    <BscScoreBadge className="p-1" score={bscMetricData.score} />
                </span>
            </ListGroup.Item>
        );
    }
}

interface newHireBadgeProps {
    nhMetric?: Cerebrum.BalancedScorecardMetric;
    listItemEntity: Axiom.SelectedUser;
}

function NewHireBadge(props: newHireBadgeProps) {
    const { nhMetric, listItemEntity } = props;

    const NEW_HIRE_SCORE = '1';
    const TENURE_SCORE = '0';
    const AGENT_TYPE = 'agent';

    // if no new hire score is given, default to 0
    const nhScore = nhMetric?.score ?? TENURE_SCORE;

    // No badge is shown for Sups
    const showBadge = (nhScore == NEW_HIRE_SCORE) && (listItemEntity.type == AGENT_TYPE);

    return <>
        {showBadge && <KatBadge
            className={`axiom-badge-gray`}
            type="info"
            label='NH'>
        </KatBadge>
        }
    </>;
}

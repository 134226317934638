import { TaskEvent, TaskEventName } from "src/components/tasks/events/TasksEvents";

export class EditTaskEvent implements TaskEvent<Cerebrum.Tasks.Task> {
    public readonly name: TaskEventName = TaskEventName.EDIT_TASK_EVENT;
    public readonly payload: Cerebrum.Tasks.Task;

    constructor(task: Cerebrum.Tasks.Task) {
        // for some (maybe optimization related) reason, there are runtime errors
        // if we use constructor assignment here...
        this.payload = task;
    }
}

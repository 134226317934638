import { toOptionsArray } from "src/components/common/Enums";
import { TaskSource } from "src/components/tasks/model/TaskSource";
import i18n from "i18next";
import { Grouping } from "src/components/common/Grouper";
import { getLocalTimezoneMoment } from "src/components/common/DateFormatter";

export enum TaskGroupingOption {
    DEFAULT = 'Default',
    DUE_DATE = 'Due Date',
    PRIORITY = 'Priority',
    DATE_ADDED = 'Date Added',
    TYPE = 'Type',
    NONE = 'None'
}

export function TaskGroupingOptionFromString(taskGroupingOption: string): TaskGroupingOption {
    return taskGroupingOption ?
        TaskGroupingOption[taskGroupingOption.toUpperCase() as keyof typeof TaskGroupingOption] :
        TaskGroupingOption.DEFAULT;
}

export const TaskGroupingFromOption: Record<TaskGroupingOption, Grouping<Cerebrum.Tasks.Task>> = {
    [TaskGroupingOption.DEFAULT]: {
        name: TaskGroupingOption.DEFAULT,
        groupDecider: (task: Cerebrum.Tasks.Task) => {
                return task.taskSource === TaskSource.MANUAL ?
                    i18n.t('wfm_axiom_tasks_user_created') :
                    i18n.t('wfm_axiom_tasks_recommended')
        }
    },
    [TaskGroupingOption.DUE_DATE]: {
        name: TaskGroupingOption.DUE_DATE,
        groupDecider: (task: Cerebrum.Tasks.Task) => {
            return task.dueDateTime ? getLocalTimezoneMoment(task.dueDateTime).format('l') : 'No due date'
        }
    },
    [TaskGroupingOption.PRIORITY]: {
        name: TaskGroupingOption.PRIORITY,
        groupDecider: (task: Cerebrum.Tasks.Task) => task.importance || 'Other Tasks'
    },
    [TaskGroupingOption.DATE_ADDED]: {
        name: TaskGroupingOption.DATE_ADDED,
        groupDecider: (task: Cerebrum.Tasks.Task) => {
            return task.createdDateTime ? getLocalTimezoneMoment(task.createdDateTime)
                .format('l') : 'No creation date'
        }
    },
    [TaskGroupingOption.TYPE]: {
        name: TaskGroupingOption.TYPE,
        groupDecider: (task: Cerebrum.Tasks.Task) => task.taskType || 'No type'
    },
    [TaskGroupingOption.NONE]: {
        name: TaskGroupingOption.NONE,
        groupDecider: () => 'All tasks'
    }
} as const;

export function getSelectedTaskGroupingFromString(s: string) {
    return TaskGroupingFromOption[TaskGroupingOptionFromString(s)] || TaskGroupingFromOption.Default;
}

export const taskGroupingOptions = toOptionsArray(TaskGroupingOption);

import React from "react";
import { KatCheckbox } from "@amzn/katal-react";

export interface CrudCheckboxProps {
    checked?: boolean;
    label?: string;
    disabled?: boolean;
    indeterminate?: boolean;
    changeHandler?: (e: Event) => void;
    value?: string;
}

function CrudCheckbox(props: CrudCheckboxProps) {
    const { checked, label, disabled, indeterminate, changeHandler, value } = props;

    const onChange = (event: Event) => {
        if (changeHandler) {
            changeHandler(event);
        }
    };

    return (
        <KatCheckbox
            checked={checked}
            label={label}
            disabled={disabled}
            onChange={onChange}
            value={value}
            indeterminate={indeterminate}
        ></KatCheckbox>
    );
}

export { CrudCheckbox };

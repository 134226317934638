import { ExtensionVisitor } from './ExtensionVisitor';
import { VisitableExtension } from './VisitableExtension';


/**
 * Helper class to group multiple ExtensionVisitors and later
 * execute them over a list of VisitableExtensions
 */
export class ExtensionVisitorsRegistry {

    private visitors: ExtensionVisitor<any>[];
    private readonly debug: boolean;

    constructor(debug: boolean) {
        this.visitors = [];
        this.debug = debug;
    }

    registerVisitor = (visitor: ExtensionVisitor<any>) => this.visitors.push(visitor);

    visit = (extensions: VisitableExtension[]) => {
        this.log('Visiting extensions', extensions);
        extensions.forEach(this.visitOne);
    };

    private visitOne = (extension: VisitableExtension) => {
        this.visitors.forEach(
            visitor => visitor.visit(extension)
        );
    };

    private log = (message: string, ...args: any) => {
        if (this.debug) {
            if (args && args.length) {
                // tslint:disable-next-line:no-console
                console.debug(message, args);
            } else {
                // tslint:disable-next-line:no-console
                console.debug(message);
            }
        }
    };
}

import { KatBox } from "@amzn/katal-react";
import React, { PropsWithChildren } from "react";
import "./Layouts.scss"

interface MainLayoutProps {
    main: JSX.Element;
    isItDown?: JSX.Element;
    variant?: KatBox.Variant; // Variant currently optional to minimize changes in old pages
}

/**
 * Main layout for Axiom pages defining placeholders for isItDown component, main content and a sidebar component
 */
export default function MainLayout(props: MainLayoutProps) {
    const { variant } = props;

    const Wrapper = (wrapperProps: PropsWithChildren<unknown>) => {
        return variant ?
            <KatBox variant={variant}>{wrapperProps.children}</KatBox> :
            <div>{wrapperProps.children}</div>;
    };

    return (
        <Wrapper>
            {props.isItDown && <div>{props.isItDown}</div>}
            <div className="container-fluid">
                {props.main}
            </div>
        </Wrapper>
    )
}

import React, { useEffect, useState } from "react";
import { KatRow } from "@amzn/katal-react";
import { TaskCard } from "src/components/tasks/TaskCard";
import { EmptyListView } from "src/components/common/EmptyListView";
import { CreateTaskCard } from "src/components/tasks/CreateTaskCard";

interface TasksListProps {
    title: string;
    tasks: Cerebrum.Tasks.Task[];
    viewUserLogin: string;
    showCreateTaskButton?: boolean;
    showFeedbackOption?: boolean;
    noTasksMessage?: string;
}

export const TasksList: React.FC<TasksListProps> = props => {

    const { title, viewUserLogin, showCreateTaskButton, showFeedbackOption, noTasksMessage } = props;

    const [tasks, setTasks] = useState<Cerebrum.Tasks.Task[]>(props.tasks);

    useEffect(() => {
        setTasks(props.tasks);
    }, [props.tasks]);

    const TasksHeader = () => (
        <>
            <KatRow>
                <h5>{title}</h5>
            </KatRow>
        </>
    );

    const TasksContent = () => (
        <>
            {tasks.map((task, index) => {
                return <TaskCard task={task}
                                 key={task.id}
                                 viewUserLogin={viewUserLogin}
                                 showDivider={index !== tasks.length - 1}
                                 showFeedbackOption={showFeedbackOption}/>
            })}
        </>
    );

    const CreateTaskButton = () => (
        <>{showCreateTaskButton &&
        <CreateTaskCard viewUserLogin={viewUserLogin}/>
        }</>
    )

    const NoTasksView = () => (
        <>{tasks.length === 0 && noTasksMessage &&
        <EmptyListView iconName='check' iconBorder message={noTasksMessage}/>
        }</>
    )

    return (
        <>
            <TasksHeader/>
            <CreateTaskButton/>
            <TasksContent/>
            <NoTasksView/>
        </>
    );
}
import React from "react";
import { KatDropdown } from "@amzn/katal-react";
import { DropdownFilterConfig } from "src/@types/extendedPolarisTable";

export interface DropdownFilterProps {
    config: DropdownFilterConfig;
    eventHandler: (e: KatDropdown.ChangeEvent) => void;
    value: string;
}

export default function DropdownFilter(props: DropdownFilterProps) {
    const { config, eventHandler, value } = props;
    const { attributeName, options, customStyle } = config;

    return (
        <div className="mr-3 mb-sm-1 " style={customStyle || {minWidth: '110px'}}>
            <KatDropdown
                options={options}
                value={value}
                key={`drop-${attributeName}}`}
                onChange={eventHandler}
                data-attribute-name={attributeName}
            />
        </div>
    );
}

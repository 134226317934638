import React, { useEffect, useState } from "react";
import "../tasks/Tasks.scss";
import "./Notifications.scss";
import { KatBox, KatCheckbox, KatColumn, KatDivider, KatRow, KatSpinner } from "@amzn/katal-react";
import { CerebrumService } from "src/service/CerebrumCoralService";
import { CoralError } from "src/components/error/ErrorBoundComponent";
import { ErrorMessage } from "src/components/error/ErrorMessage";
import { taskTypeOptions } from "src/components/tasks/model/TaskAndNotificationType";
import moment from "moment";
import { ClearableDropdown } from "src/components/common/ClearableDropdown";
import { ClearableDatePicker } from "src/components/common/ClearableDatePicker";
import { AxiomMetricsDriver } from "src/metrics/AxiomMetricsDriver";
import { NotificationsList } from "src/components/notifications/NotificationsList";
import { EmptyListView } from "src/components/common/EmptyListView";
import { useStateWithTypedSearchParams } from "src/components/common/searchParameters/useStateWithTypedSearchParams";
import { ItemOrganizer } from "src/components/common/itemOrganizer/ItemOrganizer";
import { Grouped } from "src/components/common/Grouper";
import { NOTIFICATION_SORTING_GROUPING_CONFIG } from "src/components/notifications/Notifications";
import { DEFAULT_CHARLOTTE_LOG_FILTER } from "src/components/tasks/TasksLog";
import { compareReverseAlphaNumerical } from "src/components/common/Comparator";
import { UrlParameterConfigKeys } from "src/config/ConfigManager";

interface NotificationsLogProps {
    viewUserLogin: string;
}

const COMPONENT_NAME = "NotificationsLog";

export const NotificationsLog: React.FC<NotificationsLogProps> = (props) => {

    const { viewUserLogin } = props;

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [notifications, setNotifications] = useState<Cerebrum.Tasks.Notification[]>([]);

    const [groupedNotifications, setGroupedNotifications] = useState<Grouped<Cerebrum.Tasks.Notification>>({});

    // filter with representation in url search parameters to allow deep linking/bookmarks
    const [filter, setFilter] = useStateWithTypedSearchParams<Cerebrum.Tasks.NotificationFilter>(UrlParameterConfigKeys.FILTER, DEFAULT_CHARLOTTE_LOG_FILTER);

    const [error, setError] = useState<CoralError>();

    useEffect(() => {
        getNotifications();
    }, [viewUserLogin, filter]);

    // Publish page load metrics
    useEffect(() => {
        document.title = `${COMPONENT_NAME} - ${viewUserLogin}`;

        const additionalMetrics: Axiom.AdditionalMetric[] = [{ name: "viewUserLogin", value: viewUserLogin }];
        AxiomMetricsDriver.publishPageLoad(COMPONENT_NAME, window.location.href, additionalMetrics);
    }, [viewUserLogin]);

    const getNotifications = async () => {
        setIsLoading(true);
        const input: Cerebrum.Tasks.GetNotificationsInput = {
            notificationOwner: viewUserLogin,
            notificationFilter: filter
        };
        await CerebrumService.getNotifications(input)
            .then(handleResponse)
            .catch(handleError);
    }

    // Api calls handlers
    const handleResponse = (response: Cerebrum.Tasks.GetNotificationsOutput) => {
        setNotifications(response.notifications)
        setIsLoading(false);
        setError(undefined);
    }

    const handleError = (coralError: CoralError) => {
        // tslint:disable-next-line:no-console
        console.error(coralError);
        setNotifications([]);
        setIsLoading(false);
        setError(coralError);
    }

    // UI elements
    const LoadingSpinner = () => (
        <>{isLoading &&
        <div className="row justify-content-center"><KatSpinner size="large"/></div>}
        </>
    );

    const LoadingErrorMessage = () => (
        // TODO: error handling to be improved in https://issues.amazon.com/issues/SUPSUP-781
        <>{error &&
        <ErrorMessage
            title={"Error getting Notifications..."}
            message={error.__type + "\n" + error.message ? error.message : ""}
            level="warning"/>
        }</>
    );

    /**
     * Helper curried function to avoid repeating the event handler for each input field.
     * Receives a key of NotificationFilter to have compiler's help with the field to update definition.
     * @param key indicating the field to update
     */
    const update = (key: keyof Cerebrum.Tasks.NotificationFilter) => {
        return (event: any) => {
            setFilter(current => {
                const updated = { ...current };
                updated[key] = event.target.value;
                return updated;
            });
        }
    }

    /**
     * Helper curried function to avoid repeating the event handler for each date input field.
     * Receives a key of NotificationFilter to have compiler's help with the field to update definition.
     * @param dateField indicating the field to update
     */
    const updateDate = (dateField: keyof Cerebrum.Tasks.NotificationFilter) => {
        return (event: any) => {
            const time = moment(event.target.value);
            update(dateField)({ target: { value: time.toISOString() } })
        }
    }

    /**
     * Helper curried function to avoid repeating the event handler for each check box input field.
     * Receives a key of TaskFilter to have compiler's help with the field to update definition.
     * @param key indicating the field to update
     */
    const updateCheckBox = (key: keyof Cerebrum.Tasks.NotificationFilter) => {
        return (event: any) => {
            setFilter(current => {
                const updated = { ...current };
                updated[key] = event.target.checked;
                return updated;
            });
        }
    }

    const SearchParameters = () => <>
        <h5>Search parameters</h5>
        <ClearableDropdown label='Category' options={taskTypeOptions} value={filter.notificationType}
                           onUpdate={update("notificationType")}/>
        <KatCheckbox className='py-2' label='Show dismissed' checked={filter.dismissed}
                     onChange={updateCheckBox('dismissed')}/>
        <KatCheckbox className='pt-2 pb-4' label='Include expired' checked={filter.includeExpired}
                     onChange={updateCheckBox('includeExpired')}/>
        <ClearableDatePicker label='Date created from' value={filter.createdFrom}
                             onUpdate={updateDate('createdFrom')}/>
        <ClearableDatePicker label='Date created to' value={filter.createdTo}
                             onUpdate={updateDate('createdTo')}/>
    </>;

    const NotificationsLists = () => (
        <>{!isLoading && notifications.length > 0 &&
            <>
                {Object.keys(groupedNotifications)
                    .sort(compareReverseAlphaNumerical)
                    .map((key: string, index: number) => <>
                    <NotificationsList title={key}
                                       key={`${key}-${index}-notification`}
                                       notifications={groupedNotifications[key]}
                                       viewUserLogin={viewUserLogin}/>
                    {index !== Object.keys(groupedNotifications).length - 1 &&
                        <KatDivider variant='eastern' key={`${key}-${index}-divider`}/>}
                </>)}
            </>}
        </>
    );

    const SearchResults = () => <>
        <LoadingErrorMessage/>
        <LoadingSpinner/>
        <>
            {notifications.length > 0 &&
            <NotificationsLists/>}
        </>
    </>;

    const NoResultsView = () => (
        <>{!isLoading && notifications.length === 0 &&
        <EmptyListView iconName='check' iconBorder
                       message='There are no results for the selected filters.'/>
        }</>
    )

    return (
        <div className='d-flex justify-content-center pt-5'>
            <KatBox className='pt-3 w-50 h-75' variant='ltgrey'>
                <h1>Notifications Log</h1>
                <KatRow>
                    <KatColumn md={5}>
                        <SearchParameters/>
                    </KatColumn>
                    <KatColumn md={7}>
                        <div className="d-flex flex-row">
                            <ItemOrganizer<Cerebrum.Tasks.Notification>
                                parentComponentName={COMPONENT_NAME} label={'Organize Search Results'}
                                items={notifications}
                                itemOrganizerConfigOptions={NOTIFICATION_SORTING_GROUPING_CONFIG}
                                updateItemsToDisplay={setGroupedNotifications}/>
                        </div>
                        <SearchResults/>
                        <NoResultsView/>
                    </KatColumn>
                </KatRow>
            </KatBox>
        </div>
    )
}

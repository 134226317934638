const ENGLISH = "en-US"
const DEUTSCH = "de-DE"
const JAPANESE = "ja-JP"
const CHINESE = "zh-CN"

const locales = [
    {
        "name": "English",
        "value": ENGLISH
    },
    {
        "name": "Deutsch",
        "value": DEUTSCH
    },
    {
        "name": "日本語",
        "value": JAPANESE
    },
    {
        "name": "中文",
        "value": CHINESE
    }
];

const shortLocales: {[key: string]: string} = {
    'en-us': 'EN',
    'de-de': 'DE',
    'ja-jp': 'JP',
    'zn-cn': 'CN'
}

export {
    ENGLISH,
    DEUTSCH,
    JAPANESE,
    CHINESE,
    shortLocales
}

export default locales;

// @ts-ignore
import { UserManager } from "src/user/UserIdentityManager";

/**
 * Wraps a class instance and intercepts all calls to its methods. Assuring that the user is authenticated before
 * executing the method.
 * @param targetClass
 */
// @ts-ignore
export const withCheckUserAuthenticated = (targetClass) => {
    return new Proxy(targetClass, {
        get(target, prop) {
            // @ts-ignore
            const origMethod = target[prop];
            if (typeof origMethod === 'function') {
                // @ts-ignore
                return (...args) => {
                    if (!UserManager.isUserAuthenticated()) {
                        throw new Error("User is not authenticated");
                    }
                    return origMethod.apply(target, args);
                }
            }
        }
    });
}
import React, { useEffect, useState } from 'react';
import { KatDivider } from '@amzn/katal-react';
import { FormSubmission, FormSubmissionsResponse } from "src/service/amplify/appsync";
import { FormsService } from "src/service/Forms.service";
import { ErrorMessage } from "src/components/error/ErrorMessage";
import { UserContext } from "src/context/UserContext";
import { Appsync } from "src/@types/appsync";
import { FormState } from '@amzn/wfm-axiom-forms-renderer-stencil/dist/types/model/Forms';
import { TypeMetadataVisitor } from 'src/components/forms/extensions/TypeMetadataVisitor';
import { TargetEntityVisitor } from 'src/components/forms/extensions/TargetEntityVisitor';
import { TargetItemVisitor } from 'src/components/forms/extensions/TargetItemVisitor';
import { buildMetadataLabel, MetadataLabelType } from 'src/components/common/MetadataLabel';
import { ExtensionFormatter } from "src/components/forms/extensions/ExtensionFormatter";
import { buildFormDetailsLabel } from 'src/components/forms/submissions_table/FormDetailsLabel';
import FormSubmissionsTable
    from "src/components/forms/submissions_table/polaris_submissions_table/FormSubmissionsTable";
import {
    DateRange,
    getDateRangePickerValueFromParamString,
    parseRange
} from "src/components/forms/date_range_picker/FormsDateRangePicker";
import { useStateWithSearchParams } from "src/components/common/searchParameters/useStateWithSearchParams";
import { UrlParameterConfigKeys } from "src/config/ConfigManager";

interface FormSubmissionTableContainerProps {
    userLoginProp?: string;
    fetchSubmissionOperation: (userLogin: string, dateRange: DateRange) => Promise<FormSubmissionsResponse | undefined>
}

export const FormSubmissionSearchBarIdentifier = 'form-submission-searchbar';

/**
 * Container to hold logic for search, status filters, FormSubmission Table, Pagination
 *
 * it also sets up the data and config for the submissions_table
 */

export const FormSubmissionTableContainer: React.FC<FormSubmissionTableContainerProps> = (props) => {

    const { userLoginProp, fetchSubmissionOperation } = props;
    const userContext = React.useContext(UserContext);
    const userLogin = userLoginProp || userContext.userManager.userIdentity!.login;
    
    const [paramDateRangePickerValue, setParamDateRangePickerValue] = useStateWithSearchParams(UrlParameterConfigKeys.FORMS_LOG_DATE_RANGE,"");
    const [formsSubmissions, setFormsSubmissions] = useState<FormSubmission[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>();

    const getFormSubmissions = async () => {
        await fetchSubmissionOperation(userLogin, getDateRangeFromURLParamString())
            .then(handleResponse)
            .catch(handleError);
    }

    const getDateRangeFromURLParamString = () =>{
        return parseRange(
            getDateRangePickerValueFromParamString(paramDateRangePickerValue || ''));
    }

    useEffect(() => {
        if (paramDateRangePickerValue !== '') {
            setIsLoading(true);
            getFormSubmissions();
        }
    }, [fetchSubmissionOperation, paramDateRangePickerValue]);

    // Flatten the data, so it can be easily used as row data for the submissions_table, and add extra data contextual points
    // @ts-ignore
    const tableData: Appsync.FormSubmissionsTableData = formsSubmissions.map(
        (formSubmission, index) => {
            const { formDefinition, submitterEntityId, ...fields } = formSubmission;
            return {
                rowNumber: index + 1,
                ...formDefinition,
                ...fields,
                submitterEntityId: buildMetadataLabel(MetadataLabelType.AGENT, submitterEntityId),
                formDetails: buildMetadataLabel(MetadataLabelType.FORM_DETAILS, buildFormDetailsLabel(formDefinition)),
                targetEntity: ExtensionFormatter.formatExtensionsIntoString(formSubmission,
                    (formState: FormState) => new TargetEntityVisitor(formState)),
                targetItem: ExtensionFormatter.formatExtensionsIntoString(formSubmission,
                    (formState: FormState) => new TargetItemVisitor(formState)),
                metadata: ExtensionFormatter.formatExtensionsIntoString(formSubmission,
                    (formState: FormState) => new TypeMetadataVisitor(formState))
            };
        });

    // Api calls handlers
    const handleResponse = (response?: FormSubmissionsResponse) => {
        if (FormsService.isSuccessful(response)) {
            const filteredArray: FormSubmission[] = response?.body! as FormSubmission[];
            if (filteredArray) {
                setFormsSubmissions(filteredArray);
            } else {
                setFormsSubmissions([]);
            }
            setIsLoading(false);
            setError(undefined);
        } else {
            setIsLoading(false);
            setError("Unable to fetch submissions for user " + userLogin + ".\n" +
                ` ${response ? JSON.stringify(response) : 'No response object from server.'}`);
            setFormsSubmissions([]);
        }
    };

    const handleError = (response: any) => {
        // tslint:disable-next-line:no-console
        console.log('Error getting FormsSubmissions... ', response);
        setFormsSubmissions([]);
        setIsLoading(false);
        if(!FormsService.isNotFound(response!.data!.getFormSubmissionsSharedWith)){
            setError(JSON.stringify(response));
        }
    };

    // UI elements
    const LoadingErrorMessage = () => (
        <>{error &&
            <ErrorMessage
                title={"Error getting FormsSubmissions..."}
                message={error}
                level='warning'/>
        }</>
    );

    return (
        <>
            <LoadingErrorMessage/>
            <FormSubmissionTableContainerLayout
                formSubmissionTable={
                    <FormSubmissionsTable itemList={tableData} loading={isLoading}

                                          onDateRangeChanged={ (changedDateRange) => {
                                              setParamDateRangePickerValue(JSON.stringify(changedDateRange.dateRangePickerValue))
                                          }
                    }/>
                }
            />
        </>
    );
};

interface FormSubmissionTableContainerLayoutProps {
    searchInputGroup?: JSX.Element;
    formSubmissionTable: JSX.Element;
    title?: JSX.Element;
}

function FormSubmissionTableContainerLayout(props: FormSubmissionTableContainerLayoutProps) {
    const { formSubmissionTable, title } = props;
    return (
        <div>
            {title}
            {title && <KatDivider variant='athens'/>}
            {formSubmissionTable}
        </div>
    );
}
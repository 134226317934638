/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const formSubmissionShared = /* GraphQL */ `
  subscription FormSubmissionShared(
    $formSubmissionId: String
    $entityId: String
  ) {
    formSubmissionShared(
      formSubmissionId: $formSubmissionId
      entityId: $entityId
    ) {
      statusCode
      message
      entityId
      formSubmissionId
      body {
        formSubmissionId
        entityIds
      }
    }
  }
`;

export const enumValueToOption = (aValue: any) => {
    const noSpaceValue = typeof aValue === "string" ?
        aValue.replace(/ /g,"_") :
        aValue
    return {
        name: aValue,
        value: noSpaceValue,
    };
}

export const toOptionsArray = (theEnum: any) => Object.values(theEnum).map(enumValueToOption);

import { compareIfDefined } from "src/components/common/Comparator";
import { toOptionsArray } from "src/components/common/Enums";

export enum TaskImportance {
    HIGH = 'High',
    NORMAL = 'Normal'
}

export const taskImportanceToInt: Record<TaskImportance, number> = {
    [TaskImportance.HIGH]: 0,
    [TaskImportance.NORMAL]: 1
} as const;

export function toTypedTaskImportance(taskImportance: string): TaskImportance | undefined {
    return taskImportance ?
        TaskImportance[taskImportance.toUpperCase() as keyof typeof TaskImportance] :
        undefined;
}

export function importanceStringToNumber(importance: string): number | undefined {
    const typedImportance = importance ? toTypedTaskImportance(importance) : undefined;
    return typedImportance ? taskImportanceToInt[typedImportance] : typedImportance;
}

export function compareImportance(importanceA: string, importanceB: string): number {
    const asNumberA = importanceStringToNumber(importanceA);
    const asNumberB = importanceStringToNumber(importanceB);
    return compareIfDefined(asNumberA, asNumberB, (a, b) => a - b);
}

export const taskImportanceOptions = toOptionsArray(TaskImportance);

import React from 'react';
import moment from 'moment';
import { KatButtonGroup, KatDropdown } from '@amzn/katal-react';
import { publishAuditSelectorMetric } from './selectorMetrics';


interface WeekSelectorProps {
    disabled: boolean;
    selectedAuditType: Cerebrum.Audits.AuditType;
    onChangeHandler: (event: any) => void;
    weekIndex: string;
}

export function WeekSelector(props: WeekSelectorProps) {
    const { selectedAuditType, weekIndex, onChangeHandler } = props;
    const dropdownOptions = [
        {
            name: 'Current Week',
            value: '0'
        },
        {
            name: '1 week ago',
            value: '1'
        },
        {
            name: '2 weeks ago',
            value: '2'
        },
        {
            name: '3 weeks ago',
            value: '3'
        },

    ];

    const onchange = (event: KatDropdown.ChangeEvent) => {
        const selectedWeekIndex: string = event.detail.value;

        publishAuditSelectorMetric({
            selectorName: 'timeFrameSelector',
            selectorValue: `week-${selectedWeekIndex}`,
            selectedAuditType: selectedAuditType
        });

        onChangeHandler(selectedWeekIndex);
    };

    return (
        <KatDropdown
            options={dropdownOptions}
            value={weekIndex}
            onChange={onchange}
        />
    );
}

/** Returns the week number, given the number of weeks to subtract from the current date */
export const getWeekNumber = (weekIndex: number) => {
    return moment(new Date()).subtract(weekIndex, 'week').week();
};

/** 
 * Returns an audits timeframe for a week given the number of weeks to subtract from the current date
 * 
 * @note This uses a Saturday (00:00:00) To Friday (23:59:59) week timeframe
 * 
 * @todo: Validate if Titus will use this or the amazon common Sunday - Saturday week
 */
// Defaults to the current week ("0") index.
export const getWeekTimeFrame = (weekIndex: string = "0"): Cerebrum.Audits.TimeFrame => {
    return {
        // Saturday (00:00:00) To Friday (23:59:59)
        fromDate: moment().startOf('week').subtract(1, 'day').subtract(weekIndex, 'week').toDate().getTime(),
        toDate: moment().endOf('week').subtract(1, 'day').subtract(weekIndex, 'week').toDate().getTime(),
    };
};


import React from 'react';
import { HashRouter as Router, Redirect, Route, Switch, useRouteMatch, } from "react-router-dom";
import { UserManager } from "src/user/UserIdentityManager";
import { MyPerformanceNavContainer } from "./MyPerformanceNav";
import { MyPerformanceFormContainer, myPerformanceForms } from "./MyPerformanceForm";
import { MyPerformanceHomeContainer } from "./MyPerformanceHome";
import { MyPerformanceCoachingLogContainer } from "./MyPerformanceCoachingLog";
import { UserRestrictedComponent } from "src/components/authorization/UserRestrictedComponent"
import { ComponentID } from '../authorization/ComponentRightsMapping';
import { AxiomIsItDown } from "src/components/common/AxiomIsItDown";
import MainLayout from "src/components/layout/MainLayout";

const COMPONENT_DISPLAY_NAME = "MyPerformance"
const IS_IT_DOWN_COMP_ID = 'my-performance';

export function MyPerformanceRouter() {
    const userIdentity = UserManager.getUserIdentity();
    const { path, url } = useRouteMatch();
    document.title = `${SITE_NAME} - ${COMPONENT_DISPLAY_NAME}`;
    return (
        <>
            <Router>
                <MainLayout
                    isItDown={<AxiomIsItDown componentId={IS_IT_DOWN_COMP_ID} />}
                    main={
                        <MyPerformanceNavContainer>
                            <Switch>
                                <Route path={[`${path}/feedback/:uniqueFormId`, `${path}/feedback`]} render={(props) =>
                                    <UserRestrictedComponent componentId={ComponentID.HEADER_MYPERFORMANCE_RIGHTS_ID} showNoAccessError>
                                        <MyPerformanceFormContainer formType={myPerformanceForms.feedback} uniqueFormId={props.match.params.uniqueFormId || ''} />
                                    </UserRestrictedComponent>} >
                                </Route>
                                <Route path={[`${path}/coaching-note/:uniqueFormId`, `${path}/coaching-note`]} render={(props) =>
                                    <UserRestrictedComponent componentId={ComponentID.HEADER_MYPERFORMANCE_RIGHTS_ID} showNoAccessError>
                                        <MyPerformanceFormContainer formType={myPerformanceForms.coachingNote} uniqueFormId={props.match.params.uniqueFormId || ''} />
                                    </UserRestrictedComponent>} >
                                </Route>
                                <Route path={[`${path}/leader-coaching/:uniqueFormId`, `${path}/leader-coaching`]} render={(props) =>
                                    <UserRestrictedComponent componentId={ComponentID.HEADER_MYPERFORMANCE_RIGHTS_ID} showNoAccessError>
                                        <MyPerformanceFormContainer formType={myPerformanceForms.leaderCoaching} uniqueFormId={props.match.params.uniqueFormId || ''} />
                                    </UserRestrictedComponent>} >
                                </Route>
                                <Route path={`${path}/log`} render={(props) =>
                                    <UserRestrictedComponent componentId={ComponentID.HEADER_MYPERFORMANCE_RIGHTS_ID} showNoAccessError>
                                        <MyPerformanceCoachingLogContainer />
                                    </UserRestrictedComponent>} >
                                </Route>
                                <Route exact path={`${path}`}>
                                    <Redirect to={`${path}/home/${userIdentity.login}`} />
                                </Route>
                                <Route path={`${path}/home/:viewUserId`} render={(props) =>
                                    <UserRestrictedComponent componentId={ComponentID.HEADER_MYPERFORMANCE_RIGHTS_ID} showNoAccessError>
                                        <MyPerformanceHomeContainer viewUserId={props.match.params.viewUserId || ''} />
                                    </UserRestrictedComponent>} >
                                </Route>
                            </Switch>
                        </MyPerformanceNavContainer>
                    }
                />
            </Router>
        </>
    )
}
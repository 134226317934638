import { ColumnFormatter } from "./ColumnFormatters";
import { UserManager } from "src/user/UserIdentityManager";
import { ColumnConfiguration, TableConfiguration } from "src/components/common/table/TableConfiguration";


abstract class AuditTableConfiguration implements TableConfiguration {
    // Must be set in derived classes
    abstract columns: ColumnConfiguration[];

    // Defaults, Can be overriden in derived classes
    fieldsBannedFromSearch = [];
    defaultSortColumn: keyof Cerebrum.Audits.RowData = "agent";
    itemsPerPage = 10;

    isOutSourced = UserManager.isOutSourced;
}


export class CustomerObsessionTableConfig extends AuditTableConfiguration implements TableConfiguration {
    public columns: ColumnConfiguration[];

    constructor() {
        super();
        this.columns = [
            {
                label: "",
                property: "caseId",
                formatter: ColumnFormatter.checkboxAuditCompleted
            },
            {
                label: "Associate",
                property: "agent",
                sortable: true,
                formatter: ColumnFormatter.agent
            },
            {
                label: "Case",
                property: "caseId",
                sortable: true,
                formatter: ColumnFormatter.caseId
            }
        ];

        // NOTE: Currently hiding this column for OS until Murali finalizes new river workflow approach
        const inHouseOnlyColumns = [
            {
                label: "",
                property: "",
                formatter: ColumnFormatter.moreActions
            }
        ];

        // Add the inHouseOnlyColumns columns for inhouse users.
        !this.isOutSourced && this.columns.push(...inHouseOnlyColumns);
    }
}


export class TitusTableConfig extends AuditTableConfiguration implements TableConfiguration {
    public columns: ColumnConfiguration[];

    constructor() {
        super();
        this.columns = [
            {
                label: "",
                property: "caseId",
                formatter: ColumnFormatter.checkboxAuditCompleted
            },
            {
                label: "Associate",
                property: "agent",
                sortable: true,
                formatter: ColumnFormatter.agent
            },
            {
                label: "Case",
                property: "caseId",
                sortable: true,
                formatter: ColumnFormatter.caseId
            },
            {
                label: "Ticket",
                property: "ticketId",
                sortable: true,
                formatter: ColumnFormatter.ticketId
            },
            {
                label: "",
                property: "",
                formatter: ColumnFormatter.beginAuditLink
            },
            {
                label: "Ticket Resolve Date",
                property: "resolvedDateUtc",
                sortable: true,
                formatter: ColumnFormatter.ticketResolveDate
            },
            {
                label: "Group",
                property: "assignedGroup",
                sortable: true,
                formatter: ColumnFormatter.ticketAssignedToGroup
            },
            {
                label: "Root Cause",
                property: "rootCause",
                sortable: true,
                formatter: ColumnFormatter.ticketRootCause
            }
        ];

        // NOTE: Currently hiding this column for OS until Murali finalizes new river workflow approach
        const inHouseOnlyColumns = [
            {
                label: "",
                property: "",
                formatter: ColumnFormatter.moreActions
            }
        ];

        // Add the inHouseOnlyColumns columns for inhouse users.
        !this.isOutSourced && this.columns.push(...inHouseOnlyColumns);
    }
}

export class FalconIntrospectionTableConfig extends AuditTableConfiguration implements TableConfiguration {
    public columns: ColumnConfiguration[];

    constructor() {
        super();
        this.columns = [
            {
                label: "",
                property: "caseId",
                formatter: ColumnFormatter.checkboxFalconCompleted
            },
            {
                label: "Case",
                property: "caseId",
                sortable: true,
                formatter: ColumnFormatter.falconCaseId
            },
            {
                label: "Form",
                property: "",
                formatter: ColumnFormatter.beginFalconIntrospectionLink
            },
            {
                label: "Action Date",
                property: "actionDate",
                sortable: true,
                formatter: ColumnFormatter.falconIntrospectionDate
            },
            {
                label: "Falcon Rule ID",
                property: "falconRuleId",
                sortable: true,
                formatter: ColumnFormatter.falconWikiLink
            },
            {
                label: "Org Name",
                property: "orgName",
                sortable: true
            },
            {
                label: "Case Channel",
                property: "caseChannel",
                sortable: true,
                formatter: ColumnFormatter.falconCaseChannel
            },
            {
                label: "L3 Atlas",
                property: "l3Atlas",
                sortable: true
            }
        ];
    }
}


// ts won't allow using the abstract class as the value type for the map, so they have to be listed here
type TableConfigClass =
    typeof CustomerObsessionTableConfig |
    typeof TitusTableConfig |
    typeof FalconIntrospectionTableConfig;

/** Map that connects the audit type string to the corresponding TableConfig */
export const AuditTypeTableConfigMap: { [key: string]: TableConfigClass; } = {
    // these classes are not instantiated until render time to ensure user identity has already been set
    customerObsession: CustomerObsessionTableConfig,
    titus: TitusTableConfig,
    falconIntrospection: FalconIntrospectionTableConfig
};
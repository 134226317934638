import React, { useEffect, useState, useMemo } from "react";
import "./Tasks.scss";
import moment from "moment";
import i18n from "i18next";
import { KatBox, KatCheckbox, KatColumn, KatDivider, KatInput, KatLabel, KatRow, KatSpinner } from "@amzn/katal-react";
import { CerebrumService } from "src/service/CerebrumCoralService";
import { CoralError } from "src/components/error/ErrorBoundComponent";
import { ErrorMessage } from "src/components/error/ErrorMessage";
import { taskStatusOptions } from "src/components/tasks/model/TaskStatus";
import { TasksList } from "src/components/tasks/TasksList";
import { taskTypeOptions } from "src/components/tasks/model/TaskAndNotificationType";
import { ClearableDropdown } from "src/components/common/ClearableDropdown";
import { ClearableDatePicker } from "src/components/common/ClearableDatePicker";
import { AxiomMetricsDriver } from "src/metrics/AxiomMetricsDriver";
import { EmptyListView } from "src/components/common/EmptyListView";
import { useStateWithTypedSearchParams } from "src/components/common/searchParameters/useStateWithTypedSearchParams";
import { ItemOrganizer } from "src/components/common/itemOrganizer/ItemOrganizer";
import { TASK_SORTING_GROUPING_CONFIG } from "src/components/tasks/Tasks";
import { Grouped } from "src/components/common/Grouper";
import { UrlParameterConfigKeys } from "src/config/ConfigManager";
import { Treatment, Weblab } from "src/user/Weblab";
import { TaskLogTable } from "./task_log_table/TaskLogTable";
import { Weblabs } from "src/user/WeblabsManager";
import { useHistory } from "react-router-dom";
import { UserContext } from "src/context/UserContext";

interface TasksLogProps {
    viewUserLogin: string;
}

const COMPONENT_NAME = "TasksLog";

const FILTER_CREATED_NUM_WEEKS_AGO = 6;

export const DEFAULT_CHARLOTTE_LOG_FILTER: Cerebrum.Tasks.TaskFilter & Cerebrum.Tasks.NotificationFilter = {
    includeExpired: true,
    deleted: false,
    createdFrom: moment().clone().startOf('day').subtract(FILTER_CREATED_NUM_WEEKS_AGO, 'week').toISOString()
};

export const TasksLog: React.FC<TasksLogProps> = (props) => {

    const userContext = React.useContext(UserContext);
    const loggedInUser = userContext.userManager.getUserIdentity();
    const { viewUserLogin } = props;

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [tasks, setTasks] = useState<Cerebrum.Tasks.Task[]>([]);

    const [groupedTasks, setGroupedTasks] = useState<Grouped<Cerebrum.Tasks.Task>>({});

    // filter with representation in url search parameters to allow deep linking/bookmarks
    const [filter, setFilter] = useStateWithTypedSearchParams<Cerebrum.Tasks.TaskFilter>(UrlParameterConfigKeys.FILTER, DEFAULT_CHARLOTTE_LOG_FILTER);

    const [error, setError] = useState<CoralError>();

    const history = useHistory();
    
    useEffect(() => {
        getTasks();
    }, [viewUserLogin, filter]);

    // Publish page load metrics
    useEffect(() => {
        document.title = `${SITE_NAME} - ${COMPONENT_NAME}- ${viewUserLogin}`;

        const additionalMetrics: Axiom.AdditionalMetric[] = [{ name: "viewUserLogin", value: viewUserLogin }];
        AxiomMetricsDriver.publishPageLoad(COMPONENT_NAME, window.location.href, additionalMetrics);
    }, [viewUserLogin]);

    const isLoggedInUserAuthorizedToSeeTasksOf = (requestedLogin: string) => {
        // Check if the requested login is the same as user's login or if the user is a supervisor or admin
        // If so, the user should have access to view the requested user's tasks
        return (requestedLogin === loggedInUser.login || loggedInUser.supervisor || userContext.userManager.isTestUser);
    }

    const getTasks = async () => {
        setIsLoading(true);
        const input: Cerebrum.Tasks.GetTasksInput = { taskOwner: viewUserLogin, taskFilter: filter };
        if (isLoggedInUserAuthorizedToSeeTasksOf(viewUserLogin)) {
            await CerebrumService.getTasks(input)
                .then(handleResponse)
                .catch(handleError);
        } else {
            handleError({
                __type: "Unauthorized",
                message: `${loggedInUser.login}@ is not authorized to view tasks for ${viewUserLogin}@`
            })
        }
    }

    const handleResponse = (response: Cerebrum.Tasks.GetTasksOutput) => {
        setTasks(response.tasks)
        setIsLoading(false);
        setError(undefined);
    }

    const handleError = (coralError: CoralError) => {
        // tslint:disable-next-line:no-console
        console.error(coralError);
        setTasks([]);
        setIsLoading(false);
        setError(coralError);
    }

    // UI Elements
    const LoadingSpinner = () => (
        <>{isLoading &&
        <div className="row justify-content-center"><KatSpinner size="large"/></div>}
        </>
    );

    const LoadingErrorMessage = () => (
        // TODO: error handling to be improved in https://issues.amazon.com/issues/SUPSUP-781
        <>{error &&
        <ErrorMessage
            title={"Error getting Tasks..."}
            message={error.__type + "\n" + error.message ? error.message : ""}
            level="warning"/>
        }</>
    );

    /**
     * Helper curried function to avoid repeating the event handler for each input field.
     * Receives a key of TaskFilter to have compiler's help with the field to update definition.
     * @param key indicating the field to update
     */
    const update = (key: keyof Cerebrum.Tasks.TaskFilter) => {
        return (event: any) => {
            setFilter(current => {
                const updated = { ...current };
                updated[key] = event.target.value;
                return updated;
            });
        }
    }

    /**
     * Helper curried function to avoid repeating the event handler for each date input field.
     * Receives a key of TaskFilter to have compiler's help with the field to update definition.
     * @param dateField indicating the field to update
     */
    const updateDate = (dateField: keyof Cerebrum.Tasks.TaskFilter) => {
        return (event: any) => {
            const time = moment(event.target.value);
            update(dateField)({ target: { value: time.toISOString() } })
        }
    }

    /**
     * Helper curried function to avoid repeating the event handler for each check box input field.
     * Receives a key of TaskFilter to have compiler's help with the field to update definition.
     * @param key indicating the field to update
     */
    const updateCheckBox = (key: keyof Cerebrum.Tasks.TaskFilter) => {
        return (event: any) => {
            setFilter(current => {
                const updated = { ...current };
                updated[key] = event.target.checked;
                return updated;
            });
        }
    }

    const SearchParameters = () => <>
        <h5>Search parameters</h5>
        <ClearableDropdown label='Category' options={taskTypeOptions} value={filter.taskType}
                           onUpdate={update("taskType")}/>
        <ClearableDropdown label='Status' options={taskStatusOptions} value={filter.taskStatus}
                           onUpdate={update("taskStatus")}/>
        <KatCheckbox className='py-2' label='Show deleted' checked={filter.deleted}
                     onChange={updateCheckBox('deleted')}/>
        <KatCheckbox className='pt-2 pb-4' label='Include expired' checked={filter.includeExpired}
                     onChange={updateCheckBox('includeExpired')}/>
        <ClearableDatePicker label='Date created from' value={filter.createdFrom}
                             onUpdate={updateDate('createdFrom')}/>
        <ClearableDatePicker label='Date created to' value={filter.createdTo}
                             onUpdate={updateDate('createdTo')}/>
    </>;

    /* TODO: When removing tasks revamp weblab, rename this to SearchParameters and remove the one above */
    const HorizontalSearchParameters = () => <>
        <fieldset className="border mx-2 mb-3">
            <legend className="d-inline-block ml-2 px-1 mb-0 mr-auto" style={{width: '8rem'}}>
                <h5 className="pt-2">Query parameters</h5>
            </legend>

            <KatRow>
                <KatColumn md={12} className="d-flex pb-2" >
                    <ClearableDatePicker
                        label='Date created from'
                        value={filter.createdFrom}
                        onUpdate={updateDate('createdFrom')}
                    />
                    <ClearableDatePicker
                        label='Date created to'
                        value={filter.createdTo}
                        onUpdate={updateDate('createdTo')}
                    />
                    <KatColumn md={2}>
                        <ClearableDropdown
                            label='Category'
                            options={taskTypeOptions}
                            value={filter.taskType}
                            onUpdate={update("taskType")}
                        />
                    </KatColumn>
                    <KatColumn md={2}>
                        <ClearableDropdown
                            label='Status'
                            options={taskStatusOptions}
                            value={filter.taskStatus}
                            onUpdate={update("taskStatus")}
                        />
                    </KatColumn>
                    <KatCheckbox
                        className='align-self-center pt-3 mr-3'
                        label='Show deleted'
                        checked={filter.deleted}
                        onChange={updateCheckBox('deleted')}
                    />
                    <KatCheckbox
                        className='align-self-center pt-3'
                        label='Include expired'
                        checked={filter.includeExpired}
                        onChange={updateCheckBox('includeExpired')}
                    />
                </KatColumn>
            </KatRow>
        </fieldset>
    </>

    const TasksLists = () => (
        <>{!isLoading && tasks.length > 0 &&
            <>
                {Object.keys(groupedTasks).sort().map((key: string, index: number) => <>
                    <TasksList title={key}
                               key={`${key}-${index}-task`}
                               tasks={groupedTasks[key]}
                               viewUserLogin={viewUserLogin}
                               noTasksMessage={i18n.t('wfm_axiom_tasks_no_tasks')}
                               showFeedbackOption/>
                    {index !== Object.keys(groupedTasks).length - 1 &&
                        <KatDivider variant='eastern' key={`${key}-${index}-divider`}/>}
                </>)}
            </>}
        </>
    );

    const SearchResults = () => <>
        <LoadingErrorMessage/>
        <LoadingSpinner/>
        <>
            {tasks.length > 0 &&
            <TasksLists/>}
        </>
    </>;

    const NoResultsView = () => (
        <>{!isLoading && tasks.length === 0 &&
        <EmptyListView iconName='check' iconBorder
                       message='There are no results for the selected filters.'/>
        }</>
    )

    return (
        <div className='p-3'>
            <KatRow>
                <KatColumn md={12}>
                    <h1>Tasks Log</h1>
                </KatColumn>
            </KatRow>
            <div>
                <HorizontalSearchParameters />
                <LoadingErrorMessage />
                {!error && <KatRow>
                    <KatColumn md={12} className="mb-4">
                        <TaskLogTable
                            taskList={tasks}
                            loading={isLoading}
                            setTasks={setTasks}
                            viewUserLogin={viewUserLogin}
                        />
                    </KatColumn>
                </KatRow>}
            </div>
        </div>
    );
}

import React from "react";
import {
    CollectionPreferences,
    CollectionPreferencesProps
} from "@amzn/awsui-components-react";

interface PreferencesProps {
    preferences: CollectionPreferencesProps.Preferences;
    setPreferences: Function;
    pageSizeOptions?: CollectionPreferencesProps.PageSizeOption[];
    contentDisplayOptions: CollectionPreferencesProps.ContentDisplayOption[];
}

export const DEFAULT_PAGE_SIZE_OPTIONS = [
    { value: 20, label: "20" },
    { value: 50, label: "50" },
    { value: 100, label: "100" }
];

const Preferences = (props: PreferencesProps) => {
    const {
        preferences,
        setPreferences,
        pageSizeOptions,
        contentDisplayOptions
    } = props;

    return (
        <CollectionPreferences
            preferences={preferences as any}
            onConfirm={({detail}) => setPreferences(detail)}
            pageSizePreference={{
                title: "Page size",
                options: pageSizeOptions ?? DEFAULT_PAGE_SIZE_OPTIONS
            }}
            wrapLinesPreference={{
                label: "Wrap lines",
                description: "Wrap lines to fit the table width."
            }}
            stripedRowsPreference={{
                label: "Striped rows",
                description:
                    "Display alternating rows with different background colors."
            }}
            contentDensityPreference={{
                label: "Content density",
                description: "Adjust the density of the table content."
            }}
            contentDisplayPreference={{ options: contentDisplayOptions }}
            stickyColumnsPreference={{
                firstColumns: {
                    title: "Stick first column(s)",
                    description:
                        "Keep the first column(s) visible while horizontally scrolling the table content.",
                    options: [
                        { label: "None", value: 0 },
                        { label: "First column", value: 1 },
                        { label: "First two columns", value: 2 }
                    ],
                },
                lastColumns: {
                    title: "Stick last column",
                    description:
                        "Keep the last column visible while horizontally scrolling the table content.",
                    options: [
                        { label: "None", value: 0 },
                        { label: "Last column", value: 1 }
                    ],
                },
            }}
            confirmLabel="Confirm"
            cancelLabel="Cancel"
            title="Preferences"
        />
    );
};

export { Preferences };

import React from 'react';
import { KatButton, KatCard, KatCheckbox, KatInput, KatPredictiveInput } from '@amzn/katal-react';
import { UserContext } from "src/context/UserContext";
import { Form } from 'react-bootstrap';
import { ConfigManager } from "src/config/ConfigManager";
import { useStateWithLocalStorage } from "src/components/common/storage/UseStateWithLocalStorage";


export const localStorageKeyMap: Axiom.Map = {
    SELECTED_TENANT: "selectedTenant",
    SELECTED_VERSION: "selectedVersion",
    SELECTED_LOGIN: "selectedLogin",
    SELECTED_USER_GROUP: "selectedUserGroup",
    SELECTED_HELP_CONTENT_NAV_GUID: "selectedHelpContentNavGuid",
    GROUPING_OPTION_SUFFIX: "GroupingOption",
    SORTING_OPTION_SUFFIX: "SortingOption",
    DISABLE_REVERB_STORAGE_KEY: "disableReverb",
};


export default function UserDetailsSwitcher() {
    return (
        <div className='col-6'>
            <KatCard>
                <span slot="title">User Details Switcher</span>
                <ResetUserButton />
                <Form>
                    <UserLoginField />
                    <UserGroupField />
                    <TenantField />
                    <VersionField />
                    <HelpContentNavIdField />
                    <DisableReverbField />
                </Form>
            </KatCard>
        </div>
    );
}

function UserLoginField() {
    const userContext = React.useContext(UserContext);
    const localStorageKey = localStorageKeyMap.SELECTED_LOGIN;
    const [value, setValue] = useStateWithLocalStorage<string>(localStorageKey, '');
    if (!value) {
        setValue(userContext.userManager.userIdentity!.login);
    }
    const onChange = (event: any) => {
        setValue(event.target.value);
    };

    React.useEffect(() => {
        userContext.userManager.userIdentity!.login = value;
        userContext.setUserManager();
    }, [value]);

    return (
        <KatInput className="mb-2"
                  type="text"
                  label="User Login Id (just type in the id and hit `Enter`)"
                  value={value}
                  onChange={onChange}
        />
    );
}

function UserGroupField() {
    const userContext = React.useContext(UserContext);
    const localStorageKey = localStorageKeyMap.SELECTED_USER_GROUP;
    const [value, setValue] = useStateWithLocalStorage<string>(localStorageKey, '');
    if (!value) {
        setValue(userContext.userManager.userIdentity!.outsourced.toString());
    }
    const onChange = (event: any) => {
        setValue(event.target.checked.toString());
    };

    React.useEffect(() => {
        userContext.userManager.isOutSourced = value === 'true';
        userContext.setUserManager();
    }, [value]);

    return (
        <KatCheckbox label="isOutsourced" checked={value === 'true'} onChange={onChange} className="mb-2" />
    );
}

function TenantField() {
    const userContext = React.useContext(UserContext);
    const options = ["0", "1"];
    const localStorageKey = localStorageKeyMap.SELECTED_TENANT;
    const [value, setValue] = useStateWithLocalStorage<string>(localStorageKey, '1');
    const onChange = (event: any) => {
        setValue(event.target.value);
    };

    React.useEffect(() => {
        userContext.userManager.tenantId = value;
        userContext.setUserManager();
    }, [value]);

    return (
        <KatPredictiveInput className="mb-2"
                            label="Tenant Id Selection"
                            options={options}
                            value={value}
                            onChange={onChange}
        />
    );
}

function VersionField() {
    const userContext = React.useContext(UserContext);
    const options = ["v3", "1"];
    const localStorageKey = localStorageKeyMap.SELECTED_VERSION;
    const [value, setValue] = useStateWithLocalStorage<string>(localStorageKey, '');
    const onChange = (event: any) => {
        setValue(event.target.value);
    };

    React.useEffect(() => {
        userContext.userManager.versionId = value;
        userContext.setUserManager();
    }, [value]);

    return (
        <KatPredictiveInput className="mb-2"
                            label={"BSC Version Id Selection"}
                            options={options}
                            value={value}
                            onChange={onChange}
        />
    );
}

// Admin can set the help content nav GUID with override value for testing purposes
function HelpContentNavIdField() {
    const localStorageKey = localStorageKeyMap.SELECTED_HELP_CONTENT_NAV_GUID;
    const [value, setValue] = useStateWithLocalStorage<string>(localStorageKey, '');
    const onChange = (event: any) => {
        setValue(event.target.value);
    };

    if (!value) {
        setValue(ConfigManager.getStaticConfig().isProd ? // TODO(SUPSUP-977): move definition of menu id to tenant config
            'G28CK2TSKJEUARLW' :
            'GR3PJMEH7BXDJQJT');
    }

    return (
        <KatInput className="mb-2"
                  type="text"
                  label={"Help Content Nav GUID"}
                  value={value}
                  onChange={onChange}
        />
    );
}

// Admins can disable reverb modal from popping up on page load
function DisableReverbField() {
    const localStorageKey = localStorageKeyMap.DISABLE_REVERB_STORAGE_KEY;
    const [value, setValue] = useStateWithLocalStorage<boolean>(localStorageKey, false);

    const onChange = (event: any) => {
        setValue(event.target.checked.toString());
    };

    return (
        <KatCheckbox label="disableReverb" checked={value} onChange={onChange} className="mb-2" />
    );
}

function ResetUserButton() {
    const onClick = () => {
        for (const key in localStorageKeyMap) {
            localStorage.removeItem(localStorageKeyMap[key]);
        }
        location.reload();
    };
    return (
        <KatButton label="Reset" slot="action" variant="secondary" onClick={onClick} />
    );
}
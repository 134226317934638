import React from 'react'
import { AxiomMetricsDriver } from "src/metrics/AxiomMetricsDriver";
import { Navbar } from "react-bootstrap";

export function HeaderBrand() {
    const homeHref = '#'
    return (
        <Navbar.Brand onClick={() => AxiomMetricsDriver.publishButtonClick('Header', 'home')}
            href={homeHref} className="no-underline">
            <svg width="40px" height="30px" viewBox="0 0 123 117" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <title>Home</title>
                <g id="Home" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <polygon id="Triangle-Copy-7" fill="#00A4B4" points="60 2.24216442 24.3691589 109.242164 1.10791122 44.53067"></polygon>
                    <polygon id="Triangle-Copy-6" fill="#00A4B4" transform="translate(90.176849, 51.219443) rotate(145.000000) translate(-90.176849, -51.219443) " points="118.676849 -4.78055708 84.8275495 107.219443 61.6768486 37.0983394"></polygon>
                    <path d="M74.8105602,80.116117 L59.6826112,129.911224 C48.2979409,129.190733 42.0976993,124.541949 41.0818863,115.964874 C40.0660734,107.387798 41.9227923,96.3670403 46.652043,82.9025993 L74.8105602,80.116117 Z" id="Triangle-Copy-5" fill="#00A4B4" transform="translate(57.811515, 105.013671) rotate(72.000000) translate(-57.811515, -105.013671) "></path>
                    <polygon id="Triangle-Copy-4" fill="#00A4B4" transform="translate(57.739606, 66.038763) rotate(82.000000) translate(-57.739606, -66.038763) " points="79.7417743 46.7188649 74.3113051 85.3586608 35.7374371 57.7662342"></polygon>
                    <path d="M64.4225532,3.13045632 L118.924203,42.3926486 C120.691572,43.6658354 121.431341,45.9366892 120.75304,48.0065939 L99.9526186,111.48119 C99.2795186,113.535222 97.3627365,114.924174 95.2012289,114.924174 L27.7987711,114.924174 C25.6372635,114.924174 23.7204814,113.535222 23.0473814,111.48119 L2.24696033,48.0065939 C1.5686592,45.9366892 2.30842775,43.6658354 4.07579677,42.3926486 L58.5774468,3.13045632 C60.322938,1.87302992 62.677062,1.87302992 64.4225532,3.13045632 Z" id="Polygon-Copy" stroke="#002F36" strokeWidth="4"></path>
                    <path d="M41.5130168,113.486956 L47.0551302,94.7914294 L75.0015314,94.7914294 L80.7794793,113.486956 L100,113.486956 L64.1492672,2.50108317 L58.4164305,2.50108317 L23,113.486956 L41.5130168,113.486956 Z M72,81.4869561 L51,81.4869561 L61.3793103,47.4869561 L72,81.4869561 Z" id="A-Copy" fill="#002F36" fillRule="nonzero"></path>
                    <path d="M41.5130168,113.486956 L47.0551302,94.7914294 L75.0015314,94.7914294 L80.7794793,113.486956 L100,113.486956 L64.1492672,2.50108317 L58.4164305,2.50108317 L23,113.486956 L41.5130168,113.486956 Z M72,81.4869561 L51,81.4869561 L61.3793103,47.4869561 L72,81.4869561 Z" id="A-Copy" fill="#002F36" fillRule="nonzero"></path>
                </g>
            </svg>
        </Navbar.Brand >
    )
}

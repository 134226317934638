import React from 'react';
import { AxiomIsItDown } from '../common/AxiomIsItDown';

const DASHBOARD_ID = 'wfm-axiom';
export interface AuditIsItDownProps {
    auditType: Cerebrum.Audits.AuditType;
}

/** Provides the proper IsItDown Props based on the selected Audit Type */
export const AuditIsItDown: React.FC<AuditIsItDownProps> = (props) => {
    const { auditType } = props;
    const componentIdMap: Axiom.Map = {
        atm: 'audit',
        titus: 'titus',
        falconIntrospection: 'falcon-introspection',
    };
    const componentId = componentIdMap[auditType];
    // Note: the key prop is used to ensure that React properly refreshes the component. 
    return <AxiomIsItDown key={componentId} componentId={componentId} dashboardId={DASHBOARD_ID} />;
};

import React, { useEffect } from 'react'
import { KatCard, KatInput, KatSpinner, KatDivider, KatAlert } from '@amzn/katal-react'
import { UserContext } from "src/context/UserContext";
import { CerebrumService } from 'src/service/CerebrumCoralService';
import UserMocks from "../../../__mocks__/src/service/UserMocks";

// Sets up an empty details object for the initial state
let emptyDetails = UserMocks.GET_USER_DETAILS_AGENT;
emptyDetails.login = "";

export default function UserLookup() {
    const userContext = React.useContext(UserContext);
    const userLogin = userContext.userManager.userIdentity!.login;
    const [login, setLogin] = React.useState(userLogin);
    const [userDetails, setUserDetails] = React.useState(emptyDetails);
    const [hasError, setHasError] = React.useState<boolean>(false);
    const onChange = (event: any) => { setLogin(event.target.value) };

    async function fetchUserData(id: string) {
        const response = await CerebrumService.getUserDetails(id).catch(error => { setHasError(true) });
        if (response) {
            setUserDetails(await response);
        }
    }

    useEffect(() => {
        setHasError(false);
        setUserDetails(emptyDetails);
        fetchUserData(login);
    }, [login]);

    let detailsContent = userDetails.login ? <UserDetailsReadout userDetails={userDetails} /> : <KatSpinner size="large" />;

    return (
        <div className='col-6'>
            <KatCard>
                <span slot="title">User Lookup</span>
                <KatInput className="mb-2"
                    type="text"
                    label="Login id"
                    value={login}
                    onChange={onChange}
                />
                <KatDivider variant="athens" />
                {hasError
                    ? <KatAlert variant="warning">There was an error getting this user; Check network response</KatAlert>
                    : detailsContent
                }
            </KatCard>
        </div>
    )
}

interface UserDetailsReadoutProps {
    userDetails: WFM.GetUserDetails;
}

function UserDetailsReadout(props: UserDetailsReadoutProps) {
    return (
        <div><pre>{JSON.stringify(props.userDetails, null, 2)}</pre></div>
    )
}
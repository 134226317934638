import React, { PropsWithChildren, useContext, useEffect, useState } from "react";
import { AxiomMetricsDriver } from "src/metrics/AxiomMetricsDriver";
import { useHistory } from "react-router-dom";
import { KatAlert } from "@amzn/katal-react";
import { LayoutContext } from "./LayoutContext";

interface BannerContext {
    toggleBannerVisible: (toggleBannerVisibleProps: ToggleBannerVisibleProps) => void;
    clearBanner: () => void;
    isBannerVisible: boolean;
    description: string;
    extraContent: JSX.Element;
    header: string;
    linkHref: string;
    linkLabel: string;
    variant: KatAlert.Variant;
}

export interface ToggleBannerVisibleProps {
    description: string;
    extraContent?: JSX.Element;
    header: string;
    linkHref?: string;
    linkLabel?: string;
    dismissBannerAfterSeconds?: number;
    variant: KatAlert.Variant;
}

const defaultBannerContext: BannerContext = {
    toggleBannerVisible: () => undefined,
    clearBanner: () => undefined,
    isBannerVisible: false,
    description: '',
    extraContent: <div/>,
    header: '',
    linkHref: '',
    linkLabel: '',
    variant: 'info' as KatAlert.Variant
};

export const BannerContext = React.createContext<BannerContext>(defaultBannerContext)

interface BannerContextProps {
    children: any;
}

const COMPONENT_NAME = 'BannerContext';

export const BannerContextProvider = (props: PropsWithChildren<BannerContextProps>) => {

    const [description, setDescription] = useState('');
    const [header, setHeader] = useState('');
    const [linkHref, setLinkHref] = useState('');
    const [linkLabel, setLinkLabel] = useState('');
    const [variant, setVariant] = useState<KatAlert.Variant>('info');
    const [extraContent, setExtraContent] = useState(<div/>);

    const { isBannerVisible, setBannerVisible} = useContext(LayoutContext)
    const history = useHistory();

    const [loadedInPath, setLoadedInPath] = useState(history.location.pathname);

    useEffect(() => {
        const additionalMetrics = [{name: "window.location.href", value: window.location.href}];
        AxiomMetricsDriver.publishButtonClick(COMPONENT_NAME, isBannerVisible ? 'bannerVisible' : 'bannerHidden', additionalMetrics);
    }, [isBannerVisible])

    const toggleBannerVisible = (bannerContext: ToggleBannerVisibleProps) => {
        const SECOND = 1000;

        const {
            description: descriptionProp,
            extraContent: extraContentProp,
            header: headerProp,
            linkHref: linkHrefProp,
            linkLabel: linkLabelProp,
            dismissBannerAfterSeconds: dismissBannerAfterSecondsProp,
            variant: variantProp
        } = bannerContext;

        setDescription(descriptionProp);
        setExtraContent(extraContentProp || <div/>);
        setHeader(headerProp);
        setLinkHref(linkHrefProp || '');
        setLinkLabel(linkLabelProp || '');
        setVariant(variantProp);
        setBannerVisible(true);
        if (dismissBannerAfterSecondsProp) {
            setTimeout(() => {
                clearBanner();
            }, dismissBannerAfterSecondsProp * SECOND);
        }
    };

    // Always clear the banner on route change
    useEffect(() => {
        history.listen(() => {
            if(loadedInPath === '/') setLoadedInPath(history.location.pathname);
            else if(loadedInPath !== history.location.pathname) clearBanner();
        })
    }, [history])

    const clearBanner = () => {
        setDescription('');
        setExtraContent(<div/>);
        setHeader('');
        setLinkHref('');
        setLinkLabel('');
        setBannerVisible(false);
        setVariant('info');
    }

    const context: BannerContext = {
        toggleBannerVisible,
        clearBanner,
        // read-only fields:
        isBannerVisible,
        description,
        extraContent,
        header,
        linkHref,
        linkLabel,
        variant
    }

    return (
        <BannerContext.Provider value={context}>
            {props.children}
        </BannerContext.Provider>
    );
}

export default class ExternalResourceService {

    public static userAvatar(userIdentity: WFM.GetUserInformation) {
        return "https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=" +
            (userIdentity ? userIdentity.login : "");
    }

    public static phoneToolLink(userId: string): string {
        return userId ? `https://phonetool.amazon.com/users/${userId}` : "";
    }

}
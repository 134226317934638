import React, { useState } from 'react';
import i18n from 'i18next';
import { AxiomMetricsDriver } from 'src/metrics/AxiomMetricsDriver';
import {
    FeedbackCard,
    FeedbackCardProps,
    FeedbackResponse,
    FeedbackState
} from '@amzn/wfm-axiom-flash-briefing-ui-components';

export interface FeedbackContainerProps {
    cardMetadata: Partial<Cerebrum.CardMetadata>,
    onClose: () => void,
    onSubmit?: (x: FeedbackResponse) => void,
}

export function FeedbackContainer(props: FeedbackContainerProps) {
    const [hmdState, setHmdState] = useState<FeedbackState>("normal");

    const onClose = () => {
        if (hmdState !== "normal") setHmdState("normal");
        props.onClose();
    }

    const handleFeedback = (feedback: FeedbackResponse) => {
        publishFeedbackMetrics(feedback, props.cardMetadata);
    }

    const onSubmit = (r: FeedbackResponse) => {
        setHmdState("busy");
        handleFeedback(r);
        setTimeout(() => {
            setHmdState("success");
        }, 500);
    }

    const feedbackCardProps: FeedbackCardProps = {
        state: hmdState,
        feedbackTitle: i18n.t("wfm_axiom_fb_feedback_card_title"),
        feedbackActionTitle: i18n.t("wfm_axiom_fb_feedback_card_action_title"),
        feedbackActionSuccessTitle: i18n.t("wfm_axiom_fb_feedback_close"),
        starRatingLabel: i18n.t("wfm_axiom_fb_feedback_card_stars_label"),
        commentPlaceholder: i18n.t("wfm_axiom_fb_feedback_card_comment_placeholder"),
        missingStarsLabel: i18n.t("wfm_axiom_fb_feedback_card_empty_stars_label"),
        onClose,
        onSubmit,
    }

    return (
        <FeedbackCard {...feedbackCardProps} />
    )
}


function publishFeedbackMetrics(feedback: FeedbackResponse, cardMetadata: Partial<Cerebrum.CardMetadata>) {
    const { publisherName, tenantId, actionUrl, cardId, cardType, cardTitle } = cardMetadata;
    const { stars, comment } = feedback;
    const additionalMetrics = [
        {
            name: "publisherName",
            value: publisherName || ''
        },
        {
            name: "tenantId",
            value: tenantId || ''
        },
        {
            name: "stars",
            value: stars.toString()
        },
        {
            name: "comment",
            value: comment || ""
        },
        {
            name: "cardId",
            value: cardId || ''
        },
        {
            name: "cardType",
            value: cardType || ''
        },
        {
            name: "cardTitle",
            value: cardTitle || ''
        }
    ];
    if (actionUrl) {
        additionalMetrics.push({
            name: "destination",
            value: actionUrl
        })
    }
    AxiomMetricsDriver.publishButtonClick('FlashBriefingCard', 'Feedback', additionalMetrics);
}
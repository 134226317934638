import React from "react";
import { KatButton, KatButtonGroup } from "@amzn/katal-react";
import { ButtonGroupFilterConfig, GroupButton, } from "src/@types/extendedPolarisTable";

export interface ButtonGroupFilterProps {
    config: ButtonGroupFilterConfig;
    eventHandler: (e: KatButtonGroup.ChangeEvent) => void;
    value: string;
}

export default function ButtonGroupFilter(props: ButtonGroupFilterProps) {
    const { config, eventHandler, value } = props;
    const { attributeName, buttons, customStyle } = config;

    return (
        <div className="mr-3 mb-sm-1" style={customStyle}>
            <KatButtonGroup
                value={value}
                onChange={eventHandler}
                data-attribute-name={attributeName}
            >
                {buttons.map((button: GroupButton, index: number) => {
                    return (
                        <KatButton
                            label={button.label}
                            key={`filter-button-${attributeName}-${index}`}
                            value={button.value as string}
                        ></KatButton>
                    );
                })}
            </KatButtonGroup>
        </div>
    );
}

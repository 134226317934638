import moment from 'moment';

/**
 * Returns the Amazon Week Number for the given date as an integer
 * See: https://w.amazon.com/bin/view/CalculatingAmazonWeekNumbers
 * @param date
 */
export function dateToAmazonWeekAsInt(date: Date) {
    const ww = dateToAmazonWeekAsWW(date);
    return parseInt(ww);
}

/**
 * Returns the Amazon Week Number for the given date formatted as a two digits string
 * See: https://w.amazon.com/bin/view/CalculatingAmazonWeekNumbers
 * @param date
 */
export function dateToAmazonWeekAsWW(date: Date) {
    return moment(date).add(1, 'day').format('WW');
}

/**
 * Returns the Amazon Week Number corresponding to the current date minus
 * the given number of weeksAgo formatted as a two digits string
 * See: https://w.amazon.com/bin/view/CalculatingAmazonWeekNumbers
 * @param weeksAgo The amount of weeks in the past from now
 */
export function amazonWeeksAgoAsInt(weeksAgo: number) {
    const now = new Date();
    return subtractAmazonWeeksAsInt(now, weeksAgo);
}

/**
 * Subtracts an amount of weeks to the given baseDate and returns its Amazon Week as int
 * See: https://w.amazon.com/bin/view/CalculatingAmazonWeekNumbers
 * @param baseDate
 * @param weeksToSubtract
 */
export function subtractAmazonWeeksAsInt(baseDate: Date, weeksToSubtract: number) {
    const past = moment(baseDate).subtract(weeksToSubtract, 'weeks');
    return dateToAmazonWeekAsInt(past.toDate());
}

import React, { Component } from 'react';
import i18n from 'i18next';
import { UserManager } from "src/user/UserIdentityManager";
import { KatDataTable, KatPagination } from '@amzn/katal-react';
import { diveColumnFormatter, osAgentDiveColumnFormatter } from './DiveColumnFormatters';


enum KatalChannelIcons {
    WORKITEM = "email", VOICE = "call", CHAT = "chat-bubbles"
}

interface RecommendationTableProps {
    recommendations?: Cerebrum.RecommendationTableData[];
    selectionFilters?: Axiom.SelectionFilter[];
    isSupervisor: boolean;

    //search
    searchTerm?: string;
}

interface RecommendationTableState {
    //pagination
    currentPageNumber: number;
    currentPageRecommendations: Cerebrum.RecommendationTableData[]; //to keep only the X amount of visible recommendations

    //sort
    sortDirection: string;
    sortProperty: string;

    filteredAndSortedRecommendations: Cerebrum.RecommendationTableData[]; //to keep the sorted data
}

const ITEMS_PER_PAGE = 10;
const FIELDS_BANNED_FROM_SEARCH = ["diveLink", "rank"];

export default class RecommendationTable extends Component<RecommendationTableProps, RecommendationTableState> {

    agentColumn = {
        label: i18n.t('wfm_axiom_v2_agent').toUpperCase(),
        property: "agentId",
        sortable: true
    };

    columns = [
        this.agentColumn,
        {
            label: i18n.t('wfm_axiom_v2_Metric').toUpperCase(),
            property: "metric",
            formatter: this.formatMetricColumn,
            sortable: true
        },
        {
            label: i18n.t('wfm_axiom_v2_impact').toUpperCase(),
            property: "scoreImpact",
            formatter: this.formatScoreImpactColumn,
            sortable: true
        },
        {
            label: i18n.t('wfm_axiom_v2_channel').toUpperCase(),
            property: "channel",
            formatter: this.formatChannelColumn,
            sortable: true
        },
        {
            label: i18n.t('wfm_axiom_v2_skill').toUpperCase(),
            property: "skill",
            sortable: true
        },
        {
            label: i18n.t('wfm_axiom_v2_dive').toUpperCase(),
            property: "diveLink",
            formatter: this.formatDiveLinkColumn
        }
    ];

    katDataTableRef: any;
    katDataTable: any;

    constructor(props: RecommendationTableProps) {
        super(props);
        this.katDataTableRef = React.createRef();
        this.state = {
            currentPageNumber: 1,
            currentPageRecommendations: [],
            filteredAndSortedRecommendations: [],
            sortDirection: "",
            sortProperty: ""
        };

        this.pageChangedHandler = this.pageChangedHandler.bind(this);
        this.settingsChangedHandler = this.settingsChangedHandler.bind(this);
        this.filterRecommendations = this.filterRecommendations.bind(this);
        this.sortRecommendations = this.sortRecommendations.bind(this);
        this.paginateRecommendations = this.paginateRecommendations.bind(this);
    }

    componentDidMount() {
        this.katDataTable = this.katDataTableRef.current;
        this.katDataTable.addEventListener("settingsChanged", this.settingsChangedHandler);
    }

    componentDidUpdate(prevProps: Readonly<RecommendationTableProps>): void {
        if (this.props == prevProps) {
            return;
        }

        let agentColumnIndex = this.columns.indexOf(this.agentColumn);
        let columns = this.columns;
        //If the user is an agent, all the recommendations are for him/her, so the Agent column is not required.
        if (agentColumnIndex >= 0 && !this.props.isSupervisor) {
            columns.splice(agentColumnIndex, 1);
        }
        //if the user is a supervisor, she/he needs the agent column at index zero for the recommendations.
        else if (agentColumnIndex == -1 && this.props.isSupervisor) {
            columns.splice(0, 0, this.agentColumn);
        }
        this.columns = [...columns];

        //the API call finished and must refresh the UI or a new selection filter must be applied.
        this.setState({ filteredAndSortedRecommendations: this.props.recommendations! },
            () => this.filterRecommendations());
    }

    render() {
        return (
            <div>
                <KatDataTable
                    ref={this.katDataTableRef}
                    columns={this.columns}
                    rowData={this.state.currentPageRecommendations}
                />
                <KatPagination
                    totalItems={this.state.filteredAndSortedRecommendations.length}
                    itemsPerPage={ITEMS_PER_PAGE}
                    page={this.state.currentPageNumber}
                    onPageChanged={this.pageChangedHandler}
                    className={"kat-pull-right mt-2"}
                />
            </div>
        )
    }

    /*
    * Formatting functions
    * */
    private formatMetricColumn(rowIndex: number, rowData: Cerebrum.RecommendationTableData, previouslyRenderedElement: any) {
        return { text: rowData.metric.toUpperCase() };
    }

    private formatScoreImpactColumn(rowIndex: number, rowData: Cerebrum.RecommendationTableData, previouslyRenderedElement: any) {
        let number: number = rowData.scoreImpact;
        let fixedDecimals = Math.floor(number * 100) / 100; //100 to round to 2 decimals
        return { text: fixedDecimals };
    }

    private formatChannelColumn(rowIndex: number, rowData: Cerebrum.RecommendationTableData, previouslyRenderedElement: any) {
        let iconName = KatalChannelIcons[rowData.channel.toUpperCase() as keyof typeof KatalChannelIcons];
        let attributes = { "class": "text-center" };

        if (iconName === undefined) {
            return { text: "-", attributes: attributes };
        }

        // Use previouslyRenderedElement where possible to improve performance.
        let element = previouslyRenderedElement ? previouslyRenderedElement : document.createElement("kat-icon");
        element.name = iconName;
        element.size = "small";

        return { element: element, attributes: attributes };
    }

    private formatDiveLinkColumn(rowIndex: number, rowData: Cerebrum.RecommendationTableData, previouslyRenderedElement: any) {
        let linkformatter = diveColumnFormatter; // This is the default formatter
        if (UserManager.isOutSourced && UserManager.isAgent) {
            // Unique logic for OS agents, treating as a special case because it could reduce performance.
            linkformatter = osAgentDiveColumnFormatter;
        }
        return linkformatter(rowIndex, rowData, previouslyRenderedElement);
    }

    /*
    * Event Handlers
    * */
    private settingsChangedHandler(event: any) {
        if (!event.detail.sortColumn || !event.detail.sortDirection) {
            return;
        }

        this.setState({
            sortDirection: event.detail.sortDirection,
            sortProperty: event.detail.sortColumn.property
        }, () => this.sortRecommendations())
    }

    private pageChangedHandler(event: any) {
        if (!event.detail.page) {
            return;
        }
        this.setState({ currentPageNumber: event.detail.page }, () => this.paginateRecommendations())
    }

    /* Data Handling
    * The steps must be followed in this priority order:
    * 1- Filter by selection filters (e.g selected metric in the BSC Table) and then by search term
    * 2- Sort
    * 3- Paginate
    */
    private filterRecommendations() {
        let recommendations = this.props.recommendations!;
        let searchTerm = this.props.searchTerm?.toLowerCase() || '';
        let filters = this.props.selectionFilters ?? [];

        if (this.props.searchTerm != "" || filters.length > 0) {
            recommendations = recommendations.filter((recommendation: Cerebrum.RecommendationTableData) => {
                //Entries are [field, value]
                let entries = Object.entries(recommendation).filter((entry) => { return !FIELDS_BANNED_FROM_SEARCH.includes(entry[0]) });
                let matchesFilter = true;

                //first filter by selected filters
                for (let filter of filters) {
                    if (entries.filter((entry) => { return filter.field == entry[0] && filter.value == entry[1] }).length <= 0) {
                        matchesFilter = false;
                        break;
                    }
                }

                if (!matchesFilter || searchTerm == "") { return matchesFilter }

                //then filter by search term
                for (let [, value] of entries) {
                    if ((value as any).toString().toLowerCase().includes(searchTerm.toLowerCase())) {
                        return true;
                    }
                }
                return false
            })
        }

        this.setState({ filteredAndSortedRecommendations: recommendations }, () => this.sortRecommendations())
    }

    private sortRecommendations() {
        let recommendations = this.state.filteredAndSortedRecommendations;
        let property = this.state.sortProperty;
        let direction = this.state.sortDirection;

        if (property != "" && direction != "") {
            recommendations = recommendations.sort(function (recommendationA: any, recommendationB: any) {
                if (recommendationA[property] < recommendationB[property]) {
                    return -1;
                } else if (recommendationA[property] > recommendationB[property]) {
                    return 1;
                } else {
                    return 0;
                }
            });

            if (direction === "desc") {
                recommendations = recommendations.reverse();
            }
        }

        this.setState({ filteredAndSortedRecommendations: recommendations }, () => this.paginateRecommendations());
    }

    private paginateRecommendations() {
        let startIndex = (this.state.currentPageNumber - 1) * ITEMS_PER_PAGE; //* -1 because index start at zero
        let endIndex = (startIndex + ITEMS_PER_PAGE) > this.state.filteredAndSortedRecommendations.length
            ? this.state.filteredAndSortedRecommendations.length : (startIndex + ITEMS_PER_PAGE);

        this.setState({ currentPageRecommendations: this.state.filteredAndSortedRecommendations.slice(startIndex, endIndex) });
    }
}

import React from 'react';
import { CoralError } from '../error/ErrorBoundComponent';
import { ErrorMessage } from '../error/ErrorMessage';
import { HeaderRoute } from "src/components/common/header/HeaderRoute";
import { useHistory } from "react-router-dom";
import { KatLink } from "@amzn/katal-react";
import { AxiomMetricsDriver } from "src/metrics/AxiomMetricsDriver";


interface AuditErrorProps {
    error: CoralError;
}

export function AuditError(props: AuditErrorProps) {
    const { __type } = props.error;
    const title = `Error:  ${__type}`;
    return (
        <ErrorMessage
            message={<Message {...props} />}
            title={title}
            level="warning"
        />
    );
}

function Message(props: AuditErrorProps) {

    const COMPONENT_NAME = 'AuditErrorMessage';
    const history = useHistory();

    const auditHelpLink = (isAuxClick: boolean) => () => {
        const route = HeaderRoute.HELP;  // Specific Audit page not available in new help content yet
        AxiomMetricsDriver.publishLinkClick(COMPONENT_NAME, 'AuditHelp', route, undefined, isAuxClick);
        if (isAuxClick) {
            window.open(`#${route}`); // open in new tab
        } else {
            history.push(route);
        }
    };

    return (
        <div className="mt-2">
            <pre>
                {props.error.message}
            </pre>
            <p>Refer to the <KatLink
                onClick={auditHelpLink(false)}
                // @ts-ignore
                onAuxClick={auditHelpLink(true)}>
                Audit help page</KatLink> for more info on how to resolve this issue.</p>
            <p><strong>To search for another user please use the input on the right side of the navbar</strong></p>
        </div>
    );
}
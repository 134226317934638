import React from 'react';
import { AxiomMetricsDriver } from "src/metrics/AxiomMetricsDriver";

import {
  ManagerLandingPage,
  ManagerLandingPageProps
} from "@amzn/wfm-my-performance-ui-components-react"
import i18n from "i18next";
import { CerebrumService } from 'src/service/CerebrumCoralService';
import { UserManager } from 'src/user/UserIdentityManager';
import { useHistory } from "react-router-dom";
import { defaultTenant } from "src/config/definitions";

interface MyPerformanceManagerLandingPageContainerProps { };

const myPerformanceRootPath = "/myp";

export function MyPerformanceManagerLandingPageContainer(
  props: MyPerformanceManagerLandingPageContainerProps
) {
  const history = useHistory();
  const myPerformanceProps: ManagerLandingPageProps = {
    user: UserManager.userIdentity?.login || '',
    metricsDriver: AxiomMetricsDriver,
    coralService: CerebrumService,
    tenantId: UserManager.tenantId || defaultTenant,
    i18n: i18n.t.bind(i18n),
    viewDetailsCallback: (formType: string, uniqueId: string) => { history.push(`${myPerformanceRootPath}/${formType}/${uniqueId}`) }
  };
  return <ManagerLandingPage {...myPerformanceProps} />;
};
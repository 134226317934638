import React from 'react';
import { KPICard, KPILoadingCard } from '@amzn/wfm-axiom-flash-briefing-ui-components';
import CardLoader from './CardLoader';
import { KPICardAdapterFunction } from 'src/components/flash_briefing/CardLoaderAdapter'

interface KPICardLoaderProps {
    entityId: string;
    cardMetadata: Cerebrum.CardMetadata;
}

export function KPICardLoader(props: KPICardLoaderProps) {
    return (
        <CardLoader
            entityId={props.entityId}
            cardMetadata={props.cardMetadata}
            CardComponent={KPICard}
            cardAdapterFunction={KPICardAdapterFunction}
            LoadingCardComponent={KPILoadingCard}
        />
    )
}
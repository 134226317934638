import React, { Component } from 'react';
import { KatDataTable, KatPagination } from '@amzn/katal-react';
import './AuditTable.scss';
import { TableProps, TableState, TableConfiguration } from "src/components/common/table/TableConfiguration";

// TODO:
//  Try to make this a generic table component that can
//  be used for any table (https://sim.amazon.com/issues/AXIOM-660)
interface AuditTableProps extends TableProps {
    auditTableData: Cerebrum.Audits.TableData;
    auditType: Cerebrum.Audits.AuditType;
}

/**
 * A Generic Audit Table which is given data and config to dictate how it displays
 */
export class AuditTable extends Component<AuditTableProps, TableState> {
    katDataTableRef: any;
    katDataTable: any;

    tableConfig: TableConfiguration;

    constructor(props: AuditTableProps) {
        super(props);
        this.katDataTableRef = React.createRef();
        this.tableConfig = props.tableConfig;
        this.state = {
            currentPageNumber: 1,
            currentPageElements: [],
            filteredAndSortedElements: [],
            sortDirection: "asc",
            sortProperty: this.tableConfig.defaultSortColumn
        };

        this.pageChangedHandler = this.pageChangedHandler.bind(this);
        this.settingsChangedHandler = this.settingsChangedHandler.bind(this);
        this.filterRows = this.filterRows.bind(this);
        this.sortRows = this.sortRows.bind(this);
        this.paginateRows = this.paginateRows.bind(this);
    }

    componentDidMount() {
        this.katDataTable = this.katDataTableRef.current;
        this.katDataTable.addEventListener("settingsChanged", this.settingsChangedHandler);
        this.refreshTable();
    }

    componentDidUpdate(prevProps: Readonly<AuditTableProps>) {
        if (this.props == prevProps) {
            return;
        }
        this.refreshTable();
    }

    private refreshTable() {
        this.tableConfig = this.props.tableConfig;
        this.setState({ filteredAndSortedElements: this.props.auditTableData! },
            () => this.filterRows());
    }

    render() {
        return (
            <div>
                <KatDataTable
                    ref={this.katDataTableRef}
                    columns={this.tableConfig.columns}
                    rowData={this.state.currentPageElements}
                />
                <KatPagination
                    totalItems={this.state.filteredAndSortedElements.length}
                    itemsPerPage={this.tableConfig.itemsPerPage}
                    page={this.state.currentPageNumber}
                    onPageChanged={this.pageChangedHandler}
                    className={"kat-pull-right mt-2"}
                />
            </div>
        );
    }


    /*
    * Event Handlers
    * */
    private settingsChangedHandler(event: any) {
        if (!event.detail.sortColumn || !event.detail.sortDirection) {
            return;
        }

        this.setState({
            sortDirection: event.detail.sortDirection,
            sortProperty: event.detail.sortColumn.property
        }, () => this.sortRows());
    }

    private pageChangedHandler(event: any) {
        if (!event.detail.page) {
            return;
        }
        this.setState({ currentPageNumber: event.detail.page }, () => this.paginateRows());
    }

    private paginateRows() {
        const ITEMS_PER_PAGE = this.tableConfig.itemsPerPage;
        let startIndex = (this.state.currentPageNumber - 1) * ITEMS_PER_PAGE; //* -1 because index start at zero
        let endIndex = (startIndex + ITEMS_PER_PAGE) > this.state.filteredAndSortedElements.length
            ? this.state.filteredAndSortedElements.length : (startIndex + ITEMS_PER_PAGE);

        this.setState({ currentPageElements: this.state.filteredAndSortedElements.slice(startIndex, endIndex) });
    }

    private sortRows() {
        let audits = this.state.filteredAndSortedElements;
        let property = this.state.sortProperty;
        let direction = this.state.sortDirection;

        if (property != "" && direction != "") {
            audits = audits.sort(function (auditA: any, auditB: any) {
                if (auditA[property] < auditB[property]) {
                    return -1;
                } else if (auditA[property] > auditB[property]) {
                    return 1;
                } else {
                    return 0;
                }
            });

            if (direction === "desc") {
                audits = audits.reverse();
            }
        }
        this.setState({ filteredAndSortedElements: audits, currentPageNumber: 1 }, () => this.paginateRows());
    }

    private filterRows() {
        const FIELDS_BANNED_FROM_SEARCH = this.tableConfig.fieldsBannedFromSearch;

        let audits = this.props.auditTableData!;
        let searchTerm = this.props.searchTerm?.toLowerCase() || '';
        let filters = this.props.selectionFilters ?? [];

        if (this.props.searchTerm != "" || filters.length > 0) {
            audits = audits.filter((audits: any) => {
                //Entries are [field, value]
                let entries = Object.entries(audits).filter((entry) => { return !FIELDS_BANNED_FROM_SEARCH.includes(entry[0]); });
                let matchesFilter = true;

                //first filter by selected filters
                for (let filter of filters) {
                    if (entries.filter((entry) => { return filter.field == entry[0] && filter.value == entry[1]; }).length <= 0) {
                        matchesFilter = false;
                        break;
                    }
                }

                if (!matchesFilter || searchTerm == "") { return matchesFilter; }

                //then filter by search term
                for (let [, value] of entries) {
                    if ((value as any).toString().toLowerCase().includes(searchTerm.toLowerCase())) {
                        return true;
                    }
                }
                return false;
            });
        }
        this.setState({ filteredAndSortedElements: audits, currentPageNumber: 1 }, () => this.sortRows());
    }
}
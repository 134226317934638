import React, { useContext, useState } from 'react';
import i18n from 'i18next';
import { UserManager } from "src/user/UserIdentityManager";
import { KatButton, KatHmd } from '@amzn/katal-react';
import { connectHmdElementToPublisher } from 'src/metrics/hmdMetrics';
import { LayoutContext } from "src/components/layout/LayoutContext";


/**
 * Global Katal HMD Modal for Axiom
 *
 * @see https://katal.corp.amazon.com/#/developer/hmd
 * @see https://code.amazon.com/packages/SpectrometerWebsite/blobs/mainline/--/src/views/layout/footer.tsx
 */
export default function AxiomHmd() {

    interface Feedback {
        rating: number;
        subject: string;
        comment: string;
    }

    const feedbackInit = { rating: 0, subject: "", comment: "" };

    const [hmdVisible, setHmdVisible] = useState<boolean>(false);
    const [hmdState, setHmdState] = useState<KatHmd.State>("normal");
    const [feedbackState, setFeedbackState] = useState<Feedback>(feedbackInit);

    const userIdentity = UserManager.getUserIdentity();

    const layoutContext = useContext(LayoutContext);

    const hmdClass = `axiom-hmd-feedback-button ${layoutContext.isSidebarVisible ? 'hmd-with-sidebar' : ''}`;

    return (
        <>{
            !layoutContext.isHeadlessMode && <>
                <div className={hmdClass}>
                    <KatButton
                        label={i18n.t('wfm_axiom_give_us_feedback').toLocaleUpperCase()}
                        onClick={() => {
                            setFeedbackState(feedbackInit);
                            setHmdVisible(true);
                            connectHmdElementToPublisher(userIdentity.login);
                        }}
                    />
                </div>
                <KatHmd
                    id="hmd-form"
                    mode="modal"
                    locale={i18n.language}
                    ratingStyle="numerical"
                    visible={hmdVisible}
                    onClose={() => {
                        setHmdVisible(false);
                        if (hmdState !== "normal") setHmdState("normal");
                    }}
                    onSubmit={(e: KatHmd.SubmitEvent) => {
                        setHmdState("busy");
                        setTimeout(() => setHmdState("success"), 500);
                        setFeedbackState(e.detail);
                    }}
                    state={hmdState}
                    rating={feedbackState.rating}
                    subject={feedbackState.subject}
                    comment={feedbackState.comment}
                />
            </>
        }</>
    );
};

import React from 'react';
import { KatAlert, KatExpander } from "@amzn/katal-react";
import i18n from "i18next";
import { Trans } from "react-i18next";


export interface GlobalErrorProps {
    message: string | JSX.Element;
    title: string;
    details?: string | JSX.Element;
    level?: KatAlert.Variant;
    helpLink?: string;
    openTicketMessage?: string | JSX.Element;
}

export class ErrorMessage extends React.Component<any, GlobalErrorProps> {
    render() {
        const level: KatAlert.Variant = this.props?.level || "danger";

        const details = this.props.details ? (
            <KatExpander label={i18n.t("wfm_axiom_v2_absolute_additional_details")}>
                {this.props.details}
            </KatExpander>
        ) : "";

        const helpLink = this.props.helpLink
            ? <div>
                <br/>
                <p>
                    For more information on the permissions, <a href={this.props.helpLink}>click here</a>
                </p>
            </div>
            : "";

        const ticketMessage = this.props.openTicketMessage ||
            <Trans i18nKey="wfm_axiom_error_following_steps">
                The above error was detected while processing your request, please try reloading the
                page and clearing your browser cache. If the error persists please report it&nbsp;
                <a target="_blank"
                   href="https://river.amazon.com/?org=psas&buildingId=VIR&q0=ba18406b-1638-41d5-b497-e03551e2d247">here</a>
            </Trans>;

        return (
            <div className="text-left">
                <KatAlert persistent variant={level}
                          header={this.props.title}>
                    {this.props.message}
                    {helpLink}
                    {level === "danger" && <p>{ticketMessage}</p>}
                    {details}
                </KatAlert>
            </div>
        );
    }
}


import { toOptionsArray } from "src/components/common/Enums";
import i18n from "i18next";
import { Grouping } from "src/components/common/Grouper";
import { isCurrentWeek } from "src/components/common/Comparator";

export enum NotificationGroupingOption {
    DEFAULT = 'Default',
    PRIORITY = 'Priority',
    SOURCE = 'Source',
    TYPE = 'Type',
    NONE = 'None'
}

export function NotificationGroupingOptionFromString(taskGroupingOption: string): NotificationGroupingOption {
    return taskGroupingOption ?
        NotificationGroupingOption[taskGroupingOption.toUpperCase() as keyof typeof NotificationGroupingOption] :
        NotificationGroupingOption.DEFAULT;
}

export const NotificationGroupingFromOption: Record<NotificationGroupingOption, Grouping<Cerebrum.Tasks.Notification>> = {
    [NotificationGroupingOption.DEFAULT]: {
        name: NotificationGroupingOption.DEFAULT,
        groupDecider: (notification: Cerebrum.Tasks.Notification) => {
                return isCurrentWeek(notification.createdDateTime) ?
                    i18n.t('wfm_axiom_notifications_this_week') :
                    i18n.t('wfm_axiom_notifications_last_week')
        }
    },
    [NotificationGroupingOption.PRIORITY]: {
        name: NotificationGroupingOption.PRIORITY,
        groupDecider: (notification: Cerebrum.Tasks.Notification) => notification.priority || 'Other Notifications'
    },
    [NotificationGroupingOption.SOURCE]: {
        name: NotificationGroupingOption.SOURCE,
        groupDecider: (notification: Cerebrum.Tasks.Notification) => notification.notificationSource || 'Other Notifications'
    },
    [NotificationGroupingOption.TYPE]: {
        name: NotificationGroupingOption.TYPE,
        groupDecider: (notification: Cerebrum.Tasks.Notification) => notification.notificationType || 'No type'
    },
    [NotificationGroupingOption.NONE]: {
        name: NotificationGroupingOption.NONE,
        groupDecider: () => 'All available notifications'
    }
} as const;

export function getSelectedNotificationGroupingFromString(s: string) {
    return NotificationGroupingFromOption[NotificationGroupingOptionFromString(s)] || NotificationGroupingFromOption.Default;
}

export const notificationGroupingOptions = toOptionsArray(NotificationGroupingOption);

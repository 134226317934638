import { NotificationEvent, NotificationEventName } from "src/components/notifications/events/NotificationEvents";

export class NotificationUpdatedEvent implements NotificationEvent<Cerebrum.Tasks.Notification> {
    public readonly name: NotificationEventName = NotificationEventName.NOTIFICATION_UPDATED_EVENT;
    public readonly payload: Cerebrum.Tasks.Notification;

    constructor(notification: Cerebrum.Tasks.Notification) {
        // for some (maybe optimization related) reason, there are runtime errors
        // if we use constructor assignment here...
        this.payload = notification;
    }
}

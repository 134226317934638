import { AxiomRight, AxiomRole, isAxiomRole, rolesToRightsMapping } from "src/config/AxiomCSRights";
import { UserAccessManager } from "src/user/UserAccessManager";

interface CognitoFederateIdentity {
    userId: string;
    providerName: string;
    providerType: string;
    issuer: string;
    primary: string;
    dateCreated: string;
}

export interface CognitoTokenUser {
    at_hash: string;
    sub: string;
    "cognito:groups": string[];
    email_verified: boolean;
    iss: string;
    "cognito:username": string;
    given_name: string;
    nonce: string;
    "custom:groups": string;
    origin_jti: string;
    aud: string;
    identities: CognitoFederateIdentity[];
    token_use: string;
    auth_time: number;
    exp: number;
    iat: number;
    family_name: string;
    jti: string;
    email: string
}


class UserCognitoIdentityManager implements UserAccessManager {
    private _cognitoUser?: Partial<CognitoTokenUser>;
    private _cognitoFederateIdentity?: Partial<CognitoFederateIdentity>;
    private _userGroupsRoles?: Set<AxiomRole>;
    private _userRights?: Set<AxiomRight>;

    public async setCognitoUser(cognitoTokenUser: CognitoTokenUser) {
        this._cognitoUser = cognitoTokenUser;
        this._cognitoFederateIdentity = cognitoTokenUser.identities.find(identity => identity.providerName === "FederateOIDC");

        // Ignore custom:groups that don't match an AXIOM_ROLE
        const customGroups: AxiomRole[] = cognitoTokenUser["custom:groups"].split(',')
            .filter(isAxiomRole) as AxiomRole[] || [] as AxiomRole[];
        this._userGroupsRoles = new Set<AxiomRole>(customGroups);

        // Get user rights from user groups
        const userRights = new Set<AxiomRight>();
        for (const group of customGroups) {
            const rights = rolesToRightsMapping[group];
            if (rights) {rights.forEach(right => userRights.add(right));}
        }

        this._userRights = userRights;
        this.loadPreferences();

        // TODO: remove this console log. Used for testing in beta.
        // tslint:disable-next-line:no-console
        console.log("UserCognitoIdentityManager: -> userRights: ", this._userRights); console.log("UserCognitoIdentityManager: -> userGroups: ", this._userGroupsRoles); console.log("UserCognitoIdentityManager: -> userIdentity: ", this._cognitoUser); console.log("UserCognitoIdentityManager: -> federatedIdentity: ", this._cognitoFederateIdentity);
    }

    private loadPreferences() {
        if(!this._userRights?.has(AxiomRight.ADMIN_VIEW)) { return; }
        // For each AxiomRight
        for (const right of Object.values(AxiomRight)) {
            const rightSessionValue = window.sessionStorage.getItem(right);
            if (rightSessionValue === 'true') { this._userRights?.add(right); }
        }
    }

    public isUserIdentityAvailable() {
        return this._cognitoUser !== undefined && this._userGroupsRoles !== undefined
            && this._userRights !== undefined && this._cognitoFederateIdentity !== undefined;
    }

    public isUserInGroup(group: AxiomRole) {
        return this._userGroupsRoles?.has(group) ?? false;
    }

    public isUserAuthorizedForRight(right: AxiomRight) {
        return this._userRights?.has(right) ?? false;
    }

    // Getters and setters for userIdentity, userGroups, and userRights
    get cognitoUser(): Partial<CognitoTokenUser> {
        return this._cognitoUser || {};
    }

    get userGroupsRoles(): Set<AxiomRole> {
        return this._userGroupsRoles || new Set();
    }

    set userGroupsRoles(value: Set<AxiomRole>) {
        this._userGroupsRoles = value;
    }

    get userRights(): Set<AxiomRight> {
        return this._userRights || new Set();
    }

    set userRights(value: Set<AxiomRight>) {
        this._userRights = value;
    }

    get cognitoFederateIdentity(): Partial<CognitoFederateIdentity> {
        return this._cognitoFederateIdentity || {};
    }

    set cognitoFederateIdentity(value: Partial<CognitoFederateIdentity>) {
        this._cognitoFederateIdentity = value;
    }

}

export const CognitoUserManager = new UserCognitoIdentityManager();
export enum Levels {
    OK = 0,
    INFO = 1,
    WARN = 2,
    DOWN = 3,
}

export class IsItDown {
    name: string | null;
    message: string | null;
    level: Levels | null;
    tickets: string | null;

    constructor(name?: string, message?: string, level?: Levels, tickets?: string) {
        this.name = name ?? null;
        this.message = message ?? null;
        this.level = level ?? null;
        this.tickets = tickets ?? null;
    }
}

export function convertToIsItDown(payload: { [key: string]: any }): IsItDown {
    let isItDown: IsItDown = new IsItDown();

    isItDown.name = payload.name;
    isItDown.message = payload.message;
    isItDown.level = payload.level;
    isItDown.tickets = payload.tickets;
    
    return isItDown;
}

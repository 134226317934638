/**
 * Represents the currently available Weblabs in Axiom.
 *
 * In order to add a new Weblab the required changes are to include a new entry in this enum with
 * the new weblab name and add its possible treatment options to Treatment enum if is not there yet.
 */
export enum Weblab {
    IS_IT_DOWN = 'WFM_AXIOM_IS_IT_DOWN_UPDATE_810923',
}

/**
 * Represents the possible treatments a Weblab in Axiom can get allocated with.
 *
 * Add more as required in case a weblab has any other treatment available.
 */
export enum Treatment {
    C = 'C',
    T1 = 'T1'
}

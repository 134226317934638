import React from "react";
import { Box } from "@amzn/awsui-components-react";

export default function EmptyTable(props: any) {
    const { title, subtitle, action } = props;
    return (
        <Box textAlign="center" color="inherit">
            <Box variant="strong" textAlign="center" color="inherit">
                {title}
            </Box>
            <Box variant="p" padding={{ bottom: "s" }} color="inherit">
                {subtitle}
            </Box>
            {action}
        </Box>
    );
}

import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { UserManager } from "src/user/UserIdentityManager";
import { KatBadge, KatButton } from "@amzn/katal-react";
import { LayoutContext } from "src/components/layout/LayoutContext";
import { ConfigManager } from 'src/config/ConfigManager';
import { ReverbForm } from "src/components/reverb/ReverbForm";
import { CerebrumService } from "src/service/CerebrumCoralService";
import { CoralError } from "src/components/error/ErrorBoundComponent";
import { AxiomMetricsDriver } from "src/metrics/AxiomMetricsDriver";
import { useStateWithLocalStorage } from '../common/storage/UseStateWithLocalStorage';

import "./Reverb.scss";

const COMPONENT_NAME = 'Reverb';
const SNOOZED_SURVEY_STORAGE_KEY = 'reverbSnoozedSurveys';

export default function Reverb() {
    const config: AxiomConfig.Configuration = ConfigManager.getStaticConfig();
    const DISABLE_REVERB_STORAGE_KEY = config.localStorageKeys.DISABLE_REVERB;

    const [formVisible, setFormVisible] = useState<boolean>(false);
    const [snoozedSurveys, setSnoozedSurveys] = useStateWithLocalStorage<Cerebrum.Reverb.SnoozedSurveys>(SNOOZED_SURVEY_STORAGE_KEY, {});
    const [disabled, setDisabled] = useStateWithLocalStorage<boolean>(DISABLE_REVERB_STORAGE_KEY, false);

    const userIdentity = UserManager.getUserIdentity();
    const layoutContext = useContext(LayoutContext);

    const [surveys, setSurveys] = useState<Cerebrum.Reverb.SurveyQuestion[]>([]);

    useEffect(() => {
        getSurveys();
    }, []);

    // Api calls
    const getSurveys = async () => {
        const input: Cerebrum.Reverb.GetSurveyQuestionsInput = {
            entityId: userIdentity.login
        };
        await CerebrumService.getSurveyQuestions(input)
            .then(handleResponse)
            .catch(handleError);
    };

    const handleResponse = (response: Cerebrum.Reverb.GetSurveyQuestionsOutput) => {

        const toTypedSurveyQuestion =
            (question: Cerebrum.Reverb.SurveyQuestionUntyped): Cerebrum.Reverb.SurveyQuestion => {

                // converts option info from a pair [string: string], like `"5": "This is question number five`
                // into a key value entry to be included in a map, like [5, "This is question number five"]
                const toTypedOption = (optionStr: string): [number, string] =>
                    ([parseInt(optionStr, 10), question.options[parseInt(optionStr, 10)] || '']);

                const optionsMap = new Map<number, string>(Object.keys(question.options).map(toTypedOption));

                return ({ // same question but with typed options in a map
                    ...question,
                    options: optionsMap
                });
            };

        const typedSurveyQuestions: Cerebrum.Reverb.SurveyQuestion[] = response.questions.map(toTypedSurveyQuestion);
        setSurveys(typedSurveyQuestions);

        setFormVisible(
            // if the survey is not in snoozed list; undefined results in current moment and thus not filtered
            (typedSurveyQuestions.filter(survey => moment(snoozedSurveys[survey.surveyId]) <= moment()).length > 0)
            // Don't show Reverb modal if user clicked on help content icon
            && (window.location.hash != '#/help/Reverb')
            // Don't show Reverb modal if user has the local storage override
            && (!disabled)
        );
    };

    const handleError = (coralError: CoralError) => {
        // tslint:disable-next-line:no-console
        console.error(coralError);
    };

    const respondSurvey = async (response: Cerebrum.Reverb.SurveyResponse) => {
        await CerebrumService.createSurveyResponse(response)
            .then(getSurveys)
            .catch(handleError);
    };

    // Metrics
    const sendButtonClickedMetric = (buttonName: string) => {
        const additionalMetric = { name: 'surveyId', value: surveys[0].surveyId };
        AxiomMetricsDriver.publishButtonClick(COMPONENT_NAME, buttonName, [additionalMetric]);
    };

    // Handlers
    const onClose = () => {
        setFormVisible(false);
        sendButtonClickedMetric('close');
    };

    const onRemindMeLater = () => {
        const snoozeTime = moment().add(1, 'hour').toDate();
        const newSnoozedSurveys = surveys.reduce(
            (map, current) => (map[current.surveyId] = snoozeTime, map),
            {} as Cerebrum.Reverb.SnoozedSurveys
        );
        setSnoozedSurveys(newSnoozedSurveys);
        setFormVisible(false);
        sendButtonClickedMetric('remindMeLater');
    };

    const onSubmit = (response: Cerebrum.Reverb.SurveyResponse) => {
        setFormVisible(false);
        sendButtonClickedMetric('submit');
        if (response.useful !== undefined) sendButtonClickedMetric(response.useful ? 'useful' : 'unuseful');
        respondSurvey(response);
    };

    const hmdClass = `reverb-feedback-button ${layoutContext.isSidebarVisible ? 'reverb-with-sidebar' : ''}`;

    const Badge = () => <>{surveys.length > 0 &&
        <KatBadge slot='icon' className='reverb-badge' type='default' label={`${surveys.length}`} />
    }</>;

    return (<>{surveys.length > 0 && <>
        <div className={hmdClass}>
            <KatButton
                label={`Reverb`}
                onClick={() => {
                    setFormVisible(true);
                    sendButtonClickedMetric('reverb');
                }}>
                <Badge />
            </KatButton>
        </div>
        {formVisible &&
            <ReverbForm survey={surveys[0]} onClose={onClose} onSnooze={onRemindMeLater} onSubmit={onSubmit} />
        }
    </>
    }</>);
};

import { AxiomRight } from "src/config/AxiomCSRights";

enum MyPerformanceFormPermissions {
    FORM_ALLOW_CREATE = 'allowCreate',
    FORM_ALLOW_EDIT = 'allowEdit',
    FORM_ALLOW_REMOVE = 'allowRemove',
}

enum MyPerformanceNavPermissions {
    FORM_ALLOW_CREATE_COACHING = 'allowCreateCoaching',
    NAV_ALLOW_CREATE_FEEDBACK = 'allowCreateFeedback',
    NAV_ALLOW_CREATE_LEADER_COACHING = 'allowCreateLeaderCoaching',
}

const LeaderCoachingFormPermissionsForManager: { [permission in MyPerformanceFormPermissions]: boolean } = {
    [MyPerformanceFormPermissions.FORM_ALLOW_CREATE]: true,
    [MyPerformanceFormPermissions.FORM_ALLOW_EDIT]: true,
    [MyPerformanceFormPermissions.FORM_ALLOW_REMOVE]: true,
}

const LeaderCoachingFormPermissionsForSupervisor: { [permission in MyPerformanceFormPermissions]: boolean } = {
    [MyPerformanceFormPermissions.FORM_ALLOW_CREATE]: false,
    [MyPerformanceFormPermissions.FORM_ALLOW_EDIT]: false,
    [MyPerformanceFormPermissions.FORM_ALLOW_REMOVE]: false,
}

const FormPermissionsForSupervisor: { [permission in MyPerformanceFormPermissions]: boolean } = {
    [MyPerformanceFormPermissions.FORM_ALLOW_CREATE]: true,
    [MyPerformanceFormPermissions.FORM_ALLOW_EDIT]: true,
    [MyPerformanceFormPermissions.FORM_ALLOW_REMOVE]: true,
}

const FormPermissionsForAssociate: { [permission in MyPerformanceFormPermissions]: boolean } = {
    [MyPerformanceFormPermissions.FORM_ALLOW_CREATE]: false,
    [MyPerformanceFormPermissions.FORM_ALLOW_EDIT]: false,
    [MyPerformanceFormPermissions.FORM_ALLOW_REMOVE]: false,
}

const NavPermissionsForManager: { [permission in MyPerformanceNavPermissions]: boolean } = {
    [MyPerformanceNavPermissions.FORM_ALLOW_CREATE_COACHING]: true,
    [MyPerformanceNavPermissions.NAV_ALLOW_CREATE_FEEDBACK]: true,
    [MyPerformanceNavPermissions.NAV_ALLOW_CREATE_LEADER_COACHING]: true,
}

const NavPermissionsForSupervisor: { [permission in MyPerformanceNavPermissions]: boolean } = {
    [MyPerformanceNavPermissions.FORM_ALLOW_CREATE_COACHING]: true,
    [MyPerformanceNavPermissions.NAV_ALLOW_CREATE_FEEDBACK]: true,
    [MyPerformanceNavPermissions.NAV_ALLOW_CREATE_LEADER_COACHING]: false,
}

const NavPermissionsForAssociate: { [permission in MyPerformanceNavPermissions]: boolean } = {
    [MyPerformanceNavPermissions.FORM_ALLOW_CREATE_COACHING]: false,
    [MyPerformanceNavPermissions.NAV_ALLOW_CREATE_FEEDBACK]: false,
    [MyPerformanceNavPermissions.NAV_ALLOW_CREATE_LEADER_COACHING]: false,
}


export function getPermissionsForForm(userIdentity: WFM.GetUserInformation): { [permission in MyPerformanceFormPermissions]: boolean } {
    return userIdentity.accessRights[AxiomRight.SUPERVISOR_VIEW] ? FormPermissionsForSupervisor : FormPermissionsForAssociate;
}

export function getPermissionsForLeaderCoachingForm(userIdentity: WFM.GetUserInformation): { [permission in MyPerformanceFormPermissions]: boolean } {
    return userIdentity.accessRights[AxiomRight.MANAGER_VIEW] ? LeaderCoachingFormPermissionsForManager : LeaderCoachingFormPermissionsForSupervisor;
}

export function getPermissionsForNav(userIdentity: WFM.GetUserInformation): { [permission in MyPerformanceNavPermissions]: boolean } {
    if (userIdentity.accessRights[AxiomRight.MANAGER_VIEW]) {
        return NavPermissionsForManager;
    }
    else if (userIdentity.accessRights[AxiomRight.SUPERVISOR_VIEW]) {
        return NavPermissionsForSupervisor;
    }
    else {
        return NavPermissionsForAssociate;
    }
}

export function hasAccessToLeaderCoachingLogs(userIdentity: WFM.GetUserInformation): boolean {
    return userIdentity.accessRights[AxiomRight.MANAGER_VIEW] || userIdentity.accessRights[AxiomRight.SUPERVISOR_VIEW];
}
import { FormsLogTab } from "src/components/forms/FormsLog";
import { KatTab, KatTabs } from "@amzn/katal-react";
import React from "react";
import { AssitantTab } from "src/components/assistant/AssistantContext";
import "./TabsSelector.scss"

export interface TabData {
    name: AssitantTab | FormsLogTab | string,
    label: string,
    unseen?: number
}


export interface TabsSelectorProps {
    onChange: (evt: KatTabs.ChangeEvent) => void,
    currentTab: TabData,
    tabs: JSX.Element[],
    className?: string
}

export interface TabProps {
    tab: TabData,
    isTabSelected: (tabName: string) => boolean
}


export const Tab: React.FC<TabProps> = ({tab, isTabSelected}) => {
    return <KatTab tabId={tab.name}>
        <h4 slot='label' className={isTabSelected(tab.name) ? 'selected-tab' : ''}>
            {tab.label}
        </h4>
    </KatTab>;
}

export const TabsSelector: React.FC<TabsSelectorProps> = (props) => {
    return <KatTabs
        onChange={props.onChange}
        selected={props.currentTab.name}
        className={'tabs-selector'}
    >
        {props.tabs}
    </KatTabs>;
}
import { AuthenticationService } from "./AuthenticationService";
import { MetricStatus, AxiomMetricsDriver } from "src/metrics/AxiomMetricsDriver";
import { AUTHENTICATION_REDIRECTING_CODE } from "src/service/AuthenticationService";
import KatalMetricTimerStopwatch from "@katal/metrics/lib/metricObject/KatalMetricTimerStopwatch";
import { Auth } from 'aws-amplify';
import {ConfigManager} from "src/config/ConfigManager";

export const SUCCESSFUL_AUTHENTICATION_REDIRECT_STATUS = 200;

/**
 * Base service with common useful methods to retrieve data from Coral
 */
export abstract class BaseCoralService {

    /**
     * Implementation of a coral invocation with the post method
     *
     * @param activity the target coral activity that will be invoked
     * @param data the parameters for the activity in json format
     */
    protected async doCoralPost(activity: string, data: any) {
        const fetchPromise: Promise<Response> = this.invokeCoral(activity, 'POST', null, data);
        const response: Response = await this.handleResponse(fetchPromise, activity);

        try {
            const responseContent: string = await response.text();
            return (responseContent.length > 0) ? JSON.parse(responseContent) : {};
        } catch (error) {
            console.error(error);
            throw new Error("Invalid json response from server");
        }
    }

    /**
     * Implementation of a coral invocation with the get method
     *
     * @param activity  the target coral activity that will be invoked. Here optionally null, useful for authentication
     * requests.
     * @param params request search parameters
     * @param validateAuth when set to false 401 responses from the backend will be ignored. Useful for edge cases when
     * we want to prevent an authentication loop
     */
    protected async doCoralGet(activity: string | null,
        params: {} | null) {
        const fetchPromise: Promise<Response> = this.invokeCoral(activity, 'GET', params, null);
        return await this.handleResponse(fetchPromise, activity);
    }

    public async handleResponse(promise: Promise<Response>, activity: string | null): Promise<Response> {
        let timer = new KatalMetricTimerStopwatch("apiTimer");
        let apiName = this.getAPIName(activity);
        const response: Response = await promise
            .then(response => {
                AxiomMetricsDriver.publishAPIResponseTime(apiName, timer);
                return AuthenticationService.handleRedirection(response);
            })
            .catch(error => {
                AxiomMetricsDriver.publishAPIResponseTime(apiName, timer);
                if (error != AUTHENTICATION_REDIRECTING_CODE) {
                    AxiomMetricsDriver.publishAPICallStatus(apiName, MetricStatus.Failure)
                }
                return Promise.reject(error);
            });

        if (response.status != SUCCESSFUL_AUTHENTICATION_REDIRECT_STATUS) {
            AxiomMetricsDriver.publishAPICallStatus(apiName, MetricStatus.Failure)
            throw await response.json().catch(error => "Invalid error response from server");
        } else {
            AxiomMetricsDriver.publishAPICallStatus(apiName, MetricStatus.Success)
        }

        return response;
    }

    private async invokeCoral(activity: string | null,
        method: string,
        params: { [key: string]: string } | null,
        data: {} | null = null) {
        const endpoint = await this.getApiEndpoint();
        const apiName = this.getAPIName(activity); // Has no effect, but more clearly shows activity in browser dev tools.
        const url = new URL(endpoint + apiName);
        if (params) {
            for (let [key, values] of Object.entries(params)) {
                if (Array.isArray(values)) {
                    for (let value of values) {
                        url.searchParams.append(key, value);
                    }
                } else {
                    url.searchParams.append(key, values);
                }
            }
        }

        const currentSession = (await Auth.currentSession()).getIdToken().getJwtToken();

        const headers: { [key: string]: string } = {
            'Content-Type': 'application/json; charset=UTF-8',
            'Content-Encoding': 'amz-1.0',
            'kiken': currentSession
        };
        if (activity) {
            headers['X-Amz-Target'] = activity!;
        }
        const fetchParams: { [key: string]: any } = {
            method: method,
            mode: 'cors',
            cache: 'default',
            credentials: 'include',
            headers: headers,
            redirect: 'manual',
            referrerPolicy: 'no-referrer',
        };
        if (data) {
            fetchParams.body = JSON.stringify(data);
        }
        return fetch(url.href, fetchParams);
    }

    /*
    * It extracts the last component of the coral activity, which is the API name.
    * Example: com.amazon.wfmaxiomcerebrumservice.WFMAxiomCerebrumService.ListAgentRecommendations
    * */
    private getAPIName(activity: string | null) {
        let apiName = "";

        if (activity) {
            let components = activity.split(".");
            if (components.length > 1) {
                apiName = components[components.length - 1];
            }
        }

        return apiName;
    }

    protected paramsToJson(searchParams: URLSearchParams): { [key: string]: string | string[] } {
        const jsonParams: { [key: string]: string | string[] } = {};
        searchParams.forEach((value, key) => {
            const previousValue: string[] | string | null = jsonParams[key];
            if (previousValue) {
                if (Array.isArray(previousValue)) {
                    jsonParams[key] = [...previousValue, value];
                } else {
                    jsonParams[key] = [previousValue, value]
                }
            } else {
                jsonParams[key] = value;
            }
        });
        return jsonParams;
    }

    /**
     * Child classes must implement this method to provide the url of the Service Endpoint (https://my-coral-service-url)
     */
    protected abstract getApiEndpoint(): string;
}

import React from 'react';
import { AxiomMetricsDriver } from "src/metrics/AxiomMetricsDriver";

import {
    SupervisorLandingPage,
    SupervisorLandingPageProps
} from "@amzn/wfm-my-performance-ui-components-react"
import i18n from "i18next";
import { CerebrumService } from 'src/service/CerebrumCoralService';
import { UserManager } from 'src/user/UserIdentityManager';
import { useHistory } from "react-router-dom";
import { defaultTenant } from "src/config/definitions";

interface MyPerformanceSupervisorLandingPageContainerProps { };

const myPerformanceRootPath = "/myp";

export function MyPerformanceSupervisorLandingPageContainer(
    props: MyPerformanceSupervisorLandingPageContainerProps
) {
    const history = useHistory();
    const myPerformanceProps: SupervisorLandingPageProps = {
        user: UserManager.userIdentity?.login || '',
        metricsDriver: AxiomMetricsDriver,
        coralService: CerebrumService,
        tenantId: UserManager.tenantId || defaultTenant,
        i18n: i18n.t.bind(i18n),
        viewDetailsCallback: (formType: string, uniqueId: string) => { history.push(`${myPerformanceRootPath}/${formType}/${uniqueId}`) }
    };
    return <SupervisorLandingPage {...myPerformanceProps} />;
};
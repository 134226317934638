import React, { useContext } from 'react';
import { BannerContext } from "src/components/layout/BannerContext";
import { KatAlert } from "@amzn/katal-react";

export const Banner: React.FC = () => {
    const {isBannerVisible, header, linkHref, linkLabel,
        variant, description, clearBanner, extraContent} = useContext(BannerContext);
    return <div>
        {isBannerVisible &&
            <KatAlert variant={variant as KatAlert.Variant} onDismiss={clearBanner}>
                <span slot="header">{header}</span>{description} {(linkHref && linkLabel) && <a href={linkHref}>{linkLabel}</a>}.
                {extraContent}
            </KatAlert>
        }</div>
}
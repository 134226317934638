import React, { useState } from "react";
import ExtendedPolarisTable from "src/components/polaris_table/ExtendedPolarisTable";
import { HeaderConfig, PolarisTableUniqueLocalStorageKeys } from "src/@types/extendedPolarisTable";
import EmptyTable from "src/components/polaris_table/EmptyTable";
import { TasksDateLabel } from "src/components/tasks/TasksDateLabel";
import { Description } from "src/components/tasks/task_log_table/Description";
import { TaskLinks } from "src/components/tasks/task_log_table/TasksLinks";
import { CrudCheckbox } from "src/components/common/CrudCheckbox";
import { TaskStatus } from "src/components/tasks/model/TaskStatus";
import { CerebrumService } from "src/service/CerebrumCoralService";
import { CoralError } from "src/components/error/ErrorBoundComponent";
import { KatAlert, KatColumn, KatModal, KatRow } from "@amzn/katal-react";
import { TaskActionsMenu } from "src/components/tasks/task_log_table/TaskActionsMenu";
import { FlashBriefingModal } from "@amzn/wfm-axiom-flash-briefing-ui-components";
import { FeedbackContainer } from "src/components/flash_briefing/FeedbackCard";
import { UserManager } from "src/user/UserIdentityManager";
import { TaskForm } from "src/components/tasks/TaskForm";
import i18n from "src/i18n";

interface TaskLogTableProps {
    taskList: Cerebrum.Tasks.Task[];
    loading: boolean;
    setTasks: Function;
    viewUserLogin: string;
}

function TaskLogTable(props: TaskLogTableProps) {
    const { taskList, loading, setTasks, viewUserLogin } = props;

    const [activeTask, setActiveTask] = useState<Cerebrum.Tasks.Task | undefined>();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [showMetadata, setShowMetadata] = useState(false);
    const [showEditTask, setShowEditTask] = useState(false);
    const [showFeedback, setShowFeedback] = useState(false);

    const showMetadataModal = (task: Cerebrum.Tasks.Task) => {
        setActiveTask(task);
        setShowMetadata(true);
    };

    const hideMetadataModal = () => {
        setShowMetadata(false);
        setActiveTask(undefined);
    };

    const showEditTaskModal = (task: Cerebrum.Tasks.Task) => {
        setActiveTask(task);
        setShowEditTask(true);
    };

    const hideEditTaskModal = () => {
        setShowEditTask(false);
        setActiveTask(undefined);
    };

    const showFeedbackModal = (task: Cerebrum.Tasks.Task) => {
        setActiveTask(task);
        setShowFeedback(true);
    };

    const hideFeedbackModal = () => {
        setShowFeedback(false);
        setActiveTask(undefined);
    };

    const deleteTask = (task: Cerebrum.Tasks.Task) => {
        const input: Cerebrum.Tasks.DeleteTaskInput = {
            id: task.id,
            tenantId: UserManager.tenantId,
            taskOwner: task.taskOwner,
            deleted: true
        };

        CerebrumService.deleteTask(input)
            .then((res) => removeTaskFromList(res.task))
            .catch((err) => handleError(err));
    };

    const removeTaskFromList = (task: Cerebrum.Tasks.Task) => {
        const updatedTaskList = taskList.filter(_task => _task.id !== task.id);

        setTasks([...updatedTaskList]);
    };

    const updateTaskStatus = (event: any) => {
        const newStatus = event.target.checked
            ? TaskStatus.COMPLETED
            : TaskStatus.NOT_STARTED;

        const taskIndex = taskList.findIndex(task => task.id === event.target.value);
        const task = taskList[taskIndex];

        const input: Cerebrum.Tasks.ChangeTaskStatusInput = {
            id: task.id,
            taskOwner: task.taskOwner,
            newStatus
        };

        CerebrumService.changeTaskStatus(input)
            .then((res) => handleChangeTaskStatusResponse(taskIndex, res))
            .catch(handleError);
    };

    const handleChangeTaskStatusResponse = (
        taskIndex: number,
        response: Cerebrum.Tasks.ChangeTaskStatusOutput,
    ) => {
        taskList[taskIndex] = {
            ...taskList[taskIndex],
            taskStatus: response.task.taskStatus,
        };

        setTasks([...taskList]);
    };

    const handleError = (error: CoralError) => {
        setErrorMessage(error.message);
    };

    const buildFeedbackMetadata = () => {
        if (activeTask) {
            return {
                cardId: activeTask.id,
                cardTitle: activeTask.title,
                cardType: "activeTask",
                createdDate: activeTask.createdDateTime,
                entityId: viewUserLogin,
                publisherName: activeTask.createdBy
            };
        }
    };

    const columnDefinitions = [
        {
            id: "",
            header: "",
            cell: (item: Cerebrum.Tasks.Task) => (
                <CrudCheckbox
                    checked={item.taskStatus === TaskStatus.COMPLETED}
                    changeHandler={updateTaskStatus}
                    value={item.id}
                />
            ),
            isSearchable: false
        },
        {
            id: "title",
            header: "Title",
            cell: (item: Cerebrum.Tasks.Task) => item.title,
            sortingField: "title",
            isSearchable: true
        },
        {
            id: "body",
            header: "Description",
            cell: (item: Cerebrum.Tasks.Task) => <Description task={item} />,
            sortingField: "body",
            isSearchable: true,
            maxWidth: "50rem",
            minWidth: "30rem"
        },
        {
            id: "taskSourceName",
            header: "Source",
            cell: (item: Cerebrum.Tasks.Task) => item.taskSourceName,
            sortingField: "taskSourceName",
            isSearchable: true
        },
        {
            id: "dueDateTime",
            header: "Due Date",
            cell: (item: Cerebrum.Tasks.Task) => item.dueDateTime
            ?   <TasksDateLabel
                    dueDate={item.dueDateTime}
                    defaultClass={"text-size-sm text-secondary"}
                    noSpacer={true}
                />
            :   "-",
            sortingField: "dueDateTime",
            minWidth: "105px",
            isSearchable: true,
        },
        {
            id: "item.taskType",
            header: "Actions",
            cell: (item: Cerebrum.Tasks.Task) => {
                return (
                    <KatRow>
                        <KatColumn sm={10}>
                            <TaskLinks task={item} />
                        </KatColumn>
                        <KatColumn sm={2}>
                            <TaskActionsMenu
                                task={item}
                                updateTaskList={removeTaskFromList}
                                handleError={handleError}
                                setShowMetadata={showMetadataModal}
                                deleteTask={deleteTask}
                                setShowEditTaskModal={showEditTaskModal}
                                showFeedbackModal={showFeedbackModal}
                            />
                        </KatColumn>
                    </KatRow>
                );
            },
            sortingField: "item.taskType",
            isSearchable: false,
            minWidth: "10rem"
        }
    ];

    const headerConfig: HeaderConfig = {
        title: `${i18n.t('wfm_axiom_query_results_for')} ${viewUserLogin}@`,
        counter: `${taskList.length || ""}`,
        variant: "awsui-h1-sticky"
    };

    return (
        <>
            {errorMessage && (
                <KatAlert
                    variant="danger"
                    onDismiss={() => setErrorMessage(null)}
                >
                    {errorMessage}
                </KatAlert>
            )}
            {showMetadata && (
                <FlashBriefingModal
                    visible={showMetadata}
                    title="Task Metadata" // TODO: Internationalize (AXIOM-390)
                    body={JSON.stringify(activeTask, null, 2)}
                    onClose={hideMetadataModal}
                />
            )}
            {showEditTask && (
                <KatModal
                    visible={showEditTask}
                    title="Edit Task"
                    onClose={hideEditTaskModal}
                >
                    <TaskForm
                        viewUserLogin={viewUserLogin}
                        task={activeTask}
                        onCancel={hideEditTaskModal}
                        onSuccess={hideEditTaskModal}
                    />
                </KatModal>
            )}
            {showFeedback && (
                <KatModal
                    visible={showFeedback}
                    title="Provide feedback"
                    onClose={hideFeedbackModal}
                >
                    <FeedbackContainer
                        cardMetadata={
                            buildFeedbackMetadata() as Partial<Cerebrum.CardMetadata>
                        }
                        onClose={hideFeedbackModal}
                    />
                </KatModal>
            )}
            <ExtendedPolarisTable<Cerebrum.Tasks.Task>
                headerConfig={headerConfig}
                itemList={taskList}
                filterConfigs={[]}
                columnDefinitions={columnDefinitions}
                defaultSortingColumn={3}
                trackBy="id"
                loading={loading}
                loadingText="Loading tasks..."
                includePropertyFilter={true}
                showPreferencesButton={true}
                localStorageUniqueKey={PolarisTableUniqueLocalStorageKeys.TASK_LOG_TABLE}
                emptyTable={
                    <EmptyTable
                        title="No Tasks"
                        subtitle="No tasks to display."
                    />
                }
            />
        </>
    );
}

export { TaskLogTable };
import moment from "moment-timezone";
import { isCurrentYear, isToday, isTomorrow, isYesterday } from "src/components/common/Comparator";
import { Moment } from "moment";

/**
 * Formats the given moment with the specified format after matching in the parameters order.
 *
 * @param aMoment
 * @param onYesterday
 * @param onToday
 * @param onTomorrow
 * @param onCurrentYear
 * @param onDifferentYear
 */
export function patternMatchMomentAndFormat(aMoment: moment.Moment, onYesterday: string, onToday: string,
                                            onTomorrow: string, onCurrentYear: string, onDifferentYear: string): string {
    const format =
        isYesterday(aMoment) ? onYesterday :
            isToday(aMoment) ? onToday :
                isTomorrow(aMoment) ? onTomorrow :
                    isCurrentYear(aMoment) ? onCurrentYear :
                        onDifferentYear;

    return `${aMoment.format(format)}`;
}

/**
 * Returns an ISO string representing the beginning of last week
 */
export function beginningOfLastWeek(): string {
    return moment().startOf('week')
        .subtract(1, 'week')
        .toISOString();
}

/**
 * Returns a moment representing the beginning of tomorrow
 */
export function beginningOfTomorrowMoment(): moment.Moment {
    return moment().startOf('day')
        .add(1, 'day');
}

/**
 * Returns a moment object representing an UTC date in the local browser timezone
 */
export function getLocalTimezoneMoment(timestamp: string): moment.Moment {
    return moment.utc(timestamp).tz(moment.tz.guess());
}

/**
 * Returns a moment object representing an UTC date based on a local date at the end of the day. When creating a task,
 * the desired behavior is that the task expires at the end of the chosen date.
 */
export function getDueDateUTCTimeString(timestamp: string): string {
    return moment(timestamp).startOf('day').utc().toISOString();
}

export function toISOStringUTC(dateTime: string): string {
    return dateTime
        ? momentToISOStringUTC(moment(dateTime))
        : dateTime;
}

export function momentToISOStringUTC(momentInstance: Moment): string {
    return momentInstance
        ? momentInstance.utc().toISOString()
        : momentInstance;
}

export function formatCreatedDateTime(dateTimeString: string | undefined) {
    if (!dateTimeString) return "";
    const createdTimeMoment = getLocalTimezoneMoment(dateTimeString)
    return moment(createdTimeMoment).format('MM/DD/YYYY hh:mm:ss A');
}

export function formatCreatedDate(dateString: string | undefined) {
    if (!dateString) return "-";
    return moment(dateString, 'DD/MM/YY').format('MM/DD/YY');
}
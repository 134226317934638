import React from 'react';
import { KatBadge, KatButton, KatButtonGroup } from '@amzn/katal-react';
import { publishAuditSelectorMetric } from './selectorMetrics';
import './AuditSelector.scss';

interface AuditStatusSelectorProps {
    disabled?: boolean;
    auditData: Cerebrum.Audits.GetAuditsOutput;
    selectedAuditType: Cerebrum.Audits.AuditType;
    onChangeHandler: (selectedStatus: Cerebrum.Audits.AuditStatus) => void;
}


export function AuditStatusSelector(props: AuditStatusSelectorProps) {
    const { disabled, auditData, selectedAuditType, onChangeHandler } = props;

    const auditStatusOptions = [
        {
            label: "All",
            value: "all"
        },
        {
            label: "Pending",
            value: "pending"
        },
        {
            label: "Completed",
            value: "completed"
        }
    ];

    /** counts the number of audits with the given status
     * if the status given is 'all' then every audit is counted
     */
    const countAuditsByStatus = (status: string) => {
        return auditData.audits.reduce((count, audit) => {
            return count + ((audit.status == status || status == "all") ? 1 : 0);
        }, 0);
    };

    const onchange = (event: KatButtonGroup.ChangeEvent) => {
        const selectedStatus: string = event.detail.value;
        publishAuditSelectorMetric({
            selectorName: 'statusSelector',
            selectorValue: selectedStatus,
            selectedAuditType: selectedAuditType
        });
        onChangeHandler(selectedStatus);
    };

    return (
        <KatButtonGroup onChange={onchange} value={"all"}>
            {auditStatusOptions.map(auditStatus =>
                <KatButton
                    key={auditStatus.value}
                    label={auditStatus.label}
                    value={auditStatus.value}
                    variant="primary"
                >
                    <StatusButtonLabel label={auditStatus.label} count={countAuditsByStatus(auditStatus.value)} />
                </KatButton>
            )}
        </KatButtonGroup>
    );
}

interface StatusButtonLabelProps {
    label: string;
    count: number;
}

function StatusButtonLabel(props: StatusButtonLabelProps) {
    const { label, count } = props;
    return (
        <div className="row align-items-center m-0 statusButtonLabel">
            <span className="col px-1">{label}</span>
            <KatBadge className="col px-1 axiom-badge-light-gray" type="info" label={count.toString()}></KatBadge>
        </div>
    );
}
import { UserManager } from "src/user/UserIdentityManager";
import { CerebrumService } from "src/service/CerebrumCoralService";

/**
 * Formats an array of tuples into an object with the respective key/values from each Tuple
 * @param templateContent
 */
const tuplesToObject = (templateContent: Cerebrum.Tasks.Tuple[]) => (
    Object.assign({}, ...templateContent.map(tuple => ({ [tuple.key]: tuple.value })))
);

const buildGetNarrativeTemplateInput = (templateName: string): Cerebrum.GetNarrativeTemplateInput => ({
    templateName,
    locale: localStorage.getItem('i18nextLng') || undefined,
    component: 'flash-briefing', // To be changed once we have templates for Tasks
    entityId: UserManager.getUserIdentity().login
});

/**
 * Resolves the content of a narrative template and formats its data if available.
 *
 * @param task
 */
const resolveTemplate = (task: Cerebrum.Tasks.Task): Promise<Cerebrum.Tasks.Task> => {
    if (!task.templateName) {
        // there is no defined template, just return the same old task
        return Promise.resolve(task);
    }

    // format content
    if (task.templateContent) {
        task.templateData = tuplesToObject(task.templateContent!);
    }

    // retrieves the template content from our backend where it is cached and can be easily invalidated
    // we could spare network requests if in the future we end up using the same temp;ate to many tasks
    // by creating an in-memory or local-storage based cache in the user's browser
    return CerebrumService.getNarrativeTemplate(buildGetNarrativeTemplateInput(task.templateName))
        .then(output => {
            task.template = output.content;
            return task;
        })
        .catch(reason => {
            // tslint:disable-next-line:no-console
            console.error(`Error getting template: [${JSON.stringify(reason)}]`)
            return task;
        });
}

/**
 * Updates a Cerebrum.Tasks.GetTasksOutput by resolving templates and formatting data in Tasks
 * where the information is available.
 *
 * @param getTasksResponse
 */
export const resolveTasksTemplates = async (getTasksResponse: Cerebrum.Tasks.GetTasksOutput)
    : Promise<Cerebrum.Tasks.GetTasksOutput> => (
    resolveTemplates(getTasksResponse.tasks)
        .then(tasks => ({ tasks }))
);

/**
 * Updates a Cerebrum.Tasks.CreateTaskOutput by resolving templates and formatting data in its Task
 * if the information is available.
 *
 * @param createTaskResponse
 */
export const resolveTaskTemplates = async (createTaskResponse: Cerebrum.Tasks.CreateTaskResponse)
    : Promise<Cerebrum.Tasks.CreateTaskResponse> => (
    resolveTemplates([createTaskResponse.task])
        .then(tasks => ({
                success: createTaskResponse.success,
                message: createTaskResponse.message,
                task: tasks[0]
            })
        )
);

/**
 * Updates an array of Cerebrum.Tasks.Task by resolving templates and formatting data in Tasks
 * where the information is available.
 *
 * @param tasks the tasks list to work with
 */
const resolveTemplates = async (tasks: Cerebrum.Tasks.Task[]): Promise<Cerebrum.Tasks.Task[]> => {
    const templatePromises = tasks
        .filter(task => task.templateName)
        .map(resolveTemplate);
    const tasksWithTemplate = await Promise.all(templatePromises);

    // replace updated tasks in original response
    return tasks.map(
        task => tasksWithTemplate.find(withTemplate => withTemplate.id === task.id) || task
    )
}
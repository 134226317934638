import React from 'react';
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";


/** Writes the value of an url search params with on configured key.
 *
 * @argument key: the param key which appears in the URL eg. `?key=value`
 * @returns a function to with a new value on the configured key
 */
export function useSearchParamWriter(key: string): (value: string) => void {

    const history = useHistory();
    const { search } = useLocation();

    return (value: string) => {
        const queryValues = queryString.parse(search);
        queryValues[key] = value!;
        const searchString = queryString.stringify(queryValues);
        history.replace({ search: searchString });
    };
}
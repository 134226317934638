import { ConfigManager } from 'src/config/ConfigManager';
import { TenantConfig } from 'src/user/TenantConfigManager';
import { Weblabs } from "src/user/WeblabsManager";
import { Treatment, Weblab } from "src/user/Weblab";
import { AxiomRight } from "src/config/AxiomCSRights";
import { UserAccessManager } from "src/user/UserAccessManager";

const TEST_USER_RIGHT_ID = "axiom.admin.view";

class UserIdentityManager implements UserAccessManager {
    public userIdentity?: WFM.GetUserInformation;
    public loggedUserIdentity?: WFM.GetUserInformation;

    /**
     * @deprecated
     * @warning this property is only defined when the admin tools are used to override tenant
     */
    public tenantId?: string;

    /**
     * @deprecated
     * @warning this property is only defined when the admin tools are used to override version_id
     */
    public versionId?: string;

    // These are currently unused.
    // TODO: refactor these into userIdentity Add these properties to admin panel for devs to set
    private _isOutSourced?: boolean;
    private _isAgent?: boolean;
    private _canViewHiddenContent?: boolean;

    public get isOutSourced() {
        if (this.userIdentity) {
            return this._isOutSourced ?? this.userIdentity.outsourced;
        }
        throw new Error("User is not authenticated");
    }

    public set isOutSourced(bool: boolean) {
        if (!this.canViewHiddenContent) {
            throw new Error("Only test users can alter this property");
        }
        this._isOutSourced = bool;
    }

    public get isAgent(): boolean {
        if (this.userIdentity) {
            // TEMP: Logic needed to identify if user is Agent
            return this._isAgent ?? !this.userIdentity.supervisor;
        }
        throw new Error("User is not authenticated");
    }

    public set isAgent(bool: boolean) {
        if (!this.isTestUser) {
            throw new Error("Only test users can alter this property");
        }
        this._isAgent = bool;
    }

    public get canViewHiddenContent() {
        // Testing users, like Devs have a bypass for debugging, so they can see all the features.
        return this.isTestUser;
    }

    public set canViewHiddenContent(bool: boolean) {
        if (!this.isTestUser) {
            throw new Error("Only test users can alter this property");
        }
        this._canViewHiddenContent = bool;
    }

    public get isTestUser() {
        if (this.userIdentity) {
            return this.userIdentity.accessRights[TEST_USER_RIGHT_ID];
        }
        return false;
    }

    public getUserIdentity(): WFM.GetUserInformation {
        if (this.userIdentity) {
            return this.userIdentity;
        }
        throw new Error("User is not authenticated");
    }

    public async setUserIdentity(userIdentity: WFM.GetUserInformation) {
        this.userIdentity = userIdentity;
        this.loggedUserIdentity = { ...userIdentity };
        Weblabs.initialize(userIdentity.login);
        TenantConfig.initialize(userIdentity.login);
        this.loadPreferences();
    }

    public isUserIdentityAvailable() {
        return this.userIdentity !== undefined;
    }

    /**
     * Basic initial implementation for loading preferences from browser local storage
     *
     * current prefs allow devs to select tenant, user, and usergroup which now persist through refresh
     */
    public loadPreferences() {
        // This feature is only supported for users with admin.view right
        if (!this.isTestUser) {
            return;
        }
        const localStorageKeys = ConfigManager.getStaticConfig().localStorageKeys;

        // Loading Tenant
        this.tenantId = localStorage.getItem(localStorageKeys.SELECTED_TENANT) ?? undefined;

        // Loading isOutSourced
        // only attempt to set _isOutSourced if it has been set previously to avoid json parse error
        const selectedUserGroup = localStorage.getItem(localStorageKeys.SELECTED_USER_GROUP);
        selectedUserGroup && (this._isOutSourced = Boolean(JSON.parse(selectedUserGroup)));

        // Loading User Login
        this.userIdentity && (this.userIdentity.login = localStorage.getItem(localStorageKeys.SELECTED_LOGIN) ?? this.userIdentity.login);

        // Loading weblab preferences
        for (const weblab in Weblab) {
            const treatment = localStorage.getItem(Weblab[weblab as keyof typeof Weblab]);
            treatment && Weblabs.overrideTreatment(Weblab[weblab as keyof typeof Weblab], Treatment[treatment as keyof typeof Treatment]);
        }

        // Load rights overrides from session storage
        // tslint:disable-next-line:forin
        for (const right in this.userIdentity?.accessRights) {
            const sessionValue = window.sessionStorage.getItem(right)
            if (sessionValue != null) {
                this.userIdentity!.accessRights[right] = window.sessionStorage.getItem(right) === 'true'
            }
        }
    }

    public isUserAuthenticated() {
        return !!this.userIdentity?.login;
    }

    isUserAuthorizedForRight(right: AxiomRight): boolean {
        return this.userIdentity?.accessRights[right] ?? false;
    }
}

export const UserManager = new UserIdentityManager();
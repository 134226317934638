import React, { Component } from 'react';
import { Radar, defaults } from 'react-chartjs-2';

const BSC_RADAR_METRICS_LIST = ['acht', 'paa', 'pma', 'ro', 'trans'];

const CHART_COLORS = {
    red: 'rgb(255, 99, 132)',
    orange: 'rgb(255, 159, 64)',
    yellow: 'rgb(255, 205, 86)',
    green: 'rgb(75, 192, 192)',
    blue: 'rgb(54, 162, 235)',
    purple: 'rgb(153, 102, 255)',
    grey: 'rgb(201, 203, 207)',
    faded: 'rgba(0, 0, 0, .05)',
    clear: 'rgba(0, 0, 0, 0)'
};

// TODO: bulk setting properties is described on this page and can be done cleaner with lodash https://github.com/jerairrest/react-chartjs-2
defaults.global.defaultColor = CHART_COLORS.faded; //this is the gray color of the non-selected line
defaults.global.elements.line.backgroundColor = CHART_COLORS.clear; //no backgroundcolor
defaults.global.elements.line.borderColor = CHART_COLORS.faded;
defaults.global.elements.point.hitRadius = 20;
defaults.global.elements.line.borderWidth = 3;
defaults.global.animation.duration = 500;
defaults.global.hover.animationDuration = 200;
defaults.global.defaultFontSize = 10;

defaults.global.tooltips.bodyFontColor = 'rgba(59, 59, 59, 1)';
defaults.global.tooltips.backgroundColor = 'rgba(204, 204, 204, 0.9)';
defaults.global.tooltips.titleFontColor = 'rgba(59, 59, 59, 1)';
defaults.global.tooltips.bodyFontSize = 10;
defaults.global.tooltips.bodySpacing = 1;
defaults.global.tooltips.xPadding = 10;
defaults.global.tooltips.yPadding = 10;
defaults.global.tooltips.cornerRadius = 3;

const RADAR_OPTIONS = {
    startAngle: 0,
    legend: { display: false },
    title: { display: false },
    scale: {
        ticks: {
            max: 100,
            stepSize: 25,
            min: -20,
        },
        pointLabels: { fontSize: 14 },
    },
    tooltips: {
        mode: 'index'
    }
};

interface RadarChartProps {
    userBalancedScorecardData: Cerebrum.UserBalancedScorecardData;
    selectedEntity: Axiom.SelectedUser;
}

export interface RadarChartState {
    showRadar: boolean;
}

export default class RadarChart extends Component<RadarChartProps, RadarChartState> {
    constructor(props: RadarChartProps) {
        super(props);
        this.buildRadarData = this.buildRadarData.bind(this);
        this.buildAgentRadarDataset = this.buildAgentRadarDataset.bind(this);
        this.state = {
            showRadar: false
        }
    }

    componentDidMount(): void {
        /*
        Adding this setTimeout as a workaround to a Firefox bug in versions 105 and above
        Chart.js users get NS_ERROR_FAILURE when rendering charts https://github.com/chartjs/Chart.js/issues/10720
        Ionic framework users find out it's due to calling .fillText() on a canvas on a shadow DOM component
        https://github.com/chartjs/Chart.js/issues/10720
        Bug report in Mozilla https://github.com/ionic-team/ionic-framework/issues/26039
        Adding setTimeout call to the component that renders the chart fixes the problem
         */
        setTimeout(() =>this.setState({showRadar: true}));
    }

    private buildRadarData() {
        try {
            const bsc = this.props.userBalancedScorecardData;
            const agentDatasets = bsc.agentBalancedScorecards.map(abs => this.buildAgentRadarDataset(abs));
            const teamDataset = this.buildTeamRadarDataset(bsc);
            const labels = bsc.teamBalancedScorecard.metrics
                .filter(this.filterRadarMetrics)
                .map(metric => metric.name.toUpperCase());
            const data = {
                labels: labels,
                datasets: [
                    ...agentDatasets,
                    teamDataset
                ]
            };
            return data;
        } catch (error) {
            console.error("Invalid UserScore value from Cerebrum Service");
            return null;
        }
    }

    private buildAgentRadarDataset(bsc: Cerebrum.AgentBalancedScorecard) {
        const isSelected = this.props.selectedEntity.userId == bsc.agent;
        return {
            label: bsc.agent,
            borderColor: isSelected ? 'rgba(0, 130, 150, 1)' : 'rgba(0, 130, 150, .1)', // if the agent is not selected the line will be mostly transparent
            borderWidth: isSelected ? 4 : 3,
            pointRadius: isSelected ? 3 : 2,
            data: this.fillRadarDataset(bsc.balancedScorecard.metrics)
        };
    }

    private buildTeamRadarDataset(bsc: Cerebrum.UserBalancedScorecardData) {
        const isSelected = this.props.selectedEntity.userId == bsc.supervisor;
        return {
            label: bsc.supervisor,
            borderColor: 'rgba(255, 143, 0, 1)',
            borderWidth: isSelected ? 4 : 3,
            pointRadius: isSelected ? 3 : 2,
            data: this.fillRadarDataset(bsc.teamBalancedScorecard.metrics)
        };
    }

    /**
     * Fills datapoint array with nulls in correct locations to ensure diagram displays properly if metrics are not fully present
     * 
     * @param dataset 
     * @param metricsList optional list of metrics, defaults to BSC_RADAR_METRICS_LIST,
     */
    private fillRadarDataset(dataset: Cerebrum.BalancedScorecardMetric[], metricsList: string[] = BSC_RADAR_METRICS_LIST) {
        return metricsList.map(
            metricName => (
                dataset.filter(metric => metric.name == metricName)[0]
                || { name: metricName, score: null, value: null }
            ).score
        );
    }

    // Include only designated metrics in the diagram
    private filterRadarMetrics(metric: Cerebrum.BalancedScorecardMetric) {
        return BSC_RADAR_METRICS_LIST.includes(metric.name);
    }

    render() {
        return (
            this.state.showRadar && <Radar data={this.buildRadarData()} options={RADAR_OPTIONS} />
        );
    }
}

import { JSONEditor } from "vanilla-jsoneditor";
import React, { useEffect, useRef } from "react";
import "./SvelteJsonEditor.css";

// Wrapper for vanilla-jsoneditor based on the example at https://codesandbox.io/s/svelte-jsoneditor-react-59wxz
// @ts-ignore
export default function SvelteJsonEditor(props) {
    const refContainer = useRef(null);
    const refEditor = useRef(null);

    useEffect(() => {
        // create editor
        // tslint:disable-next-line:no-console
        console.log("create editor", refContainer.current);
        // @ts-ignore
        refEditor.current = new JSONEditor({
            // @ts-ignore
            target: refContainer.current,
            props: {}
        });

        return () => {
            // destroy editor
            if (refEditor.current) {
                // tslint:disable-next-line:no-console
                console.log("destroy editor");
                // @ts-ignore
                refEditor.current.destroy();
                refEditor.current = null;
            }
        };
    }, []);

    // update props
    useEffect(() => {
        if (refEditor.current) {
            // tslint:disable-next-line:no-console
            console.log("update props", props);
            // @ts-ignore
            refEditor.current.updateProps(props);
        }
    }, [props]);

    return <div className='svelte-jsoneditor-react' ref={refContainer}></div>;
}

import React from 'react';
import i18n from 'i18next';
import { KatPopover, KatIcon } from '@amzn/katal-react';
import { AxiomMetricsDriver } from "src/metrics/AxiomMetricsDriver";
import { MetricDefinitionMoreInfo } from './MetricDefinitionMoreInfo';


interface MetricDefinitionPopoverProps {
    metricList: Axiom.metricList;
}

export default function MetricDefinitionPopover(props: MetricDefinitionPopoverProps) {
    const { metricList } = props;
    const METRIC_DEFINITIONS: Axiom.MetricDefinitionsList = {
        "prr": {
            "display": "PRR",
            "longName": i18n.t('wfm_axiom_v2_positive_response_rate'),
        },
        "acht": {
            "display": "ACHT",
            "longName": i18n.t('wfm_axiom_v2_avg_concurrent_handling_time'),
        },
        "trans": {
            "display": "TRANS",
            "longName": i18n.t('wfm_axiom_v2_transfer_rate'),
        },
        "pma": {
            "display": "PMA",
            "longName": `PMA ${i18n.t('wfm_axiom_rate')}`,
        },
        "res": {
            "display": "RES",
            "longName": i18n.t('wfm_axiom_v2_resolve_rate'),
        },
        "andon": {
            "display": "ANDON",
            "longName": i18n.t('wfm_axiom_v2_andon_rate'),
        },
        "paa": {
            "display": "PAA",
            "longName": `PAA ${i18n.t('wfm_axiom_rate')}`,
        },
        "arr": {
            "display": "ARR",
            "longName": i18n.t('wfm_axiom_v2_absolute_resolve_rate'),
        },
        "ro": {
            "display": "RO",
            "longName": i18n.t('wfm_axiom_v2_reopen_rate'),
        },
        "pa": {
            "display": "PA",
            "longName": "Process Adherence", // TODO: Add translation strings
        },
        "oa": {
            "display": "OA",
            "longName": "Outcome Accuracy",
        }
    };

    return (
        <KatPopover hideBehavior="click" katAriaBehavior="interactive" variant="info" position="left">
            <KatIcon
                slot="trigger"
                onClick={() => AxiomMetricsDriver.publishButtonClick('RadarTable', 'metric_info')}
                name="info-circle"
                size="tiny"
                className="pl-1"
            />
            <dl className="row">
                {metricList.map(metric => (
                    <React.Fragment key={metric}>
                        <dt className="col-3">{METRIC_DEFINITIONS[metric]?.display || metric.toUpperCase()}</dt>
                        <dd className="col-9">{METRIC_DEFINITIONS[metric]?.longName || `[!] (${metric} ${i18n.t('wfm_axiom_v2_definition_not_found')})`}</dd>
                    </React.Fragment>
                ))}
            </dl>
            <MetricDefinitionMoreInfo />
        </KatPopover>
    );
}
import React from "react";
import ExtendedPolarisTable from "src/components/polaris_table/ExtendedPolarisTable";
import COMPARATORS from "src/components/utils/Comparators";
import { formatCreatedDateTime } from "src/components/common/DateFormatter";
import { MetadataLabel, parseMetadataLabel } from 'src/components/common/MetadataLabel'
import { Appsync } from "src/@types/appsync";
import { ExtensionFormatter } from "src/components/forms/extensions/ExtensionFormatter";
import { HeaderRoute } from "src/components/common/header/HeaderRoute";
import { FilterConfig, HeaderConfig, PolarisTableUniqueLocalStorageKeys } from "src/@types/extendedPolarisTable";
import EmptyTable from "src/components/polaris_table/EmptyTable";
import { NewSubmissionButton } from "src/components/forms/new_submission_button/NewSubmissionButton";
import 'src/components/forms/Forms.scss';
import { DateRange, FormsDateRangePicker } from "src/components/forms/date_range_picker/FormsDateRangePicker";

type CustomerObsessionAuditTableProps = {
    itemList: Appsync.FormSubmissionsTableData;
    loading: boolean
    onDateRangeChanged: (dateRange: DateRange) => void;
};
/**
 * Implemented with Polaris.
 */
export default function FormSubmissionsTable(
    props: CustomerObsessionAuditTableProps,
) {
    const { itemList, loading, onDateRangeChanged } = props;

    const getCellMetadataLabelsFromItem = (item: Appsync.FormSubmissionRowData, cellId: string, newLine: boolean = false) => {
        return ExtensionFormatter
            .splitExtensionsString((item as any)[cellId])
            .map(
                (individualLabel: string) => {
                    return newLine ?
                        <div>{parseMetadataLabel(cellId, individualLabel)}</div> : parseMetadataLabel(cellId, individualLabel)
                }
            )
    }

    const comparators = COMPARATORS.formSubmission;

    const columnDefinitions = [
        {
            id: 'formDetails',
            header: 'Forms Details',
            cell: (item: Appsync.FormSubmissionRowData) => getCellMetadataLabelsFromItem(item, 'formDetails'),
            sortingField: 'formDetails',
            isSearchable: true,
            minWidth: '10%',
            width: '26%'
        },
        {
            id: "targetEntity",
            header: "Target Entity",
            cell: (item: Appsync.FormSubmissionRowData) => getCellMetadataLabelsFromItem(item, 'targetEntity'),
            sortingField: 'targetEntity',
            minWidth: '10%',
            width: '12%',
            isSearchable: true
        },
        {
            id: "targetItem",
            header: "Target Item",
            cell: (item: Appsync.FormSubmissionRowData) => getCellMetadataLabelsFromItem(item, 'targetItem', true),
            sortingField: 'targetItem',
            minWidth: '10%',
            width: '15%',
            isSearchable: true
        },
        {
            id: "submitterEntityId",
            header: "Submitter",
            cell: (item: Appsync.FormSubmissionRowData) => getCellMetadataLabelsFromItem(item, 'submitterEntityId'),
            sortingField: 'submitterEntityId',
            minWidth: '10%',
            width: '12%',
            isSearchable: true
        },
        {
            id: "createdDateTime",
            header: "Submit Date",
            cell: (item: Appsync.FormSubmissionRowData) => formatCreatedDateTime(item.createdDateTime),
            sortingComparator: comparators.createdDateTime,
            minWidth: '10%',
            width: '12%',
            isSearchable: true
        },
        {
            id: "metadata",
            header: "Metadata",
            cell: (item: Appsync.FormSubmissionRowData) => getCellMetadataLabelsFromItem(item, 'metadata', true),
            sortingField: 'metadata',
            minWidth: '10%',
            width: '17%',
            isSearchable: true
        },
        {
            id: "viewDetails",
            header: "View",
            minWidth: '10%',
            width: '6%',
            cell: (item: Appsync.FormSubmissionRowData) => {
                const additionalMetrics: Axiom.AdditionalMetric[] = [
                    { name: 'submitterEntityId', value: item.submitterEntityId! },
                    { name: 'submissionId', value: item.id! }
                ];

                return <MetadataLabel
                    name='ViewSubmission'
                    iconName={'assignment'}
                    href={`#${HeaderRoute.FORMS_VIEW}/${item.id}`}
                    key={item.id}
                    additionalMetrics={additionalMetrics}
                />
            }
        },
    ];

    const filterConfigs = [
        {
            type: "dropdown",
            attributeName: "formName",
            defaultSelectedValue: "0",
            defaultSelectedLabel: "All Forms",
            customStyle: { minWidth: '15em' }
        }
    ] as FilterConfig[];

    const emptyTable = <EmptyTable
        title="No Form Submissions"
        subtitle="No submissions to display."
    />;

    function getCounterOfSubmissions() {
        return itemList.length > 0 ? itemList.length : '';
    }

    const headerConfig: HeaderConfig = {
        title: "Submissions",
        counter: `${(getCounterOfSubmissions())}`,
        variant: "awsui-h1-sticky",
        actions: [
            { type: "custom", customComponent: <NewSubmissionButton/> }
        ],
    };


    return (
        <>
            <ExtendedPolarisTable<Appsync.FormSubmissionRowData>
                itemList={itemList}
                columnDefinitions={columnDefinitions}
                filterConfigs={filterConfigs as FilterConfig[]}
                customFilter={<FormsDateRangePicker onDateRangeChanged={onDateRangeChanged}/>}
                defaultSortingColumn={4} // createdDateTime
                includeSearchByText={true}
                trackBy={"id"}
                pageSize={15}
                resizableColumns
                wrapLines
                emptyTable={emptyTable}
                loading={loading}
                loadingText={"Loading form submissions..."}
                headerConfig={headerConfig as HeaderConfig}
                localStorageUniqueKey={PolarisTableUniqueLocalStorageKeys.FORM_SUBMISSIONS_TABLE}
            />
        </>
    );
}

import { KatIcon } from "@amzn/katal-react";
import React, { FC } from "react";

interface EmptyListView {
    iconName: KatIcon.Name;
    iconBorder?: boolean;
    message: string;
}

export const EmptyListView: FC<EmptyListView> = (props) => {

    const { iconName, iconBorder, message } = props;

    return (
        <div className='d-flex flex-column align-items-center'>
            <div className={`mt-5 mb-3 p-3 ${iconBorder ? 'border' : ''}`}>
                <KatIcon className='empty-list-icon' name={iconName} size='large'/>
            </div>
            <span className='d-block text-wrap text-center mx-5 px-5 '>
                {message}
            </span>
        </div>
    );
}

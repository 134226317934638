import React, { useEffect } from "react";
import { PopoverDropdowns } from "src/components/common/popoverDropdowns/PopoverDropdowns";
import { AxiomMetricsDriver } from "src/metrics/AxiomMetricsDriver";
import { useStateWithLocalStorage } from "src/components/common/storage/UseStateWithLocalStorage";
import { Sorting, useSorter } from "src/components/common/Sorter";
import { Grouped, Grouping, useGrouper } from "src/components/common/Grouper";
import { localStorageKeyMap } from "src/components/admin/UserDetailsSwitcher";

export type ItemOrganizerConfigOptions<Type> = {
    sortStrategies: Record<string, Sorting<Type>>,
    groupStrategies: Record<string, Grouping<Type>>,
    sortingOptionFromString: (taskSortingOption: string) => string,
    groupingOptionFromString: (taskSortingOption: string) => string,
    groupingOptions: { name: any, value: any }[],
    sortingOptions: { name: any, value: any }[],
}

type ItemOrganizerProps<Type> = {
    parentComponentName: string,
    label: string,
    items: Type[],
    itemOrganizerConfigOptions: ItemOrganizerConfigOptions<Type>,
    updateItemsToDisplay: (groupedItems: Grouped<Type>) => void,
}

const GROUP_BY_LABEL = 'Group by';
const SORT_BY_LABEL = 'Sort by';
const localStorageGroupByKeySuffix = localStorageKeyMap.GROUPING_OPTION_SUFFIX;
const localStorageSortByKeySuffix = localStorageKeyMap.SORTING_OPTION_SUFFIX;

export function ItemOrganizer<Type>(props: ItemOrganizerProps<Type>) {
    const { label, parentComponentName, items, updateItemsToDisplay,
        itemOrganizerConfigOptions: {sortingOptionFromString, groupingOptionFromString, groupingOptions, sortingOptions, sortStrategies, groupStrategies} } = props;

    const groupingDefaultValue = groupingOptions[0].value;
    const sortingDefaultValue = sortingOptions[0].value;

    const [groupByFilter, setGroupByFilter] = useStateWithLocalStorage<string>(`${parentComponentName}${localStorageGroupByKeySuffix}`, groupingDefaultValue);
    const [sortByFilter, setSortByFilter] = useStateWithLocalStorage<string>(`${parentComponentName}${localStorageSortByKeySuffix}`, sortingDefaultValue);
    const [sortedTasks] = useSorter<Type>(sortingOptionFromString(sortByFilter), items, sortStrategies);
    const [groupedTasks] = useGrouper<Type>(groupingOptionFromString(groupByFilter), sortedTasks, groupStrategies);

    useEffect(() => {
        updateItemsToDisplay(groupedTasks)
    }, [groupedTasks]);

    const getPopoverDropdownsInputs = () => {
        return [{
            label: GROUP_BY_LABEL,
            options: groupingOptions,
            onChange: (event: any) => {
                setGroupByFilter(event.target.value);
                publishMetric(event, GROUP_BY_LABEL);
            },
            value: groupByFilter || groupingDefaultValue,
            clearToValue: groupingDefaultValue,
        }, {
            label: SORT_BY_LABEL,
            options: sortingOptions,
            onChange: (event: any) => {
                setSortByFilter(event.target.value);
                publishMetric(event, SORT_BY_LABEL);
            },
            value: sortByFilter || sortingDefaultValue,
            clearToValue: sortingDefaultValue,
        }];
    }

    const publishMetric = (event: any, inputLabel: string) => {
        // Method metrics do not accept spaces, replacing before publishing.
        const trimmedInputLabel = inputLabel.replace(/ /g,"_");
        const additionalMetrics: Axiom.AdditionalMetric[] = [{
            name: "selectedValue",
            value: event.target.value
        }];
        AxiomMetricsDriver.publishButtonClick(parentComponentName, `ItemOrganizer.${trimmedInputLabel}`, additionalMetrics);
    }

    return (
        <PopoverDropdowns label={label} popoverDropdownsInputs={getPopoverDropdownsInputs()}/>
    );

}
import React, { useEffect, useState } from "react";
import "./Tasks.scss"
import { KatButton, KatDivider, KatSpinner } from "@amzn/katal-react";
import { CerebrumService } from "src/service/CerebrumCoralService";
import { CoralError } from "src/components/error/ErrorBoundComponent";
import { ErrorMessage } from "src/components/error/ErrorMessage";
import EventEmitter, { asEventListener } from "src/context/EventEmitter";
import { TaskEventName } from "src/components/tasks/events/TasksEvents";
import { TaskUpdatedEvent } from "src/components/tasks/events/TaskUpdatedEvent";
import { TaskCreatedEvent } from "src/components/tasks/events/TaskCreatedEvent";
import { TasksList } from "src/components/tasks/TasksList";
import { uniqueId } from "src/context/UniqueId";
import { AxiomMetricsDriver } from "src/metrics/AxiomMetricsDriver";
import { useHistory } from "react-router-dom";
import { HeaderRoute } from "src/components/common/header/HeaderRoute";
import { EmptyListView } from "src/components/common/EmptyListView";
import { CreateTaskCard } from "src/components/tasks/CreateTaskCard";
import i18n from "i18next";
import { AssitantTab } from "src/components/assistant/AssistantContext";
import {
    TaskGroupingFromOption,
    TaskGroupingOptionFromString,
    taskGroupingOptions
} from "src/components/tasks/model/TaskGroupingOptions";
import {
    TaskSortingFromOption,
    TaskSortingOptionFromString,
    taskSortingOptions
} from "src/components/tasks/model/TaskSortingOptions";
import { Grouped } from "src/components/common/Grouper";
import { ItemOrganizer, ItemOrganizerConfigOptions } from "src/components/common/itemOrganizer/ItemOrganizer";

interface TasksProps {
    viewUserLogin: string;
    setUnseenNumberCallback: (tabName: AssitantTab, unseenNumber: number) => void;
}

const COMPONENT_NAME = "Tasks";
const FILTER_TASKS_VIEW_TEXT = "View";

export const TASK_SORTING_GROUPING_CONFIG: ItemOrganizerConfigOptions<Cerebrum.Tasks.Task> = {
    sortStrategies: TaskSortingFromOption,
    groupStrategies: TaskGroupingFromOption,
    sortingOptionFromString: TaskSortingOptionFromString,
    groupingOptionFromString: TaskGroupingOptionFromString,
    groupingOptions: taskGroupingOptions,
    sortingOptions: taskSortingOptions
}

export const Tasks: React.FC<TasksProps> = (props) => {

    const [instanceId] = useState(() => uniqueId(COMPONENT_NAME));

    const { viewUserLogin } = props;

    const history = useHistory();

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [tasks, setTasks] = useState<Cerebrum.Tasks.Task[]>([]);

    const [error, setError] = useState<CoralError>();

    const [groupedTasks, setGroupedTasks] = useState<Grouped<Cerebrum.Tasks.Task>>({});


    useEffect(() => {
        getTasks();
    }, [viewUserLogin]);

    const markUnseenTasksAsSeen = () => {
        const unseenTasks = tasks.filter(task => !task.seen).map(task => task.id);
        if (unseenTasks.length > 0) {
            const input: Cerebrum.Tasks.MarkTasksAsSeenInput = {
                ids: unseenTasks,
                taskOwner: viewUserLogin
            };
            CerebrumService.markTasksAsSeen(input)
                // tslint:disable-next-line:no-console
                .catch(coralError => console.error(coralError));
        }
    }
    useEffect(markUnseenTasksAsSeen, [tasks]);

    const getUnseenUndeletedTasks = (allTasks: Cerebrum.Tasks.Task[]) => {
        return allTasks.filter(task => !task.deleted && !task.seen);
    }

    // Event listening and cleanup
    const onTaskCreated = (taskCreatedEvent: TaskCreatedEvent) => {
        setTasks(currentTasks => [...currentTasks, taskCreatedEvent.payload]);
    }

    const onTaskUpdated = (taskUpdatedEvent: TaskUpdatedEvent) => {
        const updatedTask = taskUpdatedEvent.payload;

        setTasks(currentTasks => currentTasks.map(
            currentTask => currentTask.id === updatedTask.id ?
                updatedTask :
                currentTask)
        );
    }

    const taskCreatedListener = asEventListener(instanceId, onTaskCreated);
    const taskUpdatedListener = asEventListener(instanceId, onTaskUpdated);
    useEffect(() => {
        EventEmitter.subscribe(TaskEventName.TASK_CREATED_EVENT, taskCreatedListener);
        EventEmitter.subscribe(TaskEventName.TASK_UPDATED_EVENT, taskUpdatedListener);
        return () => {
            EventEmitter.unSubscribe(TaskEventName.TASK_CREATED_EVENT, instanceId);
            EventEmitter.unSubscribe(TaskEventName.TASK_UPDATED_EVENT, instanceId);
        }
    }, []);

    // Api calls
    const getTasks = async () => {
        const input: Cerebrum.Tasks.GetTasksInput = {
            taskOwner: viewUserLogin,
            taskFilter: { taskStatus: "NotStarted", deleted: false }
        };
        await CerebrumService.getTasks(input)
            .then(handleResponse)
            .catch(handleError);
    }

    // Api calls
    const handleResponse = (response: Cerebrum.Tasks.GetTasksOutput) => {
        setTasks(response.tasks);
        setIsLoading(false);
        setError(undefined);
        props.setUnseenNumberCallback(AssitantTab.TASKS, getUnseenUndeletedTasks(response.tasks).length);
    }

    const handleError = (coralError: CoralError) => {
        // tslint:disable-next-line:no-console
        console.error(coralError);
        setTasks([]);
        setIsLoading(false);
        setError(coralError);
    }

    const handleViewLogClick = () => {
        const route = HeaderRoute.TASKS_LOG;
        AxiomMetricsDriver.publishLinkClick(COMPONENT_NAME, 'ViewLog', route);
        history.push(route);
    }

    // UI elements
    const LoadingSpinner = () => (
        <>{isLoading &&
            <div className="row justify-content-center"><KatSpinner size="large"/></div>}
        </>
    );

    const LoadingErrorMessage = () => (
        <>{error &&
            <ErrorMessage
                title={i18n.t('wfm_axiom_tasks_error_getting_tasks')}
                message={error.__type + "\n" + error.message ? error.message : ""}
                level="warning"/>
        }</>
    );

    const NoTasksView = () => (
        <>{!isLoading && tasks.length === 0 &&
            <EmptyListView iconName='check' iconBorder
                           message={i18n.t('wfm_axiom_tasks_no_tasks')}/>
        }</>
    );

    const TasksLists = () => (
        <>{!isLoading && tasks.length > 0 &&
            <>
                {Object.keys(groupedTasks).sort().map((key: string, index: number) => <>
                    <TasksList title={key}
                               key={`${key}-${index}-task`}
                               tasks={groupedTasks[key].filter(task => !task.deleted)}
                               viewUserLogin={viewUserLogin}
                               noTasksMessage={i18n.t('wfm_axiom_tasks_no_tasks')}
                               showFeedbackOption/>
                    {index !== Object.keys(groupedTasks).length - 1 &&
                        <KatDivider variant='eastern' key={`${key}-${index}-divider`}/>}
                </>)}
            </>}
        </>
    );

    return (
        <div className="d-flex flex-column px-3 h-100">
            <div className="d-flex flex-row">
                <CreateTaskCard viewUserLogin={viewUserLogin}/>
                <ItemOrganizer<Cerebrum.Tasks.Task> parentComponentName={COMPONENT_NAME}
                                                    label={FILTER_TASKS_VIEW_TEXT}
                                                    items={tasks}
                                                    itemOrganizerConfigOptions={TASK_SORTING_GROUPING_CONFIG}
                                                    updateItemsToDisplay={setGroupedTasks}/>
            </div>
            <LoadingErrorMessage/>
            <LoadingSpinner/>
            <NoTasksView/>
            <TasksLists/>
            <KatButton className='task-button' label={i18n.t('wfm_axiom_tasks_view_log')} variant='link'
                       onClick={handleViewLogClick}>
                <span className='task-button-label'>{i18n.t('wfm_axiom_tasks_view_log')}</span>
            </KatButton>
        </div>
    );
}
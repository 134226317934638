import React, { useState } from 'react';
import { KatBox, KatButton, KatIcon, KatLabel } from '@amzn/katal-react';

interface WasUsefulBannerProps {
    onFeedback: (useful: boolean) => void;
}

export const WasUsefulBanner: React.FC<WasUsefulBannerProps> = (props) => {
    const label = 'This question is important to me';

    const { onFeedback } = props;
    const [selectedOption, setSelectedOption] = useState<boolean | undefined>(undefined);

    // Handlers
    const onOptionClick = (useful: boolean) => {
        onFeedback(useful);
        setSelectedOption(useful);
    };

    return <>
        <KatBox className='d-flex flex-row px-0 py-2'>
            <KatLabel className='pt-2 pr-2 flex-grow-1 text-right'>{label}</KatLabel>
            <KatButton
                size='small'
                className='pl-1 thumb-button thumb-button-left'
                variant={selectedOption ? 'primary' : 'link'}
                onClick={() => onOptionClick(true)}
            >
                <KatIcon title='Useful' name='thumb_up' size='small' />
            </KatButton>
            <KatButton
                size='small'
                className='pr-1 thumb-button thumb-button-right'
                variant={!(selectedOption ?? true) ? 'primary' : 'link'}
                onClick={() => onOptionClick(false)}
            >
                <KatIcon title='Not useful' name='thumb_down' size='small' />
            </KatButton>
        </KatBox>
    </>;
};

import React, { useCallback, useEffect, useState } from 'react';
import './Forms.scss';
import { KatAlert, KatBox, KatModal, KatSpinner } from '@amzn/katal-react';
import { FormsService } from "src/service/Forms.service";
import { FormDefinition, FormDefinitionResponse } from "src/service/amplify/appsync";

import SvelteJsonEditor from 'src/components/common/jsonEditor/SvelteJsonEditor';
import { FormDisplay, FormResponses } from "src/components/forms/FormDisplay";
import { AXIOM_FORMS_PREFIX } from "src/components/forms/constants/FormConstants";


interface FormDesignerProps {
    formId?: string;
}

interface EditorContent {
    json?: any;
}

const PAGE_NAME = 'FormDesigner';
const COMPONENT_NAME = AXIOM_FORMS_PREFIX + PAGE_NAME;

export const FormDesigner: React.FC<FormDesignerProps> = ({ formId }: FormDesignerProps) => {

    const [isLoading, setLoading] = useState<boolean>(true);

    const [errorMessage, setErrorMessage] = useState<any>();
    const [infoMessage, setInfoMessage] = useState<any>();

    const [formTemplate, setFormTemplate] = useState<EditorContent>({
        json: undefined
    });
    const [formMetadata, setFormMetadata] = useState<EditorContent>({
        json: undefined
    });

    const [formDefinition, setFormDefinition] = useState<FormDefinition>();

    const [formSubmission, setFormSubmission] = useState<FormResponses>();

    // Publish page load metrics
    useEffect(() => {
        document.title = `${SITE_NAME} - ${PAGE_NAME}`;
        // Do not send metrics if this page is not meant to stay
        // AxiomMetricsDriver.publishPageLoad(COMPONENT_NAME, window.location.href);
    }, []);

    useEffect(() => {
        getForm();
    }, [formId]);

    useEffect(() => {
        submitForm();
    }, [formSubmission]);

    useEffect(() => {
        if (formMetadata && formDefinition) {
            try {
                const newFormDefinition = {
                    ...formMetadata.json,
                    formTemplate: JSON.stringify(formTemplate.json)
                };
                setFormDefinition(newFormDefinition);
            } catch (error) {
                // @ts-ignore
                setErrorMessage(error.toString());
                // tslint:disable-next-line:no-console
                console.error(error, formTemplate.json);
            }
        }
    }, [formMetadata]);

    useEffect(() => {
        if (formTemplate && formDefinition) {
            try {
                const newFormDefinition = {
                    ...formDefinition,
                    formTemplate: JSON.stringify(formTemplate.json)
                };
                setFormDefinition(newFormDefinition);
            } catch (error: any) {
                setErrorMessage(error.toString());
                // tslint:disable-next-line:no-console
                console.error(error, formTemplate.json);
            }
        }
    }, [formTemplate]);

    // Api calls
    const getForm = async () => {
        if (formId) {

            setLoading(true);
            setErrorMessage(undefined);
            setFormDefinition(undefined);

            FormsService.getFormDefinition(formId)
                .then(handleGetFormResponse)
                .catch(handleError);
        }
    };

    const submitForm = async () => {
        if (formSubmission) {
            // not actually submitting the form but showing the responses
            setInfoMessage(formSubmission);
        }
    };

    // Api calls handlers
    const handleGetFormResponse = (response?: FormDefinitionResponse) => {
        if (FormsService.isSuccessful(response) && response?.body?.formDefinition) {
            const _formDefinition = response.body.formDefinition
            setFormDefinition(_formDefinition);
            setFormTemplate({ json: JSON.parse(_formDefinition.formTemplate) });

            const newMetadata = { ..._formDefinition } as any;
            delete newMetadata.formTemplate;
            setFormMetadata({ json: newMetadata });

            setErrorMessage(undefined);
            setLoading(false);
        } else {
            handleError(`Form with id ${formId} not found`);
        }
    };

    const handleError = (responseError: any) => {
        setLoading(false);
        setInfoMessage(undefined);
        setErrorMessage(typeof responseError === 'string' ? responseError : JSON.stringify(responseError));
        // tslint:disable-next-line:no-console
        console.error(errorMessage);
    };

    // UI elements
    const LoadingSpinner = () => (
        <>{isLoading &&
            <div className='row justify-content-center'><KatSpinner size='large'/></div>}
        </>
    );

    const ErrorBanner = () => (
        <>{errorMessage &&
            <KatAlert title='Error loading form' variant='danger' description={errorMessage} persistent/>
        }</>
    );

    const InfoBanner = () => (
        <>{infoMessage &&
            <KatAlert title='Info' variant='info' description={infoMessage}/>
        }</>
    );

    const SubmissionDialog = () => (<>
        {formSubmission &&
            <KatModal className='pt-3' visible title='Form Submission'>
                <InfoBanner/>
                <ErrorBanner/>
                <LoadingSpinner/>
                <pre>{JSON.stringify(formSubmission, null, 2)}</pre>
            </KatModal>}
    </>);

    const handleSubmit = useCallback((responses: FormResponses) => {
        setFormSubmission(responses);
    }, []);

    return (
        <div className='d-flex justify-content-center pt-5'>
            <div className='flex-column w-50'>
                <KatBox className='pt-3 d-flex flex-column' variant='ltgrey'>
                    <h1>Form Definition (without `formTemplate`)</h1>
                    <SvelteJsonEditor content={formMetadata} onChange={setFormMetadata} readOnly={false}/>
                    <h1>Form Template</h1>
                    <SvelteJsonEditor content={formTemplate} onChange={setFormTemplate} readOnly={false}/>
                </KatBox>
            </div>
            <div className='flex-column w-50'>
                <KatBox className='pt-3 d-flex flex-column' variant='ltgrey'>
                    <InfoBanner/>
                    <ErrorBanner/>
                    <LoadingSpinner/>
                    {formDefinition && <>
                        <FormDisplay formDefinition={formDefinition}
                                     onSubmit={handleSubmit}/>
                    </>}
                </KatBox>
                <SubmissionDialog/>
            </div>
        </div>
    );
};

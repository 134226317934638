import { KatBox, KatIcon, KatToggle } from "@amzn/katal-react";
import { UserRestrictedComponent } from "src/components/authorization/UserRestrictedComponent";
import { ComponentID } from "src/components/authorization/ComponentRightsMapping";
import React from "react";
import { CopyToClipboardButton } from "src/components/common/CopyToClipboardButton";

/** Provides a quicklink to edit the current help page content in ajuda
 * restricted to render only for users with admin right
 */
export const AjudaPageEditLink = (props: { currentGuid: string; }) => (
    <a href={`https://ajuda.a2z.com/cms.html?repo=GMRQKLMVEA6TDSCA&object=${props.currentGuid}`}
       target='_blank'>Edit in Ajuda <KatIcon name="open_in_new" size="tiny"/>
    </a>
);

export const HelpContentAdminBar = (props: {
    onEditingToggle: () => void,
    currentGuid?: string,
    editing?: boolean,
    textToCopyProvider: () => string
}) => {
    const { onEditingToggle, currentGuid, editing, textToCopyProvider } = props;

    return (
        <UserRestrictedComponent componentId={ComponentID.HEADER_ADMIN_RIGHTS_ID}>
            <KatBox variant='mojito' className='p-0 m-0'>
                <KatToggle className='p-3' textUnchecked='Edit source' textChecked='Preview'
                           onChange={onEditingToggle}/>
                {!editing &&
                    <CopyToClipboardButton label='Copy source' textToCopyProvider={textToCopyProvider}/>
                }
                {currentGuid &&
                    <AjudaPageEditLink currentGuid={currentGuid}/>
                }
            </KatBox>
        </UserRestrictedComponent>
    );
}

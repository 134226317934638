export default class UserMocks {

    static ACCESS_RIGHTS: WFM.AxiomAccessRights = {
        "axiom.ops_leadership.view": false,
        "axiom.auditor.view": false,
        "axiom.admin.view": false,
        "axiom.supervisor.view": false,
        "axiom.power_user.view": false,
        "axiom.basic.view": false,
    };

    static LOGIN = "creigw";

    static GET_USER_INFORMATION_SUP: WFM.GetUserInformation = {
        login: "yvvargas",
        supervisor: true,
        outsourced: false,
        additionalAttributes: {
            email: "deadpool@amazon.com",
            manager: "stan",
            givenName: "wade",
            familyName: "wilson",
            supervisorId: "ajassy",
            supervisorFullName: "Andrew Jassy"
        },
        accessRights: UserMocks.ACCESS_RIGHTS
    };

    static GET_USER_INFORMATION_AGENT: WFM.GetUserInformation = {
        login: UserMocks.LOGIN,
        supervisor: false,
        outsourced: false,
        additionalAttributes: {
            email: "deadpool@amazon.com",
            manager: "stan",
            givenName: "wade",
            familyName: "wilson",
            supervisorId: "ajassy",
            supervisorFullName: "Andrew Jassy"
        },
        accessRights: UserMocks.ACCESS_RIGHTS
    };

    static GET_USER_DETAILS_SUP: WFM.GetUserDetails = {
        login: "yvvargas",
        supervisor: true,
        reports: [
            "creigw",
            "esqua",
            "feja",
            "godjhoel"
        ],
        accessRights: UserMocks.ACCESS_RIGHTS
    };

    static GET_USER_DETAILS_AGENT: WFM.GetUserDetails = {
        login: UserMocks.LOGIN,
        supervisor: false,
        reports: [],
        accessRights: UserMocks.ACCESS_RIGHTS
    };

    static GET_USER_TREATMENT: WFM.GetWeblabTreatment = {
        weblabName: 'mock_weblab_name',
        treatment: 'T1'
    };
}

import moment from 'moment';
import React from 'react';
import { CSVLink } from 'react-csv';
import { AxiomMetricsDriver } from "src/metrics/AxiomMetricsDriver";

const COMPONENT_METRIC_NAME = 'Audit';
interface AuditTableExportProps {
    auditTableData: Cerebrum.Audits.TableData;
    auditMetaData: Cerebrum.Audits.ExportMetaData;
}

/**
 * Creates Data Export link for users to download the given table data
 */
export function AuditTableExport(props: AuditTableExportProps) {
    const { auditType, auditor, selectedTimeFrame } = props.auditMetaData;
    const auditWeek = moment(selectedTimeFrame.toDate).week();
    const filename = `${auditType}_audits_${auditor}_week_${auditWeek}.csv`;

    const dataBuilder = new ExportDataBuilder({ ...props });

    function onClick(e: any) {
        const additionalMetrics: Axiom.AdditionalMetric[] = [
            { name: 'auditType', value: auditType },
            { name: 'auditWeek', value: auditWeek.toString() }
        ];
        AxiomMetricsDriver.publishButtonClick(COMPONENT_METRIC_NAME, 'exportCSV', additionalMetrics);
    }

    return (
        <CSVLink
            data={dataBuilder.csvData}
            filename={filename}
            onClick={onClick}
        > Export+
        </CSVLink>
    );
}

/**
 * Contains the table data export build logic for each audit type
 */
class ExportDataBuilder {

    auditTableData: Cerebrum.Audits.TableData;
    auditType: Cerebrum.Audits.AuditType;

    constructor(props: AuditTableExportProps) {
        this.auditTableData = props.auditTableData;
        this.auditType = props.auditMetaData.auditType;
    }

    public get csvData(): Axiom.Map[] {
        // Each Audit type has its own data builder function to define which columns are included
        const dataBuilderFunctionMap: { [key: string]: () => Axiom.Map[]; } = {
            titus: this.titusBuilder,
            atm: this.cutomerObsessionBuilder,
            falconIntrospection: this.falconIntrospectionBuilder
        };
        return dataBuilderFunctionMap[this.auditType].bind(this)();
    }

    private titusBuilder(): Axiom.Map[] {
        return this.auditTableData.map(row => {
            const rowData = row as Cerebrum.Audits.TitusRowData;
            return {
                agent: rowData.agent,
                agentName: rowData.agentName,
                status: rowData.status,
                externalAuditId: rowData.externalAuditId,
                caseId: rowData.caseId,
                ticketId: rowData.ticketId,
                resolvedDateUtc: rowData.resolvedDateUtc,
                assignedGroup: rowData.assignedGroup,
                rootCause: rowData.rootCause,
                requesterLogin: rowData.requesterLogin,
                resolverGroup: rowData.resolverGroup,
                supervisorId: rowData.supervisorId,
            };
        });
    }

    private cutomerObsessionBuilder(): Axiom.Map[] {
        return this.auditTableData.map(row => {
            const rowData = row as Cerebrum.Audits.CustomerObsessionRowData;
            return {
                agent: rowData.agent,
                agentName: rowData.agentName,
                status: rowData.status,
                externalAuditId: rowData.externalAuditId
            };
        });
    }

    private falconIntrospectionBuilder(): Axiom.Map[] {
        return this.auditTableData.map(row => {
            const rowData = row as Cerebrum.Audits.FalconIntrospectionRowData;
            return {
                agent: rowData.agent,
                agentName: rowData.agentName,
                status: rowData.status,
                externalAuditId: rowData.externalAuditId,
                auditor: rowData.auditor,
                auditStartDate: rowData.associateLogin,
                caseId: rowData.caseId,
                paragonCaseUrl: rowData.paragonCaseUrl,
                actionDate: rowData.actionDate,
                falconRuleId: rowData.falconRuleId,
                falconWikiUrl: rowData.falconWikiUrl,
                auditFormUrl: rowData.auditFormUrl,
                orgName: rowData.orgName,
                caseChannel: rowData.caseChannel,
                l3Atlas: rowData.l3Atlas
            };
        });
    }
}
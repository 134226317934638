import React,{ useEffect, ReactNode } from 'react';
import { FormsService } from '../../service/Forms.service';

type EventMap = {
    [key: string]: (event: MessageEvent) => void 
};

enum FORMS_API_EVENT_TYPE {
    UPDATE_FORM_SUBMISSION = 'UPDATE_FORM_SUBMISSION',
    API_ERROR = 'API_ERROR'
};

const validateHandleUpdateFormArguments = (event: any) => {
    if (!event.formDefinitionName) {
        throw new Error('formDefinitionName is required');
    }
    if (!event.responses) {
        throw new Error('responses is required');
    }
    if (!event.submissionId) {
        throw new Error('submissionId is required');
    }
    if (!event.formSubmissionStatus) {
        throw new Error('formSubmissionStatus is required');
    }
    return true;
};

const handleUpdateForm = async (event: MessageEvent) => {
    const { submissionId, responses, formDefinitionName, formSubmissionStatus, id } = event.data;
    validateHandleUpdateFormArguments(event.data);
    const formDefinition = await FormsService.getLatestFormDefinition(formDefinitionName)
    const formDefinitionId = formDefinition?.body?.formDefinition?.id;
    if (!formDefinitionId) {
        throw new Error('formDefinitionId not found for the supplied name');
    }
    try {
        const response = await FormsService.updateFormSubmission(submissionId, responses, formDefinitionId, formSubmissionStatus);
        if (response) {
            parent.postMessage({ type: FORMS_API_EVENT_TYPE.UPDATE_FORM_SUBMISSION, result: {...response, id: id } }, "*");
        }
    } catch (error) {
        parent.postMessage({ type: FORMS_API_EVENT_TYPE.API_ERROR, message: {error: error, id: id} }, "*");
    }
};

const eventMap: EventMap = {
    [FORMS_API_EVENT_TYPE.UPDATE_FORM_SUBMISSION]: handleUpdateForm
};

interface AxiomFormsWebInterfaceContainerProps {
    children: ReactNode;
}

export const AxiomFormsWebInterfaceContainer = (props: AxiomFormsWebInterfaceContainerProps) => {
    useEffect(() => {
        const handleApiIncomingEvent = (event: MessageEvent) => {
            const handler = eventMap[event.data.type];
            if (event.data && event.data.type) {
                if (handler) {
                    handler(event);
                } else {
                    console.warn(`Unsupported operation: ${event.data.type}, skipping execution`)
                }
                
            }
          };
        
        window.addEventListener('message', handleApiIncomingEvent); 
      
        return () => {
        window.removeEventListener('message', handleApiIncomingEvent);
        };
    }, []);

    return (
    <div>
        {props.children}
    </div>
    );
}

import React, { useState } from "react";
import { KatIcon } from "@amzn/katal-react";

interface CopyToClipboardButtonProps {
    textToCopyProvider: () => string;
    label?: string;
    iconName?: KatIcon.Name;
}

export const CopyToClipboardButton = (props: CopyToClipboardButtonProps) => {

    const { textToCopyProvider, label, iconName } = props;

    const [copiedMessage, setCopiedMessage] = useState('');

    const onCopyClick = () => {
        navigator.clipboard.writeText(textToCopyProvider())
            .then(() => {
                setCopiedMessage(' Copied!');
                setTimeout(() => setCopiedMessage(''), 2000);
            });
    }

    return (
        <a className='p-2 copy-to-clipboard' onClick={onCopyClick}>
            {label}
            <KatIcon name={iconName ?? 'content_copy'} size="tiny"/>
            {copiedMessage}
        </a>);
}

import moment from 'moment';
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { dateToAmazonWeekAsInt } from 'src/components/common/AmazonWeekCalculator';
import { TableMetrics } from "./TableMetrics";
import { CardOptionsMenu } from "src/components/common/CardOptionsMenu";
import { ConfigManager } from "src/config/ConfigManager";


interface MoreActionsDropdownProps {
    rowData: Cerebrum.Audits.RowData;
}

/** This dropdown is rendered within each row of the table
 * and contains any secondary actions that may be needed at the individual audit level
 */
export function MoreActionsDropdown(props: MoreActionsDropdownProps) {
    // Note: Currently only need support for ATMs,
    // if titus features are needed later then logic will be added to make it ambiguous
    return (
        <CardOptionsMenu>
            <ReportInvalidAudit rowData={props.rowData}/>
        </CardOptionsMenu>
    );
}


interface ReportInvalidAuditProps {
    rowData: Cerebrum.Audits.RowData;
}

/** Dropdown item for users to create a SIM regarding an problem with the selection of this audit */
function ReportInvalidAudit(props: ReportInvalidAuditProps) {
    const { rowData } = props;

    const href = new InvalidAuditLinkBuilder({ rowData }).issueUrl;

    const reportInvalidAuditText = "Report Invalid Audit";

    const onClick = (_event: React.MouseEvent) => {
        const additionalMetrics = TableMetrics.buildAdditionalMetrics(rowData);
        additionalMetrics.push({ name: 'caseId', value: rowData.caseId });
        TableMetrics.publishButtonClick('reportInvalid', additionalMetrics);
    };

    return (
        <Dropdown.Item href={href} target="_blank" onClick={onClick}>
            {reportInvalidAuditText}
        </Dropdown.Item>
    );
}


class InvalidAuditLinkBuilder {

    auditType: Cerebrum.Audits.AuditType;
    rowData: Cerebrum.Audits.RowData;

    private baseSimPath = 'https://issues.amazon.com/issues/create';
    private baseSimParams = {
        descriptionContentType: 'text/amz-markdown-sim'
    };

    constructor(props: ReportInvalidAuditProps) {
        this.auditType = props.rowData.auditType;
        this.rowData = props.rowData;
    }

    public get issueUrl() {
        // Each Audit type has its own link builder function to define params required
        const linkBuilderFunctionMap: { [key: string]: () => string; } = {
            titus: this.titusBuilder
        };
        return linkBuilderFunctionMap[this.auditType].bind(this)();
    }

    private titusBuilder() {
        const {
            agent,
            externalAuditId,
            caseId,
            ticketId,
            resolvedDateUtc,
            supervisorId
        } = this.rowData as Cerebrum.Audits.TitusRowData;
        const weekNumber = InvalidAuditLinkBuilder.weekNumberForTitle(new Date(resolvedDateUtc));

        const title = `Invalid Audit Item for Week ${weekNumber} [agent: ${agent}]`;

        const description = InvalidAuditLinkBuilder.trimDescription(
            `**Invalid Audit assignment**
            *AuditID*: ${externalAuditId}
            *CaseID*: ${caseId}
            *TicketID*: ${ticketId}
            *Associate*: ${agent}
            *Supervisor*: ${supervisorId}
            *Ticket Resolve Date*: ${resolvedDateUtc}`
        );

        const assignedFolder = InvalidAuditLinkBuilder.getTitusAssignedFolder();

        return this.buildUrl({ assignedFolder, title, description });
    }

    private static getTitusAssignedFolder(): string {
        // TODO(SUPSUP-977): move definition of assigned folder to tenant config
        const configs = ConfigManager.getStaticConfig();
        if (configs.isProd) {
            switch (configs.realm) {
                case "USAmazon":
                    return "8bb0a3c1-e879-427e-a116-489c0b90522e";
                case "EUAmazon":
                    return "8df32fc4-cbb9-46b5-885b-8f5e5218fdd2";
                case "FEAmazon":
                    return "f66b3924-4357-4c34-b666-a12a02fa6d59";
            }
        }
        return "39275806-1fae-4625-8473-a07e46ea775f";
    }

    /** Removes leading and trailing white spaces from each line */
    private static trimDescription(description: string) {
        return description.split('\n').map(s => s.trim()).join('\n');
    }

    /** Calculates Week Number for SIM title
     *
     * The transaction date for the audits are from the week prior.
     * Thus 1 week is added to correlate with the week it is supposed to be audited in.
     */
    private static weekNumberForTitle(date: Date) {
        return dateToAmazonWeekAsInt(moment(date).add(1, 'weeks').toDate());
    }

    /** Builds url with base path and combines given params with base params  */
    private buildUrl(params: Axiom.Map) {
        const searchParams = new URLSearchParams({ ...params, ...this.baseSimParams });
        return new URL(`${this.baseSimPath}?${searchParams.toString()}`).toString();
    }
}
import { parse } from "src/service/is_it_down_service/response";
import { convertToIsItDown, IsItDown } from "src/service/is_it_down_service/IsItDown.model";

class IsItDownServiceController {
    public async getItIsDown(url: string): Promise<IsItDown> {
        const response = await fetch(url + ".js", {
            credentials: "include"
        });

        if (response == undefined || !response.ok) {
            throw new Error("Network response was not ok.");
        }

        const json = await response.json();
        
        return parse<IsItDown>(json, (json) => convertToIsItDown(json));
    }
}

export const IsItDownService = new IsItDownServiceController();
import { connectPublisher } from "@amzn/katal-hmd-metrics-backend";
import { AxiomMetricsDriver } from "src/metrics/AxiomMetricsDriver";

/**
 * This function integrates the Katal HMD with the existing MetricPublisher
 * 
 * @param username 
 */
export function connectHmdElementToPublisher(username: string) {
    let katHmd: HTMLElement = document.getElementById("hmd-form") as HTMLElement;
    connectPublisher(katHmd, AxiomMetricsDriver.getInitialMetricsPublisher(), publisher => {
        publisher.publishString("user", username);
    });
}
import React, { useEffect, useState } from 'react';
import EventEmitter, { asEventListener } from 'src/context/EventEmitter';
import { CerebrumService } from 'src/service/CerebrumCoralService';
import { getWeekTimeFrame } from "./selector/WeekSelector";
import { KatAlert, KatSpinner } from '@amzn/katal-react';
import { AuditHeader } from './AuditHeader';
import { AuditTableContainer } from './table/AuditTableContainer';
import { AuditIsItDown } from './AuditIsItDown';
import { AuditError } from "src/components/audit/AuditError";
import { CoralError } from '../error/ErrorBoundComponent';
import { AxiomMetricsDriver } from 'src/metrics/AxiomMetricsDriver';
import { uniqueId } from "src/context/UniqueId";
import { useStateWithSearchParams } from "src/components/common/searchParameters/useStateWithSearchParams";

const COMPONENT_NAME = "Audit";

export enum AuditStatusEnum {
    PENDING = 'pending',
    COMPLETED = 'completed'
}


interface AuditProps {
    viewUserLogin: string;
}

export default function Audit(props: AuditProps) {

    const [instanceId] = useState(() => uniqueId(COMPONENT_NAME));

    const auditor = props.viewUserLogin;

    const [error, setError] = useState<CoralError>();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    /**
     * AuditType uses useStateWithSearchParams to allow deep linking/bookmarks
     * It will also not break previous bookmarks or links after migration from existing page
     */
    const [selectedAuditType, setSelectedAuditType] = useStateWithSearchParams('type', '');

    /** selectedWeekIndex will always begin with current week */
    const [selectedWeekIndex, setSelectedWeekIndex] = useStateWithSearchParams('weekIndex', "0");

    const [auditData, setAuditData] = useState<Cerebrum.Audits.GetAuditsOutput>();

    async function fetchAuditData() {
        setAuditData(undefined);
        setIsLoading(true);
        const auditsInput: Cerebrum.Audits.GetAuditsInput = {
            fromDate: getWeekTimeFrame(selectedWeekIndex).fromDate,
            toDate: getWeekTimeFrame(selectedWeekIndex).toDate,
            auditor,
            auditType: selectedAuditType || ''
        };
        const response = await CerebrumService.getAudits(auditsInput).catch(error => {
            handleError(error);
            setIsLoading(false);
        });

        if (response) {
            setAuditData(response);
            setIsLoading(false);
        }
    }

    // Any time the selectors change a new fetch for data is triggered
    useEffect(() => {
        document.title = `${COMPONENT_NAME} - ${auditor}`;
        setAuditData(undefined); // Clears Data and thus doesn't render empty table
        setError(undefined);
        setIsLoading(true);
        if (selectedAuditType) {
            fetchAuditData();
        }
    }, [selectedAuditType, selectedWeekIndex, auditor]);

    // Event listening and cleanup
    const auditErrorListener = asEventListener(instanceId, handleError);
    const completeAuditListener = asEventListener(instanceId, handleCompleteAudit);
    useEffect(() => {
        EventEmitter.subscribe('audit-error-event', auditErrorListener);
        EventEmitter.subscribe('complete-audit-event', completeAuditListener);
        return () => {
            EventEmitter.unSubscribe('complete-audit-event', instanceId);
            EventEmitter.unSubscribe('audit-error-event', instanceId);
        };
    }, []);

    // Publish page load metrics
    useEffect(() => {
        const additionalMetrics: Axiom.AdditionalMetric[] = [{ name: "auditor", value: auditor }];
        AxiomMetricsDriver.publishPageLoad(COMPONENT_NAME, window.location.href, additionalMetrics);
    }, [auditor]);

    /**
     * Update the local audit data state with new audit status to propagate through all components without
     * refetching the data
     */
    function updateAuditStatusState(newStatus: AuditStatusEnum, externalAuditId: string) {

        function updateStatusIfIdMatches(audit: Cerebrum.Audits.AuditItem): Cerebrum.Audits.AuditItem {
            if (audit.externalAuditId === externalAuditId) {
                audit.status = newStatus;
            }
            return audit;
        }

        setAuditData(currentAuditData => ({ audits: currentAuditData!.audits.map(updateStatusIfIdMatches) }));
    }

    async function handleCompleteAudit(payload: { isCompleted: boolean, rowData: Cerebrum.Audits.RowData; }) {
        const { isCompleted, rowData } = payload;
        const completeAuditInput: Cerebrum.Audits.CompleteAuditInput = {
            auditId: rowData.externalAuditId
        };
        const operation = isCompleted ? CerebrumService.completeAudit : CerebrumService.unCompleteAudit;
        const newStatus = isCompleted ? AuditStatusEnum.COMPLETED : AuditStatusEnum.PENDING;

        operation.apply(CerebrumService, [completeAuditInput])
            .then(() => {
                updateAuditStatusState(newStatus, rowData.externalAuditId);
            })
            .catch((error: CoralError) => {
                EventEmitter.dispatch('audit-error-event', error);
            });
    }

    function handleError(error: CoralError) {
        console.error(error);
        setError(error);
        setAuditData(undefined);
    }

    const auditMetaData = {
        auditor,
        auditType: selectedAuditType || '',
        selectedTimeFrame: getWeekTimeFrame(selectedWeekIndex)
    };

    return (
        <div className='p-3'>
            <AuditIsItDown auditType={selectedAuditType || ''}/>
            {auditData && auditData.audits.length == 0 &&
                <KatAlert
                    className="my-1"
                    header={`No ${selectedAuditType} audit data found for this week`}
                    persistent={true}
                    variant="danger"/>
            }
            <>
                <AuditHeader
                    auditor={auditor}
                    selectedAuditType={selectedAuditType || ''}
                    selectedTimeFrame={getWeekTimeFrame(selectedWeekIndex)}
                    timeFrameSelectorDisabled={isLoading}
                    changeWeekIndexHandler={setSelectedWeekIndex}
                    changeAuditTypeHandler={setSelectedAuditType}
                    weekIndex={selectedWeekIndex || "0"}
                />
                <>
                    {isLoading && <div className="row justify-content-center"><KatSpinner size="large"/></div>}
                    {
                        auditData &&
                        <AuditTableContainer
                            auditType={selectedAuditType || ''}
                            auditData={auditData}
                            auditMetaData={auditMetaData}
                            refreshHandler={ fetchAuditData }
                        />
                    }
                </>
                {error && <AuditError error={error}/>}
            </>
        </div>
    );
}

// ROLES
export enum AxiomRole {
    AGENT_ROLE = "axiom-sps-agent",
    SUPERVISOR_ROLE = "axiom-sps-supervisor",
    AUDITOR_ROLE = "axiom-sps-auditor",
    ADMIN_ROLE = "axiom-sps-admin",
    OPS_LEADERSHIP_ROLE = "axiom-sps-opsLeadership",
    POWER_USER_ROLE = "axiom-sps-powerUser",
    MANAGER_ROLE = "axiom-sps-manager",
    SITE_LEADER_ROLE = "axiom-sps-siteLeader",
    FALCON_ROLE = "axiom-sps-falcon",
    CUSTOMER_OBSESSION_ROLE = "axiom-sps-customerObsession"
}
export const isAxiomRole = (s: string) => Object.values(AxiomRole).includes(s as AxiomRole);

// RIGHTS
export enum AxiomRight {
    SUPERVISOR_VIEW = "axiom.supervisor.view",
    BASIC_VIEW = "axiom.basic.view",
    AUDITOR_VIEW = "axiom.auditor.view",
    ADMIN_VIEW = "axiom.admin.view",
    MANAGER_VIEW = "axiom.manager.view",
    OPS_LEADERSHIP_VIEW = "axiom.ops_leadership.view",
    POWER_USER_VIEW = "axiom.power_user.view",
    SITE_LEADER_VIEW = "axiom.siteLeader.view",
    FALCON_VIEW = "axiom.falcon.view",
    CUSTOMER_OBSESSION_VIEW = "axiom.customerObsession.view"
}


// Based on: https://quip-amazon.com/IDfyA036bRNP/Axiom-Permissions-Minimum-Roles-and-Rights-Requirements
// @ts-ignore
export const rolesToRightsMapping: Record<AxiomRole, AxiomRight[]> = {
    [AxiomRole.AGENT_ROLE]: [AxiomRight.BASIC_VIEW],
    [AxiomRole.SUPERVISOR_ROLE]: [AxiomRight.BASIC_VIEW, AxiomRight.SUPERVISOR_VIEW, AxiomRight.AUDITOR_VIEW],
    [AxiomRole.AUDITOR_ROLE]: [AxiomRight.BASIC_VIEW, AxiomRight.AUDITOR_VIEW],
    [AxiomRole.ADMIN_ROLE]: [AxiomRight.BASIC_VIEW, AxiomRight.SUPERVISOR_VIEW, AxiomRight.ADMIN_VIEW],
    [AxiomRole.OPS_LEADERSHIP_ROLE]: [AxiomRight.BASIC_VIEW, AxiomRight.SUPERVISOR_VIEW, AxiomRight.OPS_LEADERSHIP_VIEW],
    [AxiomRole.POWER_USER_ROLE]: [AxiomRight.BASIC_VIEW, AxiomRight.SUPERVISOR_VIEW, AxiomRight.POWER_USER_VIEW],
    [AxiomRole.MANAGER_ROLE]: [AxiomRight.MANAGER_VIEW],
    [AxiomRole.SITE_LEADER_ROLE]: [AxiomRight.SITE_LEADER_VIEW],
    [AxiomRole.FALCON_ROLE]: [AxiomRight.FALCON_VIEW],
    [AxiomRole.CUSTOMER_OBSESSION_ROLE]: [AxiomRight.CUSTOMER_OBSESSION_VIEW],
} as const;
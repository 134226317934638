import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import queryString from "query-string";


/** Wraps react `useState` to sync with url search params.
 *
 * This is useful primarily to allow deeper linking from external sources and allows users to bookmark certain pages more specifically.
 * Unlike useStateWithSearchParams, this hook works as read only, meaning that it won't update the url parameter value at all.
 *
 * @note Because we use Hash router users will not be able to use the back button to revert through the state changes
 *
 * @argument key: the param key which appears in the URL eg. `?key=value`
 */
export function useSearchParamReader(key: string): string | undefined {

    const [value, setValue] = useState<string>();

    const { search } = useLocation();

    useEffect(() => {
        const queryValues = queryString.parse(search);
        const newValue = queryValues[key];
        if (typeof newValue === 'string') {
            setValue(newValue as string);
        }
    }, [search]);

    return value;
}
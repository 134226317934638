import React from 'react';
import { AxiomMetricsDriver } from "src/metrics/AxiomMetricsDriver";

import {
    AssociatesLandingPage,
    AssociatesLandingPageProps
} from "@amzn/wfm-my-performance-ui-components-react"
import i18n from "i18next";
import { CerebrumService } from 'src/service/CerebrumCoralService';
import { UserManager } from 'src/user/UserIdentityManager';
import { useHistory } from "react-router-dom";
import { defaultTenant } from "src/config/definitions";

interface MyPerformanceAssociatesLandingPageContainerProps { };

const myPerformanceRootPath = "/myp";

export function MyPerformanceAssociatesLandingPageContainer(
    props: MyPerformanceAssociatesLandingPageContainerProps
) {
    const history = useHistory();
    const myPerformanceProps: AssociatesLandingPageProps = {
        user: UserManager.userIdentity?.login || "",
        metricsDriver: AxiomMetricsDriver,
        coralService: CerebrumService,
        tenantId: UserManager.tenantId || defaultTenant,
        i18n: i18n.t.bind(i18n),
        viewDetailsCallback: (formType: string, uniqueId: string) => { history.push(`${myPerformanceRootPath}/${formType}/${uniqueId}`) }
    };
    return <AssociatesLandingPage {...myPerformanceProps} />;
};
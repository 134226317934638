import { GenericObject } from "src/@types/extendedPolarisTable";

const AUDIT_GETTERS = {
    getAuditDocumentValue: (attributeName: string, item: any) => {
        return item.auditDocument[attributeName];
    }
}

const CUSTOMER_OBSESSION_GETTERS = {
    source: (item: any) => AUDIT_GETTERS.getAuditDocumentValue('source', item),
    reviewIdentificationDate: (item: any) => AUDIT_GETTERS.getAuditDocumentValue('reviewIdentificationDate', item),
    targetItemId: (item: any) => AUDIT_GETTERS.getAuditDocumentValue('targetItemId', item),
    reviewSubject: (item: any) => AUDIT_GETTERS.getAuditDocumentValue('reviewSubject', item)
}


const FORM_SUBMISSION_GETTERS = {
    createdDateTime: (item: any) => item.createdDateTime
}

const GETTERS: GenericObject = {
    'audit': AUDIT_GETTERS,
    'customerObsession': CUSTOMER_OBSESSION_GETTERS,
    'formSubmission': FORM_SUBMISSION_GETTERS
}

export default GETTERS;
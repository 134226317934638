import React, { useEffect, useState } from "react";
import { NotificationCard } from "src/components/notifications/NotificationCard";
import { KatRow } from "@amzn/katal-react";

interface NotificationsListProps {
    viewUserLogin: string;
    title: string;
    notifications: Cerebrum.Tasks.Notification[];
}

export const NotificationsList: React.FC<NotificationsListProps> = props => {

    const { viewUserLogin, title } = props;

    const [notifications, setNotifications] = useState<Cerebrum.Tasks.Notification[]>(props.notifications);

    useEffect(() => {
        setNotifications(props.notifications);
    }, [props.notifications]);

    const Header = () => (
        <>
            <KatRow>
                <h5>{title}</h5>
            </KatRow>
        </>
    );


    const Content = () => (
        <>
            {notifications.map((notification, index) => {
                return <NotificationCard notification={notification}
                                         viewUserLogin={viewUserLogin}
                                         key={notification.id}
                                         showDivider={index !== notifications.length - 1}/>
            })}
        </>
    );

    return (
        <>
            <Header/>
            <Content/>
        </>);
}

export const encodeToString = (object: any): string => {
    if (typeof object === 'string') return object;
    // Stringify skipping nulls
    return JSON.stringify(object, (k, v) => {
        if (v !== null) return v;
    })
};

export const decodeFromString = (encoded?: string): any => {
    try {
        return JSON.parse(encoded!);
    } catch { // already a string
        return encoded
    }
}

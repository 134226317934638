import { API, graphqlOperation } from "aws-amplify";
import { getFormDefinition, getFormSubmissionById, getLatestFormDefinition } from "src/service/amplify/queries";
import { Entity, FormDefinitionResponse, FormDefinitionsResponse, FormSubmissionFilter, FormSubmissionResponse,
    FormSubmissionSharedSubscriptionVariables, FormSubmissionsResponse, FormSubmissionUpdateResponse, GetFormDefinitionQuery, 
    GetFormDefinitionQueryVariables, GetFormSubmissionByIdQuery, GetFormSubmissionByIdQueryVariables, GetFormSubmissionsBySubmitterQuery, 
    GetFormSubmissionsSharedWithQuery, GetLatestFormDefinitionQuery, GetLatestFormDefinitionQueryVariables, ListAvailableFormDefinitionsQuery, 
    ShareFormSubmissionMutation, ShareFormSubmissionMutationVariables, ShareFormSubmissionResponse, SubmitFormInput, SubmitFormMutation,
    UpdateFormSubmissionInput, UpdateFormSubmissionMutation } from "src/service/amplify/appsync";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { shareFormSubmission, submitForm, unshareFormSubmission, updateFormSubmission } from "src/service/amplify/mutations";
import { UserManager } from "src/user/UserIdentityManager";
import validator from "validator";
import { formSubmissionShared } from "src/service/amplify/subscriptions";
import Observable from 'zen-observable-ts';
import { notFalsy } from "src/components/common/NotFalsy";
import { DateRange } from "src/components/forms/date_range_picker/FormsDateRangePicker";
import { FormSubmissionStatus } from "src/components/forms/constants/FormConstants";
import getFormattedPreferredLanguage from "src/i18n/getFormattedPreferredLanguage";

const FORMS_APPLICATION = "Axiom";
const SPS_TENANT_ID = "1";


type SharedSubmissionSubscriptionResponse = {
    value: {
        data: {
            formSubmissionShared: ShareFormSubmissionResponse
        }
    }
}

export type Unsubscribable = {
    unsubscribe: () => void
}

export class FormsService {
    static async getSubmissionsBySubmitter(userLogin: string, filter?: FormSubmissionFilter) {
        const response: GraphQLResult<GetFormSubmissionsBySubmitterQuery> = (await API.graphql({
            query: `
              query GetFormSubmissionsBySubmitter(
                $getFormSubmissionsBySubmitterInput: GetFormSubmissionsBySubmitterInput!
                $filter: FormSubmissionFilter
              ) {
                getFormSubmissionsBySubmitter(
                  getFormSubmissionsBySubmitterInput: $getFormSubmissionsBySubmitterInput
                  filter: $filter
                ) {
                  statusCode
                  message
                  body {
                    id
                    submitterEntityId
                    responses
                    formDefinitionName
                    formSubmissionStatus
                    formDefinition {
                      language
                      formName
                      displayTitle
                      description
                      version
                      extensions {
                        id
                        type
                        name
                        content {
                          key
                          value
                        }
                      }
                    }
                    targetEntityId
                    createdDateTime
                    lastUpdatedDateTime
                  }
                }
              }
            `,
            variables: {
                getFormSubmissionsBySubmitterInput: FormsService.addUserTenant({
                    submitterEntityId: userLogin
                }),
                filter: filter
            }
        })) as GraphQLResult<GetFormSubmissionsBySubmitterQuery>;
        if (response.data) {
            return response.data.getFormSubmissionsBySubmitter as FormSubmissionsResponse;
        }
    }

    static getSubmittedFormsBySubmitter(userLogin: string, dateRange: DateRange) {
        return FormsService.getSubmissionsBySubmitter(userLogin, FormsService.addUserTenant({
            lastUpdatedDateTimeFrom: dateRange.from,
            lastUpdatedDateTimeTo: dateRange.to,
            formSubmissionStatus: FormSubmissionStatus.SUBMITTED
        }));
    }

    static getDraftSubmissionsBySubmitter(userLogin: string, dateRange: DateRange) {
        return FormsService.getSubmissionsBySubmitter(userLogin, FormsService.addUserTenant({
            lastUpdatedDateTimeFrom: dateRange.from,
            lastUpdatedDateTimeTo: dateRange.to,
            formSubmissionStatus: FormSubmissionStatus.DRAFT
        }));
    }

    static async getSubmissionsSharedWithUser(userLogin: string, dateRange: DateRange) {
        const response: GraphQLResult<GetFormSubmissionsSharedWithQuery> = (await API.graphql({
            query: `
              query GetFormSubmissionsSharedWith(
                $getFormSubmissionSharedWithInput: EntityInput!
                $filter: FormSubmissionFilter
              ) {
                getFormSubmissionsSharedWith(
                  getFormSubmissionSharedWithInput: $getFormSubmissionSharedWithInput
                  filter: $filter
                ) {
                  statusCode
                  message
                  body {
                    id
                    submitterEntityId
                    responses
                    formDefinitionId
                    formDefinitionName
                    formDefinition {
                      id
                      language
                      formName
                      displayTitle
                      status
                      createdDateTime
                      createdBy
                      version
                      extensions {
                        id
                        type
                        name
                        content {
                          key
                          value
                        }
                      }
                    }
                    formSubmissionStatus
                    targetEntityId
                    createdDateTime
                    lastUpdatedDateTime
                  }
                }
              }
            `,
            variables: {
                getFormSubmissionSharedWithInput: {
                    entityId: userLogin
                }, filter: FormsService.addUserTenant({
                    lastUpdatedDateTimeFrom: dateRange.from,
                    lastUpdatedDateTimeTo: dateRange.to
                })
            }
        })) as GraphQLResult<GetFormSubmissionsSharedWithQuery>;

        if (response.data) {
            return response.data.getFormSubmissionsSharedWith as FormSubmissionsResponse;
        }
    }

    static async listAvailableFormDefinitions(
        userLogin: string,
        language: string = getFormattedPreferredLanguage()
    ): Promise<FormDefinitionsResponse | undefined> {
        if (!language) {
            throw new Error('Unable to fetch language preference from local storage');
        }

        const response: GraphQLResult<ListAvailableFormDefinitionsQuery> = (await API.graphql({
            query: `
                  query ListAvailableFormDefinitions(
                    $listAvailableFormDefinitionsInput: ListAvailableFormDefinitionsInput!
                  ) {
                    listAvailableFormDefinitions(
                      listAvailableFormDefinitionsInput: $listAvailableFormDefinitionsInput
                    ) {
                      statusCode
                      message
                      body {
                        id
                        language
                        formName
                        displayTitle
                      }
                    }
                  }
            `,
            variables: {
                listAvailableFormDefinitionsInput: FormsService.addUserTenant({
                    application: FORMS_APPLICATION,
                    language,
                    username: userLogin
                })
            }
        })) as GraphQLResult<ListAvailableFormDefinitionsQuery>;

        if (response.errors || !response.data) {
            throw new Error(`Error getting form definitions: ' ${JSON.stringify(response, null, 2)}`);
        }

        if (response.data && response.data.listAvailableFormDefinitions) {
            return response.data.listAvailableFormDefinitions as FormDefinitionsResponse;
        }
    }

    // If formId is a UUID, it will get that form, if not, it will get the latest version of the formName
    static async getFormDefinition(formIdOrName: string) {
        if (!validator.isUUID(formIdOrName)) {
            return FormsService.getLatestFormDefinition(formIdOrName);
        }

        const queryVariables: GetFormDefinitionQueryVariables = {
            getFormDefinitionInput: {
                id: formIdOrName
            }
        };

        const response: GraphQLResult<GetFormDefinitionQuery> = (await API.graphql({
            query: getFormDefinition,
            variables: queryVariables
        })) as GraphQLResult<GetFormDefinitionQuery>;

        if (response.data && response.data.getFormDefinition) {
            return response.data.getFormDefinition as FormDefinitionResponse;
        }
    }

    static async getLatestFormDefinition(formName: string, language: string = getFormattedPreferredLanguage()) {
        const queryVariables: GetLatestFormDefinitionQueryVariables = {
            getLatestFormDefinitionInput: FormsService.addUserTenant({
                application: FORMS_APPLICATION,
                language,
                formName
            })
        };

        const response: GraphQLResult<GetLatestFormDefinitionQuery> = (await API.graphql({
            query: getLatestFormDefinition,
            variables: queryVariables
        })) as GraphQLResult<GetLatestFormDefinitionQuery>;

        if (response.errors || !response.data) {
            throw new Error(`Error getting form definition: ' ${JSON.stringify(response, null, 2)}`);
        }

        if (response.data && response.data.getLatestFormDefinition) {
            return response.data.getLatestFormDefinition as FormDefinitionResponse;
        }
    }

    static async submitForm(formDefinitionId: string, responses: string, status: string) {

        const submitFormInput: SubmitFormInput = this.addUserTenant({
            application: FORMS_APPLICATION,
            formDefinitionId,
            responses,
            formSubmissionStatus: status
        });

        const response: GraphQLResult<SubmitFormMutation> = (await API.graphql({
            query: submitForm,
            variables: { submitFormInput }
        })) as GraphQLResult<SubmitFormMutation>;

        if (!response || response.errors || !response.data || !response.data?.submitForm) {
            throw new Error(`Error submitting form: ${JSON.stringify(response, null, 2)}`);
        }

        if (response.data && response.data.submitForm) {
            return response.data.submitForm as FormSubmissionResponse;
        }
    }

    static async updateFormSubmission(formSubmissionId: string, responses: any, formDefinitionId: string, formSubmissionStatus: string) {
        const updateFormSubmissionInput: UpdateFormSubmissionInput = this.addUserTenant({
            application: FORMS_APPLICATION,
            formSubmissionId,
            formDefinitionId,
            responses,
            formSubmissionStatus
        });

        const response: GraphQLResult<UpdateFormSubmissionMutation> = (await API.graphql({
            query: updateFormSubmission,
            variables: { updateFormSubmissionInput }
        })) as GraphQLResult<UpdateFormSubmissionMutation>;

        if (!response || response.errors || !response.data || !response.data?.updateFormSubmission) {
            throw new Error(`Error updating form: ${JSON.stringify(response, null, 2)}`);
        }

        if (response.data && response.data.updateFormSubmission) {
            return response.data.updateFormSubmission as FormSubmissionUpdateResponse;
        }
    }

    static async getFormSubmission(formSubmissionId: string) {
        const queryVariables: GetFormSubmissionByIdQueryVariables = {
            getFormSubmissionByIdInput: {
                id: formSubmissionId
            }
        };

        try {
            const response = (await API.graphql({
                query: getFormSubmissionById,
                variables: queryVariables
            })) as GraphQLResult<GetFormSubmissionByIdQuery>;

            if (response.errors || !response.data) {
                throw new Error(`Error getting form definition: ' ${JSON.stringify(response, null, 2)}`);
            }

            if (response.data && response.data.getFormSubmissionById) {
                return response.data.getFormSubmissionById as FormSubmissionResponse;
            }
        } catch (e) {
            // tslint:disable-next-line:no-console
            console.error(e);
            throw new Error(`Error retrieving submission with id '${formSubmissionId}'`);
        }
    }

    static async shareFormSubmission(submissionId: string, login: string) {
        const input: ShareFormSubmissionMutationVariables = {
            shareFormSubmissionInput: {
                formSubmissionId: submissionId,
                entity: {
                    entityId: login
                }
            }
        };

        try {
            const response = (await API.graphql({
                query: shareFormSubmission,
                variables: input
            })) as GraphQLResult<ShareFormSubmissionMutation>;

            if (response.errors || !response.data) {
                throw new Error(`Error sharing form submission: ' ${JSON.stringify(response, null, 2)}`);
            }

            if (response.data && response.data.shareFormSubmission) {
                return response.data.shareFormSubmission as ShareFormSubmissionResponse;
            }
        } catch (e) {
            // tslint:disable-next-line:no-console
            console.error(e);
            throw new Error(`Error sharing submission with '${login}'`);
        }
    }

    static async unShareFormSubmission(submissionId: string, login: string) {
        const input: ShareFormSubmissionMutationVariables = {
            shareFormSubmissionInput: {
                formSubmissionId: submissionId,
                entity: {
                    entityId: login
                }
            }
        };

        try {
            const response = (await API.graphql({
                query: unshareFormSubmission,
                variables: input
            })) as GraphQLResult<ShareFormSubmissionMutation>;

            if (response.errors || !response.data) {
                throw new Error(`Error un-sharing form submission: ' ${JSON.stringify(response, null, 2)}`);
            }

            if (response.data && response.data.shareFormSubmission) {
                return response.data.shareFormSubmission as ShareFormSubmissionResponse;
            }
        } catch (e) {
            // tslint:disable-next-line:no-console
            console.error(e);
            throw new Error(`Error un-sharing submission with '${login}'`);
        }
    }

    static async subscribeToFormSubmissionShared(
        onSubmissionShared: (response: ShareFormSubmissionResponse) => void,
        onError: (errorValue: any) => void,
        submissionId?: string,
        entityId?: string,
    ): Promise<Unsubscribable> {
        try {
            const variables: FormSubmissionSharedSubscriptionVariables = {}
            if (submissionId) variables.formSubmissionId = submissionId
            if (entityId) variables.entityId = entityId;

            return ((API.graphql(
                    graphqlOperation(formSubmissionShared, variables))
            ) as Observable<SharedSubmissionSubscriptionResponse>)
                .subscribe({
                    next: (response => onSubmissionShared(response.value.data.formSubmissionShared)),
                    error: onError
                });
        } catch (e) {
            // tslint:disable-next-line:no-console
            console.error(e);
            throw new Error(`Error subscribing to submission '${submissionId}'`);
        }
    }

    static isSuccessful(formsResponse: any): boolean {
        return formsResponse && formsResponse?.statusCode === 200 && !formsResponse.error;
    }

    static isPartiallySuccessful(formsResponse: any): boolean {
        return formsResponse && formsResponse?.statusCode === 207 && !formsResponse.error;
    }

    static isUnauthorized(formsResponse: any): boolean {
        return formsResponse && formsResponse?.statusCode === 401;
    }

    static isNotFound(formsResponse: any): boolean {
        return formsResponse && formsResponse?.statusCode === 404;
    }

    static addUserTenant(payload: any) {
        // Adding tenant override in case of admins or "0" as per "SPS"
        // eventually we need to resolve the tenant in the backend to prevent unwanted overrides from the UI
        return {
            ...payload,
            "tenantId": UserManager.tenantId || SPS_TENANT_ID
        };
    }

    static extractEntities(response: ShareFormSubmissionResponse): Entity[] {
        return response.body?.entityIds!
            .filter(notFalsy)
            .map(
                (id: string) => ({
                    __typename: "Entity",
                    entityId: id
                })
            ) || [];
    }
}
import { toOptionsArray } from "src/components/common/Enums";
import { KatIcon } from "@amzn/katal-react";

export enum TaskAndNotificationType {
    ACCOLADE = 'Accolade',
    ANNOUNCEMENT = 'Announcement',
    AUDIT = 'Audit',
    BALANCED_SCORECARD = 'BalancedScorecard',
    COACHING = 'Coaching',
    CUSTOMER_OBSESSION = 'CustomerObsession',
    FALCON = 'Falcon',
    FORMS = 'Forms',
    GENERAL = 'General',
    METRIC = 'Metric',
}

export const taskAndNotificationTypeToIconName: Record<TaskAndNotificationType, KatIcon.Name> = {
    [TaskAndNotificationType.ACCOLADE]: 'card_giftcard',
    [TaskAndNotificationType.ANNOUNCEMENT]: 'rss_feed',
    [TaskAndNotificationType.AUDIT]: 'list',
    [TaskAndNotificationType.BALANCED_SCORECARD]: 'explore',
    [TaskAndNotificationType.COACHING]: 'account_circle',
    [TaskAndNotificationType.CUSTOMER_OBSESSION]: 'account_circle',
    [TaskAndNotificationType.FALCON]: 'school',
    [TaskAndNotificationType.FORMS]: 'assignment',
    [TaskAndNotificationType.GENERAL]: 'notifications_active',
    [TaskAndNotificationType.METRIC]: 'trending_up',
} as const;

export const taskTypeOptions = toOptionsArray(TaskAndNotificationType);

import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { ConfigManager } from "src/config/ConfigManager";


/** Wraps react `useState` to read url search params ignoring the config keys defined in UrlParameterConfigKeys. */
export function useNoConfigKeysSearch(): string {

    const [value, setValue] = useState<string>('');

    const { search } = useLocation();

    useEffect(() => {
        const queryValues = queryString.parse(search);
        const noConfigQueryValues = Object.keys(queryValues)
            .filter(key => !ConfigManager.isUrlParameterConfigKey(key))
            .reduce((acc: any, key: string) => {
                acc[key] = queryValues[key];
                return acc;
            },  {});
        const stringified = queryString.stringify(noConfigQueryValues);
        setValue(stringified ? '?' + stringified : '');
    }, [search]);

    return value;
}
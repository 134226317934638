import { AxiomRight, } from "src/config/AxiomCSRights";
import { UserAccessManager } from "src/user/UserAccessManager";

// Component Ids
export enum ComponentID {

    CHARLOTTE_RIGHTS_ID = 'charlotte',
    LANDING_RIGHTS_ID = 'landing',
    ABOUT_RIGHTS_ID = 'about',
    HEADER_BALANCEDSCORECARD_RIGHTS_ID = 'headerBalancedScorecard',
    HEADER_AUDIT_RIGHTS_ID = 'headerAudit',
    HEADER_ADMIN_RIGHTS_ID = 'headerAdmin',
    HEADER_SUPPORT_RIGHTS_ID = 'headerSupport',
    HEADER_SEARCH_RIGHTS_ID = 'headerSearch',
    HOME_BALANCEDSCORECARD_RIGHTS_ID = 'homeBalancedScorecard',
    BSC_DROPDOWN_PHONETOOL_RIGHTS_ID = 'bscDropdownPhoneTool',
    BSC_DROPDOWN_VIEW_RIGHTS_ID = 'bscDropdownView',
    HEADER_FLASHBRIEFING_RIGHTS_ID = 'flashBriefing',
    HEADER_MYPERFORMANCE_RIGHTS_ID = 'myPerformance',
    HEADER_HELP_RIGHTS_ID = 'headerHelp',
    FORMS_RIGHTS_ID = 'forms',
    REVERB_RIGHTS_ID = 'reverb',
    FALCON_RIGHTS_ID = 'falcon',
    SUPERVISOR_REVIEWS_ID = 'titus',
    HEADER_TASKS_RIGHTS_ID = 'headerTasks',
}


class ComponentRightsMapper {

    private componentRightsMap: Record<ComponentID, AxiomRight[]> = {
        [ComponentID.CHARLOTTE_RIGHTS_ID]: [AxiomRight.BASIC_VIEW],
        [ComponentID.LANDING_RIGHTS_ID]: [AxiomRight.BASIC_VIEW, AxiomRight.SUPERVISOR_VIEW],
        [ComponentID.ABOUT_RIGHTS_ID]: [AxiomRight.BASIC_VIEW],
        [ComponentID.HEADER_BALANCEDSCORECARD_RIGHTS_ID]: [AxiomRight.BASIC_VIEW],
        [ComponentID.HOME_BALANCEDSCORECARD_RIGHTS_ID]: [AxiomRight.BASIC_VIEW],
        [ComponentID.HEADER_AUDIT_RIGHTS_ID]: [AxiomRight.AUDITOR_VIEW, AxiomRight.SUPERVISOR_VIEW],
        [ComponentID.HEADER_ADMIN_RIGHTS_ID]: [AxiomRight.ADMIN_VIEW],
        [ComponentID.HEADER_FLASHBRIEFING_RIGHTS_ID]: [AxiomRight.SUPERVISOR_VIEW],
        [ComponentID.HEADER_SEARCH_RIGHTS_ID]: [AxiomRight.SUPERVISOR_VIEW, AxiomRight.AUDITOR_VIEW],
        [ComponentID.HEADER_SUPPORT_RIGHTS_ID]: [AxiomRight.BASIC_VIEW],
        [ComponentID.BSC_DROPDOWN_PHONETOOL_RIGHTS_ID]: [AxiomRight.SUPERVISOR_VIEW],
        [ComponentID.BSC_DROPDOWN_VIEW_RIGHTS_ID]: [AxiomRight.SUPERVISOR_VIEW],
        [ComponentID.HEADER_MYPERFORMANCE_RIGHTS_ID]: [AxiomRight.BASIC_VIEW],
        [ComponentID.HEADER_HELP_RIGHTS_ID]: [AxiomRight.BASIC_VIEW],
        [ComponentID.FORMS_RIGHTS_ID]: [AxiomRight.BASIC_VIEW],
        [ComponentID.REVERB_RIGHTS_ID]: [AxiomRight.BASIC_VIEW],
        [ComponentID.HEADER_TASKS_RIGHTS_ID]: [AxiomRight.BASIC_VIEW],
        /**
         * TODO: Remove these two after enabling Falcon for custom cognito group Falcon_Users instead of auditors.
         */
        [ComponentID.FALCON_RIGHTS_ID]: [AxiomRight.AUDITOR_VIEW, AxiomRight.FALCON_VIEW],
        /**
         * TODO: This should not exist, remove or rename. Since Supervisor Reviews is not a component,
         * it should not be here, but we need to differentiate FALCON (auditors) only users from SUPERVISORS easily for now.
         */
        [ComponentID.SUPERVISOR_REVIEWS_ID]: [AxiomRight.SUPERVISOR_VIEW],
    };

    /** Components that should only be shown to inhouse users regardless of rights */
    private inHouseComponentList = [
        ComponentID.HEADER_MYPERFORMANCE_RIGHTS_ID,
        ComponentID.BSC_DROPDOWN_PHONETOOL_RIGHTS_ID
    ];

    /** Components that should only be shown to OS users regardless of rights  */
    private outsourceComponentList = [
    ];

    /** Components that should only be shown to Admin users regardless of rights */
    private hiddenComponentList = [
        ComponentID.HEADER_ADMIN_RIGHTS_ID,
    ];

    public isComponentForInHouse(component: ComponentID) {
        return !!this.inHouseComponentList.find(item => item === component);
    }

    public isComponentForOutsource(component: ComponentID) {
        return !!this.outsourceComponentList.find(item => item === component);
    }

    public isComponentHidden(component: ComponentID) {
        return !!this.hiddenComponentList.find(item => item === component);
    }

    public userHasAccessRightsForComponent(component: ComponentID) {
        const componentRights = this.componentRightsMap[component] || [];
        return !!componentRights.find(item => UserAccessManager.isUserAuthorizedForRight(item));
    }

    public getRightsForComponent(componentId: ComponentID) {
        return this.componentRightsMap[componentId];
    }
}

export const ComponentRightsManager = new ComponentRightsMapper();
import React, { MutableRefObject, useEffect } from "react";
import { useSearchParamReader } from "src/components/common/searchParameters/useSearchParamReader";

/**
 * Highlights the component referenced by {componentRef} when an url parameter named {paramKey}
 * has the value of {targetValue} by smoothly scrolling it into view
 *
 * @param paramKey the key of the parameter to look up
 * @param targetValue the value to look a match with
 * @param componentRef a reference to the component to scroll to
 * @param onHighlight an optional callback called when the paramValue matches targetValue
 * @param onUnHighlight an optional callback called when the paramValue doesn't match targetValue
 */
export const useHighlighter = (paramKey: string, targetValue: string, componentRef: MutableRefObject<any>,
                               onHighlight?: () => void, onUnHighlight?: () => void) => {

    const paramValue = useSearchParamReader(paramKey);

    const scrollTo = (ref: MutableRefObject<any>) => {
        if (ref && ref.current) {
            ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    useEffect(() => {
        if (paramValue === targetValue) {
            scrollTo(componentRef);
            if (onHighlight) onHighlight();
        } else {
            if (onUnHighlight) onUnHighlight();
        }
    }, [paramValue]);
};
import React from "react";
import { KatIcon, KatPopover } from "@amzn/katal-react";

type AuditStatusFormatterProps = {
    status: Cerebrum.Audits.AuditStatus;
    explicitLabel?: boolean;
};

type IconDetails = {
    iconName: KatIcon.Name;
    color: string;
    label: string;
};

type StatusToIconDetails = {
    [key: string]: IconDetails;
};

const statusToIconDetails: StatusToIconDetails = {
    pending: {
        iconName: "schedule",
        color: "text-black",
        label: "To Be Reviewed",
    },
    completed: {
        iconName: "done",
        color: "text-success",
        label: "Reviewed",
    },
};

export default function AuditStatusFormatter(props: AuditStatusFormatterProps) {
    const { status, explicitLabel } = props;
    const { iconName, color, label } = statusToIconDetails[status];

    return (
        <div className={`${color}`}>
            <KatPopover trigger-type="hover" position="top" variant="tooltip">
                <KatIcon name={iconName} size="small"  slot="trigger"/>
                {label}
            </KatPopover>
            {explicitLabel &&
            <span className="ml-1">{label}</span>}
        </div>
    );
}

import React from 'react';
import { FlashBriefingCard , LoadingCard, ErrorCard} from '@amzn/wfm-axiom-flash-briefing-ui-components';
import CardLoader from './CardLoader';
import { buildFlashBriefingCardProps } from "src/components/flash_briefing/CardLoaderAdapter";

interface FlashBriefingCardLoaderProps {
    entityId: string;
    cardMetadata: Cerebrum.Card;
}

export function FlashBriefingCardLoader(props: FlashBriefingCardLoaderProps) {
    return (
        <CardLoader
            entityId={props.entityId}
            cardMetadata={props.cardMetadata}
            CardComponent={FlashBriefingCard}
            cardAdapterFunction={buildFlashBriefingCardProps}
            LoadingCardComponent={LoadingCard}
            ErrorCardComponent={ErrorCard} />
    )
}
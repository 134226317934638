import React, { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { loadHelpContentPage } from "src/components/support/HelpContentHelper";
import { useNavigationStack } from "src/components/utils/useNavigationStack";
import { ConfigManager } from "src/config/ConfigManager";
import { UserContext } from "src/context/UserContext";

interface HelpPageData {
    pageGuid: string;
    pageName?: string;
}

interface HelpPageMapping {
    [componentName: string]: HelpPageData;
}

export interface HelpContextOperations {
    setContextualHelpForComponent: (componentName: string) => void;
    navigateForward: () => void;
    navigateBackward: () => void;
}

export interface HelpContextData {
    contextualMappingPageId: string;
    currentContextualHelpId?: string;
    canNavigateForward: boolean;
    canNavigateBack: boolean;
    operations: HelpContextOperations;
}

const initalState: HelpContextData = {
    contextualMappingPageId: "",
    currentContextualHelpId: "",
    canNavigateBack: false,
    canNavigateForward: false,
    operations: {
        setContextualHelpForComponent: () => {},
        navigateForward: () => {},
        navigateBackward: () => {}
    }
};

export const HelpContext = createContext(initalState);

export const HelpContextProvider = ({ children }: { children: ReactNode }) => {
    const [availableContextualHelpPages, setContextualAvailableHelpPages] = useState<HelpPageMapping>();
    const [currentComponentName, setCurrentComponentName] = useState<string>("");
    const [navigationCurrent, canNavigateBack, canNavigateForward, goBack, goForward, navigationPush] =
        useNavigationStack<string>();

    // TODO: move definition of mappingPageId to tenant config (SUPSUP-977) and add actual prod version
    const contextualMappingPageId = ConfigManager.getStaticConfig().isProd ? "GYALZBF87ULWMZMP" : "GYALZBF87ULWMZMP";

    useEffect(() => {
        const loadAvailableHelpPages = (pageId: string) => {
            loadHelpContentPage(pageId)
                .then((helpContent: Cerebrum.HelpContent) => {
                    const availableHelpPages: HelpPageMapping = JSON.parse(helpContent.content.replace(/\n/g, ""));
                    setContextualAvailableHelpPages(availableHelpPages);
                    pushNewHelpPageToStack(currentComponentName);
                })
                .catch((error) => {
                    // tslint:disable-next-line:no-console
                    console.log(`Error loading mapping file ${error}`);
                    setContextualAvailableHelpPages({});
                });
        };

        loadAvailableHelpPages(contextualMappingPageId);
    }, []);

    const pushNewHelpPageToStack = (helpPageName: string) => {
        if (availableContextualHelpPages) {
            const helpPage: HelpPageData = availableContextualHelpPages[helpPageName];
            const helpPageId = helpPage?.pageGuid ?? helpPageName;
            if (navigationCurrent !== helpPageId) {
                navigationPush(helpPageId);
            }
        }
    };

    useEffect(() => {
        pushNewHelpPageToStack(currentComponentName);
    }, [availableContextualHelpPages, currentComponentName]);

    const operations: HelpContextOperations = {
        setContextualHelpForComponent: setCurrentComponentName,
        navigateForward: goForward,
        navigateBackward: goBack
    };

    return (
        <HelpContext.Provider
            value={{
                contextualMappingPageId,
                currentContextualHelpId: navigationCurrent,
                canNavigateBack,
                canNavigateForward,
                operations
            }}
        >
            {children}
        </HelpContext.Provider>
    );
};

export interface SetHelpPageProps {
    componentName: string;
}

export function SetHelpPageFunction(props: SetHelpPageProps) {
    const { componentName } = props;

    const helpContext = useContext(HelpContext);
    const userContext = useContext(UserContext);

    useEffect(() => {
        if (userContext.userManager.isTestUser) {
            // only for admins at the moment
            helpContext.operations.setContextualHelpForComponent(props.componentName);
        }
    }, [componentName]);

    return <></>;
}

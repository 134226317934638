import { UserManager } from "src/user/UserIdentityManager";

interface DiveLinkProps {
    diveData: Cerebrum.RecommendationTableData;
}

abstract class DiveLink {
    public readonly diveData: Cerebrum.RecommendationTableData;

    constructor(props: DiveLinkProps) {
        this.diveData = props.diveData;
    }

    public get regionId() {
        const REGION_ID_MAP: Axiom.Map = {
            1: 'na',
            2: 'eu',
            3: 'fe',
        };
        // Paragon Case region can be found using the last digit of the case_id
        const caseIdLastDigit = this.diveData.caseIdList[0].slice(-1);
        return REGION_ID_MAP[caseIdLastDigit];
    }

    public abstract getUrl(): string;
}

class ParagonDiveLink extends DiveLink {

    constructor(props: DiveLinkProps) {
        super(props);
    }

    private get caseListParam() {
        return this.diveData.caseIdList.join(' OR ');
    }

    private get searchParams() {
        const params: Axiom.Map = {
            searchQuery: this.caseListParam,
            sortField: 'creationDate',
            sortOrder: 'desc'
        };
        let paramList = [];
        for (const param in params) {
            paramList.push(`${param}=${params[param]}`);
        }
        return paramList.join('&');
    }

    public getUrl() {
        return `https://paragon-${this.regionId}.amazon.com/hz/dox-search?${this.searchParams}`;
    }
}

/** Builder for Dive Links to Quicksight 
 * 
 * example full link `https://us-east-1.quicksight.aws.amazon.com/sn/dashboards/a76a88d1-523a-4906-ae1f-82b50f9f95a8#p.AssociateLogin=johnsonbermundo_abg&p.Skill=AmazonUsTamProFbaEmail&p.Channel=WORKITEM&Metric=acht`
 * 
 * @see https://sim.amazon.com/issues/SeSuAnalytics-11316
 */
class QuickSightDiveLink extends DiveLink {
    private readonly qsOrigin = 'https://us-east-1.quicksight.aws.amazon.com';
    private readonly qsPath = '/sn/dashboards/eb02c75b-c562-4b58-8d3c-e9aed7b5d1b2';

    private readonly mediaActionMetrics = ['paa', 'pma', 'trans'];

    constructor(props: DiveLinkProps) {
        super(props);
    }

    private get mediaAction() {
        const metric = this.diveData.metric;
        if (metric == 'trans') { return 'TRANSFER'; }
        return metric.toUpperCase();
    }

    private get searchParams() {
        const params: Axiom.Map = {
            "p.AssociateLogin": this.diveData.agentId,
            "p.Skill": this.diveData.skill,
            "p.Channel": this.diveData.channel,
            "p.Metric": this.diveData.metric,
        };
        // Certain metrics need specific params
        if (this.mediaActionMetrics.includes(this.diveData.metric)) {
            params["p.MediaAction"] = this.mediaAction;
        }
        if (this.diveData.metric == 'ro') {
            params["p.IsReopen"] = '1';
        }
        return new URLSearchParams(params);
    }

    public getUrl() {
        // NOTE: params for QS are only prepended by `#` and do not use the standard `?`,
        return `${this.qsOrigin}${this.qsPath}#${this.searchParams.toString()}`;
    }
}

export class DiveLinkManager extends DiveLink {

    constructor(props: DiveLinkProps) {
        super(props);
    }

    private get diveLink() {
        // OS Agents should only be eligible for Paragon Link 
        if (UserManager.isOutSourced && UserManager.isAgent) {
            return new ParagonDiveLink({ diveData: this.diveData });
        }

        //  All remaining users receive a Quicksight Link
        return new QuickSightDiveLink({ diveData: this.diveData });
    }

    public getUrl() {
        return this.diveLink.getUrl();
    }
}




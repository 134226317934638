import React, { PropsWithChildren, ReactNode, useContext, useEffect, useState } from "react";
import { LayoutContext } from "src/components/layout/LayoutContext";
import { Banner } from "../common/banner/Banner";
import { BannerContext } from "./BannerContext";

interface MainContainerProps {
    children?: ReactNode
}

export const MainContainer = (props: PropsWithChildren<MainContainerProps>) => {
    const { children } = props;
    
    return (
        <LayoutContext.Consumer>
            {
                layout => {
                    return ( 
                        <div className={`flex-column ${layout.isSidebarPinned && layout.isSidebarVisible ? "w-75" : "w-100"}`}>
                            <Banner/>
                            <div className={`main ${layout.isBannerVisible ? 'with-banner' : ''}`}>
                                {children}
                            </div>
                        </div>
                    )
                }
            }
        </LayoutContext.Consumer>
    );
}
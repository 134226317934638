import i18n from "i18next";
import {
    AlertCardContentProps,
    cardTypes,
    SingleDataPointContentProps,
    TabularDataPointContentProps,
    KPICardProps,
    KPIVariants,
    FlashBriefingCardProps,
    InfoCardContentProps,
    QuicksightCardProps,
    Variants,
} from "@amzn/wfm-axiom-flash-briefing-ui-components";
import { CerebrumService } from "src/service/CerebrumCoralService";

export function getCardMetadataProps(cardMetadata: Cerebrum.CardMetadata): Cerebrum.CardMetadata {
    return {
        ...cardMetadata,
        cardTitle: i18n.t(cardMetadata.cardTitle)
    }
}

export function getCardContentProps(cardContent: Cerebrum.CardContent, cardType: cardTypes, entityId: string, cardId: string) {
    const { actionTitle, cardSubtitle } = cardContent
    let contentProps = {
        ...(actionTitle  && actionTitle !== '0' && { actionTitle: i18n.t(actionTitle) }),
        ...(cardSubtitle  && cardSubtitle !== '0' && { cardSubtitle: i18n.t(cardSubtitle) })
    };
    switch (cardType) {
        case cardTypes.singleDataPoint:
            contentProps = {
                ...contentProps, ...singleDataPointTranslatedProps(
                    cardContent as Cerebrum.SingleDataPointContent)
            };
            break;
        case cardTypes.tabularDataPoint:
            contentProps = {
                ...contentProps, ...tabularDataPointTranslatedProps(
                    cardContent as Cerebrum.TabularDataPointContent)
            };
            break;
        case cardTypes.alert:
            contentProps = {
                ...contentProps, ...alertTranslatedProps(
                    cardContent as Cerebrum.AlertContent)
            };
            break;
        case cardTypes.infoCard:
            contentProps = {
                ...contentProps, ...infoContentTranslatedProps(
                    cardContent as Cerebrum.InfoCardContent, entityId, cardId)
            };
            break;
        case cardTypes.quicksight:
            contentProps = {
                ...contentProps, ...quicksightTranslatedProps(
                    cardContent as Cerebrum.QuicksightCardContent)
            };
            break;
        default:
            break;
    }
    return contentProps;
}

export function singleDataPointTranslatedProps(cardContent: Cerebrum.SingleDataPointContent):
    SingleDataPointContentProps {
    return {
        dataPoint: cardContent.dataPoint,
        dataPointLabel: i18n.t(cardContent.dataPointLabel),
        dataPointDescription: i18n.t(cardContent.dataPointDescription)
    }
}

export function tabularDataPointTranslatedProps(cardContent: Cerebrum.TabularDataPointContent):
    TabularDataPointContentProps {
    let parsedRows = JSON.parse(cardContent.rows);
    return {
        rows: parsedRows.map((row: [string, string]) => [row[0], i18n.t(row[1])])
    }
}

export function alertTranslatedProps(cardContent: Cerebrum.AlertContent): AlertCardContentProps {
    return {
        alertMessage: i18n.t(cardContent.alertMessage),
        description: i18n.t(cardContent.description)
    }
}

export function quicksightTranslatedProps(cardContent: Cerebrum.QuicksightCardContent): QuicksightCardProps {
    const {
        previewUrl,
        cardTitle,
        style,
        cardSubtitle,
        actionUrl,
        actionTitle,
        titleLinkHref,
        titleLinkString
    } = cardContent

    return {
        previewUrl,
        cardTitle,
        style,
        ...(actionUrl && actionUrl !== '0' && { actionUrl }),
        ...(actionTitle && actionTitle !== '0' && { actionTitle }),
        ...(cardSubtitle && cardSubtitle !== '0' && { cardSubtitle }),
        ...(titleLinkHref && titleLinkHref !== '0' && { titleLinkHref }),
        ...(titleLinkString && titleLinkString !== '0' && { titleLinkString })
    }
}

export function infoContentTranslatedProps(cardContent: Cerebrum.InfoCardContent, entityId: string, cardId: string): InfoCardContentProps {
    let parsedRows = JSON.parse(cardContent.infoItems);
    let parsedActionLinkFlag = cardContent.actionLinkAbove ? JSON.parse(cardContent.actionLinkAbove) : false;
    return {
        infoItems: parsedRows.map((item: Cerebrum.InfoCardContentItem) => ({
            date: item.date,
            readMoreUrl: item.readMoreUrl,
            readMoreLabel: i18n.t(item.readMoreLabel),
            infoCardContentMetric: item.infoContentMetric ? item.infoContentMetric : false,
            templateName: item.templateName,
            metric: item.metric
        })),
        actionLinkAbove: parsedActionLinkFlag,
        getNarrativeTemplateCallback: CerebrumService.getNarrativeTemplate.bind(CerebrumService),
        entityId: entityId,
        cardId: cardId
    }
}

export function kpiContentTranslatedProps(cardContent: Cerebrum.KPIContent): KPICardProps {
    let tableContent = JSON.parse(cardContent.tableContent);
    tableContent.actionLabel = i18n.t(tableContent);
    return tableContent;
}

export function buildFlashBriefingCardProps(card: Cerebrum.Card): FlashBriefingCardProps {
    const cardType = convertCardType(card.cardType);
    const cardVariant = card.cardContent?.cardVariant ? convertCardVariant(card.cardContent.cardVariant) : undefined;
    return {
        ...getCardMetadataProps(card),
        ...getCardContentProps(card.cardContent!, cardType, card.entityId, card.cardId),
        cardType: cardType,
        cardVariant: cardVariant
    }
}

function convertCardType(cardType: string): cardTypes {
    return cardTypes[cardType as keyof typeof cardTypes];
}

function convertCardVariant(cardVariant: string): Variants {
    return Variants[cardVariant as keyof typeof Variants];
}

export function KPICardAdapterFunction(card: Cerebrum.Card): KPICardProps {
    let cardContent = card.cardContent as Cerebrum.KPIContent;
    return {
        ...getCardMetadataProps(card),
        ...cardContent,
        tableContent: JSON.parse(cardContent.tableContent),
        variant: convertKPIVariant(cardContent.variant),
    }
}

function convertKPIVariant(variant: string): KPIVariants {
    return KPIVariants[variant as keyof typeof KPIVariants];
}
